import React, { useState, useEffect, useRef, useCallback } from "react";

import { useSubheader } from "../../../_metronic/layout";

import {
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import { Button, Spinner, Tabs, Tab } from "react-bootstrap";

import ModalError from "../../components/ModalError";
import useMenu from "../../hooks/menu";
import api from "../../services/Api";
import { api as ApiUrl } from "../../services/ApiURL";
import { useHistory, useParams } from "react-router";
import { Profile } from "../../types/Profile";
import { Autocomplete } from "@material-ui/lab";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import PermissionMenuSelector from "../../components/PermissionMenuSelector";
import { sanitizeInput, SanitizeInputType } from "../../utils/sanitizeInput";
import { yesOrNo } from "../../types/yesOrNo";
import useCompany from "../../hooks/company";
import { useDispatch, useSelector } from "react-redux";
import * as auth from "../../modules/Auth";
import logService from "../../services/LogService";
import CompanyService from "../../services/CompanyService";
import { formatCpfCnpjDynamic } from "../../utils/formatCpfCnpj";
import { useCompanyBranch } from "../../hooks/companyBranch";
import {
  submenu,
  useCustomerPermissionHandler,
} from "../../hooks/customPermissionHandler";
import UserService from "../../services/UserService";
import useAllUf from "../../hooks/uf";
import { CompanyAccountant } from "../../types/CompanyAccountant";
import { cnpjSearch } from "../../utils/cnpjSearch";
import { addressSearch } from "../../utils/addressSearch";
import { CompanyFormAccountant } from "../../components/CompanyForm/CompanyFormAccountant";
import { PatternFormat } from "../../components/PatternFormat";
import {
  ExtraPermissionsMapped,
  PermissionMenu,
} from "../../types/PermissionMenu";
import { useStyles } from "../../hooks/styles";
import PermissionMenuService from "../../services/PermissionMenuService";
import ModalSuccess from "../../components/ModalSuccess";
import CustomButton from "../../components/DS/Button";

type Branch = {
  checked: boolean;
  data: any;
  id: number;
};

export function EditUser() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const history = useHistory();

  const classes = useStyles();

  const subHeader = useSubheader();
  subHeader.setTitle("Editar Usuário");

  const { user } = useSelector((state: any) => state.auth);

  const { companies } = useCompany({
    only: user.companyId,
    appendSubscriptionPlan: true,
  });
  const [company] = companies;
  const { selectedCompany } = useCompanyBranch();

  const [activeTab, setActiveTab] = useState("details");

  //   console.log("user@@#", user);
  //   console.log("companies@@#", companies);
  /* Photo */
  const imgFileInput = useRef<HTMLInputElement>(null);
  const [photo, setPhoto] = useState("");
  const [savedPhoto, setSavedPhoto] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [cell, setCell] = useState("");
  const [email, setEmail] = useState("");
  const [isAccountant, setIsAccountant] = useState(false);

  /* Permissoes */
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [profileId, setProfileId] = useState<number>();
  const { menus } = useMenu();
  const {
    permissionMenus,
    setPermissionMenus,
    handleChangePermission,
    menuSystem,
    setMenuSystem,
    handleChangePermissionFastAccess,
  } = useCustomerPermissionHandler();
  const [permissionsAccountant, setPermissionsAccountant] = useState<any>([]);
  const [fastAccessAccountant, setfastAccessAccountant] = useState<any>([]);
  const [
    originalAccountantBranchesIds,
    setOriginalAccountantBranchesIds,
  ] = useState<number[]>([]);

  const [savedPermissions, setSavedPermissions] = useState<Array<number> | any>(
    []
  );
  const [savedExtraMenuPermissions, setSavedExtraMenuPermissions] = useState<
    ExtraPermissionsMapped[] | any
  >([]);
  const [savedFastAccess, setSavedFastAccess] = useState<Array<number> | any>(
    []
  );
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);

  const [canAccessMainCompany, setCanAccessMainCompany] = useState<yesOrNo>(
    yesOrNo.YES
  );
  const [userBranches, setUserBranches] = useState<Branch[]>([]);
  const [userCompany, setUserCompany] = useState<any>();

  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [firstnameInvalid, setFirstnameInvalid] = useState(false);
  const [cellInvalid, setCellInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [loginInvalid, setLoginInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const [beforeSubmitData, setBeforeSubmitData] = useState<Object>({});

  //Dados adicionais de contador
  const [companyAccountantInfoId, setCompanyAccountantInfoId] = useState(0);
  const [accountantCpf, setAccountantCpf] = useState("");
  const [accountantCnpj, setAccountantCnpj] = useState("");
  const [accountantCrc, setAccountantCrc] = useState("");
  const [accountantPhone, setAccountantPhone] = useState("");
  const [accountantZipCode, setAccountantZipCode] = useState("");
  const [accountantAddress, setAccountantAddress] = useState("");
  const [accountantNumber, setAccountantNumber] = useState("");
  const [accountantDistrict, setAccountantDistrict] = useState("");
  const [accountantComplement, setAccountantComplement] = useState("");
  const [accountantCity, setAccountantCity] = useState("");
  const [accountantState, setAccountantState] = useState("");
  const [accountantNameHasError, setAccountantNameHasError] = useState(false);
  const [accountantEmailHasError, setAccountantEmailHasError] = useState(false);
  const [accountantCellHasError, setAccountantCellHasError] = useState(false);
  const [accountantCpfHasError, setAccountantCpfHasError] = useState(false);
  const [accountantCnpjHasError, setAccountantCnpjHasError] = useState(false);
  const { allStates } = useAllUf();

  useEffect(() => {
    if (!selectedCompany) {
      return;
    }

    async function loadData() {
      const response = await api.get(`users/${id}`, {
        params: { includeCompanyAccountantInfo: true },
      });
      const data = response.data;

      setFirstname(data.firstname ?? "");
      setLastname(data.lastname ?? "");
      setEmail(data.email ?? "");
      setCell(data.cell ?? "");
      setIsAccountant(data.isAccountant == "y" ? true : false);
      setProfileId(data.userProfileId ?? undefined);
      setLogin(data.login ?? "");

      if (data.isAccountant == "y") {
        var permissionsAccountant = JSON.parse(data.permissions).find(
          (permission: any) => permission.companyId == user.companyId
        );
        var fastAccessAccountant = JSON.parse(data.fastAccess).find(
          (fastAccess: any) => fastAccess.companyId == user.companyId
        );

        setSavedPermissions(JSON.parse(permissionsAccountant.permissions));
        setSavedFastAccess(JSON.parse(fastAccessAccountant.fastAccess));

        setPermissionsAccountant(JSON.parse(data.permissions));
        setfastAccessAccountant(JSON.parse(data.fastAccess));
      } else {
        setSavedPermissions(JSON.parse(data.permissions));
        setSavedExtraMenuPermissions(
          JSON.parse(data.extraMenuPermissions ?? "[]")
        );
      }

      setBeforeSubmitData(data);

      if (data.photo) {
        setPhoto(`${ApiUrl}/${data.photo}`);
        setSavedPhoto(data.photo);
      }

      setCanAccessMainCompany(data.canAccessMainCompany);

      setUserCompany(await CompanyService.getCompanyById(user.companyId));

      const allBranches = await CompanyService.getCompanyBranches(
        user.companyId
      );

      const userBranchesIds: any[] = data.branchesIds
        ? JSON.parse(data.branchesIds)
        : [];

      setUserBranches(
        allBranches.map((branch: any) => {
          return {
            id: branch.id,
            checked: userBranchesIds.includes(branch.id),
            data: branch,
          };
        })
      );

      setOriginalAccountantBranchesIds(userBranchesIds);

      // Informações adicionais de contador
      const companyAccountantInfos: CompanyAccountant[] =
        data.companyAccountantInfos ?? [];
      const companyAccountantInfo = companyAccountantInfos.find(
        (info) => info.companyId === selectedCompany.id
      );

      if (companyAccountantInfo) {
        setCompanyAccountantInfoId(Number(companyAccountantInfo.id));
        setAccountantCpf(companyAccountantInfo.cpf);
        setAccountantCnpj(companyAccountantInfo.cnpj);
        setAccountantCrc(companyAccountantInfo.crc);
        setAccountantPhone(companyAccountantInfo.phone);
        setAccountantZipCode(companyAccountantInfo.zipCode);
        setAccountantAddress(companyAccountantInfo.address);
        setAccountantNumber(companyAccountantInfo.number);
        setAccountantDistrict(companyAccountantInfo.district);
        setAccountantComplement(companyAccountantInfo.complement);
        setAccountantCity(companyAccountantInfo.city);
        setAccountantState(companyAccountantInfo.state);
      }
    }

    loadData();
  }, [selectedCompany]);

  useEffect(() => {
    async function getMenusSystem() {
      const menus = await api.get("/menus");

      let fastAccess: any = {};

      if (isAccountant) {
        fastAccess = savedPermissions;
      } else {
        const response = await api.get(`users/${id}`);
        fastAccess = JSON.parse(response.data.fastAccess);
      }

      var header = menus.data
        .filter((menuHeader: any) => menuHeader.menuId == null)
        .sort((data1: any, data2: any) => {
          return data1.order - data2.order;
        });

      var menusArr: any = [];
      header.map(async (menuHeader: any, indexHeader: number) => {
        var moduleArr = {
          id: menuHeader.id,
          menuId: menuHeader.menuId,
          name: menuHeader.name,
          checked: fastAccess
            ? fastAccess.find((moduleId: number) => moduleId == menuHeader.id)
            : false,
          subMenu: [],
        };

        var submenu1Data = menus.data
          .filter((submenu1: any) => submenu1.menuId == menuHeader.id)
          .sort((data1: any, data2: any) => {
            return data1.order - data2.order;
          });

        var submenuArr: any = [];
        submenu1Data.map(async (submenu1Data: any, indexSubmenu1: number) => {
          var submenu1Arr = {
            id: submenu1Data.id,
            menuId: submenu1Data.menuId,
            name: submenu1Data.name,
            checked: fastAccess
              ? fastAccess.find(
                  (moduleId: number) => moduleId == submenu1Data.id
                )
              : false,
            subMenu: [],
          };

          var submenu2Arr: any = [];
          menus.data
            .filter(
              (submenu2Data: any) => submenu2Data.menuId == submenu1Data.id
            )
            .map(async (submenu2Data: any, indexSubmenu2: number) => {
              submenu2Arr.push({
                id: submenu2Data.id,
                menuId: submenu2Data.menuId,
                name: submenu2Data.name,
                checked: fastAccess
                  ? fastAccess.find(
                      (moduleId: number) => moduleId == submenu2Data.id
                    )
                  : false,
              });
            });

          submenu1Arr = {
            ...submenu1Arr,
            subMenu: submenu2Arr,
          };

          submenuArr.push(submenu1Arr);
        });

        moduleArr = {
          ...moduleArr,
          subMenu: submenuArr,
        };
        menusArr.push(moduleArr);
      });

      setMenuSystem(menusArr);
    }

    getMenusSystem();
  }, [fastAccessAccountant, isAccountant]);

  useEffect(() => {
    async function getProfiles() {
      const { data } = await api.get<Profile[]>("userProfiles");
      let administrator: Profile[] = [
        {
          id: 0,
          name: "Administrador",
          permissions: company ? company.permissions : "[]",
          extraMenuPermissions: JSON.stringify(
            PermissionMenuService.getMenuExtraPermissionsFromMenuPermissions(
              company ? company.permissions : "[]"
            )
          ),
          visible: yesOrNo.YES,
          createdDate: new Date(),
          updatedDate: new Date(),
        },
      ];

      let accountant: Profile[] = [
        {
          id: 0.1,
          name: "Contador",
          permissions: company ? company.permissions : "[]",
          extraMenuPermissions: "[]",
          visible: yesOrNo.YES,
          createdDate: new Date(),
          updatedDate: new Date(),
        },
      ];

      var allProfiles = [...administrator, ...accountant, ...data];
      setProfiles(allProfiles);
    }

    getProfiles();
  }, [company]);

  useEffect(() => {
    if (!menus.length) return;
    if (!user) return;
    if (!company) return;

    const companyPermissions: Array<number> = JSON.parse(company.permissions);
    const filtered = PermissionMenuService.filterCompanyPermissionMenu(
      menus as PermissionMenu[],
      companyPermissions
    );
    let permissions = PermissionMenuService.mapPermissionsChecked(
      savedPermissions,
      filtered
    );
    permissions = PermissionMenuService.mapMenuExtraPermissionsChecked(
      savedExtraMenuPermissions,
      permissions
    );

    setPermissionMenus([...permissions]);
  }, [menus, user, company, savedPermissions, savedExtraMenuPermissions]);

  async function inputsVerify(): Promise<boolean> {
    setFirstnameInvalid(false);
    setCellInvalid(false);
    setEmailInvalid(false);
    setLoginInvalid(false);
    setPasswordInvalid(false);

    if (!firstname) {
      setIsSubmit(false);
      setMsgError("Preencha o nome corretamente");
      setShowModalError(true);
      setFirstnameInvalid(true);
      return false;
    }
    if (!cell) {
      setActiveTab("details");
      setIsSubmit(false);
      setMsgError("Preencha o celular corretamente");
      setShowModalError(true);
      setCellInvalid(true);
      return false;
    }

    // const cellExists = await UserService.verifyCellExists(cell, id);
    // if(cellExists) {
    //     setActiveTab("details");
    //     setIsSubmit(false)
    //     setMsgError("Celular já existe");
    //     setShowModalError(true);
    //     setCellInvalid(true);
    //     return false;
    // }

    if (!email) {
      setActiveTab("details");
      setIsSubmit(false);
      setMsgError("Preencha o e-mail corretamente");
      setShowModalError(true);
      setEmailInvalid(true);
      setEmailInvalid(true);
      return false;
    }

    const emailExists = await UserService.verifyEmailExists(email, id);
    if (emailExists) {
      setActiveTab("details");
      setIsSubmit(false);
      setMsgError("E-mail já existe");
      setShowModalError(true);
      setEmailInvalid(true);
      return false;
    }

    // if(!isAccountant && !login) {
    //     setActiveTab("details");
    //     setIsSubmit(false)
    //     setMsgError("Preencha o Login corretamente")
    //     setShowModalError(true);
    //     setLoginInvalid(true);
    //     return false;
    // }

    // const loginExists = await UserService.verifyLoginExists(isAccountant ? email : login, id);
    // if(loginExists) {
    //     setActiveTab("details");
    //     setIsSubmit(false)
    //     setMsgError(`O ${isAccountant ? 'e-mail' : 'login'} digitado não pode ser usado, informe um ${isAccountant ? 'e-mail' : 'login'} diferente`);
    //     setShowModalError(true);
    //     setLoginInvalid(true);
    //     return false;
    // }

    if (changePassword && !password) {
      setActiveTab("details");
      setIsSubmit(false);
      setMsgError("Preencha a senha corretamente");
      setShowModalError(true);
      setPasswordInvalid(true);
      return false;
    }

    return true;
  }

  function verifyAccountantAdditionalInfo() {
    if (
      accountantCpf &&
      sanitizeInput(SanitizeInputType.NUMERIC, accountantCpf).length !== 11
    ) {
      setAccountantCpfHasError(true);
      setActiveTab("details");
      setMsgError("CPF do Contador é inválido");
      return false;
    }

    if (
      accountantCnpj &&
      sanitizeInput(SanitizeInputType.NUMERIC, accountantCnpj).length !== 14
    ) {
      setAccountantCnpjHasError(true);
      setActiveTab("details");
      setMsgError("CNPJ do Contador é inválido");
      return false;
    }

    return true;
  }

  function handleUploadClick() {
    if (imgFileInput && imgFileInput.current) {
      imgFileInput.current.click();
    }
  }

  const handleChangeProfile = useCallback(
    (event: React.ChangeEvent<{}>, profile: Profile | null) => {
      setProfileId(profile?.id ?? undefined);
      if (profile) {
        const aux = permissionMenus.map((menu) => ({ ...menu }));
        let permissions = PermissionMenuService.mapPermissionsChecked(
          JSON.parse(profile.permissions),
          aux
        );
        permissions = PermissionMenuService.mapMenuExtraPermissionsChecked(
          JSON.parse(profile.extraMenuPermissions ?? "[]"),
          permissions
        );
        setPermissionMenus([...permissions]);
      }
    },
    [permissionMenus]
  );

  function handleChangeImgFile(event: any) {
    const input: HTMLInputElement = event.target;
    if (!input.files || input.files.length === 0) return;
    const fileUrl = window.URL.createObjectURL(input.files[0]);
    setPhoto(fileUrl);
  }

  function mapMenusSystem(menus: any): Array<number> {
    let mapped = menus
      .filter((menuChecked: any) => menuChecked.checked)
      .map((menu: any) => menu.id);

    for (let i = 0; i < menus.length; i++) {
      if (menus[i].subMenu?.length) {
        mapped = mapped.concat(mapMenusSystem(menus[i].subMenu as submenu[]));
      }
    }

    return mapped;
  }

  function handleCheckMainCompany(checked: boolean) {
    setCanAccessMainCompany(checked ? yesOrNo.YES : yesOrNo.NO);
  }

  function handleCheckBranch(checked: boolean, branchId: number) {
    setUserBranches((prevState) => {
      return prevState.map((branch) => {
        if (branch.id !== branchId) return branch;

        return {
          ...branch,
          checked,
        };
      });
    });
  }

  const onSubmit = async (evt: any) => {
    evt.preventDefault();

    // console.log("userBranches@@##", userBranches);

    setIsSubmit(true);

    if (!(await inputsVerify())) return;

    const aux = permissionMenus.map((menu) => ({ ...menu }));

    const menusFiltered = PermissionMenuService.filterPermissionMenu(aux);
    const permissions = PermissionMenuService.mapPermissionMenu(menusFiltered);
    const extraPermissions = PermissionMenuService.mapMenuExtraPermissions(
      menusFiltered
    );
    const fastAccess = mapMenusSystem(menuSystem);
    const filteredBranches = userBranches.filter((branch) => branch.checked);
    const branchesIds = filteredBranches.map((branch) => branch.id);

    var permissionsAccountantArray: any = [];
    var fastAccessAccountantArray: any = [];

    let accountantBranchesIds = originalAccountantBranchesIds;

    if (isAccountant) {
      for (let permission of permissionsAccountant) {
        if (permission.companyId == selectedCompany.id) {
          permissionsAccountantArray.push({
            companyId: permission.companyId,
            permissions: JSON.stringify(permissions),
          });
        } else {
          permissionsAccountantArray.push({
            companyId: permission.companyId,
            permissions: permission.permissions,
          });
        }
      }

      for (let access of fastAccessAccountant) {
        if (access.companyId == selectedCompany.id) {
          fastAccessAccountantArray.push({
            companyId: access.companyId,
            fastAccess: JSON.stringify(fastAccess),
          });
        } else {
          fastAccessAccountantArray.push({
            companyId: access.companyId,
            fastAccess: access.fastAccess,
          });
        }
      }

      const selectedCompaniesIds = branchesIds;

      if (canAccessMainCompany === yesOrNo.YES) {
        selectedCompaniesIds.push(Number(user.companyId));
      }

      for (const selectedCompanyId of selectedCompaniesIds) {
        if (!accountantBranchesIds.includes(selectedCompanyId)) {
          accountantBranchesIds.push(selectedCompanyId);
        }
      }

      const mainCompanyIdIndex = accountantBranchesIds.findIndex(
        (companyId) => Number(companyId) === Number(user.companyId)
      );

      if (mainCompanyIdIndex !== -1 && canAccessMainCompany === yesOrNo.NO) {
        accountantBranchesIds.splice(mainCompanyIdIndex, 1);
      }
    }

    const raw = {
      permissions: isAccountant
        ? JSON.stringify(permissionsAccountantArray)
        : JSON.stringify(permissions),
      extraMenuPermissions: isAccountant
        ? "[]"
        : JSON.stringify(extraPermissions),
      fastAccess: isAccountant
        ? JSON.stringify(fastAccessAccountantArray)
        : JSON.stringify(fastAccess),
      userProfileId: profileId ?? null,
      isAdmin: profileId === 0 ? yesOrNo.YES : yesOrNo.NO,
      firstname: firstname ? firstname : null,
      lastname: lastname ? lastname : null,
      cell: cell ? cell : null,
      email: email ? email : null,
      login: login ? login : null,
      password: changePassword && password ? password : undefined,
      canAccessMainCompany,
    };

    try {
      const editResponse = await api.put(
        `users/${id}`,
        !isAccountant
          ? { ...raw, branchesIds: JSON.stringify(branchesIds) }
          : { ...raw, branchesIds: JSON.stringify(accountantBranchesIds) }
      );

      if (isAccountant) {
        // Atualizar dados de contador no cadastro da empresa
        await CompanyService.saveCompanyAccountant(
          {
            id: String(companyAccountantInfoId || ""),
            companyId: selectedCompany.id,
            userId: editResponse.data.id,
            name: `${firstname} ${lastname}`,
            cpf: accountantCpf,
            cnpj: accountantCnpj,
            crc: accountantCrc,
            email,
            cell,
            phone: accountantPhone,
            zipCode: accountantZipCode,
            address: accountantAddress,
            district: accountantDistrict,
            city: accountantCity,
            state: accountantState,
            number: accountantNumber,
            complement: accountantComplement,
          },
          selectedCompany
        );
      }

      logService.logEdit({
        itemId: editResponse.data.id,
        itemName: `${editResponse.data.firstname} ${editResponse.data
          .lastname ?? ""}`,
        module: "Usuários",
        oldData: beforeSubmitData,
        newData: editResponse.data,
        formattedFields: {
          userProfileId: (value) =>
            profiles.find((obj) => obj.id === Number(value))?.name ?? "",
          password: (value) => "******",
        },
        fieldsMap: {
          userProfileId: "Perfil",
          firstname: "Nome",
          lastname: "Sobrenome",
          cell: "Celular",
          email: "E-mail",
          login: "Login",
          password: "Senha",
        },
      });

      // Upload foto
      let uploadSuccess = true;
      try {
        uploadSuccess = await uploadPhoto(Number(id));
      } catch (error) {
        uploadSuccess = false;
      }
      if (uploadSuccess && savedPhoto) {
        await removeOldPhoto(savedPhoto);
      }

      if (user.id === Number(id)) {
        dispatch(
          auth.actions.setUser({
            ...user,
            ...raw,
          })
        );
      }

      setTimeout(() => {
        setMsgSuccess("Edição realizada com sucesso!");
        setShowModalSuccess(true);
      }, 1000);
    } catch (error) {
      console.log("error", error);
      setMsgError("Ocorreu um erro ao processar a requisição");
      setShowModalError(true);
    }

    setIsSubmit(false);
  };

  async function removeOldPhoto(filename: string) {
    await api.delete("/file", { data: { filename } });
  }

  async function uploadPhoto(id: number): Promise<boolean> {
    if (imgFileInput && imgFileInput.current) {
      if (
        !imgFileInput.current.files ||
        imgFileInput.current.files.length === 0
      ) {
        return false;
      }
      const imageFile = imgFileInput.current.files[0];
      if (!imageFile) {
        return false;
      }

      var formFile = new FormData();
      formFile.append("image", imageFile, imageFile.name);

      const response = await api.post("/image", formFile);
      const raw = {
        photo: response.data,
      };

      await api.put(`users/${id}`, raw);
      return true;
    }

    return false;
  }

  async function CheckCnpj(value: string) {
    if (value) {
      const searchResponse = await cnpjSearch(value ?? "");

      if (searchResponse.status === "ERROR") {
        return;
      }

      setFirstname(searchResponse.nome);
      setEmail(searchResponse.email);
      setAccountantPhone(searchResponse.telefone);

      setAccountantZipCode(searchResponse.cep ?? "");
      if (searchResponse.cep) {
        infoAddress(searchResponse.cep ?? "");
      }
    }
  }

  async function infoAddress(zipcode: string) {
    const address = await addressSearch(zipcode ?? "");

    setAccountantAddress(address.logradouro);
    setAccountantDistrict(address.bairro);
    setAccountantCity(address.localidade);
    setAccountantState(address.uf);
  }

  return (
    <div className="row card card-body pt-4 newProductWrapper">
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />
      <ModalSuccess
        msgModal={msgSuccess}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        redirect="/usuarios"
      />
      <form
        className={"makeStyles-container-12"}
        onSubmit={(evt) => onSubmit(evt)}
      >
        <div className="row">
          <div className="col-lg-2 text-center">
            <img
              src={
                photo
                  ? photo
                  : toAbsoluteUrl("/media/logos/header/logo-header-black.png")
              }
              className="img-fluid d-block"
              alt=""
            />
            {user.isAccountant == "n" ? (
              <CustomButton
                className="mt-3 mb-2"
                variant="primary"
                onClick={handleUploadClick}
              >
                Upload
              </CustomButton>
            ) : (
              <></>
            )}
            <input
              type="file"
              className="d-none"
              ref={imgFileInput}
              onChange={(e) => handleChangeImgFile(e)}
              accept="image/png, image/jpeg"
              disabled={user.isAccountant == "y" ? true : false}
            />
            {/* <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label> */}
          </div>
          <div className="col-4">
            <TextField
              label="Nome*"
              size="small"
              className={classes.error}
              margin="normal"
              variant="outlined"
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
              disabled={user.isAccountant == "y" ? true : false}
              error={firstnameInvalid}
            />
          </div>
          <div className="col-4">
            <TextField
              label="Sobrenome"
              size="small"
              className={classes.error}
              margin="normal"
              variant="outlined"
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
              disabled={user.isAccountant == "y" ? true : false}
            />
          </div>

          {user.isAccountant == "n" ? (
            <div className="col d-flex align-items-start justify-content-end">
              <Button
                type="submit"
                className="mt-4"
                variant="primary"
                disabled={isSubmit}
              >
                {isSubmit ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <>
                    <span>Salvar</span>
                  </>
                )}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="edituser-form-tabs"
        >
          <Tab eventKey="details" title="Dados">
            <div className="row">
              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="E-mail*"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailInvalid(e.target.value ? false : true);
                  }}
                  error={emailInvalid}
                  disabled={user.isAccountant == "y" ? true : false}
                />
              </div>
              <div className="col-lg-4">
                <PatternFormat
                  label="Celular*"
                  className={classes.error}
                  format="(##) #####-####"
                  mask="_"
                  value={cell}
                  onChange={(e) => {
                    setCell(e.target.value);
                    setCellInvalid(e.target.value ? false : true);
                  }}
                  error={cellInvalid}
                  disabled={user.isAccountant == "y" ? true : false}
                />
              </div>
            </div>

            {!isAccountant ? (
              <div className="row d-flex align-items-center">
                {/* <div className="col-lg-4">
                                    <TextField
                                        size="small"
                                        label="Login*"
                                        margin="normal"
                                        variant="outlined"
                                        value={login}
                                        onChange={(e) => setLogin(e.target.value)}
                                        autoComplete='off'
                                        disabled={user.isAccountant == "y" ? true : false}
                                        error={loginInvalid}
                                    />
                                </div> */}
                <div className="col-lg-2 pl-0">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        disabled={user.isAccountant == "y" ? true : false}
                        color="primary"
                        onChange={(e) => setChangePassword(e.target.checked)}
                        checked={changePassword}
                      />
                    }
                    label="Alterar Senha"
                    labelPlacement="end"
                    className="ml-1"
                  />
                </div>
                {changePassword && (
                  <div className="col-lg-4">
                    <TextField
                      size="small"
                      label="Senha*"
                      type="password"
                      margin="normal"
                      variant="outlined"
                      value={password}
                      autoComplete="off"
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={user.isAccountant == "y" ? true : false}
                      error={passwordInvalid}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="row mt-3">
                  <div className="col">
                    <h4>Informações Adicionais do Contador</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <CompanyFormAccountant
                      accountantCpf={accountantCpf}
                      onChangeAccountantCpf={(value) => setAccountantCpf(value)}
                      accountantCnpj={accountantCnpj}
                      onChangeAccountantCnpj={(value) =>
                        setAccountantCnpj(value)
                      }
                      accountantCrc={accountantCrc}
                      onChangeAccountantCrc={(value) => setAccountantCrc(value)}
                      accountantPhone={accountantPhone}
                      onChangeAccountantPhone={(value) =>
                        setAccountantPhone(value)
                      }
                      accountantZipCode={accountantZipCode}
                      onChangeAccountantZipCode={(value) =>
                        setAccountantZipCode(value)
                      }
                      accountantAddress={accountantAddress}
                      onChangeAccountantAddress={(value) =>
                        setAccountantAddress(value)
                      }
                      accountantNumber={accountantNumber}
                      onChangeAccountantNumber={(value) =>
                        setAccountantNumber(value)
                      }
                      accountantDistrict={accountantDistrict}
                      onChangeAccountantDistrict={(value) =>
                        setAccountantDistrict(value)
                      }
                      accountantComplement={accountantComplement}
                      onChangeAccountantComplement={(value) =>
                        setAccountantComplement(value)
                      }
                      accountantCity={accountantCity}
                      onChangeAccountantCity={(value) =>
                        setAccountantCity(value)
                      }
                      accountantState={accountantState}
                      onChangeAccountantState={(value) =>
                        setAccountantState(value)
                      }
                      accountantNameHasError={accountantNameHasError}
                      accountantEmailHasError={accountantEmailHasError}
                      accountantCellHasError={accountantCellHasError}
                      accountantCpfHasError={accountantCpfHasError}
                      accountantCnpjHasError={accountantCnpjHasError}
                      allStates={allStates}
                      CheckCnpj={CheckCnpj}
                      infoAddress={infoAddress}
                      currentIndex={0}
                    />
                  </div>
                </div>
              </>
            )}
          </Tab>

          <Tab eventKey="access" title="Acesso">
            <div className="row">
              <div className="col-lg-4">
                <Autocomplete
                  key={profileId}
                  size="small"
                  disableClearable={false}
                  noOptionsText="Sem opções"
                  options={profiles}
                  getOptionLabel={({ name }) => name}
                  value={
                    profiles.find((profile) => profile.id === profileId) ?? null
                  }
                  onChange={(event, newInputValue) =>
                    handleChangeProfile(event, newInputValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="Perfil"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                  disabled={
                    user.isAccountant == "y" || isAccountant ? true : false
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <PermissionMenuSelector
                  permissionMenus={permissionMenus}
                  setPermissionMenus={setPermissionMenus}
                  customChangePermissionHandler={handleChangePermission}
                />
              </div>
            </div>
          </Tab>

          {!isAccountant && (
            <Tab eventKey="fastAccess" title="Acesso rápido (Dashboard)">
              <div className="row">
                <div className="col-12">
                  <Tabs defaultActiveKey="1" id="permissionTabs">
                    {menuSystem.map((menu, i) => (
                      <Tab key={i} eventKey={menu.id} title={menu.name}>
                        <div className="row" key={`${i}`}>
                          <div className="col-12 d-flex align-items-center">
                            <Checkbox
                              disabled={user.isAccountant == "y" ? true : false}
                              checked={menu.checked ?? false}
                              inputProps={{
                                "aria-label": "success checkbox",
                              }}
                              onChange={(e, checked) =>
                                handleChangePermissionFastAccess(checked, [i])
                              }
                            />
                            <strong>Menu Ativo</strong>
                          </div>
                          <div className="col-12">
                            <div className="row ml-2">
                              {menu.subMenu.length > 0 &&
                                menu.subMenu.map((submenu1: submenu, j) => (
                                  <div
                                    className={
                                      submenu1.subMenu.length
                                        ? "col-12"
                                        : "col-lg-3"
                                    }
                                    key={`${i}-${j}`}
                                  >
                                    <Checkbox
                                      disabled={
                                        user.isAccountant == "y" ? true : false
                                      }
                                      checked={submenu1.checked ?? false}
                                      inputProps={{
                                        "aria-label": "success checkbox",
                                      }}
                                      onChange={(e, checked) =>
                                        handleChangePermissionFastAccess(
                                          checked,
                                          [i, j]
                                        )
                                      }
                                    />
                                    <span>{submenu1.name}</span>
                                    <div className="row ml-2">
                                      {submenu1.subMenu.map(
                                        (submenu2: submenu, k) => (
                                          <div
                                            className="col-lg-3"
                                            key={`${i}-${j}-${k}`}
                                          >
                                            <Checkbox
                                              disabled={
                                                user.isAccountant == "y"
                                                  ? true
                                                  : false
                                              }
                                              checked={
                                                submenu2.checked ?? false
                                              }
                                              inputProps={{
                                                "aria-label":
                                                  "success checkbox",
                                              }}
                                              onChange={(e, checked) =>
                                                handleChangePermissionFastAccess(
                                                  checked,
                                                  [i, j, k]
                                                )
                                              }
                                            />
                                            <span>{submenu2.name}</span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
              </div>
            </Tab>
          )}

          {!!company &&
            CompanyService.companyCanHaveBranches(company) &&
            CompanyService.isMainCompany(selectedCompany) && (
              <Tab eventKey="branches" title="Filiais">
                <div className="row">
                  <div className="col">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Acesso</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>CPF/CNPJ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              disabled={user.isAccountant == "y" ? true : false}
                              color="primary"
                              onChange={(e, checked) => {
                                handleCheckMainCompany(checked);
                              }}
                              checked={canAccessMainCompany === "y"}
                            />
                          </TableCell>
                          <TableCell>
                            Matriz /{" "}
                            {CompanyService.getCorporateName(userCompany)}
                          </TableCell>
                          <TableCell>
                            {formatCpfCnpjDynamic(
                              CompanyService.getCpfCnpj(userCompany)
                            )}
                          </TableCell>
                        </TableRow>
                        {userBranches.map((branch) => (
                          <TableRow key={branch.data.id}>
                            <TableCell>
                              <Checkbox
                                disabled={
                                  user.isAccountant == "y" ? true : false
                                }
                                color="primary"
                                onChange={(e, checked) =>
                                  handleCheckBranch(checked, branch.id)
                                }
                                checked={branch.checked}
                              />
                            </TableCell>
                            <TableCell>
                              {CompanyService.getCorporateName(branch.data)}
                            </TableCell>
                            <TableCell>
                              {formatCpfCnpjDynamic(
                                CompanyService.getCpfCnpj(branch.data)
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </Tab>
            )}
        </Tabs>
      </form>
    </div>
  );
}
