import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { format, parseISO, isAfter } from "date-fns";
import { useHistory } from "react-router-dom";

import api from "../../services/Api";
import {
  ObjectOrder,
  OrdersSituation,
  HeadDataBaseProps,
  BodyDataBaseProps,
  OnThrowToBillsData,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import { ServiceOrderData } from "../../types/ServiceOrderData";
import {
  Collapse,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { NumericFormat } from "../../components/NumericFormat";
import { Search } from "../../components/Search";
import "../../style.css";
import { YesOrNoBadge } from "../../components/YesOrNoBadge";
import ModalChangeStatus, {
  StatusHistory,
} from "../../components/ModalChangeStatus";
import {
  dateIsBetweenRange,
  extractDateStringFromTimestamp,
} from "../../utils/dateTimeHelper";
import { SubCategory } from "../../types/Dre";
import { verifyProductsJSON } from "../../utils/verifyProductsJSON";
import { BsVariant } from "../../types/BsVariant";
import useBackendLoad from "../../hooks/backendReload";
import { getSituationFromText } from "../../utils/getSituationText";
import ServiceOrderService from "../../services/ServiceOrderService";
import CustomerService from "../../services/CustomerService";
import { useLinkedFieldsError } from "../../hooks/linkedFieldsError";
import ModalLinkedFieldsError from "../../components/ModalLinkedFieldsError";
import SellerService from "../../services/SellerService";
import { useSelector } from "react-redux";
import { yesOrNo } from "../../types/yesOrNo";

type Filters = {
  searchQuery: string;
  situation: string;
  initialDate: string;
  finalDate: string;
  minValue: number;
  maxValue: number;
  type: string;
};

const headData: HeadDataBaseProps[] = [
  { reference: "id", value: "Nº" },
  { reference: "date", value: "Data" },
  { reference: "client", value: "Cliente" },
  { reference: "referencies", value: "Referência" },
  { reference: "amount", value: "Valor Total" },
  {
    reference: "situation",
    value: "Situação",
    situation: true,
    notSortable: true,
  },
  { reference: "billCreated", value: "Lançado em contas", notSortable: true },
];

type status = {
  id: number;
  label: string;
  color: string;
  isDefault: string;
  companyId: number;
};

export function ListServiceOrder() {
  const { user } = useSelector((state: any) => state.auth);

  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
  const [situationData, setSituationData] = useState<ObjectOrder[]>([]);
  const [serviceOrders, setServiceOrders] = useState<ServiceOrderData[]>([]);
  const [allStatus, setAllStatus] = useState<status[]>([]);
  const [countTotalServiceOrders, setCountTotalServiceOrders] = useState(0);

  // Campos da Busca
  const [searchQuery, setSearchQuery] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [situation, setSituation] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [type, setType] = useState("");
  const filtersRef = useRef<Filters | null>(null);

  const [linkedFieldsErrorMessage, setLinkedFieldsErrorMessage] = useState("");
  const [linkedFieldsRedirectUrl, setLinkedFieldsRedirectUrl] = useState("");
  const {
    showModalLinkedFieldsError,
    setShowModalLinkedFieldsError,
    linkedFieldsErrors,
    setLinkedFieldsErrors,
  } = useLinkedFieldsError();

  const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

  // Modal alterar status
  const [idChangeStatus, setIdChangeStatus] = useState(0);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [statusHistory, setStatusHistory] = useState<StatusHistory[]>([]);

  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();

  const loadData = useCallback(
    async ({
      rowsPerPage,
      currentPage,
      sortDirection,
      sortReference,
    }: LoadDataParams) => {
      const { data } = await api.get<{
        rows: ServiceOrderData[];
        count: number;
      }>("service-order", {
        params: {
          skip: rowsPerPage * currentPage,
          take: rowsPerPage,
          filters: filtersRef.current
            ? JSON.stringify(filtersRef.current)
            : undefined,
          sortReference,
          sortDirection,
        },
      });

      const { rows, count } = data;

      const initialSituationData = await Promise.all(
        rows.map(async (serviceOrder) => {
          const situation: OrdersSituation[] = JSON.parse(
            serviceOrder.situation
          );

          return {
            id: serviceOrder.id,
            situation,
            installments: serviceOrder.installments,
            sellerId: serviceOrder.seller ?? "",
            sellerHasCommission: serviceOrder.sellerCommissioning === "y",
            sellerCommissionPercentage: await SellerService.getCommissionPercentage(
              serviceOrder.seller,
              serviceOrder.sellerEntity
            ),
          };
        })
      );

      for (const serviceOrder of rows) {
        serviceOrder.customerName = CustomerService.getCustomerName(
          serviceOrder.customerEntity,
          serviceOrder.customerName
        );

        const deliveryDate = serviceOrder.deliveryDate;
        if (deliveryDate) {
          if (
            dateIsBetweenRange(
              deliveryDate,
              "",
              extractDateStringFromTimestamp()
            ) &&
            serviceOrder.status !== "canceled" &&
            serviceOrder.status !== "attended"
          ) {
            serviceOrder.status = "late";
          }
        }
      }

      setSituationData(initialSituationData);
      setServiceOrders(rows);
      setCountTotalServiceOrders(count);
    },
    []
  );

  useEffect(() => {
    getAllStatus();
  }, []);

  useLayoutEffect(() => {
    getServicerOrders();
  }, [serviceOrders]);

  async function getServicerOrders() {
    const list: BodyDataBaseProps[][] = [];
    const aux = serviceOrders;

    // var allStatus = await api.get("/status");
    aux.forEach((order) => {
      const amount = formatCurrency(order.totalValue);
      const status =
        order.status === "open"
          ? "Em aberto"
          : order.status === "progress"
          ? "Em andamento"
          : order.status === "attended"
          ? "Finalizado"
          : order.status === "canceled"
          ? "Cancelado"
          : order.status === "aproved"
          ? "Aprovado"
          : order.status === "late"
          ? "Vencido"
          : order.status;

      // Formatando data
      const date = parseISO(String(order.createdAt));
      const formatedDate = format(date, "dd/MM/yyyy");

      const hasProducts = verifyProductsJSON(order.products);

      const data: BodyDataBaseProps[] = [
        { for: "id", value: String(order.id), id: true },
        { for: "date", value: formatedDate },
        { for: "client", value: order.customerName },
        { for: "referencies", value: order.referencies ?? "" },
        { for: "amount", value: amount },
        { for: "situation", value: status },
        {
          for: "billCreated",
          value: order.billCreated,
          jsx: <YesOrNoBadge value={order.billCreated} />,
        },
        {
          for: "movedToStock",
          value: hasProducts ? order.movedToStock : "false",
          hidden: true,
        },
        { for: "customStatusLabel", value: order.customStatus?.label ?? "" },
        { for: "customStatusColor", value: order.customStatus?.color ?? "" },
      ];

      list.push(data);
    });

    setBodyData(list);
  }

  async function getAllStatus() {
    var statusData = await api.get("/status");
    setAllStatus(statusData.data);
  }

  const clearSearch = () => {
    setSearchQuery("");
    setSituation("");
    setInitialDate("");
    setFinalDate("");
    setMinValue("");
    setMaxValue("");
    setType("");
  };

  const handleClickSearch = useCallback(() => {
    filtersRef.current = {
      searchQuery,
      situation,
      minValue: formatToFloat(minValue),
      maxValue: formatToFloat(maxValue),
      initialDate,
      finalDate,
      type,
    };

    reloadData();
  }, [
    serviceOrders,
    searchQuery,
    situation,
    initialDate,
    finalDate,
    minValue,
    maxValue,
    type,
  ]);

  const handleClickDelete = useCallback(
    async (id: string) => {
      try {
        const filtered = serviceOrders.filter(
          (order) => order.id !== Number(id)
        );

        await api.delete(`service-order/${id}`);

        setServiceOrders([...filtered]);
      } catch (error) {
        console.log(error);
      }
    },
    [serviceOrders]
  );

  const handleClickEdit = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/${id}`);
    },
    [pathname]
  );

  const handleClickClone = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/duplicar/${id}`);
    },
    [pathname]
  );

  const handleChangeOrder = useCallback(
    async (id: number, situation: OrdersSituation) => {
      try {
        const aux = serviceOrders;
        const aux2 = situationData;
        const order = aux.find((order) => order.id === id);
        const sitData = aux2.find((situ) => situ.id === id);

        if (!order || !sitData) {
          throw new Error();
        }

        const hasSituation = sitData.situation;

        if (!hasSituation) {
          throw new Error();
        }

        hasSituation.push(situation);

        order.status = situation.statusSituation;
        order.situation = JSON.stringify(sitData.situation);

        await api.put(`service-order/${id}`, order);

        setSituationData(aux2);
        setServiceOrders([...aux]);
      } catch (error) {
        console.log(error);
      }
    },
    [serviceOrders, situationData]
  );

  const handleThrowToBillsToReceive = useCallback(
    async ({
      id,
      revenue,
      subCategoryId,
      centerCost,
      installments,
      installmentsCommission,
      centerCostCommission,
      subCategoryIdCommission,
    }: OnThrowToBillsData) => {
      try {
        const aux = serviceOrders;
        const auxSituation = situationData;

        const soIndex = aux.findIndex((so) => so.id === id);
        const situationIndex = auxSituation.findIndex((situ) => situ.id === id);

        if (soIndex < 0 || situationIndex < 0) {
          throw new Error();
        }

        var lastBank = 0;
        var lastBilletNumber = 0;
        var billetNumber = 0;

        installments.forEach(
          async ({
            date,
            value,
            isPaid,
            payment,
            comments,
            bankAccount,
            paymentDate,
          }) => {
            const [day, month, year] = date.split("/");
            const formated = new Date(`${year}-${month}-${day} 23:59:59`);
            const late = isAfter(Date.now(), formated);

            const bank = await api.get(`/accountBank/${Number(bankAccount)}`);

            if (lastBank == Number(bankAccount)) {
              billetNumber = lastBilletNumber + 1;
            } else {
              billetNumber = Number(bank.data.billetSequence);
            }

            let customerId = null;
            if (aux[soIndex].customerId) {
              const customer = await CustomerService.getCustomerById(
                aux[soIndex].customerId
              );
              customerId = customer ? customer.id : null;
            }

            const raw = {
              payment,
              comments,
              centerCost: centerCost ? Number(centerCost) : null,
              bankAccount: bankAccount ? bankAccount : null,
              nameBank: bankAccount ? bank.data.nameBank : null,
              amount: value,
              dueDate: year + "-" + month + "-" + day,
              payedDate: isPaid ? paymentDate : null,
              totalPaid: isPaid ? value : null,
              remaining: value,
              recordType: payment === "Boleto" ? "billet" : "account",
              categoryName: revenue,
              dreSubCategoryId: subCategoryId,
              occurrence: installments.length > 1 ? "parcelada" : "unica",
              customer: customerId,
              docNumber: aux[soIndex].numberSO,
              issuanceDate: aux[soIndex].createdAt,
              name: `Ordem de serviço ${aux[soIndex].id}`,
              status: isPaid ? "paid" : late ? "late" : "pending",
              billetNumber: billetNumber,
            };

            lastBank = Number(bankAccount);
            lastBilletNumber = billetNumber;

            await api.post(`/accountBank/${Number(bankAccount)}`, {
              billetSequence: billetNumber + 1,
            });
            await api.post("billsToReceive", raw);
          }
        );

        // Salvar Comissão
        if (installmentsCommission && subCategoryIdCommission) {
          for (const commissionData of installmentsCommission) {
            const bank = await api.get(
              `/accountBank/${parseInt(commissionData.bankAccount)}`
            );

            const [day, month, year] = commissionData.date.split("/");
            const formated = new Date(`${year}-${month}-${day} 23:59:59`);
            const late = isAfter(Date.now(), formated);

            const raw = {
              payment: commissionData.payment,
              comments: commissionData.comments,
              centerCost: centerCostCommission
                ? Number(centerCostCommission)
                : null,
              bankAccount: commissionData.bankAccount
                ? commissionData.bankAccount
                : null,
              nameBank: commissionData.bankAccount ? bank.data.nameBank : null,
              amount: commissionData.value,
              dueDate: year + "-" + month + "-" + day,
              payedDate: commissionData.isPaid
                ? commissionData.paymentDate
                : null,
              totalPaid: commissionData.isPaid ? commissionData.value : null,
              remaining: commissionData.value,
              recordType: "account",
              categoryName: revenue,
              dreSubCategoryId: subCategoryIdCommission,
              occurrence:
                installmentsCommission.length > 1 ? "parcelada" : "unica",
              beneficiaryType: "seller",
              seller: aux[soIndex].seller,
              docNumber: aux[soIndex].numberSO,
              issueDate: aux[soIndex].createdAt,
              name: `Comissão - OS N° ${
                aux[soIndex].id
              } - ${await CustomerService.getNameByIdAsync(
                aux[soIndex].customerId
              )} - ${commissionData.date}`,
              status: commissionData.isPaid
                ? "paid"
                : late
                ? "late"
                : "pending",
            };

            await api.post("billsToPay", raw);
          }
        }

        aux[soIndex].billCreated = "y";

        await api.put(`service-order/${id}`, aux[soIndex]);

        setSituationData([...auxSituation]);
        setServiceOrders([...aux]);
      } catch (error) {
        console.log(error);
      }
    },
    [serviceOrders, situationData]
  );

  const updateStockMoved = useCallback(
    (id: string, movedToStock: string) => {
      const aux = serviceOrders;
      const auxIndex = aux.findIndex((obj) => obj.id === Number(id));

      aux[auxIndex].movedToStock = movedToStock;

      setServiceOrders([...aux]);
    },
    [serviceOrders, situationData]
  );

  const handleClickAdd = useCallback(() => {
    pushHistory(`${pathname}/adicionar`);
  }, []);

  const handleOpenChangeStatusModal = useCallback(
    (id: string) => {
      const order = serviceOrders.find(
        (serviceObj) => serviceObj.id === Number(id)
      );
      if (!order) return;

      setStatusHistory(JSON.parse(order.situation));
      setIdChangeStatus(Number(id));
      setShowChangeStatusModal(true);
    },
    [serviceOrders]
  );
  const handleClickChangeStatus = useCallback(
    async (newStatus: StatusHistory): Promise<string> => {
      if (!newStatus.statusSituationId) return "invalid-status";

      const aux = [...statusHistory];
      const lastStatus = aux[aux.length - 1];

      aux.push(newStatus);

      let status =
        newStatus.statusIsDefault === "y"
          ? getSituationFromText(newStatus.statusSituationLabel)
          : "custom";
      let customStatus =
        newStatus.statusIsDefault === "n" ? newStatus.statusSituationId : null;

      const customStatusObject = customStatus
        ? allStatus.find((statusObj) => statusObj.id === customStatus)
        : null;

      const raw = {
        status: status,
        customStatusId: customStatus,
        situation: JSON.stringify(aux),
      };

      const { billCreated } = serviceOrders.find(
        (item) => item.id === idChangeStatus
      ) || { billCreated: null };

      if (billCreated === yesOrNo.NO && status === "attended") {
        return "error-bill-to-receive-required";
      }

      await api.put(`service-order/${idChangeStatus}`, raw);

      ServiceOrderService.dispatchStatusChangeNotificationAndEmail({
        serviceOrderId: idChangeStatus,
        lastStatusHistory: lastStatus,
        newStatusHistory: newStatus,
      });

      setStatusHistory([...aux]);
      setServiceOrders((prevState) =>
        prevState.map((order) => {
          if (order.id !== idChangeStatus) {
            return order;
          }

          const late =
            order.deliveryDate &&
            dateIsBetweenRange(
              order.deliveryDate,
              "",
              extractDateStringFromTimestamp()
            ) &&
            newStatus.statusSituationLabel !== "Cancelado" &&
            newStatus.statusSituationLabel !== "Finalizado";

          return {
            ...order,
            situation: JSON.stringify(aux),
            status: late ? "late" : status ?? "custom",
            customStatus: customStatusObject,
            customStatusId: customStatus,
          };
        })
      );

      return "success";
    },
    [idChangeStatus, statusHistory, allStatus]
  );

  const handleBeforeOpenSendEmailOrWhatsappModal = useCallback(
    (id: number, type: "email" | "whatsapp") => {
      const foundServiceOrder = serviceOrders.find(
        (serviceOrderObj) => serviceOrderObj.id === Number(id)
      );

      if (!foundServiceOrder) {
        return false;
      }

      const linkErrors = ServiceOrderService.verifyCustomerLink(
        foundServiceOrder
      );

      if (linkErrors.length > 0) {
        setLinkedFieldsErrors(linkErrors);
        setLinkedFieldsRedirectUrl(`${pathname}/${id}`);
        setLinkedFieldsErrorMessage(
          `Para enviar ${
            type === "email" ? "email" : "whatsapp"
          } é necessário vincular um cliente cadastrado à Ordem de Serviço!`
        );
        setShowModalLinkedFieldsError(true);
        return false;
      }

      return true;
    },
    [serviceOrders]
  );

  const handleBeforeAction = useCallback(
    (id: number) => {
      const foundServiceOrder = serviceOrders.find(
        (serviceOrderObj) => serviceOrderObj.id === Number(id)
      );

      if (!foundServiceOrder) {
        return false;
      }

      const linkErrors = ServiceOrderService.verifyLinkedFields(
        foundServiceOrder
      );

      if (linkErrors.length > 0) {
        setLinkedFieldsErrors(linkErrors);
        setLinkedFieldsRedirectUrl(`${pathname}/${id}`);
        setLinkedFieldsErrorMessage(
          `Para realizar esta ação é necessário completar o cadastro da Ordem de Serviço!`
        );
        setShowModalLinkedFieldsError(true);
        return false;
      }

      return true;
    },
    [serviceOrders]
  );

  return (
    <div className="card card-body pt-4 newProductWrapper">
      <ModalLinkedFieldsError
        message={linkedFieldsErrorMessage}
        errors={linkedFieldsErrors}
        showModal={showModalLinkedFieldsError}
        setShowModal={setShowModalLinkedFieldsError}
        redirect={linkedFieldsRedirectUrl}
      />
      <ModalChangeStatus
        showModal={showChangeStatusModal}
        setShowModal={setShowChangeStatusModal}
        history={statusHistory}
        onClickChangeStatus={handleClickChangeStatus}
        listStatus={allStatus}
      />
      <div className="row d-flex align-items-center">
        <div className="col-lg-9 mt-3">
          {user.isAccountant == "n" ? (
            <Button
              type="button"
              variant="success"
              className="mr-2"
              onClick={() => handleClickAdd()}
            >
              Nova ordem de serviço
            </Button>
          ) : (
            <></>
          )}
        </div>
        <div className="col-lg-3 mt-3">
          <Search
            query={searchQuery}
            setQuery={setSearchQuery}
            setCollapseAdvancedSearch={setAdvancedSearch}
            onClickSearch={handleClickSearch}
          />
        </div>
      </div>
      <Collapse in={advancedSearch}>
        <div className="row">
          <div className="col-lg-3">
            <TextField
              select
              size="small"
              label="Situação"
              margin="normal"
              variant="outlined"
              value={situation}
            >
              <MenuItem key="0" value="" onClick={() => setSituation("")}>
                Todos
              </MenuItem>

              {allStatus.map((status) => (
                <MenuItem
                  key={status.id}
                  value={
                    status.isDefault === "y"
                      ? getSituationFromText(status.label)
                      : status.id
                  }
                  onClick={(event) => {
                    setSituation(
                      event.currentTarget.getAttribute("data-value") ?? ""
                    );
                  }}
                >
                  {status.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="col-lg-2">
            <TextField
              size="small"
              type="date"
              label="Data inicial"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </div>
          <div className="col-lg-2">
            <TextField
              size="small"
              type="date"
              label="Data final"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor mínimo"
              startAdornment="R$"
              value={minValue}
              onChange={(evt) => setMinValue(evt.target.value)}
            />
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor máximo"
              startAdornment="R$"
              value={maxValue}
              onChange={(evt) => setMaxValue(evt.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              select
              size="small"
              label="Tipo"
              margin="normal"
              variant="outlined"
              value={type}
            >
              <MenuItem key="0" value="" onClick={() => setType("")}>
                Todos
              </MenuItem>

              <MenuItem
                key="1"
                value="budget"
                onClick={() => setType("budget")}
              >
                Orçamento
              </MenuItem>

              <MenuItem
                key="2"
                value="serviceOrder"
                onClick={() => setType("serviceOrder")}
              >
                Ordem de Serviço
              </MenuItem>

              {/* <MenuItem key="3" value="inactive" onClick={() => setType('inactive')}>
                                    Agrupado
                                </MenuItem> */}
            </TextField>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button onClick={handleClickSearch} className="mr-3">
              Pesquisar
            </Button>

            <Button onClick={clearSearch}>Limpar</Button>
          </div>
        </div>
      </Collapse>

      <div className="mt-3">
        <ListWithModalChangeSituation
          isOs
          hasStock
          order
          typeOrder="service"
          headData={headData}
          bodyData={bodyData}
          onEdit={handleClickEdit}
          objectOrder={situationData}
          onClone={handleClickClone}
          onDelete={handleClickDelete}
          onChangeOrder={handleChangeOrder}
          onThrowToBills={handleThrowToBillsToReceive}
          onStockMoved={updateStockMoved}
          onBeforeOpenSendEmailModal={(id) =>
            handleBeforeOpenSendEmailOrWhatsappModal(id, "email")
          }
          onBeforeOpenSendWhatsappModal={(id) =>
            handleBeforeOpenSendEmailOrWhatsappModal(id, "whatsapp")
          }
          onBeforeAction={handleBeforeAction}
          sortable={true}
          loadData={loadData}
          totalCount={countTotalServiceOrders}
          triggerLoad={triggerLoad}
          setTriggerLoad={setTriggerLoad}
          customButtons={
            user.isAccountant == "n"
              ? [
                  {
                    class: "btn-primary",
                    content: <i className="p-0 flaticon-list-2"></i>,
                    variant: BsVariant.PRIMARY,
                    popup: "Alterar Status",
                    onClick: handleOpenChangeStatusModal,
                  },
                ]
              : []
          }
        />
      </div>
    </div>
  );
}
