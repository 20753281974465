import React, { useState, useEffect } from "react";
import { Badge, Button, Modal, Spinner } from "react-bootstrap";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import api from "../services/Api";

type nfeSelectMonth = {
  showModal: boolean;
  nfeType: string;
};

const NfeSelectMonthModal: React.FC<nfeSelectMonth> = ({
  showModal,
  nfeType,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  const [years, setYears] = useState<any>([]);
  const [message, setMessage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const months = [
    { value: 1, name: "Janeiro" },
    { value: 2, name: "Fevereiro" },
    { value: 3, name: "Março" },
    { value: 4, name: "Abril" },
    { value: 5, name: "Maio" },
    { value: 6, name: "Junho" },
    { value: 7, name: "Julho" },
    { value: 8, name: "Agosto" },
    { value: 9, name: "Setembro" },
    { value: 10, name: "Outubro" },
    { value: 11, name: "Novembro" },
    { value: 12, name: "Dezembro" },
  ];

  useEffect(() => {
    setDisplayModal(showModal);
    const fetch = async () => {
      try {
        let resp = await api.get(`/sendToAccountants/nfe/nfeYears/${nfeType}`);
        setYears(resp.data);
      } catch (error) {
        console.log(error);
        setMessage({ error: 1, text: "*Houve um erro interno" });
      }
    };
    fetch().then();
  }, [showModal]);

  const closeModal = () => {
    setDisplayModal(false);
    setMessage(null);
  };

  const sendXmlLinks = async () => {
    setLoading(true);
    try {
      if (selectedMonth && selectedYear) {
        let mess = "";
        let noEmailMessage =
          "Não foram encontrados emails e/ou emails válidos de contadores registrados nesta empresa";
        if (nfeType == "nfe") {
          let resp = await api.post("/sendToAccountants/nfe/sendNfe", {
            selectedMonth,
            selectedYear,
          });
          if (resp.data.nfesCount && resp.data.vEmLength) {
            mess = "Xml das NFE enviadas";
          } else {
            if (!resp.data.nfesCount) {
              mess = "Não foram encontradas NFEs aprovadas neste periodo";
            }
            if (!resp.data.vEmLength) {
              if (!resp.data.nfesCount) {
                mess += "\n";
              }
              mess += noEmailMessage;
            }
          }
        } else if (nfeType == "nfce") {
          let resp = await api.post("/sendToAccountants/nfce/sendNfce", {
            selectedMonth,
            selectedYear,
          });
          if (resp.data.nfesCount && resp.data.vEmLength) {
            mess = "Xml das NFCE enviadas";
          } else {
            if (!resp.data.nfesCount) {
              mess = "Não foram encontradas NFCEs aprovadas neste periodo";
            }
            if (!resp.data.vEmLength) {
              if (!resp.data.nfesCount) {
                mess += "\n";
              }
              mess += noEmailMessage;
            }
          }
        }
        setMessage({ error: 0, text: mess });
      } else {
        setMessage({ error: 1, text: "*Selecione mês e ano" });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: 1, text: "*Houve um erro interno" });
    }
    setLoading(false);
  };

  return (
    <Modal
      show={displayModal}
      onHide={() => closeModal()}
      aria-labelledby="contained-modal-warning"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <i className="flaticon2-send icon-xl text-warning mr-3"></i>
          Enviar links xml para contadores
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>
          Selecione um mês e ano para enviar os links dos xml das notas
          aprovadas para os contadores desta empresa
        </strong>
        <br />
        <br />
        <Select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value as number)}
          style={styles.selectStyle}
        >
          <MenuItem key={0} value={0}>
            Selecione um mês
          </MenuItem>
          {months.map((m) => (
            <MenuItem key={m.value} value={m.value}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value as number)}
          style={styles.selectStyle}
        >
          <MenuItem key={0} value={0}>
            Selecione um ano
          </MenuItem>
          {years.map((y: any) => (
            <MenuItem key={y.year} value={y.year}>
              {y.year}
            </MenuItem>
          ))}
        </Select>
        {message ? (
          <strong style={{ color: message.error ? "red" : "green" }}>
            {message.text}
          </strong>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => sendXmlLinks()}>
          {loading ? <Spinner animation={"border"} /> : <span>Enviar</span>}
        </Button>
        <Button variant="secondary" onClick={() => closeModal()}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const styles = {
  selectStyle: {
    width: "49%",
    marginLeft: "1%",
  },
};

export default NfeSelectMonthModal;
