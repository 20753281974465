import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ModalSuccess from "../../components/ModalSuccess";
import api from "../../services/Api";
import CompanySubscriptionPlanService from "../../services/CompanySubscriptionPlanService";
import { Indications, whoPay } from "../../types/Indications";
import { SubscriptionPlan } from "../../types/SubscriptionPlan";
import { bypassAuthHeader } from "../../utils/bypassAuthHeader";
import {
  ExecuteCreateCompanyPlanFunction,
  SubscriptionPlanCheckout,
} from "../../components/SubscriptionPlanCheckout";
import PermissionMenuService from "../../services/PermissionMenuService";

export function CompleteCompanyData() {
  const { indicationId } = useParams<{ indicationId: string }>();
  const [indication, setIndication] = useState<Indications>();

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");

  useEffect(() => {
    async function loadIndication() {
      if (!indicationId) {
        return;
      }

      try {
        const indicationResponse = await api.get<Indications>(
          `/indications/${indicationId}`,
          {
            headers: bypassAuthHeader,
          }
        );

        const subscriptionPlan = await CompanySubscriptionPlanService.getCurrentActiveCompanySubscriptionPlanByCompanyId(
          indicationResponse.data.companyEntity.id,
          { bypassAuth: true }
        );

        if (
          indicationResponse.data.whoPay !== whoPay.COMPANY ||
          subscriptionPlan
        ) {
          window.location.href = `/criar-senha/${indicationResponse.data.indicatedUserEntity.id}`;
        }

        setIndication(indicationResponse.data);
      } catch (error) {
        console.log("error", error);
      }
    }

    loadIndication();
  }, [indicationId]);

  const onSubmit = async (
    executeCreateCompanyPlan: ExecuteCreateCompanyPlanFunction,
    subscriptionPlan: SubscriptionPlan,
    expirationDate: string,
    permissions: number[]
  ) => {
    if (!indication) return;

    await executeCreateCompanyPlan();

    const rawCompany = {
      profileId: subscriptionPlan.profileId,
      permissions: JSON.stringify(permissions),
    };

    const rawUser = {
      permissions: JSON.stringify(permissions),
      extraMenuPermissions: JSON.stringify(
        PermissionMenuService.getMenuExtraPermissionsFromMenuPermissions(
          JSON.stringify(permissions)
        )
      ),
      fastAccess: JSON.stringify(permissions),
    };

    const response = await api.put(
      `companies/${indication.companyEntity.id}`,
      rawCompany,
      { headers: bypassAuthHeader }
    );
    const userResponse = await api.put(
      `users/${indication.indicatedUserEntity.id}`,
      rawUser,
      { headers: bypassAuthHeader }
    );

    await api.post(
      `indications/${indication.id}/email-welcome`,
      {},
      { headers: bypassAuthHeader }
    );

    setShowModalSuccess(true);
    setMessageSuccess(
      "Cadastro completado com sucesso! Você será redirecionado para criar a sua senha de acesso!"
    );
  };

  return (
    <div className="d-flex flex-column flex-root">
      <ModalSuccess
        msgModal={messageSuccess}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        redirect={`/criar-senha/${indication?.indicatedUserEntity.id}`}
      />
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        <div
          className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-12.jpg")})`,
          }}
        >
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
              <img
                alt="Logo"
                className="max-h-70px"
                src={toAbsoluteUrl("/media/logos/header/logo-header-gold.png")}
              />
            </Link>

            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">Bem-vindo!</h3>
              <p className="font-weight-lighter text-white">
                Milliontech, o sistema que faltava para você ter mais LUCRO e
                LIBERDADE
              </p>
            </div>

            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div className="font-weight-bold	text-white">
                {new Date().getFullYear()} &copy; Milliontech
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="mt-30 mt-lg-0">
            {indication && (
              <>
                {indication.whoPay === whoPay.COMPANY && (
                  <div className="row mt-3">
                    <div className="col-12">
                      <SubscriptionPlanCheckout
                        onSubmit={onSubmit}
                        companyId={indication?.companyId ?? 0}
                        title={"Selecione uma das opções de plano abaixo"}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
