import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, InputAdornment, makeStyles, MenuItem, TextField } from "@material-ui/core";
import { formatToFloat } from "../../../utils/formatCurrency";
import { NumericFormat } from "../../../components/NumericFormat";
import { Button, Spinner } from "react-bootstrap";
import ModalError from "../../../components/ModalError";
import api from "../../../services/Api";
import ModalDelete from "../../../components/ModalDelete";

const useStyles = makeStyles(theme => ({
    menu: {
        width: 200,
    },
}));

export type ConfigNfceProps = {
    data: React.MutableRefObject<any>;
    apiConfig: any;
    companyId: number;
};

export function ConfigNfce({
    data,
    apiConfig,
    companyId,
}: ConfigNfceProps) {
    const classes = useStyles();
    const [config, setConfig] = useState<any>();

    const [enableNfce, setEnableNfce] = useState(false);
    const [seriesHomologation, setSeriesHomologation] = useState<number | null>(0);
    const [nextNumberHomologation, setNextNumberHomologation] = useState<number | null>(0);
    const [idTokenHomologation, setIdTokenHomologation] = useState("");
    const [cscHomologation, setCscHomologation] = useState("");
    const [seriesProduction, setSeriesProduction] = useState<number | null>(0);
    const [nextNumberProduction, setNextNumberProduction] = useState<number | null>(0);
    const [idTokenProduction, setIdTokenProduction] = useState("");
    const [cscProduction, setCscProduction] = useState("");
    const [icmsCST, setIcmsCST] = useState("");
    const [icmsModality, setIcmsModality] = useState(0);
    const [pisCST, setPisCST] = useState("");
    const [cofinsCST, setCofinsCST] = useState("");
    const [typeAttendance, setTypeAttendance] = useState("");
    const [cfopConsumer, setCfopConsumer] = useState("");

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        if (!apiConfig) {
            return;
        }
        setEnableNfce(apiConfig.habilita_nfce ?? false);
        setSeriesHomologation(apiConfig.serie_nfce_homologacao ?? 1);
        setNextNumberHomologation(apiConfig.proximo_numero_nfce_homologacao ?? 1);
        setIdTokenHomologation(apiConfig.id_token_nfce_homologacao ?? '');
        setCscHomologation(apiConfig.csc_nfce_homologacao ?? '');
        setSeriesProduction(apiConfig.serie_nfce_producao ?? 1);
        setNextNumberProduction(apiConfig.proximo_numero_nfce_producao ?? 1);
        setIdTokenProduction(apiConfig.id_token_nfce_producao ?? '');
        setCscProduction(apiConfig.csc_nfce_producao ?? '');
    }, [apiConfig]);

    useEffect(() => {
        if (!config) return;

        setIcmsCST(config.icmsCST ?? '');
        setIcmsModality(config.icmsModality ?? '');
        setPisCST(config.pisCST ?? '');
        setCofinsCST(config.cofinsCST ?? '');
        setTypeAttendance(config.typeAttendance ?? '1');
        setCfopConsumer(config.cfopConsumer ?? '');
    }, [config]);

    async function getConfig() {
        const response = await api.get('nfceConfig');
        const savedConfig = response.data[0];

        setConfig(savedConfig);
        if (savedConfig) {
            data.current.id = savedConfig.id;
        }
    }

    function handleChangeEnableNfce(event: any) {
        handleChangeDataBooleanInput(!enableNfce, 'enableNfce', setEnableNfce);
    }

    function handleChangeDataTextInput(event: any, state: string, setter: React.Dispatch<React.SetStateAction<string>>) {
        data.current[state] = event.target.value;
        setter(event.target.value);
    }
    function handleChangeDataNumericInput(event: any, state: string, setter: React.Dispatch<React.SetStateAction<any>>) {
        data.current[state] = event.target.value;
        setter(formatToFloat(event.target.value));
    }
    function handleChangeDataBooleanInput(value: boolean, state: string, setter: React.Dispatch<React.SetStateAction<boolean>>) {
        data.current[state] = value;
        setter(value);
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <FormControlLabel
                                value="end"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={enableNfce}
                                        onChange={handleChangeEnableNfce}
                                    />}
                                label="Habilitar NFC-e"
                                labelPlacement="end"
                            />
                        </div>
                    </div>
                    {enableNfce && (
                        <>
                            <div className="row">
                                <div className="col">
                                    <h3>Homologação</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <NumericFormat
                                        label="Série"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={seriesHomologation}
                                        onChange={e => handleChangeDataNumericInput(e, 'seriesHomologation', setSeriesHomologation)}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <NumericFormat
                                        label="Próximo Número"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={nextNumberHomologation}
                                        onChange={e => handleChangeDataNumericInput(e, 'nextNumberHomologation', setNextNumberHomologation)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <TextField
                                        label="ID Token"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        value={idTokenHomologation}
                                        onChange={e => handleChangeDataTextInput(e, 'idTokenHomologation', setIdTokenHomologation)}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <TextField
                                        label="CSC"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        value={cscHomologation}
                                        onChange={e => handleChangeDataTextInput(e, 'cscHomologation', setCscHomologation)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h3>Produção</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <NumericFormat
                                        label="Série"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={seriesProduction}
                                        onChange={e => handleChangeDataNumericInput(e, 'seriesProduction', setSeriesProduction)}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <NumericFormat
                                        label="Próximo Número"
                                        thousandSeparator={''}
                                        decimalScale={0}
                                        value={nextNumberProduction}
                                        onChange={e => handleChangeDataNumericInput(e, 'nextNumberProduction', setNextNumberProduction)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <TextField
                                        label="ID Token"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        value={idTokenProduction}
                                        onChange={e => handleChangeDataTextInput(e, 'idTokenProduction', setIdTokenProduction)}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <TextField
                                        label="CSC"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        value={cscProduction}
                                        onChange={e => handleChangeDataTextInput(e, 'cscProduction', setCscProduction)}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {enableNfce && (
                <div className="card mt-5">
                    <div className="card-body">
                        <div className="row mt-3">
                            <div className="col bg-warning-o-30 p-5 mb-3">
                                <p>
                                    <strong>Atenção: </strong>
                                    Os dados abaixos serão aplicados aos produtos que não possuem esses campos preenchidos durante a emissão fiscal
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-lg-8'>
                                <TextField
                                    size="small"
                                    select
                                    label='ICMS-CST'
                                    margin='normal'
                                    variant='outlined'
                                    SelectProps={{
                                        style: { width: 350 },
                                        MenuProps: {
                                            // className: classes.menu,
                                        },
                                    }}
                                    value={icmsCST}
                                    onChange={(e) => handleChangeDataTextInput(e, 'icmsCST', setIcmsCST)}
                                >
                                    <MenuItem value=''>
                                        Selecione
                                    </MenuItem>
                                    <MenuItem value='00 - Tributada integralmente'>
                                        00 - Tributada integralmente
                                    </MenuItem>
                                    <MenuItem value='10 - Tributada e com cobrança do ICMS por substituição tributária'>
                                        10 - Tributada e com cobrança do ICMS por substituição tributária
                                    </MenuItem>
                                    <MenuItem value='10_partilha - Tributada e com cobrança do ICMS por substituição tributária. Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)'>
                                        10_partilha - Tributada e com cobrança do ICMS por substituição tributária. Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)
                                    </MenuItem>
                                    <MenuItem value='20 - Tributada com redução de base de cálculo'>
                                        20 - Tributada com redução de base de cálculo
                                    </MenuItem>
                                    <MenuItem value='30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária'>
                                        30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária
                                    </MenuItem>
                                    <MenuItem value='40 - Isenta'>
                                        40 - Isenta
                                    </MenuItem>
                                    <MenuItem value='41 - Não tributada'>
                                        41 - Não tributada
                                    </MenuItem>
                                    <MenuItem value='41_st - Não tributada com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário'>
                                        41_st - Não tributada com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário
                                    </MenuItem>
                                    <MenuItem value='50 - Com suspensão'>
                                        50 - Com suspensão
                                    </MenuItem>
                                    <MenuItem value='51 - Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)'>
                                        51 - Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)
                                    </MenuItem>
                                    <MenuItem value='60 - Cobrado anteriormente por substituição tributária'>
                                        60 - Cobrado anteriormente por substituição tributária
                                    </MenuItem>
                                    <MenuItem value='60_st - Cobrado anteriormente por substituição tributária com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário'>
                                        60_st - Cobrado anteriormente por substituição tributária com ICMS ST devido para a UF de destino, nas operações interestaduais de produtos que tiveram retenção antecipada de ICMS por ST na UF do remetente. Repasse via Substituto Tributário
                                    </MenuItem>
                                    <MenuItem value='70 - Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária'>
                                        70 - Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária
                                    </MenuItem>
                                    <MenuItem value='90 - Outras (regime Normal)'>
                                        90 - Outras (regime Normal)
                                    </MenuItem>
                                    <MenuItem value='90_partilha - Outras (regime Normal). Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)'>
                                        90_partilha - Outras (regime Normal). Operação interestadual para consumidor final com partilha do ICMS devido na operação entre a UF de origem e a do destinatário, ou a UF definida na legislação. (Ex. UF da concessionária de entrega do veículo)
                                    </MenuItem>
                                    <MenuItem value='101 - Tributada pelo Simples Nacional com permissão de crédito'>
                                        101 - Tributada pelo Simples Nacional com permissão de crédito
                                    </MenuItem>
                                    <MenuItem value='102 - Tributada pelo Simples Nacional com permissão de crédito'>
                                        102 - Tributada pelo Simples Nacional com permissão de crédito
                                    </MenuItem>
                                    <MenuItem value='103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta'>
                                        103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta
                                    </MenuItem>
                                    <MenuItem value='201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária'>
                                        201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária
                                    </MenuItem>
                                    <MenuItem value='202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária'>
                                        202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária
                                    </MenuItem>
                                    <MenuItem value='203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária'>
                                        203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária
                                    </MenuItem>
                                    <MenuItem value='300 - Imune'>
                                        300 - Imune
                                    </MenuItem>
                                    <MenuItem value='400 - Não tributada pelo Simples Nacional'>
                                        400 - Não tributada pelo Simples Nacional
                                    </MenuItem>
                                    <MenuItem value='500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação'>
                                        500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação
                                    </MenuItem>
                                    <MenuItem value='900 - Outras (regime Simples Nacional)'>
                                        900 - Outras (regime Simples Nacional)
                                    </MenuItem>
                                </TextField>
                            </div>
                            <div className='col-lg-4'>
                                <TextField
                                    size="small"
                                    select
                                    label='Modalidade BC ICMS'
                                    className='ml-0'
                                    margin='normal'
                                    variant='outlined'
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    value={icmsModality}
                                    onChange={(e) => handleChangeDataNumericInput(e, 'icmsModality', setIcmsModality)}
                                >
                                    <MenuItem key="0" value='0'>
                                        Margem Valor Agregado (%)
                                    </MenuItem>
                                    <MenuItem key="1" value='1'>
                                        Pauta (Valor)
                                    </MenuItem>
                                    <MenuItem key="2" value='2'>
                                        Preço Tabelado Max. (Valor)
                                    </MenuItem>
                                    <MenuItem key="3" value='3'>
                                        Valor da Operação
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-8' style={{ overflow: 'hidden' }}>
                                <TextField
                                    size="small"
                                    select
                                    label='PIS-CST'
                                    className='ml-0'
                                    margin='normal'
                                    variant='outlined'
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    value={pisCST}
                                    onChange={e => handleChangeDataTextInput(e, 'pisCST', setPisCST)}
                                >
                                    <MenuItem value=''>
                                        Selecione
                                    </MenuItem>

                                    <MenuItem value='01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)'>
                                        01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)
                                    </MenuItem>

                                    <MenuItem value='02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)'>
                                        02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)
                                    </MenuItem>

                                    <MenuItem value='03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto'>
                                        03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto
                                    </MenuItem>

                                    <MenuItem value='04 - Operação tributável: tributação monofásica (alíquota zero)'>
                                        04 - Operação tributável: tributação monofásica (alíquota zero)
                                    </MenuItem>

                                    <MenuItem value='05 - Operação tributável: substituição tributária'>
                                        05 - Operação tributável: substituição tributária
                                    </MenuItem>

                                    <MenuItem value='06 - Operação tributável: alíquota zero'>
                                        06 - Operação tributável: alíquota zero
                                    </MenuItem>

                                    <MenuItem value='07 - Operação isenta da contribuição'>
                                        07 - Operação isenta da contribuição
                                    </MenuItem>

                                    <MenuItem value='08 - Operação sem incidência da contribuição'>
                                        08 - Operação sem incidência da contribuição
                                    </MenuItem>

                                    <MenuItem value='09 - Operação com suspensão da contribuição'>
                                        09 - Operação com suspensão da contribuição
                                    </MenuItem>

                                    <MenuItem value='49 - Outras operações de saída'>
                                        49 - Outras operações de saída
                                    </MenuItem>

                                    <MenuItem value='50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno'>
                                        50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno'>
                                        51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação'>
                                        52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação
                                    </MenuItem>

                                    <MenuItem value='53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno'>
                                        53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno
                                    </MenuItem>

                                    <MenuItem value='54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação'>
                                        54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação'>
                                        55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                                        56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno'>
                                        60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno'>
                                        61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação'>
                                        62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação
                                    </MenuItem>

                                    <MenuItem value='63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno'>
                                        63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno
                                    </MenuItem>

                                    <MenuItem value='64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação'>
                                        64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação'>
                                        65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                                        66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='67 - Crédito presumido: outras operações'>
                                        67 - Crédito presumido: outras operações
                                    </MenuItem>

                                    <MenuItem value='70 - Operação de aquisição sem direito a crédito'>
                                        70 - Operação de aquisição sem direito a crédito
                                    </MenuItem>

                                    <MenuItem value='71 - Operação de aquisição com isenção'>
                                        71 - Operação de aquisição com isenção
                                    </MenuItem>

                                    <MenuItem value='72 - Operação de aquisição com suspensão'>
                                        72 - Operação de aquisição com suspensão
                                    </MenuItem>

                                    <MenuItem value='73 - Operação de aquisição a alíquota zero'>
                                        73 - Operação de aquisição a alíquota zero
                                    </MenuItem>

                                    <MenuItem value='74 - Operação de aquisição sem incidência da contribuição'>
                                        74 - Operação de aquisição sem incidência da contribuição
                                    </MenuItem>

                                    <MenuItem value='75 - Operação de aquisição por substituição tributária'>
                                        75 - Operação de aquisição por substituição tributária
                                    </MenuItem>

                                    <MenuItem value='98 - Outras operações de entrada'>
                                        98 - Outras operações de entrada
                                    </MenuItem>

                                    <MenuItem value='99 - Outras operações'>
                                        99 - Outras operações
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-8'>
                                <TextField
                                    size="small"
                                    select
                                    label='COFINS-CST'
                                    className='ml-0'
                                    margin='normal'
                                    variant='outlined'
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    value={cofinsCST}
                                    onChange={e => handleChangeDataTextInput(e, 'cofinsCST', setCofinsCST)}
                                >
                                    <MenuItem value=''>
                                        Selecione
                                    </MenuItem>

                                    <MenuItem value='01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)'>
                                        01 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)
                                    </MenuItem>

                                    <MenuItem value='02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)'>
                                        02 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)
                                    </MenuItem>

                                    <MenuItem value='03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto'>
                                        03 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto
                                    </MenuItem>

                                    <MenuItem value='04 - Operação tributável: tributação monofásica (alíquota zero)'>
                                        04 - Operação tributável: tributação monofásica (alíquota zero)
                                    </MenuItem>

                                    <MenuItem value='05 - Operação tributável: substituição tributária'>
                                        05 - Operação tributável: substituição tributária
                                    </MenuItem>

                                    <MenuItem value='06 - Operação tributável: alíquota zero'>
                                        06 - Operação tributável: alíquota zero
                                    </MenuItem>

                                    <MenuItem value='07 - Operação isenta da contribuição'>
                                        07 - Operação isenta da contribuição
                                    </MenuItem>

                                    <MenuItem value='08 - Operação sem incidência da contribuição'>
                                        08 - Operação sem incidência da contribuição
                                    </MenuItem>

                                    <MenuItem value='09 - Operação com suspensão da contribuição'>
                                        09 - Operação com suspensão da contribuição
                                    </MenuItem>

                                    <MenuItem value='49 - Outras operações de saída'>
                                        49 - Outras operações de saída
                                    </MenuItem>

                                    <MenuItem value='50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno'>
                                        50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno'>
                                        51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação'>
                                        52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação
                                    </MenuItem>

                                    <MenuItem value='53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno'>
                                        53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno
                                    </MenuItem>

                                    <MenuItem value='54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação'>
                                        54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação'>
                                        55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                                        56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno'>
                                        60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno'>
                                        61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno
                                    </MenuItem>

                                    <MenuItem value='62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação'>
                                        62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação
                                    </MenuItem>

                                    <MenuItem value='63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno'>
                                        63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno
                                    </MenuItem>

                                    <MenuItem value='64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação'>
                                        64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação'>
                                        65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação'>
                                        66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação
                                    </MenuItem>

                                    <MenuItem value='67 - Crédito presumido: outras operações'>
                                        67 - Crédito presumido: outras operações
                                    </MenuItem>

                                    <MenuItem value='70 - Operação de aquisição sem direito a crédito'>
                                        70 - Operação de aquisição sem direito a crédito
                                    </MenuItem>

                                    <MenuItem value='71 - Operação de aquisição com isenção'>
                                        71 - Operação de aquisição com isenção
                                    </MenuItem>

                                    <MenuItem value='72 - Operação de aquisição com suspensão'>
                                        72 - Operação de aquisição com suspensão
                                    </MenuItem>

                                    <MenuItem value='73 - Operação de aquisição a alíquota zero'>
                                        73 - Operação de aquisição a alíquota zero
                                    </MenuItem>

                                    <MenuItem value='74 - Operação de aquisição sem incidência da contribuição'>
                                        74 - Operação de aquisição sem incidência da contribuição
                                    </MenuItem>

                                    <MenuItem value='75 - Operação de aquisição por substituição tributária'>
                                        75 - Operação de aquisição por substituição tributária
                                    </MenuItem>

                                    <MenuItem value='98 - Outras operações de entrada'>
                                        98 - Outras operações de entrada
                                    </MenuItem>

                                    <MenuItem value='99 - Outras operações'>
                                        99 - Outras operações
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">

                                <TextField
                                    select
                                    label="Tipo de atendimento"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={typeAttendance}
                                    onChange={e => handleChangeDataTextInput(e, 'typeAttendance', setTypeAttendance)}
                                >

                                    <MenuItem key="1" value="1">
                                        Operação presencial
                                    </MenuItem>

                                    <MenuItem key="4" value="4">
                                        NFC-e em operação com entrega em domicílio
                                    </MenuItem>
                                </TextField>

                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <h4>CFOP</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <NumericFormat
                                    label="Venda consumidor"
                                    thousandSeparator={''}
                                    decimalScale={0}
                                    value={cfopConsumer}
                                    onChange={e => handleChangeDataTextInput(e, 'cfopConsumer', setCfopConsumer)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}