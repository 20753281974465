import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import api from "../../../services/Api";

import { Grid, TextField, MenuItem, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";

import "../../../style.css";
import logService from "../../../services/LogService";
import ModalSuccess from "../../../components/ModalSuccess";
import { PromotionTypesEnum } from "../../../../enums/promotion-types.enums";
import { ProductPromotion } from "../../../types/ProductPromotion";
import { validatePromotionDate } from "../../../pages/Product/Promotions/validation";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

type Data = {
  description: string;
  startDate: string;
  endDate: string;
  promotionType: PromotionTypesEnum;
  promotionValue: number;
  photo?: string;
  creativeUrl: string;
};

const DEFAULT_DATA: Data = {
  startDate: "",
  endDate: "",
  promotionType: PromotionTypesEnum.DISCOUNT_IN_VALUE,
  promotionValue: 0,
  description: "",
  creativeUrl: "",
};

type NewPromotionFormProps = {
  onPromotionCreated?: (createdPromotion: ProductPromotion) => void;
  onClosePromotionCreatedSuccessModal?: () => void;
  showSubmitButton?: boolean;
  productId?: number;
};

type UrlParam = {
  id: string;
};

export const NewPromotionForm = forwardRef(function NewPromotionForm(
  {
    onPromotionCreated,
    onClosePromotionCreatedSuccessModal,
    showSubmitButton = true,
    productId,
  }: NewPromotionFormProps,
  ref
) {
  useImperativeHandle(ref, () => ({
    submit() {
      return handleSubmit();
    },
  }));
  const classes = useStyles();
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [showModalExist, setShowModalExist] = useState(false);

  const [data, setData] = useState<Data>(DEFAULT_DATA);
  const [isSubmit, setIsSubmit] = useState(false);
  const [promotionTypeSelected, setPromotionTypeSelected] = useState(
    PromotionTypesEnum.DISCOUNT_IN_VALUE
  );
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [promotionValue, setPromotionValue] = useState(0);
  const [photo, setPhoto] = useState("");
  const [creativeUrl, setCreativeUrl] = useState("");
  const [typeInvalid, setTypeInvalid] = useState(false);
  const [valueInvalid, setValueInvalid] = useState(false);
  const { id } = useParams<UrlParam>();
  const imgFileInput = useRef<HTMLInputElement>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newData: Data = { ...data };
    const name = e.target.name as keyof Data;
    const value = e.target.value;

    if (typeof newData[name] === "string") {
      (newData[name] as string) = value;
    }

    if (typeof newData[name] === "number") {
      (newData[name] as number) = (value as unknown) as number;
    }

    if (name === "promotionType") {
      (newData[
        name
      ] as PromotionTypesEnum) = (value as unknown) as PromotionTypesEnum;
    }
    setData(newData);
  };

  function handleChangeImgFile(event: any) {
    const input: HTMLInputElement = event.target;
    if (!input.files || input.files.length === 0) return;
    const fileUrl = window.URL.createObjectURL(input.files[0]);
    setPhoto(fileUrl);
  }

  async function inputsVerify(data: Data): Promise<boolean> {
    const { promotionType, promotionValue, startDate, endDate } = data;

    if (!promotionType) {
      setIsSubmit(false);
      setMsgError("Selecione o tipo da promoção");
      setShowModalExist(true);
      setTypeInvalid(true);

      return false;
    }

    if (!promotionValue) {
      setIsSubmit(false);
      setMsgError("Preencha o valor da promoção");
      setShowModalExist(true);
      setValueInvalid(true);

      return false;
    }

    if (promotionValue && promotionValue <= 0) {
      setIsSubmit(false);
      setMsgError("O valor da promoção deve ser maior que zero");
      setShowModalExist(true);
      setValueInvalid(true);

      return false;
    }

    if (!startDate) {
      setIsSubmit(false);
      setMsgError("Preencha a data de início da promoção");
      setShowModalExist(true);

      return false;
    }

    if (!endDate) {
      setIsSubmit(false);
      setMsgError("Preencha a data de término da promoção");
      setShowModalExist(true);

      return false;
    }

    if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
      setIsSubmit(false);
      setMsgError(
        "A data de término da promoção deve ser maior que a data de início"
      );
      setShowModalExist(true);

      return false;
    }

    if (
      promotionType === PromotionTypesEnum.PERCENTAGE &&
      promotionValue > 100
    ) {
      setIsSubmit(false);
      setMsgError("O valor da promoção deve ser menor ou igual a 100%");
      setShowModalExist(true);
      setValueInvalid(true);

      return false;
    }

    const startDateValidation = validatePromotionDate(startDate);
    if (!startDateValidation.isValid) {
      setIsSubmit(false);
      setMsgError(startDateValidation.message);
      setShowModalExist(true);

      return false;
    }
    const endDateValidation = validatePromotionDate(endDate);
    if (!endDateValidation.isValid) {
      setIsSubmit(false);
      setMsgError(endDateValidation.message);
      setShowModalExist(true);

      return false;
    }

    return true;
  }

  function formatToFloat(value: string) {
    if (value.includes(",") && !value.includes(".")) {
      return parseFloat(value.replace(",", "."));
    } else {
      return parseFloat(value.replace(".", "").replace(",", "."));
    }
  }

  async function handleSubmit() {
    setIsSubmit(true);

    if (!(await inputsVerify(data))) {
      return false;
    }

    const newPromotionProductId = id ?? productId;

    const hasPromotionSamePeriod = await api.get<ProductPromotion[]>(
      `/product/${newPromotionProductId}/promotions`,
      { params: { startDate: data.startDate, endDate: data.endDate } }
    );

    if (hasPromotionSamePeriod.data.length > 0) {
      setIsSubmit(false);
      setMsgError("Já existe uma promoção cadastrada para esse período.");
      setShowModalExist(true);
      setValueInvalid(true);
      return false;
    }

    let imageUploaded;

    if (
      imgFileInput &&
      imgFileInput.current &&
      imgFileInput.current.files &&
      imgFileInput.current.files.length > 0
    ) {
      const imageFile = imgFileInput.current.files[0];
      if (!imageFile) {
        return;
      }

      var formFile = new FormData();
      formFile.append("image", imageFile, imageFile.name);

      imageUploaded = (await api.post("/image", formFile)).data;
    }

    const raw = {
      ...data,
      promotionValue: formatToFloat(data.promotionValue.toString()),
      photo: imageUploaded ?? null,
    };
    try {
      const response = await api.post<ProductPromotion>(
        `/product/${newPromotionProductId}/promotions`,
        raw
      );

      await logService.logRegister({
        itemId: response.data.id,
        module: "Produtos / Promoções",
        itemName: response.data.description || "Promoção sem descrição",
      });

      if (onPromotionCreated) {
        onPromotionCreated(response.data);
      }

      setShowModalSuccess(true);

      return true;
    } catch (error) {
      console.log("error", error);
      setMsgError("Ocorreu um erro ao processar a requisição");
      setShowModalExist(true);
    }

    setIsSubmit(false);

    return true;
  }

  function handleCloseModalSuccess() {
    if (onClosePromotionCreatedSuccessModal) {
      onClosePromotionCreatedSuccessModal();
    }
  }

  function handleUploadClick() {
    if (imgFileInput && imgFileInput.current) {
      imgFileInput.current.click();
    }
  }

  return (
    <div className="row card card-body pt-2 newProductWrapper">
      <ModalSuccess
        msgModal={"Promoção criada com sucesso!"}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        onClose={handleCloseModalSuccess}
      />

      <Modal
        show={showModalExist}
        onHide={() => setShowModalExist(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalExist(!showModalExist)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="makeStyles-container-12">
        <Grid container spacing={3} className="mb-3" style={{ border: "none" }}>
          <Grid item md={6}>
            <TextField
              size="small"
              className={classes.error}
              label="Descrição"
              margin="normal"
              variant="outlined"
              value={description}
              InputLabelProps={{ shrink: true }}
              name="description"
              onChange={(e) => {
                handleChange(e);
                setDescription(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-3">
          <Grid item md={4}>
            <TextField
              size="small"
              className={classes.error}
              label="Tipo de promoção"
              margin="normal"
              variant="outlined"
              value={promotionTypeSelected}
              name="promotionType"
              onChange={(e) => {
                handleChange(e);
                setPromotionTypeSelected(
                  (e.target.value as unknown) as PromotionTypesEnum
                );
              }}
              select
            >
              <MenuItem value={PromotionTypesEnum.DISCOUNT_IN_VALUE}>
                Desconto em valor
              </MenuItem>
              <MenuItem value={PromotionTypesEnum.PERCENTAGE}>
                Desconto em porcentagem
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item md={4}>
            <TextField
              size="small"
              label={"Valor"}
              margin="normal"
              variant="outlined"
              value={promotionValue}
              name="promotionValue"
              onChange={(e) => {
                handleChange(e);
                setPromotionValue((e.target.value as unknown) as number);
              }}
              className={classes.error}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-3">
          <Grid item md={4}>
            <TextField
              size="small"
              className={classes.error}
              label="Data de início"
              InputLabelProps={{ shrink: true }}
              type="date"
              margin="normal"
              variant="outlined"
              value={startDate}
              name="startDate"
              onChange={(e) => {
                handleChange(e);
                setStartDate(e.target.value);
              }}
            />
          </Grid>

          <Grid item md={4}>
            <TextField
              size="small"
              type="date"
              margin="normal"
              label="Data de término"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              className={classes.error}
              value={endDate}
              name="endDate"
              onChange={(e) => {
                handleChange(e);
                setEndDate(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-3">
          <Grid item md={8}>
            <TextField
              size="small"
              className={classes.error}
              label="Link"
              margin="normal"
              variant="outlined"
              value={creativeUrl}
              name="creativeUrl"
              onChange={(e) => {
                handleChange(e);
                setCreativeUrl(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <Grid style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
            <Button
              variant="primary"
              color="primary"
              onClick={handleUploadClick}
            >
              Upload
            </Button>
            <input
              type="file"
              className="d-none"
              ref={imgFileInput}
              onChange={(e) => handleChangeImgFile(e)}
              accept="image/png, image/jpeg"
            />
          </Grid>

          <img
            src={photo}
            className="img-fluid d-block"
            alt=""
            style={{ maxWidth: "100px", height: "auto" }}
          />
        </Grid>
        {showSubmitButton && (
          <Grid
            style={{
              marginTop: "26px",
              justifyContent: "flex-end",
              width: "100%",
              display: "flex",
            }}
          >
            <Button
              type="button"
              className="mr-0 mt-0 mb-2"
              variant="primary"
              disabled={isSubmit}
              onClick={() => handleSubmit()}
            >
              {isSubmit ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />

                  <span className="ml-2">Aguarde...</span>
                </>
              ) : (
                <>
                  <span>Salvar</span>
                </>
              )}
            </Button>
          </Grid>
        )}
      </div>
    </div>
  );
});
