import {
  IconButton,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { status } from "../pages/Config/CompanyData/ManageStatus";
import UserService from "../services/UserService";
import { formatDate } from "../utils/dateFormat";
import { getDate } from "../utils/dateTimeHelper";
import { getSituationText } from "../utils/getSituationText";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  btlr: {
    borderTopLeftRadius: 0,
  },
  absoluteNav: {
    position: "absolute",
    top: "-50px",
    left: 0,
    background: "#fff",
    borderRadius: "0.42rem 0.42rem 0px 0px",
    height: "50px",
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

export type StatusHistory = {
  statusSituation?: string;
  statusSituationId: number;
  statusSituationLabel: string;
  statusIsDefault: string;
  commentsSituation: string;
  dateSituation: string;
  datetimeSituation?: string;
  scheduleDateSituation?: string;
  userIdSituation?: string;
  user?: any;
};

export type ModalChangeStatusProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  history: StatusHistory[];
  listStatus: status[];
  onClickChangeStatus: (newStatus: StatusHistory) => string | Promise<string>;
};

export default function ModalChangeStatus({
  showModal,
  setShowModal,
  history,
  listStatus,
  onClickChangeStatus,
}: ModalChangeStatusProps) {
  const classes = useStyles();

  const [statusSelectedId, setStatusSeletedId] = useState(0);
  const [statusSelectedLabel, setStatusSeletedLabel] = useState("");
  const [statusSelectedIsDefault, setStatusSeletedIsDefault] = useState("");
  const [notes, setNotes] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");

  const [validationErrorMsg, setValidationErrorMsg] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [historyMapped, setHistoryMapped] = useState<StatusHistory[]>([]);

  const [pages, setPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    async function mapHistory() {
      setHistoryMapped(
        await Promise.all(
          history.map(async (historyItem) => {
            const userName = historyItem.userIdSituation
              ? await UserService.getNameById(
                  Number(historyItem.userIdSituation)
                )
              : undefined;
            return {
              ...historyItem,
              user: historyItem.userIdSituation
                ? { id: historyItem.userIdSituation, name: userName }
                : undefined,
            };
          })
        )
      );
    }

    mapHistory();
  }, [history]);

  const handleChangePage = useCallback((next: number) => {
    setPages(next);
  }, []);

  const handleRowsPerPage = useCallback((value: number) => {
    setPages(0);
    setRowsPerPage(value);
  }, []);

  async function handleClickChangeStatus() {
    setIsSubmitting(true);
    setValidationErrorMsg("");

    if (!validateInputs()) {
      setIsSubmitting(false);
      return;
    }

    const isValid = await onClickChangeStatus({
      statusSituationId: statusSelectedId,
      statusSituationLabel: statusSelectedLabel,
      statusIsDefault: statusSelectedIsDefault,
      commentsSituation: notes,
      dateSituation: getDate({ dateFormat: "y-m-d" }).dateStr,
      datetimeSituation: getDate({ dateFormat: "y-m-d" }).fullDateStr,
      scheduleDateSituation: scheduleDate,
      userIdSituation: user.id ?? undefined,
      user: user.id
        ? { id: user.id, name: `${user.firstname} ${user.lastname}` }
        : undefined,
    });

    if (isValid === "error-bill-to-receive-required") {
      setIsSubmitting(false);
      setValidationErrorMsg(
        "Para finalizar é preciso efetuar o lançamento financeiro!"
      );
      return false;
    }

    setIsSubmitting(false);
    setShowModal(false);
    setStatusSeletedId(0);
    setStatusSeletedIsDefault("");
    setNotes("");
    setScheduleDate("");
  }

  const validateInputs = useCallback(() => {
    if (!statusSelectedId) {
      setValidationErrorMsg("Selecione a situação!");
      return false;
    }

    return true;
  }, [statusSelectedId]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setPages(0);
    setIsSubmitting(false);
    setValidationErrorMsg("");
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-warning"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          Alterar Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-3">
            <TextField
              size="small"
              label="Situação"
              margin="normal"
              variant="outlined"
              value={statusSelectedId}
              select
            >
              <MenuItem
                key="0"
                value=""
                onClick={() => {
                  setStatusSeletedId(0);
                  setStatusSeletedIsDefault("");
                }}
              >
                Selecione
              </MenuItem>
              {listStatus &&
                listStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    value={status.id}
                    onClick={() => {
                      setStatusSeletedId(status.id);
                      setStatusSeletedLabel(status.label);
                      setStatusSeletedIsDefault(status.isDefault);
                    }}
                  >
                    {status.label}
                  </MenuItem>
                ))}
            </TextField>
          </div>
          <div className="col-lg-5">
            <TextField
              size="small"
              label="Observação"
              margin="normal"
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
          <div className="col-lg-4">
            <TextField
              size="small"
              type="date"
              margin="normal"
              label="Data para agendamento"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              value={scheduleDate}
              onChange={(e) => setScheduleDate(e.target.value)}
              InputProps={{
                endAdornment: (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Se esta data estiver preenchida, será notificado este
                        status na data escolhida e ficará marcado no calendário
                        de eventos do dashboard
                      </Tooltip>
                    }
                  >
                    <IconButton edge="end">
                      <i className="flaticon-info"></i>
                    </IconButton>
                  </OverlayTrigger>
                ),
              }}
            />
          </div>
        </div>
        {!!validationErrorMsg && (
          <div className="row">
            <div className="col">
              <span className="text-danger">{validationErrorMsg}</span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Observação</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Agendado</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {historyMapped
                  .slice(pages * rowsPerPage, pages * rowsPerPage + rowsPerPage)
                  .map((historyItem, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {historyItem.datetimeSituation
                            ? getDate({
                                initialDate: new Date(
                                  historyItem.datetimeSituation
                                ),
                              }).fullDateStr
                            : historyItem.dateSituation
                                .split("-")
                                .reverse()
                                .join("/")}
                        </TableCell>
                        <TableCell>{historyItem.commentsSituation}</TableCell>
                        <TableCell>
                          {getSituationText(historyItem.statusSituation)
                            ? getSituationText(historyItem.statusSituation)
                            : historyItem.statusSituationLabel}
                        </TableCell>
                        <TableCell>
                          {historyItem.user?.name ?? "SISTEMA"}
                        </TableCell>
                        <TableCell>
                          {historyItem.scheduleDateSituation
                            ? formatDate(historyItem.scheduleDateSituation)
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              labelRowsPerPage="Linhas por página"
              page={pages}
              component="div"
              count={historyMapped.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              backIconButtonProps={{
                "aria-label": "Página Anterior",
              }}
              nextIconButtonProps={{
                "aria-label": "Próxima Página",
              }}
              onChangePage={(_, next) => handleChangePage(next)}
              onChangeRowsPerPage={(evt) =>
                handleRowsPerPage(Number(evt.target.value))
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => handleClickChangeStatus()}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="ml-2">Aguarde...</span>
            </>
          ) : (
            <>
              <span>Salvar</span>
            </>
          )}
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
