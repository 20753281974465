import React, { useState, useEffect, useCallback, useRef } from "react";
import { format, isAfter } from "date-fns";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { orderByIdDesc } from "../../utils/orderTable";

import api from "../../services/Api";
import { Request } from "../../types/Request";
import {
  ObjectOrder,
  OrdersSituation,
  HeadDataBaseProps,
  BodyDataBaseProps,
  OnThrowToBillsData,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import "../../style.css";
import axios from "../../services/Api";
import { dateIsBetweenRange, getDate } from "../../utils/dateTimeHelper";
import { Search } from "../../components/Search";
import {
  Collapse,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { NumericFormat } from "../../components/NumericFormat";
import { SubCategory } from "../../types/Dre";
import useBackendLoad from "../../hooks/backendReload";
import CustomerService from "../../services/CustomerService";
import { useLinkedFieldsError } from "../../hooks/linkedFieldsError";
import ModalLinkedFieldsError from "../../components/ModalLinkedFieldsError";
import RequestService from "../../services/RequestService";
import SellerService from "../../services/SellerService";
import { useSelector } from "react-redux";

type Filters = {
  searchQuery: string;
  situation: string;
  requestDateMin: string;
  requestDateMax: string;
  valueMin: number;
  valueMax: number;
};

const headData: HeadDataBaseProps[] = [
  { reference: "id", value: "Nº" },
  { reference: "date", value: "Data" },
  { reference: "client", value: "Cliente" },
  { reference: "reference", value: "Referência" },
  { reference: "amount", value: "Valor Total" },
  { reference: "nota", value: "Nota" },
  {
    reference: "situation",
    value: "Situação",
    situation: true,
    notSortable: true,
  },
];

export function ListRequests() {
  const { user } = useSelector((state: any) => state.auth);

  const [requests, setRequests] = useState<Request[]>([]);
  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
  const [situationData, setSituationData] = useState<ObjectOrder[]>([]);
  const [countTotalRequests, setCountTotalRequests] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [situation, setSituation] = useState("");
  const [valueMin, setValueMin] = useState(0);
  const [valueMax, setValueMax] = useState(0);
  const [requestDateMin, setRequestDateMin] = useState("");
  const [requestDateMax, setRequestDateMax] = useState("");
  const filtersRef = useRef<Filters | null>(null);

  const [linkedFieldsErrorMessage, setLinkedFieldsErrorMessage] = useState("");
  const [linkedFieldsRedirectUrl, setLinkedFieldsRedirectUrl] = useState("");
  const {
    showModalLinkedFieldsError,
    setShowModalLinkedFieldsError,
    linkedFieldsErrors,
    setLinkedFieldsErrors,
  } = useLinkedFieldsError();

  const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();

  // useEffect(() => {
  //     handleClickSearch();
  // }, [requests]);

  const loadData = useCallback(
    async ({
      rowsPerPage,
      currentPage,
      sortDirection,
      sortReference,
    }: LoadDataParams) => {
      const { data } = await api.get<{ rows: Request[]; count: number }>(
        "requests",
        {
          params: {
            skip: rowsPerPage * currentPage,
            take: rowsPerPage,
            filters: filtersRef.current
              ? JSON.stringify(filtersRef.current)
              : undefined,
            sortReference,
            sortDirection,
          },
        }
      );

      const { rows, count } = data;

      const initialSituationData = await Promise.all(
        rows.map(async (requestObj) => {
          const situation: OrdersSituation[] = JSON.parse(requestObj.situation);

          return {
            id: requestObj.id,
            situation,
            installments: requestObj.installments,
            sellerId: requestObj.seller ?? "",
            sellerHasCommission: requestObj.sellerCommissioning === "y",
            sellerCommissionPercentage: await SellerService.getCommissionPercentage(
              requestObj.seller ?? ""
            ),
          };
        })
      );

      for (const request of rows) {
        request.customerName = CustomerService.getCustomerName(
          request.customerEntity,
          request.customerName
        );
      }

      setSituationData(initialSituationData);
      setRequests(rows);
      setCountTotalRequests(count);
    },
    []
  );

  useEffect(() => {
    const list: BodyDataBaseProps[][] = [];
    const aux = requests;

    aux.forEach((req) => {
      const amount = formatCurrency(req.totalValue);
      const status =
        req.status === "open"
          ? `Em aberto`
          : req.status === "progress"
          ? "Em andamento"
          : req.status === "attended"
          ? "Atendido"
          : req.status === "canceled"
          ? "Cancelado"
          : req.status === "aproved"
          ? "Aprovado"
          : "";

      // Formatando data
      const [year, month, day] = req.requestDate.split("-");
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      const formatedDate = format(date, "dd/MM/yyyy");

      const getInvoiceUrl = (type: string, urlParam: string) => {
        if (type === "NFCE") return `notas-fiscais-consumidor/${urlParam}`;

        return `notas-fiscais/${urlParam}`;
      };

      const data: BodyDataBaseProps[] = [
        { for: "id", value: String(req.id), id: true },
        { for: "date", value: formatedDate },
        { for: "client", value: req.customerName },
        { for: "reference", value: req.reference ?? "" },
        { for: "amount", value: amount },
        {
          for: "invoice",
          value: req.invoiceId && `${req.invoiceType}:${req.invoiceId}`,
          jsx: !req?.invoiceId ? (
            <></>
          ) : (
            <a
              href={getInvoiceUrl(req.invoiceType, req.invoiceId)}
            >{`${req.invoiceType}:${req.invoiceId}`}</a>
          ),
        },
        { for: "situation", value: status },
        {
          for: "billCreated",
          value: status === "Atendido" ? "y" : "n",
          hidden: true,
        },
        { for: "movedToStock", value: req.movedToStock, hidden: true },
      ];

      list.push(data);
    });

    setBodyData(list);
  }, [requests]);

  const handleClickDelete = useCallback(
    async (id: string) => {
      try {
        const filtered = requests.filter((order) => order.id !== Number(id));

        await api.delete(`requests/${id}`);

        setRequests([...filtered]);
      } catch (error) {
        console.log(error);
      }
    },
    [requests]
  );

  const handleClickEdit = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/${id}`);
    },
    [pathname]
  );

  const handleClickClone = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/duplicar/${id}`);
    },
    [pathname]
  );

  const handleChangeOrder = useCallback(
    async (id: number, situation: OrdersSituation) => {
      try {
        const aux = requests;
        const aux2 = situationData;
        const order = aux.find((order) => order.id === id);
        const sitData = aux2.find((situ) => situ.id === id);

        if (!order || !sitData) {
          throw new Error();
        }

        const hasSituation = sitData.situation;

        if (!hasSituation) {
          throw new Error();
        }

        hasSituation.push(situation);

        order.status = situation.statusSituation;
        order.situation = JSON.stringify(sitData.situation);

        await api.put(`requests/${id}`, order);

        setSituationData(aux2);
        setRequests([...aux]);
      } catch (error) {
        console.log(error);
      }
    },
    [requests, situationData]
  );

  const handleThrowToBillsToReceive = useCallback(
    async ({
      id,
      revenue,
      subCategoryId,
      centerCost,
      installments,
      installmentsCommission,
      centerCostCommission,
      subCategoryIdCommission,
    }: OnThrowToBillsData) => {
      try {
        const aux = requests;
        const auxSituation = situationData;

        const soIndex = aux.findIndex((so) => so.id === id);
        const situationIndex = auxSituation.findIndex((situ) => situ.id === id);

        if (soIndex < 0 || situationIndex < 0) {
          throw new Error();
        }

        const updatedRequest = await RequestService.throwToBills({
          requestId: id,
          installments,
          subCategoryId,
          centerCost,
          installmentsCommission,
          centerCostCommission,
          subCategoryIdCommission,
          requestObj: aux[soIndex],
        });

        const updatedSituation = JSON.parse(updatedRequest.situation);
        auxSituation[situationIndex].situation = updatedSituation;

        setSituationData([...auxSituation]);
        setRequests([...aux]);
      } catch (error) {
        console.log(error);
      }
    },
    [requests, situationData]
  );

  const updateStockMoved = useCallback(
    (id: string, movedToStock: string) => {
      const aux = requests;
      const auxIndex = aux.findIndex((obj) => obj.id === Number(id));

      aux[auxIndex].movedToStock = movedToStock;

      setRequests([...aux]);
    },
    [requests, situationData]
  );

  const handleClickAdd = useCallback(() => {
    pushHistory(`${pathname}/adicionar`);
  }, []);

  const handleBeforeOpenSendEmailOrWhatsappModal = useCallback(
    (id: number, type: "email" | "whatsapp") => {
      const foundRequest = requests.find(
        (requestObj) => requestObj.id === Number(id)
      );

      if (!foundRequest) {
        return false;
      }

      const linkErrors = RequestService.verifyCustomerLink(foundRequest);

      if (linkErrors.length > 0) {
        setLinkedFieldsErrors(linkErrors);
        setLinkedFieldsRedirectUrl(`${pathname}/${id}`);
        setLinkedFieldsErrorMessage(
          `Para enviar ${
            type === "email" ? "email" : "whatsapp"
          } é necessário vincular um cliente cadastrado ao Pedido!`
        );
        setShowModalLinkedFieldsError(true);
        return false;
      }

      return true;
    },
    [requests]
  );

  const handleBeforeAction = useCallback(
    (id: number) => {
      const foundRequest = requests.find(
        (requestObj) => requestObj.id === Number(id)
      );

      if (!foundRequest) {
        return false;
      }

      const linkErrors = RequestService.verifyLinkedFields(foundRequest);

      if (linkErrors.length > 0) {
        setLinkedFieldsErrors(linkErrors);
        setLinkedFieldsRedirectUrl(`${pathname}/${id}`);
        setLinkedFieldsErrorMessage(
          `Para realizar esta ação é necessário completar o cadastro do Pedido!`
        );
        setShowModalLinkedFieldsError(true);
        return false;
      }

      return true;
    },
    [requests]
  );

  const clearSearch = () => {
    setSearchQuery("");
    setSituation("");
    setValueMin(0);
    setValueMax(0);
    setRequestDateMin("");
    setRequestDateMax("");
  };

  const handleClickSearch = useCallback(async () => {
    filtersRef.current = {
      searchQuery,
      situation,
      valueMin,
      valueMax,
      requestDateMin,
      requestDateMax,
    };

    reloadData();
  }, [
    requests,
    searchQuery,
    situation,
    valueMin,
    valueMax,
    requestDateMin,
    requestDateMax,
  ]);

  return (
    <div className="card card-body pt-4 newProductWrapper">
      <ModalLinkedFieldsError
        message={linkedFieldsErrorMessage}
        errors={linkedFieldsErrors}
        showModal={showModalLinkedFieldsError}
        setShowModal={setShowModalLinkedFieldsError}
        redirect={linkedFieldsRedirectUrl}
      />
      <div className="row d-flex align-items-center">
        <div className="col-lg-9 mt-3">
          {user.isAccountant == "n" ? (
            <Button
              type="button"
              variant="success"
              className="mr-2"
              onClick={() => handleClickAdd()}
            >
              Novo pedido
            </Button>
          ) : (
            <></>
          )}
        </div>
        <div className="col-lg-3 mt-3">
          <Search
            query={searchQuery}
            setQuery={setSearchQuery}
            setCollapseAdvancedSearch={setAdvancedSearch}
            onClickSearch={handleClickSearch}
          />
        </div>
      </div>
      <Collapse in={advancedSearch}>
        <div className="row d-flex align-items-center">
          <div className="col-lg-2">
            <TextField
              select
              size="small"
              label="Situação"
              margin="normal"
              variant="outlined"
              value={situation}
            >
              <MenuItem key="0" value="" onClick={() => setSituation("")}>
                Nenhum
              </MenuItem>
              <MenuItem
                key="1"
                value="open"
                onClick={() => setSituation("open")}
              >
                Em Aberto
              </MenuItem>
              <MenuItem
                key="1"
                value="attended"
                onClick={() => setSituation("attended")}
              >
                Atendido
              </MenuItem>
            </TextField>
          </div>
          <div className="col-lg-2">
            <NumericFormat
              style={{ lineHeight: "10px" }}
              label="Valor"
              startAdornment="DE"
              value={valueMin}
              onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-2">
            <NumericFormat
              style={{ lineHeight: "10px" }}
              label="Valor"
              startAdornment="ATÉ"
              value={valueMax}
              onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">DE</InputAdornment>
                ),
              }}
              value={requestDateMin}
              onChange={(e) => setRequestDateMin(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">ATÉ</InputAdornment>
                ),
              }}
              value={requestDateMax}
              onChange={(e) => setRequestDateMax(e.target.value)}
            />
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button onClick={handleClickSearch} className="mr-3">
              Pesquisar
            </Button>

            <Button onClick={clearSearch}>Limpar</Button>
          </div>
        </div>
      </Collapse>

      <div className="mt-3">
        <ListWithModalChangeSituation
          hasStock
          order
          typeOrder="request"
          headData={headData}
          bodyData={bodyData}
          onEdit={handleClickEdit}
          onClone={handleClickClone}
          objectOrder={situationData}
          onDelete={handleClickDelete}
          onChangeOrder={handleChangeOrder}
          onThrowToBills={handleThrowToBillsToReceive}
          onStockMoved={updateStockMoved}
          onBeforeOpenSendEmailModal={(id) =>
            handleBeforeOpenSendEmailOrWhatsappModal(id, "email")
          }
          onBeforeOpenSendWhatsappModal={(id) =>
            handleBeforeOpenSendEmailOrWhatsappModal(id, "whatsapp")
          }
          onBeforeAction={handleBeforeAction}
          sortable={true}
          loadData={loadData}
          totalCount={countTotalRequests}
          triggerLoad={triggerLoad}
          setTriggerLoad={setTriggerLoad}
        />
      </div>
    </div>
  );
}
