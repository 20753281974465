import React, { useEffect, useState } from "react";
import { Form, FormGroup, Col } from "react-bootstrap";
import api from "../../../services/Api";
import { api as apiUrl } from "../../../services/ApiURL";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

interface ImageUploaderProps {
  data: any;
  images: any;
  setImages: any;
}

interface ImagePreview {
  preview: string | null;
  imageUploaded?: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  data,
  images,
  setImages,
}: ImageUploaderProps) => {
  const [principalImage, setPrincipalImage] = useState<ImagePreview>({
    preview: null,
  });
  const [otherImages, setOtherImages] = useState<ImagePreview[]>([]);
  const [maxOtherImagesReached, setMaxOtherImagesReached] = useState(false);

  useEffect(() => {
    if (images[0]) {
      const imageUrl = images[0] ? toAbsoluteUrl(`${apiUrl}/${images[0]}`) : "";
      setPrincipalImage({ preview: imageUrl });
    }
    if (images.length > 1) {
      setOtherImages(
        images.slice(1).map((item: any) => ({
          preview: toAbsoluteUrl(`${apiUrl}/${item?.replace("blob:", "")}`),
        }))
      );
    }
  }, [images]);

  const handleImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "principal" | "other",
    index = 0
  ) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      let newImages: String[] = [...images];

      const previewUrl = URL.createObjectURL(files[0]);
      var formFile = new FormData();
      const imageFile = files[0];
      formFile.append("image", imageFile, imageFile.name);
      const imageUploaded = (await api.post("/image", formFile)).data;

      if (type === "principal") {
        setPrincipalImage({ preview: previewUrl });
        newImages[0] = imageUploaded;
      } else {
        if (otherImages.length < 3) {
          setOtherImages([...otherImages, { preview: previewUrl }]);
          newImages[index] = imageUploaded;
          if (otherImages.length === 2) setMaxOtherImagesReached(true);
        }
      }

      setImages([...newImages]);
      const raw = {
        images: JSON.stringify([...newImages]),
      };

      await api.post(`/products/${data.id}`, raw);
    }
  };

  const removeImage = async (type: "principal" | "other", index: number) => {
    let newImages: String[] = [...images];
    if (type === "principal") {
      setPrincipalImage({ preview: null });
      newImages[index] = "null";
    } else {
      newImages.splice(index + 1, 1);
      setOtherImages(otherImages.filter((_, i) => i !== index));
      if (otherImages.length === 3 && index === otherImages.length - 1)
        setMaxOtherImagesReached(false);
    }
    setImages([...newImages]);
    const raw = {
      images: JSON.stringify([...newImages]),
    };
    await api.post(`/products/${data.id}`, raw);
  };

  return (
    <div>
      <FormGroup>
        <Form.Label>Imagem Principal:</Form.Label>
        <Col sm={9}>
          <div
            className="image-input image-input-outline"
            style={{ display: "inline-block", marginRight: "20px" }}
          >
            <div
              className="image-input-wrapper"
              style={{
                backgroundImage: `url(${principalImage.preview})`,
              }}
            />
            <Form.Label
              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="change"
              data-toggle="tooltip"
              title=""
              data-original-title="Alterar imagem"
            >
              <i className="fa fa-pen icon-sm text-muted"></i>
              <Form.Control
                type="file"
                onChange={(e: any) => handleImageChange(e, "principal")}
              />
            </Form.Label>
            {principalImage.preview && (
              <Form.Label
                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title=""
                data-original-title="Remover imagem"
                onClick={() => removeImage("principal", 0)}
              >
                <i className="fa fa-times icon-sm text-muted"></i>
              </Form.Label>
            )}
          </div>
          {!principalImage.preview && (
            <div
              data-action="add"
              data-toggle="tooltip"
              title=""
              data-original-title="Adicionar imagem"
              style={{ width: "250px", gap: "8px" }}
            >
              <Form.Control
                type="file"
                style={{ width: "400px" }}
                onChange={(e: any) => handleImageChange(e, "principal")}
              />
            </div>
          )}
        </Col>
      </FormGroup>
      <FormGroup>
        <Form.Label sm={3}>Outras Imagens (Máx. 3):</Form.Label>
        <Col sm={9}>
          {otherImages.map((imageItem: any, index: number) => (
            <div
              key={index}
              className="image-input image-input-outline"
              style={{
                display: "inline-block",
                marginRight: "20px",
                marginBottom: "10px",
              }}
            >
              <div
                className="image-input-wrapper"
                style={{
                  backgroundImage: `url(${imageItem.preview})`,
                }}
              />
              <Form.Label
                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Alterar imagem"
              >
                <i className="fa fa-pen icon-sm text-muted"></i>
                <Form.Control
                  type="file"
                  onChange={(e: any) =>
                    handleImageChange(e, "other", index + 1)
                  }
                />
              </Form.Label>
              <Form.Label
                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title=""
                data-original-title="Remover imagem"
                onClick={() => removeImage("other", index)}
              >
                <i className="fa fa-times icon-sm text-muted"></i>
              </Form.Label>
            </div>
          ))}
          {!maxOtherImagesReached && (
            <div
              className="image-input image-input-outline"
              style={{ display: "inline-block", marginRight: "20px" }}
            >
              <div
                data-action="add"
                data-toggle="tooltip"
                title=""
                data-original-title="Adicionar imagem"
                style={{ width: "250px", gap: "8px" }}
              >
                <Form.Control
                  type="file"
                  style={{ width: "400px" }}
                  onChange={(e: any) =>
                    handleImageChange(e, "other", otherImages.length + 1)
                  }
                />
              </div>
            </div>
          )}
        </Col>
      </FormGroup>
    </div>
  );
};

export default ImageUploader;
