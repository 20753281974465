import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { format } from "date-fns";
import {
  Button,
  Modal,
  Spinner,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import {
  makeStyles,
  Grid,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
  Popper,
} from "@material-ui/core";

import api from "../../services/Api";
import { ListProducts } from "./ListProducts";
import { Product, useProductsInstallments } from "./context";

import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import { NumericFormat } from "../../components/NumericFormat";
import { UnderlineLink } from "../../components/UnderlineLink";
import { OrdersSituation } from "../../components/ListWithModalChangeSituation";

import "../../style.css";
import {
  ServiceContractData,
  frequency as frequencyEnum,
} from "../../types/ServiceContractData";
import { yesOrNo } from "../../types/yesOrNo";
import { CompanyNfseActivity } from "../../types/CompanyNfseActivity";
import {
  extractDateStringFromTimestamp,
  getLastDayOfMonth,
} from "../../utils/dateTimeHelper";
import ModalError from "../../components/ModalError";
import { frequencyToMonths } from "../../utils/frequencyToMonths";
import LogService from "../../services/LogService";
import { SubCategory } from "../../types/Dre";
import CustomerSelect from "../../components/CustomerSelect";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import SellerService from "../../services/SellerService";
import { BankAccount } from "../../types/BankAccount";
import BankAccountService from "../../services/BankAccountService";
import { CenterCost } from "../../types/CenterCost";
import CenterCostService from "../../services/CenterCostService";
import DreCategoryService from "../../services/DreCategoryService";
import { ApiResourceSelectAddButton } from "../../components/ApiResourceSelectAddButton";
import { AddAccountBankModal } from "../../components/AccountBank/AddAccountBankModal";
import useAddAccountBank from "../../hooks/addAccountBank";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { Customer } from "../../types/Customer";
import { NewProductModal } from "../../components/Product/NewProductModal";
import useNewProductForm from "../../hooks/newProductForm";
import { Product as GeneralProduct } from "../../types/Product";
import { Product as SelectableProduct } from "../../services/ProductService";
import CustomerService from "../../services/CustomerService";
import useSellerUser from "../../hooks/sellerUser";
import { bankNumberIsInter } from "../../utils/bankNumberIsInter";
import BillToReceiveService from "../../services/BillToReceiveService";
import {
  deleteMultipleFiles,
  uploadMultipleFiles,
} from "../../utils/FilesHelper";
import UploadFiles from "../../components/UploadFiles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  cnpjError: {
    marginLeft: theme.spacing(1),
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  popper: {
    width: "fit-content !important",
    zIndex: 999,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

export function NewServiceContract() {
  const [forceUpdate, setForceUpdate] = useState(false);

  const [frequency, setFrequency] = useState("");

  const [activeTab, setActiveTab] = useState("service");
  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [warranty, setWarranty] = useState("");
  const [comments, setComments] = useState("");
  const [internalComments, setInternalComments] = useState("");
  const [references, setReferences] = useState("");
  const [numberSO, setNumberSO] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [customer, setCustomer] = useState<number>();
  const [seller, setSeller] = useState("");
  const [centerCost, setCenterCost] = useState("");
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [expireDay, setExpireDay] = useState("");
  const [daysBeforeExpire, setDaysBeforeExpire] = useState("5");
  const [billType, setBillType] = useState("account");
  const [accountBank, setAccountBank] = useState("");
  const [selectedBankAccountIsInter, setSelectedBankAccountIsInter] = useState(
    false
  );
  const [generateNfse, setGenerateNfse] = useState(false);
  const [sellerCommissioning, setSellerCommissioning] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendWhats, setSendWhats] = useState(false);
  const [readjustmentPeriod, setReadjustmentPeriod] = useState(0);
  const [nextReadjustmentDate, setNextReadjustmentDate] = useState("");
  const [nextReadjustmentPercentage, setNextReadjustmentPercentage] = useState(
    ""
  );
  const [dreSubCategoryId, setDreSubCategoryId] = useState(0);

  //Erros
  const [customerIsInvalid, setCustomerIsInvalid] = useState(false);
  const [totalValueIsInvalid, setTotalValueIsInvalid] = useState(false);
  const [expireDayIsInvalid, setExpireDayIsInvalid] = useState(false);
  const [dreSubCategoryIdIsInvalid, setDreSubCategoryIdIsInvalid] = useState(
    false
  );
  const [accountBankIsInvalid, setAccountBankIsInvalid] = useState(false);

  const attachmentRef = useRef<HTMLInputElement>(null);

  // NFSE
  const [activity, setActivity] = useState<number | undefined>();
  const [activities, setActivities] = useState<CompanyNfseActivity[]>([]);
  const [nature, setNature] = useState(0);
  const [placeDelivery, setPlaceDelivery] = useState("endereço do emitente");
  const [aliquot, setAliquot] = useState("0,00");
  const [holdIss, setHoldIss] = useState("n");

  const [totalValue, setTotalValue] = useState("R$0,00");
  const [totalValueServices, setTotalValueServices] = useState("R$0,00");
  const [isSubmit, setIsSubmit] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [showModalError, setShowModalError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const { idToClone } = useParams<{ idToClone: string }>();
  const classes = useStyles();
  const history = useHistory();
  const { userSellerInfo } = useSellerUser();
  const { register, handleSubmit } = useForm();
  const { services, dispatchServices } = useProductsInstallments();

  // Modal AddAccountBank
  const {
    showModalAddAccountBank,
    setShowModalAddAccountBank,
  } = useAddAccountBank();
  const handleCreateAccountBank = (accountBank: BankAccount) => {
    handleChangeAccountBank(accountBank);
  };

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomer(createdCustomer.id);
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  // Modal New Product
  const [productBeingCreated, setProductBeingCreated] = useState<{
    index: number;
    product: Product;
  } | null>(null);
  const {
    showModalNewProduct,
    setShowModalNewProduct,
    newProductDefaultData,
    setNewProductDefaultData,
  } = useNewProductForm();

  const handleCreateProduct = useCallback(
    (createdProduct: GeneralProduct) => {
      if (!productBeingCreated) return;

      const selectableProduct: SelectableProduct = {
        ...(createdProduct as any),
        saleValue: formatToFloat(createdProduct.saleValue),
      };

      const amount = productBeingCreated.product.amount || 1;

      dispatchServices({
        type: "CHANGE",
        payload: {
          index: productBeingCreated.index,
          product: {
            ...productBeingCreated.product,
            amount,
            unitaryValue:
              productBeingCreated.product.unitaryValue ||
              selectableProduct.saleValue,
            totalValue:
              productBeingCreated.product.totalValue ||
              selectableProduct.saleValue * amount,
            INSTANCE: selectableProduct,
          },
        },
      });
    },
    [productBeingCreated]
  );

  const handleClickAddProduct = (
    typedText: string,
    index: number,
    product: Product
  ) => {
    setNewProductDefaultData({
      nameProduct: typedText,
      selectedType: "service",
    });
    setProductBeingCreated({ index, product });
    setShowModalNewProduct(true);
  };

  useEffect(() => {
    if (userSellerInfo) {
      setSeller(String(userSellerInfo.id));
    }
  }, [userSellerInfo]);

  useEffect(() => {
    async function getDefaultAccountBank() {
      const {
        standardAccountForRecipes,
      } = await BankAccountService.getDefaultBankAccounts();
      handleChangeAccountBank(standardAccountForRecipes);
    }

    getDefaultAccountBank();
    getNumberOs();
    getActivities();
  }, []);

  useLayoutEffect(() => {
    setStartDate(format(new Date(), "yyyy-MM-dd"));
  }, []);

  useEffect(() => {
    const totalService = services.reduce(
      (acc, { totalValue }) => acc + totalValue,
      0
    );

    const partial = totalService;

    const total = partial;

    setTotalValueServices(formatCurrency(totalService));
    setTotalValue(formatCurrency(total));
  }, [services]);

  useEffect(() => {
    if (!idToClone) return;

    async function getOrderToCloneData() {
      const { data: contractToClone } = await api.get<ServiceContractData>(
        `serviceContract/${idToClone}`
      );

      if (!contractToClone) return;

      const services: Product[] = contractToClone.services
        ? JSON.parse(contractToClone.services)
        : [];

      dispatchServices({
        type: "INITIAL",
        payload: {
          index: 0,
          products: services,
        },
      });

      setNumberSO(contractToClone.number);
      setStartDate(
        contractToClone.startDate
          ? extractDateStringFromTimestamp(contractToClone.startDate)
          : ""
      );
      setFinalDate(
        contractToClone.finalDate
          ? extractDateStringFromTimestamp(contractToClone.finalDate)
          : ""
      );
      setCustomer(
        contractToClone.customer ? Number(contractToClone.customer) : undefined
      );
      setSeller(contractToClone.seller ? String(contractToClone.seller) : "");
      setCenterCost(
        contractToClone.centerCost ? String(contractToClone.centerCost) : ""
      );
      setNextPaymentDate(
        contractToClone.nextPaymentDate
          ? extractDateStringFromTimestamp(contractToClone.nextPaymentDate)
          : ""
      );
      setExpireDay(contractToClone.expireDay ?? "");
      setDaysBeforeExpire(contractToClone.daysBeforeExpire ?? "");
      setBillType(contractToClone.billType ?? "account");
      handleChangeAccountBank(contractToClone.bankAccountEntity);
      setGenerateNfse(contractToClone.generateNfse === "y");
      setSellerCommissioning(contractToClone.sellerCommissioning === "y");
      setSendEmail(contractToClone.sendEmail === "y");
      setSendWhats(contractToClone.sendWhats === "y");
      setTotalValue(formatCurrency(contractToClone.totalValue));
      setDescription(contractToClone.description ?? "");
      setWarranty(contractToClone.warranty ?? "");
      setComments(contractToClone.comments ?? "");
      setInternalComments(contractToClone.internalComments ?? "");
      setFrequency(contractToClone.frequency ?? frequencyEnum.MONTHLY);
      setReferences(contractToClone.referencies ?? "");
      setReadjustmentPeriod(contractToClone.readjustmentPeriod ?? 0);
      setNextReadjustmentDate(
        contractToClone.nextReadjustmentDate
          ? extractDateStringFromTimestamp(contractToClone.nextReadjustmentDate)
          : ""
      );
      setNextReadjustmentPercentage(
        formatCurrency(contractToClone.nextReadjustmentPercentage)
      );
      setDreSubCategoryId(contractToClone.dreSubCategoryId ?? 0);

      if (contractToClone.nfseData) {
        const nfseData = JSON.parse(contractToClone.nfseData);

        setActivity(nfseData.activity);
        setNature(nfseData.nature);
        setPlaceDelivery(nfseData.placeDelivery);
        setAliquot(nfseData.aliquot);
        setHoldIss(nfseData.holdIss);
      }
    }

    getOrderToCloneData();
  }, [idToClone]);

  const handleChangeAccountBank = useCallback(
    (accountBank: BankAccount | null | undefined) => {
      setAccountBank(String(accountBank?.id ?? ""));
      setSelectedBankAccountIsInter(
        accountBank ? bankNumberIsInter(accountBank.numberBank) : false
      );
    },
    []
  );

  async function getNumberOs() {
    const response = await api.get("serviceContract/lastNumber");
    const lastNumber = Number(response.data.lastNumber || 0);
    setNumberSO(lastNumber + 1);
  }

  async function onSubmit(data: any) {
    try {
      setIsSubmit(true);

      const isValid = await inputsVerify(data);

      if (!isValid) {
        setIsSubmit(false);
        return;
      }

      let filesPath: any = [];
      if (filesSelected) {
        filesPath = await uploadMultipleFiles(
          filesSelected,
          setShowModalError,
          setMsgError,
          setIsSubmit
        );
        if (filesPath === false) {
          return;
        }
      }

      if (billType === "billet") {
        // Verificando se o banco selecionado está corretamente configurado para emitir boletos
        const bank = await BankAccountService.getBankAccountById(accountBank);
        const bankError = await BankAccountService.validateBilletRequiredInformation(
          bank
        );

        if (bankError) {
          setMsgError(bankError);
          setShowModalError(true);
          setIsSubmit(false);
          return;
        }
      }

      const situation: OrdersSituation[] = [];

      situation.push({
        dateSituation: format(Date.now(), "yyyy-MM-dd"),
        commentsSituation: "",
        statusSituation: "open",
      });

      const raw = {
        description,
        warranty,
        comments,
        internalComments,
        referencies: references,
        number: numberSO,
        customer: Number(customer),
        seller: Number(seller),
        status: "open",
        totalValue: formatToFloat(totalValue),
        startDate: startDate ? new Date(startDate) : null,
        finalDate: finalDate ? new Date(finalDate) : null,
        centerCost: centerCost || null,
        nextPaymentDate: nextPaymentDate ? new Date(nextPaymentDate) : null,
        expireDay: expireDay,
        daysBeforeExpire: daysBeforeExpire || 5,
        billType: billType,
        bankAccount: accountBank || null,
        sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,
        generateNfse: generateNfse ? yesOrNo.YES : yesOrNo.NO,
        sendEmail: sendEmail ? yesOrNo.YES : yesOrNo.NO,
        sendWhats: sendWhats ? yesOrNo.YES : yesOrNo.NO,
        frequency: frequency || frequencyEnum.MONTHLY,
        readjustmentPercentage: 0,
        readjustmentPeriod: readjustmentPeriod,
        nextReadjustmentDate: nextReadjustmentDate
          ? new Date(nextReadjustmentDate)
          : null,
        nextReadjustmentPercentage: formatToFloat(nextReadjustmentPercentage),
        dreSubCategoryId: dreSubCategoryId,

        services: JSON.stringify(
          services.filter((service) => service.INSTANCE?.id)
        ),
        situation: JSON.stringify(situation),
        nfseData: generateNfse
          ? JSON.stringify({
              activity,
              nature,
              placeDelivery,
              aliquot,
              holdIss,
            })
          : null,
        attachments: JSON.stringify(filesPath),
      };

      const result = await api.post("serviceContract", raw);

      history.push("/contrato-servico");

      LogService.logRegister({
        itemId: result.data.id,
        module: "Contratos",
        itemName: "Contrato",
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadAttachment(id: number): Promise<boolean> {
    if (attachmentRef && attachmentRef.current) {
      if (
        !attachmentRef.current.files ||
        attachmentRef.current.files.length === 0
      ) {
        return false;
      }
      const file = attachmentRef.current.files[0];
      if (!file) {
        return false;
      }

      var formFile = new FormData();
      formFile.append("file", file, file.name);

      const response = await api.post("/file", formFile);
      const raw = {
        attachment: response.data,
      };

      await api.put(`serviceContract/${id}`, raw);
      return true;
    }

    return false;
  }

  async function inputsVerify(data: any) {
    setCustomerIsInvalid(false);
    setTotalValueIsInvalid(false);
    setExpireDayIsInvalid(false);
    setDreSubCategoryIdIsInvalid(false);
    setAccountBankIsInvalid(false);
    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      setServiceError(i, service, false);
    }

    if (!customer) {
      setMsgError("Informe o cliente!");
      setShowModalError(true);
      setCustomerIsInvalid(true);
      return false;
    }

    const customerObj = await CustomerService.getCustomerById(customer);
    if (customerObj.limitCredit !== null) {
      setMsgError(
        "Não é possível vincular o contrato à um cliente com limite de crédito definido. Alterar em Cadastros -> Clientes/Fornecedores"
      );
      setShowModalError(true);
      setCustomerIsInvalid(true);
      return false;
    }

    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      if (service.totalValue && !service.INSTANCE?.id) {
        setServiceError(i, service, true);
        setMsgError("Selecione um serviço!");
        setActiveTab("service");
        setShowModalError(true);
        return false;
      }
    }

    if (formatToFloat(totalValue) <= 0) {
      setActiveTab("service");
      setMsgError(
        "O total dos serviços não pode ser igual ou inferior à zero!"
      );
      setShowModalError(true);
      setTotalValueIsInvalid(true);
      return false;
    }

    if (!expireDay) {
      setActiveTab("payment");
      setMsgError("Informe o dia do vencimento!");
      setShowModalError(true);
      setExpireDayIsInvalid(true);
      return false;
    }

    if (!startDate) {
      setActiveTab("details");
      setMsgError("Informe a data inicial do contrato!");
      setShowModalError(true);
      return false;
    }

    if (!dreSubCategoryId) {
      setActiveTab("details");
      setMsgError("Campo Plano de Contas é obrigatório!");
      setShowModalError(true);
      setDreSubCategoryIdIsInvalid(true);
      return false;
    }

    if (!accountBank) {
      setActiveTab("payment");
      setMsgError("Campo conta bancária é obrigatório!");
      setShowModalError(true);
      setAccountBankIsInvalid(true);
      return false;
    }

    if (selectedBankAccountIsInter && billType === "billet") {
      const validationResult = await BillToReceiveService.validateGenerateInterBilletFields(
        { amount: totalValue, customerId: customer }
      );

      if (validationResult.error) {
        switch (validationResult.field) {
          case "amount":
            setActiveTab("totalServiceOrder");
            setTotalValueIsInvalid(true);
            break;
          case "customerId":
            setCustomerIsInvalid(true);
            break;
        }

        setMsgError(validationResult.message);
        setShowModalError(true);
        return false;
      }
    }

    return true;
  }

  function setServiceError(index: number, service: Product, error?: boolean) {
    dispatchServices({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...service,
          hasError: error,
        },
      },
    });
  }

  useEffect(() => {
    dispatchServices({ type: "INITIAL", payload: { index: 0, products: [] } });
    dispatchServices({ type: "ADD" });
  }, []);

  function validateNextPaymentDate(expire: string, paymentDate: string) {
    if (!paymentDate || !expire) return;

    const paymentDateObj = new Date(`${paymentDate} 00:00:00`);
    if (!(paymentDateObj instanceof Date)) {
      setNextPaymentDate("");
      return;
    }

    const expireDayNumber = Number(expire);
    const monthLastDay = getLastDayOfMonth(
      paymentDateObj.getFullYear(),
      paymentDateObj.getMonth() + 1
    );
    if (monthLastDay <= expireDayNumber) {
      paymentDateObj.setDate(monthLastDay);
    } else {
      paymentDateObj.setDate(expireDayNumber);
    }

    setNextPaymentDate(
      extractDateStringFromTimestamp(paymentDateObj.toISOString())
    );
  }

  function updateNextReadjustmentDate(period: number, frequency: string) {
    if (!nextPaymentDate) return;

    const readjustmentDateObj = new Date(`${nextPaymentDate} 00:00:00`);
    if (!(readjustmentDateObj instanceof Date)) {
      setNextReadjustmentDate("");
      return;
    }

    readjustmentDateObj.setMonth(
      readjustmentDateObj.getMonth() + period * frequencyToMonths(frequency)
    );

    setNextReadjustmentDate(
      extractDateStringFromTimestamp(readjustmentDateObj.toISOString())
    );
  }

  async function getActivities() {
    const response = await api.get<CompanyNfseActivity[]>(
      "companyNfseActivities"
    );
    setActivities(response.data);
  }

  function handleChangeFrequency(event: React.ChangeEvent<HTMLInputElement>) {
    setFrequency(event.target.value);
    updateNextReadjustmentDate(readjustmentPeriod, event.target.value);
  }

  function handleChangeExpireDay(event: React.ChangeEvent<HTMLInputElement>) {
    const expireDayNumber = Number(event.target.value);
    let expireDayValue = event.target.value;

    if (expireDayNumber <= 0) {
      expireDayValue = "";
    } else if (expireDayNumber > 31) {
      expireDayValue = "31";
    }

    setExpireDay(expireDayValue);
    setForceUpdate((prevState) => !prevState);

    validateNextPaymentDate(expireDayValue, nextPaymentDate);
  }

  function handleChangeNextPaymentDate(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setNextPaymentDate(event.target.value);

    validateNextPaymentDate(expireDay, event.target.value);
  }

  function handleChangeReadjustmentPeriod(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setReadjustmentPeriod(Number(event.target.value));
    updateNextReadjustmentDate(Number(event.target.value || 0), frequency);
  }

  function handleChangeBillType(event: React.ChangeEvent<HTMLInputElement>) {
    const type = event.target.value;
    if (type === "billet") {
      setSendEmail(true);
      setSendWhats(true);
    } else {
      setSendEmail(false);
      setSendWhats(false);
    }
    setBillType(type);
  }
  function handleChangeGenerateNfse(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setGenerateNfse(checked);
    setActivity(undefined);
    setNature(0);
    setPlaceDelivery("endereço do emitente");
    setAliquot("0,00");
    setHoldIss("n");
  }

  function handleChangeActivity(
    event: React.ChangeEvent<{}>,
    selected: CompanyNfseActivity | null
  ) {
    setActivity(selected?.id ?? undefined);
  }

  return (
    <div className="card card-custom gutter-b mt-4 p-5 newProductWrapper">
      <ModalError
        showModalError={showModalError}
        setShowModalError={setShowModalError}
        msgError={msgError}
      />

      <AddAccountBankModal
        showModal={showModalAddAccountBank}
        setShowModal={setShowModalAddAccountBank}
        onCreateAccountBank={handleCreateAccountBank}
      />

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <NewProductModal
        showModal={showModalNewProduct}
        setShowModal={setShowModalNewProduct}
        onCreateProduct={handleCreateProduct}
        defaultData={newProductDefaultData}
        allowChangeType={false}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body p-0 mb-6">
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <TextField
                size="small"
                label="Descrição"
                margin="normal"
                variant="outlined"
                className={classes.textField}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item lg={3}>
              <CustomerSelect
                label="Cliente *"
                hasError={customerIsInvalid}
                customerId={customer}
                setCustomerId={setCustomer}
                onClickAddCustomer={handleClickAddCustomer}
              />
            </Grid>

            <Grid item lg={3}>
              <ApiResourceSelect
                label="Vendedor"
                getOptionLabel={(option: any) => option.name}
                value={seller}
                onSelect={(option) =>
                  setSeller(option ? String(option.id) : "")
                }
                apiSearchHandler={(typedText) =>
                  SellerService.getSellersFiltered({ name: typedText })
                }
                getSelectedOption={(loadedOptions) => {
                  if (!seller) return null;
                  return (
                    loadedOptions.find(
                      (option) => option.id === Number(seller)
                    ) ?? SellerService.getSellerById(seller)
                  );
                }}
              />
            </Grid>

            <Grid item lg={1}>
              <TextField
                disabled
                type="text"
                size="small"
                margin="normal"
                variant="outlined"
                label="N°"
                className={classes.textField}
                value={numberSO}
              />
            </Grid>

            <Grid
              item
              lg={1}
              className="d-flex align-items-end justify-content-end"
            >
              <Button
                type="submit"
                className="mb-2"
                variant="primary"
                disabled={isSubmit}
              >
                {isSubmit ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />

                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <span>Salvar</span>
                )}
              </Button>
            </Grid>

            <Modal
              show={showModalSuccess}
              onHide={() => {
                setShowModalSuccess(false);
              }}
              aria-labelledby="contained-modal-warning"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                  <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                  Sucesso
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <b>Dados criados com sucesso !</b>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalSuccess(!showModalSuccess);
                    window.location.href = "/contrato-servico/adicionar";
                  }}
                >
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </Grid>
        </div>

        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="form-tabs"
          className="mb-5"
        >
          <Tab eventKey="service" title="Serviços">
            <div className={`card-body p-4`}>
              {services.map((service, index) => (
                <ListProducts
                  key={index}
                  index={index}
                  product={service}
                  service
                  onClickAddProduct={handleClickAddProduct}
                />
              ))}

              <UnderlineLink
                text="adicionar outro serviço"
                onClick={() => dispatchServices({ type: "ADD" })}
              />
            </div>
          </Tab>

          <Tab eventKey="totalServiceOrder" title="Totais da Ordem de Serviço">
            <div className="card-body">
              <Grid container spacing={5}>
                <div className="col p-1">
                  <NumericFormat
                    label="Valor dos serviços"
                    disabled
                    startAdornment="R$"
                    value={totalValueServices}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    label="Total"
                    className={`${classes.error}`}
                    disabled
                    startAdornment="R$"
                    value={totalValue}
                    error={totalValueIsInvalid}
                  />
                </div>
              </Grid>
            </div>
          </Tab>

          <Tab eventKey="payment" title="Pagamento">
            <div className="card-body p-4">
              <Grid container spacing={6} className="align-items-center">
                <Grid item lg={2}>
                  <NumericFormat
                    label="Dia de Vencimento*"
                    margin="normal"
                    variant="outlined"
                    className={`mx-2 ${classes.error}`}
                    thousandSeparator={""}
                    decimalScale={0}
                    value={expireDay}
                    onChange={handleChangeExpireDay}
                    error={expireDayIsInvalid}
                  />
                </Grid>
                <Grid item lg={2}>
                  <NumericFormat
                    label="Dias para gerar Fatura"
                    className={`mx-2 ${""}`}
                    thousandSeparator={""}
                    decimalScale={0}
                    value={daysBeforeExpire}
                    onChange={(e) => setDaysBeforeExpire(e.target.value)}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    value={frequency}
                    onChange={handleChangeFrequency}
                    size="small"
                    select
                    label="Periodicidade"
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    defaultValue={frequencyEnum.MONTHLY}
                  >
                    <MenuItem value={frequencyEnum.MONTHLY}>Mensal</MenuItem>
                    <MenuItem value={frequencyEnum.BIMONTHLY}>
                      Bimestral
                    </MenuItem>
                    <MenuItem value={frequencyEnum.TRIMONTHLY}>
                      Trimestral
                    </MenuItem>
                    <MenuItem value={frequencyEnum.QUARTERLY}>
                      Quadrimestral
                    </MenuItem>
                    <MenuItem value={frequencyEnum.SEMIANNUAL}>
                      Semestral
                    </MenuItem>
                    <MenuItem value={frequencyEnum.ANNUAL}>Anual</MenuItem>
                  </TextField>
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    size="small"
                    type="date"
                    margin="normal"
                    label="Próximo Pagamento"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={` ${classes.textField} ${classes.error} `}
                    value={nextPaymentDate}
                    onChange={handleChangeNextPaymentDate}
                  />
                </Grid>
                <Grid item lg={3}>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        checked={sellerCommissioning}
                        onChange={(e, checked) =>
                          setSellerCommissioning(checked)
                        }
                        className={classes.textField}
                      />
                    }
                    label="Emitir comissionamento (vendedor)"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={6}
                className="mt-2 d-flex align-items-center"
              >
                <Grid item lg={2}>
                  <TextField
                    size="small"
                    select
                    label="Tipo da fatura"
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    value={billType}
                    onChange={handleChangeBillType}
                  >
                    <MenuItem key="0" value="account">
                      Conta
                    </MenuItem>

                    <MenuItem key="1" value="billet">
                      Boleto
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item lg={2}>
                  <ApiResourceSelect
                    label="Conta bancária *"
                    getOptionLabel={(option: BankAccount) =>
                      `${option.nameBank} - ${option.name}`
                    }
                    value={accountBank}
                    hasError={accountBankIsInvalid}
                    onSelect={(option) =>
                      setAccountBank(String(option?.id ?? ""))
                    }
                    apiSearchHandler={(typedText) =>
                      BankAccountService.getBankAccountsFiltered({
                        name: typedText,
                        situation: "y",
                      })
                    }
                    getSelectedOption={(loadedOptions) => {
                      if (!accountBank) return null;
                      return (
                        loadedOptions.find(
                          (option) => option.id === Number(accountBank)
                        ) ?? BankAccountService.getBankAccountById(accountBank)
                      );
                    }}
                    renderAddButton={
                      <ApiResourceSelectAddButton
                        label="Adicionar Conta Bancária"
                        onClick={() => setShowModalAddAccountBank(true)}
                      />
                    }
                  />
                </Grid>
                <Grid item lg={2}>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        checked={generateNfse}
                        onChange={handleChangeGenerateNfse}
                        className={classes.textField}
                      />
                    }
                    label="Gerar nota fiscal"
                    labelPlacement="end"
                  />
                </Grid>
                {billType === "billet" && (
                  <Grid item lg={3}>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          checked={sendEmail}
                          onChange={(e, checked) => setSendEmail(checked)}
                          className={classes.textField}
                        />
                      }
                      label="Enviar cobrança por e-mail"
                      labelPlacement="end"
                    />
                  </Grid>
                )}
                {billType === "billet" && (
                  <Grid item lg={3}>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          checked={sendWhats}
                          onChange={(e, checked) => setSendWhats(checked)}
                          className={classes.textField}
                        />
                      }
                      label="Enviar cobrança por WhatsApp"
                      labelPlacement="end"
                    />
                  </Grid>
                )}
              </Grid>

              {generateNfse && (
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <Autocomplete
                        size="small"
                        noOptionsText="Sem opções"
                        options={activities}
                        getOptionLabel={({
                          serviceCode,
                          tributeCode,
                          description,
                        }) =>
                          `${serviceCode} - ${tributeCode} - ${description}`
                        }
                        onChange={(event, newInputValue) =>
                          handleChangeActivity(event, newInputValue)
                        }
                        value={
                          activities.find(
                            (activityObj) => activityObj.id === activity
                          ) ?? null
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            label="Atividade"
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-4">
                      <TextField
                        select
                        label="Natureza da operação"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={nature}
                        onChange={(e) => setNature(Number(e.target.value))}
                      >
                        <MenuItem key="0" value="0">
                          Selecione
                        </MenuItem>

                        <MenuItem key="1" value="1">
                          Tributação no município
                        </MenuItem>

                        <MenuItem key="2" value="2">
                          Tributação fora do município
                        </MenuItem>

                        <MenuItem key="3" value="3">
                          isenção
                        </MenuItem>

                        <MenuItem key="4" value="4">
                          Imune
                        </MenuItem>

                        <MenuItem key="5" value="5">
                          Processo Judicial
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-4">
                      <TextField
                        select
                        label="Local da prestação"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={placeDelivery}
                        onChange={(e) => setPlaceDelivery(e.target.value)}
                      >
                        <MenuItem key="0" value="endereço do emitente">
                          Endereço do emitente
                        </MenuItem>

                        <MenuItem key="1" value="endereço do destinatario">
                          Endereço do destinatário
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="% Alíquota"
                        value={aliquot}
                        onChange={(e) => setAliquot(e.target.value)}
                        startAdornment="%"
                      />
                    </div>

                    <div className="col-lg-3 d-flex align-items-center">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            color="primary"
                            checked={holdIss == "y" ? true : false}
                            onChange={() =>
                              setHoldIss(holdIss == "y" ? "n" : "y")
                            }
                          />
                        }
                        label="Reter ISS"
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                </div>
              )}

              <Grid container spacing={6} className="mt-3">
                <Grid item lg={2}>
                  <NumericFormat
                    label="Período Reajuste"
                    className="inputSmaller mx-2"
                    thousandSeparator={""}
                    decimalScale={0}
                    value={readjustmentPeriod}
                    onChange={handleChangeReadjustmentPeriod}
                  />
                </Grid>
                <Grid item lg={2}>
                  <NumericFormat
                    className="mx-2"
                    label="% Próximo Reajuste"
                    startAdornment="%"
                    value={nextReadjustmentPercentage}
                    onChange={(e) =>
                      setNextReadjustmentPercentage(e.target.value)
                    }
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    size="small"
                    type="date"
                    margin="normal"
                    label="Data Próximo Reajuste"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={nextReadjustmentDate}
                    onChange={(e) => setNextReadjustmentDate(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
          </Tab>

          <Tab eventKey="details" title="Detalhes da OS">
            <div className="card-body pl-6 pr-12">
              <Grid container spacing={6}>
                <Grid item>
                  <TextField
                    size="small"
                    type="date"
                    margin="normal"
                    label="Data Inicial"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={` ${classes.textField}`}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="date"
                    margin="normal"
                    label="Data Final"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={`${classes.textField} ${classes.error} `}
                    value={finalDate}
                    onChange={(e) => setFinalDate(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              Indica o término do contrato e encerramento da
                              geração de cobrança, caso não informado o contrato
                              terá duração inderteminada
                            </Tooltip>
                          }
                        >
                          <IconButton edge="end">
                            <i className="flaticon-info"></i>
                          </IconButton>
                        </OverlayTrigger>
                        // <InputAdornment position="end">
                        //     <OverlayTrigger
                        //         trigger="click"
                        //         placement="top"
                        //         overlay={
                        //             <Tooltip id='tooltip-top'>
                        //                 Indica o término do contrato e encerramento da geração de cobrança, caso não informado o contrato terá duração inderteminada
                        //             </Tooltip>
                        //         }
                        //     >
                        //         <IconButton
                        //             edge="end"
                        //         >
                        //             <i className="flaticon-info"></i>
                        //         </IconButton>
                        //     </OverlayTrigger>
                        // </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="text"
                    margin="normal"
                    label="Garantia"
                    variant="outlined"
                    className={classes.textField}
                    value={warranty}
                    onChange={(e) => setWarranty(e.target.value)}
                  />
                </Grid>

                <Grid item>
                  <ApiResourceSelect
                    label="Centro de Custos"
                    getOptionLabel={(option: CenterCost) => option.name}
                    value={centerCost}
                    onSelect={(option) =>
                      setCenterCost(option ? String(option.id) : "")
                    }
                    apiSearchHandler={(typedText) =>
                      CenterCostService.getCenterCostsFiltered({
                        name: typedText,
                      })
                    }
                    getSelectedOption={(loadedOptions) => {
                      if (!centerCost) return null;
                      return (
                        loadedOptions.find(
                          (option) => option.id === Number(centerCost)
                        ) ?? CenterCostService.getCenterCostById(centerCost)
                      );
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6} className="mt-2">
                <Grid item lg={4}>
                  <ApiResourceSelect
                    style={{ width: "100%" }}
                    label="Plano de Contas *"
                    getOptionLabel={(option: SubCategory) => option.name}
                    value={dreSubCategoryId}
                    hasError={dreSubCategoryIdIsInvalid}
                    onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                    apiSearchHandler={(typedText) =>
                      DreCategoryService.getDreSubCategoriesFiltered(
                        { name: typedText },
                        "revenue"
                      )
                    }
                    getSelectedOption={(loadedOptions) => {
                      if (!dreSubCategoryId) return null;
                      return (
                        loadedOptions.find(
                          (option) => option.id === Number(dreSubCategoryId)
                        ) ??
                        DreCategoryService.getDreSubCategoryById(
                          dreSubCategoryId
                        )
                      );
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    size="small"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="Referência"
                    className={classes.textField}
                    value={references}
                    onChange={(e) => setReferences(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Observações"
                    className={classes.textField}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </Grid>

                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Observações internas"
                    className={classes.textField}
                    value={internalComments}
                    onChange={(e) => setInternalComments(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
          </Tab>

          <Tab eventKey="attachment" title="Anexos">
            <Grid container spacing={3} className="p-4">
              <Grid item lg={6}>
                <UploadFiles
                  label="Contrato/Anexos"
                  filesSelected={filesSelected}
                  setFilesSelected={setFilesSelected}
                  filesNamesSaved={filesNamesSaved}
                  setFilesNamesSaved={setFilesNamesSaved}
                />
              </Grid>
            </Grid>
          </Tab>
        </Tabs>
      </form>
    </div>
  );
}
