import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import axios from "../../services/Api";

import {
  formatCurrency,
  formatIntegerToMoney,
  formatMoneyToInteger,
  formatToFloat,
} from "../../utils/formatCurrency";
import {
  BodyDataBaseProps,
  HeadDataBaseProps,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import { PdvSale } from "../../types/PdvSale";
import { PdvSaleItem } from "../../types/PdvSaleItem";
import { PdvSalePayment } from "../../types/PdvSalePayment";
import { convertPaymentMethodKeyToName } from "../../utils/convertPaymentMethodKeyToName";
import ListSimple from "../../components/ListSimple";
import { NumericFormat } from "../../components/NumericFormat";
import { CashRegister, yesOrNo } from "../../types/CashRegister";
import ModalCashRegister, { ModalCashRegisterType } from "./ModalCashRegister";
import { getDate } from "../../utils/dateTimeHelper";
import { useSelector } from "react-redux";
import { BsVariant } from "../../types/BsVariant";
import "../../style.css";
import { Search } from "../../components/Search";
import { Collapse, InputAdornment, TextField } from "@material-ui/core";
import useBackendLoad from "../../hooks/backendReload";
import { getNameByTypePeople } from "../../utils/getNameByTypePeople";
import useCurrentBalance from "../../hooks/useCurrentBalance";
import useNfceDetails from "../../hooks/nfceDetails";
import { ModalNfDetails } from "../../components/ModalNfDetails";
import CustomerService from "../../services/CustomerService";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import { BankAccount } from "../../types/BankAccount";
import BankAccountService from "../../services/BankAccountService";
import { useCompanyBranch } from "../../hooks/companyBranch";
import useQueryParams from "../../hooks/queryParams";

type Filters = {
  searchQuery: string;
  createdDateMin: string;
  createdDateMax: string;
  valueMin: number;
  valueMax: number;
};

const headData: HeadDataBaseProps[] = [
  { reference: "id", value: "Nº" },
  { reference: "createdDate", value: "Data/Hora" },
  { reference: "userId", value: "Operador" },
  { reference: "customerId", value: "Cliente" },
  { reference: "totalValue", value: "Valor Total" },
];
const pdvSaleItemsHeadData: HeadDataBaseProps[] = [
  { reference: "id", value: "COD" },
  { reference: "name", value: "PRODUTO" },
  { reference: "quantity", value: "QUANTIDADE" },
  { reference: "unitValue", value: "UNITÁRIO" },
  { reference: "totalValue", value: "TOTAL" },
];

export function ListPdv() {
  const { user } = useSelector((state: any) => state.auth);
  const { selectedCompany } = useCompanyBranch();

  const [data, setData] = useState<PdvSale[]>([]);
  const [countTotalSales, setCountTotalSales] = useState(0);
  const [cashRegister, setCashRegister] = useState<CashRegister>();
  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();
  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
  const [pdvSaleItemsBodyData, setPdvSaleItemsBodyData] = useState<
    BodyDataBaseProps[][]
  >([]);
  const [pdvSale, setPdvSale] = useState<PdvSale>();
  const [pdvSaleUser, setPdvSaleUser] = useState<any>();
  const [pdvSaleItems, setPdvSaleItems] = useState<PdvSaleItem[]>([]);
  const [pdvSalePayments, setPdvSalePayments] = useState<PdvSalePayment[]>([]);

  const [startValue, setStartValue] = useState(0);
  const [limitValue, setLimitValue] = useState(0);

  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalOpenCashRegister, setShowModalOpenCashRegister] = useState(
    false
  );
  const [showModalCashRegister, setShowModalCashRegister] = useState(false);
  const [modalCashRegisterType, setModalCashRegisterType] = useState<
    ModalCashRegisterType
  >(ModalCashRegisterType.CLOSE);
  const [msgError, setMsgError] = useState<string | JSX.Element>("");
  const [showModalError, setShowModalError] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [valueMin, setValueMin] = useState(0);
  const [valueMax, setValueMax] = useState(0);
  const [createdDateMin, setCreatedDateMin] = useState("");
  const [createdDateMax, setCreatedDateMax] = useState("");
  const filtersRef = useRef<Filters | null>(null);

  const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

  const [accountBank, setAccountBank] = useState("");
  const [availableMoney, setAvailableMoney] = useState(0);
  const [loadingCalc, setLoadingCalc] = useState(false);

  const { getQueryParam } = useQueryParams();
  const { getAccountBalance } = useCurrentBalance();

  const {
    showModalDetails: showModalDetailsNfce,
    setShowModalDetails: setShowModalDetailsNfce,
    cancelNote,
    setCancelNote,
    cancelReason,
    setCancelReason,
    nfceDetails,
    setNfceDetails,
    cancelError,
    setCancelError,
    cancelNfce,
    getNfe,
  } = useNfceDetails();

  useEffect(() => {
    const pdvDetailsId = getQueryParam("pdvDetailsId");
    if (pdvDetailsId) {
      handleDetailsClick(pdvDetailsId);
      pushHistory("pdv");
    }
  }, [getQueryParam]);

  async function calcAvailableMoney(accountBankId: number) {
    if (!accountBankId) return;

    setLoadingCalc(true);

    var totalValueAccount = await getAccountBalance(accountBankId);
    console.log(totalValueAccount);
    setAvailableMoney(totalValueAccount);

    setLoadingCalc(false);
  }

  async function getCashRegister() {
    const cashRegister = await axios.get("/pdvCashRegister/active/first");
    setCashRegister(cashRegister.data ? cashRegister.data : undefined);
  }

  async function getPdvSale(id: string) {
    const result = await axios.get(`/pdvSales/${id}`);
    const pdvSale = result.data;
    setPdvSale(pdvSale);

    const resultItems = await axios.get(`/pdvSaleItems/pdvSale/${id}`);
    const pdvSaleItems = resultItems.data;
    setPdvSaleItems(pdvSaleItems);

    const resultPayments = await axios.get(`/pdvSalePayments/pdvSale/${id}`);
    const pdvSalePayments = resultPayments.data;
    setPdvSalePayments(pdvSalePayments);

    const foundUser = pdvSale.userEntity;
    setPdvSaleUser(foundUser);
  }

  const handleClickOpenPdv = useCallback(() => {
    if (!cashRegister) {
      setMsgError("O caixa ainda não foi aberto");
      setShowModalError(true);
      return;
    }

    if (!selectedCompany?.pdvStockLocationId) {
      setMsgError(
        "Local de estoque padrão para vendas no PDV não foi configurado. Defina em Dados da Empresa -> Parâmetros -> PDV"
      );
      setShowModalError(true);
      return;
    }

    pushHistory(`${pathname}/venda`);
  }, [cashRegister, selectedCompany]);

  const handleOpenCashRegisterClick = useCallback(() => {
    setShowModalOpenCashRegister(true);
  }, [cashRegister]);

  const handleCloseCashRegisterClick = useCallback(() => {
    if (!cashRegister) {
      setMsgError("O caixa ainda não foi aberto");
      setShowModalError(true);
      return;
    }

    setModalCashRegisterType(ModalCashRegisterType.CLOSE);

    setShowModalCashRegister(true);
  }, [cashRegister]);

  const handleMovementCashRegisterClick = useCallback(() => {
    if (!cashRegister) {
      setMsgError("O caixa ainda não foi aberto");
      setShowModalError(true);
      return;
    }

    setModalCashRegisterType(ModalCashRegisterType.MOVEMENT);

    setShowModalCashRegister(true);
  }, [cashRegister]);

  async function handleDetailsClick(id: string) {
    setPdvSale(undefined);
    setPdvSaleItems([]);
    setPdvSalePayments([]);
    setShowModalDetails(true);

    await getPdvSale(id);
  }

  async function handleOpenCashRegisterSubmit() {
    if (!accountBank) {
      alert("Selecione uma conta bancária para o pdv!");
    } else {
      let lastCashRegister: CashRegister = (
        await axios.get(`pdvCashRegister/not_active/last`)
      ).data;
      const lastCashRegisterValue = lastCashRegister
        ? lastCashRegister.currentValue
        : 0;

      const rawPdvCashRegister = {
        userId: user.id,
        startValue: startValue,
        limitValue: limitValue,
        bankAccount: accountBank,
        currentValue: lastCashRegisterValue + startValue,
        active: yesOrNo.YES,
        createdDate: getDate().fullDateStr,
        openedDate: getDate().fullDateStr,
      };

      //
      try {
        const result = await axios.post("pdvCashRegister", rawPdvCashRegister);
        setCashRegister(result.data);

        // Guardando valor em caixa na conta bancaria selecionada
        await axios.patch(
          `/accountBank/cashierMoney/${result.data.bankAccount}`,
          { valueToAdd: startValue }
        );
      } catch (error) {
        console.log(error);
      }

      setShowModalOpenCashRegister(false);
    }
  }

  function handleMoneyInputChange(event: any, setter: Function) {
    setter(formatMoneyToInteger(event.target.value));
  }

  const detailsNfceButtonCondition = useCallback(
    (id: string) => {
      const row = data.find((sale) => String(sale.id) === id);

      if (!row) return false;

      return !!row.nfceId;
    },
    [data]
  );

  const handleClickDetailsNfce = useCallback(
    async (id: string) => {
      const row = data.find((sale) => String(sale.id) === id);

      if (!row || !row.nfceId) return false;

      setShowModalDetailsNfce(true);
      setCancelNote(false);
      setCancelError("");
      await getNfe(row.nfceId);
    },
    [getNfe, data]
  );

  const loadData = useCallback(
    async ({
      rowsPerPage,
      currentPage,
      sortDirection,
      sortReference,
    }: LoadDataParams) => {
      const { data } = await axios.get<{ rows: any[]; count: number }>(
        "pdvSales",
        {
          params: {
            skip: rowsPerPage * currentPage,
            take: rowsPerPage,
            filters: filtersRef.current
              ? JSON.stringify(filtersRef.current)
              : undefined,
            sortReference,
            sortDirection,
          },
        }
      );

      const { rows, count } = data;

      const withCustomerName = rows.map((sale: any) => {
        return {
          ...sale,
          userName: sale.userEntity
            ? `${sale.userEntity.firstname} ${sale.userEntity.lastname}`
            : "",
          customerName: getNameByTypePeople(sale.customerEntity),
        };
      });

      setData(withCustomerName);
      setCountTotalSales(count);
    },
    []
  );

  useEffect(() => {
    getCashRegister();
  }, []);

  useEffect(() => {
    const list: BodyDataBaseProps[][] = [];
    const aux = data;

    aux.forEach((item) => {
      const foundUser = item.userEntity;
      console.log(foundUser);

      const data: BodyDataBaseProps[] = [
        { for: "id", value: String(item.id), id: true },
        {
          for: "createdDate",
          value: getDate({ initialDate: new Date(item.createdDate) })
            .fullDateStr,
        },
        {
          for: "userId",
          value:
            foundUser !== undefined && foundUser !== null
              ? `${foundUser.firstname} ${foundUser.lastname}`
              : "",
        },
        {
          for: "customerId",
          value: CustomerService.getCustomerName(item.customerName) || "N/A",
        },
        { for: "totalValue", value: item.totalValue },
      ];

      list.push(data);
    });

    setBodyData(list);
  }, [data]);

  useEffect(() => {
    async function getSaleItemsTableData() {
      if (!pdvSaleItems) {
        return;
      }

      const list: BodyDataBaseProps[][] = [];
      const aux = pdvSaleItems;

      for (const saleItem of aux) {
        const data: BodyDataBaseProps[] = [
          {
            for: "id",
            value: saleItem.variationId
              ? `${saleItem.productId}-${saleItem.variationId}`
              : String(saleItem.productId),
            id: true,
          },
          {
            for: "name",
            value: saleItem.variation
              ? saleItem.variation?.name ?? ""
              : saleItem.product?.name ?? "",
          },
          { for: "quantity", value: String(saleItem.quantity) },
          {
            for: "unitValue",
            value: formatIntegerToMoney(saleItem.unitValue, true),
          },
          {
            for: "totalValue",
            value: formatIntegerToMoney(saleItem.totalValue, true),
          },
        ];

        list.push(data);
      }

      setPdvSaleItemsBodyData(list);
    }

    getSaleItemsTableData();
  }, [pdvSaleItems]);

  const clearSearch = () => {
    setSearchQuery("");
    setValueMin(0);
    setValueMax(0);
    setCreatedDateMin("");
    setCreatedDateMax("");
  };

  const handleClickSearch = useCallback(async () => {
    filtersRef.current = {
      searchQuery,
      valueMin,
      valueMax,
      createdDateMin,
      createdDateMax,
    };

    reloadData();
  }, [data, searchQuery, valueMin, valueMax, createdDateMin, createdDateMax]);

  return (
    <>
      {/* Modal detalhes da nota emitida */}
      <ModalNfDetails
        showModalDetails={showModalDetailsNfce}
        setShowModalDetails={setShowModalDetailsNfce}
        nfceDetails={nfceDetails}
        cancelNote={cancelNote}
        setCancelNote={setCancelNote}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        cancelError={cancelError}
        onConfirmCancel={async () => {
          await cancelNfce();
        }}
      />

      {/* Modal Detalhes da venda */}
      <Modal
        size="xl"
        show={showModalDetails}
        onHide={() => setShowModalDetails(false)}
        aria-labelledby="contained-modal-details"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            Detalhes do Pedido
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {pdvSale != undefined ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-7">
                  <div className="card-deck">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="card-title text-primary">
                          N° DO PEDIDO
                        </h6>
                        <p className="card-text">{pdvSale.id}</p>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h6 className="card-title text-primary">
                          VALOR TOTAL DO PEDIDO
                        </h6>
                        <p className="card-text">
                          {formatIntegerToMoney(pdvSale.totalValue, true)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-deck mt-3">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="card-title text-primary">
                          VALOR RECEBIDO
                        </h6>
                        <p className="card-text">
                          {formatIntegerToMoney(pdvSale.paidValue, true)}
                        </p>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h6 className="card-title text-primary">TROCO</h6>
                        <p className="card-text">
                          {formatIntegerToMoney(pdvSale.changeValue, true)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <strong>Operador:</strong>{" "}
                        {pdvSaleUser
                          ? `${pdvSaleUser.firstname} ${pdvSaleUser.lastname}`
                          : ""}
                      </p>
                      <p>
                        <strong>Cliente:</strong>{" "}
                        {CustomerService.getCustomerName(
                          pdvSale.customerEntity
                        ) || "N/A"}
                      </p>
                      <p>
                        <strong>Desconto:</strong>{" "}
                        {formatIntegerToMoney(pdvSale.discount, true)}{" "}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <strong>Data:</strong>{" "}
                        {
                          getDate({
                            initialDate: new Date(pdvSale.createdDate),
                          }).fullDateStr
                        }
                      </p>
                      <p>
                        <strong>&nbsp;</strong>
                      </p>
                      <p>
                        <strong>Acréscimo:</strong>{" "}
                        {formatIntegerToMoney(pdvSale.addition, true)}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <p>
                        <strong>Forma de Pagamento: </strong>
                        {pdvSalePayments.map((payment, index) => (
                          <span key={index}>
                            {convertPaymentMethodKeyToName(payment.method)}{" "}
                            {index + 1 < pdvSalePayments.length ? (
                              <span>, </span>
                            ) : (
                              <></>
                            )}
                          </span>
                        ))}
                      </p>
                    </div>
                    <div className="col-12">
                      <div>
                        <strong>Observações: </strong>
                      </div>
                      <div
                        className="border border-secondary mt-2 p-2"
                        style={{ minHeight: "80px" }}
                      >
                        {pdvSale.notes}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ListSimple
                headData={pdvSaleItemsHeadData}
                bodyData={pdvSaleItemsBodyData}
              />
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalDetails(!showModalDetails)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Abrir Caixa */}
      <Modal
        show={showModalOpenCashRegister}
        onHide={() => setShowModalOpenCashRegister(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            {cashRegister !== undefined ? "Detalhes Caixa" : "Abrir Caixa"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {cashRegister === undefined ? (
            <>
              <ApiResourceSelect
                label="Conta bancária"
                required
                getOptionLabel={(option: BankAccount) =>
                  `${option.nameBank} - ${option.name}`
                }
                value={accountBank}
                onSelect={(option) => {
                  setAvailableMoney(0);
                  setAccountBank(String(option?.id ?? ""));
                  calcAvailableMoney(option?.id ?? 0);
                }}
                apiSearchHandler={(typedText) =>
                  BankAccountService.getBankAccountsFiltered({
                    name: typedText,
                    situation: "y",
                  })
                }
                getSelectedOption={(loadedOptions) => {
                  if (!accountBank) return null;
                  return (
                    loadedOptions.find(
                      (option) => option.id === Number(accountBank)
                    ) ?? BankAccountService.getBankAccountById(accountBank)
                  );
                }}
              />
              <NumericFormat
                label="Valor em Caixa"
                value={formatIntegerToMoney(startValue)}
                onChange={(e) => {
                  handleMoneyInputChange(e, setStartValue);

                  if (formatToFloat(e.target.value) > availableMoney) {
                    alert(
                      `A conta selecionada tem apenas ${formatCurrency(
                        availableMoney
                      )} disponível!`
                    );
                    setStartValue(0);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loadingCalc ? (
                        <Spinner animation="border" variant="dark" size="sm" />
                      ) : (
                        <>
                          {accountBank
                            ? `Disponível: ${formatCurrency(availableMoney)}`
                            : ""}
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <NumericFormat
                label="Limite de Sangria"
                value={formatIntegerToMoney(limitValue)}
                onChange={(e) => handleMoneyInputChange(e, setLimitValue)}
              />
            </>
          ) : (
            <div className="row mt-2 pl-2">
              <div className="col">
                <p>
                  <strong>Caixa: </strong> Aberto
                </p>
                <p>
                  <strong>Data de abertura: </strong> {cashRegister.openedDate}
                </p>
                <p>
                  <strong>Valor inicial: </strong>{" "}
                  {formatIntegerToMoney(cashRegister.startValue, true)}
                </p>
                <p>
                  <strong>Limite de Sangria: </strong>{" "}
                  {formatIntegerToMoney(cashRegister.limitValue, true)}
                </p>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              setShowModalOpenCashRegister(!showModalOpenCashRegister)
            }
          >
            Fechar
          </Button>
          {cashRegister === undefined ? (
            <Button variant="primary" onClick={handleOpenCashRegisterSubmit}>
              Abrir Caixa
            </Button>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>

      {cashRegister !== undefined ? (
        <ModalCashRegister
          key={cashRegister.id}
          cashRegister={cashRegister}
          setCashRegister={setCashRegister}
          showModalCashRegister={showModalCashRegister}
          setShowModalCashRegister={setShowModalCashRegister}
          title={
            modalCashRegisterType === ModalCashRegisterType.CLOSE
              ? "Fechar Caixa"
              : "Sangria / Suprimento"
          }
          type={modalCashRegisterType}
        />
      ) : (
        <></>
      )}

      {/* Modal Error */}
      <Modal
        show={showModalError}
        onHide={() => setShowModalError(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalError(!showModalError)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card card-body pt-4 newProductWrapper">
        <div className="row d-flex align-items-center">
          <div className="col-lg-9 mt-3">
            {user.isAccountant == "n" ? (
              <>
                <Button
                  type="button"
                  variant="success"
                  className="mr-2 mb-2"
                  onClick={() => handleClickOpenPdv()}
                >
                  Abrir PDV
                </Button>

                <Button
                  type="button"
                  variant="success"
                  className="mr-2 mb-2"
                  onClick={handleOpenCashRegisterClick}
                >
                  {cashRegister !== undefined
                    ? "Detalhes Caixa"
                    : "Abrir Caixa"}
                </Button>

                {cashRegister !== undefined ? (
                  <>
                    <Button
                      type="button"
                      variant="success"
                      className="mr-2 mb-2"
                      onClick={handleMovementCashRegisterClick}
                    >
                      Sangria / Suprimento
                    </Button>

                    <Button
                      type="button"
                      variant="success"
                      className="mr-2 mb-2"
                      onClick={handleCloseCashRegisterClick}
                    >
                      Fechar Caixa
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="col-lg-3 mt-3">
            <Search
              query={searchQuery}
              setQuery={setSearchQuery}
              setCollapseAdvancedSearch={setAdvancedSearch}
              onClickSearch={handleClickSearch}
            />
          </div>
        </div>
        <Collapse in={advancedSearch}>
          <div className="row d-flex align-items-center">
            <div className="col-lg-2">
              <NumericFormat
                label="Valor"
                className="inputSmaller"
                margin="normal"
                customInput={TextField}
                variant="outlined"
                withPrefix={false}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">DE</InputAdornment>
                  ),
                }}
                value={formatIntegerToMoney(valueMin)}
                onChange={(evt) =>
                  setValueMin(formatMoneyToInteger(evt.target.value))
                }
              />
            </div>
            <div className="col-lg-2">
              <NumericFormat
                label="Valor"
                className="inputSmaller"
                margin="normal"
                customInput={TextField}
                variant="outlined"
                withPrefix={false}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">ATÉ</InputAdornment>
                  ),
                }}
                value={formatIntegerToMoney(valueMax)}
                onChange={(evt) =>
                  setValueMax(formatMoneyToInteger(evt.target.value))
                }
              />
            </div>
            <div className="col-lg-3">
              <TextField
                type="date"
                label="Data"
                margin="normal"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">DE</InputAdornment>
                  ),
                }}
                value={createdDateMin}
                onChange={(e) => setCreatedDateMin(e.target.value)}
              />
            </div>
            <div className="col-lg-3">
              <TextField
                type="date"
                label="Data"
                margin="normal"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">ATÉ</InputAdornment>
                  ),
                }}
                value={createdDateMax}
                onChange={(e) => setCreatedDateMax(e.target.value)}
              />
            </div>
            <div className="col-12 d-flex justify-content-end">
              <Button onClick={handleClickSearch} className="mr-3">
                Pesquisar
              </Button>

              <Button onClick={clearSearch}>Limpar</Button>
            </div>
          </div>
        </Collapse>

        <div className="mt-3">
          <ListWithModalChangeSituation
            headData={headData}
            bodyData={bodyData}
            customButtons={[
              {
                class: "btn-light-info",
                content: <i className="p-0 flaticon-eye"></i>,
                variant: BsVariant.INFO,
                popup: "Detalhes do pedido",
                onClick: handleDetailsClick,
              },
              {
                class: "btn-light-info",
                content: <i className="p-0 flaticon-info"></i>,
                variant: BsVariant.INFO,
                popup: "Visualizar nota fiscal",
                onClick: handleClickDetailsNfce,
                showCondition: detailsNfceButtonCondition,
              },
            ]}
            sortable={true}
            loadData={loadData}
            totalCount={countTotalSales}
            triggerLoad={triggerLoad}
            setTriggerLoad={setTriggerLoad}
          />
        </div>
      </div>
    </>
  );
}
