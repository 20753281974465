import React, { useState, useEffect, useCallback, useRef } from "react";
import { Autocomplete } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import { useForm } from "react-hook-form";
import api from "../../services/Api";
import axios from "axios";
import "../../style.css";
import logService from "../../services/LogService";

import {
  MenuItem,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Grid,
} from "@material-ui/core";

import {
  Button,
  Accordion,
  Card,
  Modal,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import ModalLimit from "../../components/ModalLimit";
import { useLocation } from "react-router";
import { getTypeOrderText } from "../../utils/getTypeOrderText";
import ModalSuccess from "../../components/ModalSuccess";
import {
  dateIsBetweenRange,
  extractDateStringFromTimestamp,
} from "../../utils/dateTimeHelper";
import { useStyles } from "../../hooks/styles";
import { addressSearch } from "../../utils/addressSearch";
import useAllUf from "../../hooks/uf";
import { cnpjSearch } from "../../utils/cnpjSearch";
import {
  Customer,
  limitCreditPeriodicity as limitCreditPeriodicityEnum,
} from "../../types/Customer";
import { formatToFloat } from "../../utils/formatCurrency";
import { NumericFormat } from "../NumericFormat";
import UploadFile from "../UploadFile";
import { uploadImage, uploadMultipleFiles } from "../../utils/FilesHelper";
import UploadFiles from "../UploadFiles";

type Inputs = {
  name: string;
  typeRegister: string;
  typePeople: string;
  categoryClassification: string;
  birthDate: string;
  gender: string;
  cpf: string;
  inscriptionInTheSuframa: string;
  corporateName: string;
  fantasyName: string;
  cnpj: string;
  municipalRegistration: string;
  stateRegistration: string;
  exempt: string;
  cell: string;
  tell: string;
  ramal: string;
  fax: string;
  email: string;
  website: string;
  note: string;
  rg: string;
  issuingOrgan: string;
  issueDate: string;
  passport: string;
  foreign: string;
  ruralProducer: string;
  isRuralProducer: string;
  profession: string;
  employer: string;
  isRetiree: string;
  maritalStatus: string;
  initialActivity: string;
  finalActivity: string;
  activityClosed: string;
  finalCostumer: string;
  taxpayerIcms: string;
  noteRemarks: string;
  priceList: string;
  paymentTerm: string;
  nameSeller: string;
  deliveryMode: string;
  carrying: string;
  allowToExceedLimit: string;
  taxRegime: string;
};

type NewCustomerFormProps = {
  defaultData?: NewCustomerDefaultData;
  allowedType?: "both" | "customer" | "supplier";
  onCloseModalLimit: () => void;
  onCustomerCreated?: (createdCustomer: Customer) => void;
  onCloseCustomerCreatedSuccessModal: () => void;
};

export type NewCustomerDefaultData = {
  name?: string;
  typeRegister?: string;
};

export function NewCustomerForm({
  defaultData,
  allowedType = "both",
  onCloseModalLimit,
  onCustomerCreated,
  onCloseCustomerCreatedSuccessModal,
}: NewCustomerFormProps) {
  const [canCreateCustomer, setCanCreateCustomer] = useState(true);
  const [modalLimitMsg, setModalLimitMsg] = useState<string[]>([]);
  const [showModalLimit, setShowModalLimit] = useState(false);
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);
  const classes = useStyles();

  const { allStates } = useAllUf();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      activityClosed: "n",
      categoryClassification: "",
      finalCostumer: "y",
      foreign: "n",
      isRetiree: "n",
      isRuralProducer: "n",
      taxpayerIcms: "n",
      typePeople: "physical",
      typeRegister: "customer",
    },
  });

  const [isSubmit, setIsSubmit] = useState(false);

  const [activeTab, setActiveTab] = useState("details");

  const [name, setName] = useState(defaultData?.name ?? "");
  const [issueDateInvalid, setIssueDateInvalid] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [cpfInvalid, setCpfInvalid] = useState(false);
  const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
  const [fantasyNameInvalid, setFantasyNameInvalid] = useState(false);
  const [cnpjInvalid, setCnpjInvalid] = useState(false);
  const [cellInvalid, setCellInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [limitCreditInvalid, setLimitCreditInvalid] = useState(false);
  // const [limitCreditPeriodicityInvalid, setLimitCreditPeriodicityInvalid] = useState(false);

  const [imageFile, setImageFile] = useState<File>();
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [showModalExist, setShowModalExist] = useState(false);
  const [typeRegister, setTypeRegister] = useState(
    defaultData?.typeRegister ?? "customer"
  );
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cell, setCell] = useState("");
  const [taxpayerIcms, setTaxpayerIcms] = useState("n");
  const [exempt, setExempt] = useState("n");
  const [activityClosed, setActivityClosed] = useState("n");
  const [isRetiree, setIsRetiree] = useState("n");
  const [foreign, setForeign] = useState("n");
  const [isRuralProducer, setIsRuralProducer] = useState("n");
  const [corporateName, setCorporateName] = useState("");
  const [fantasyName, setFantasyName] = useState("");
  const [typePeopleAddress, setTypePeopleAddress] = useState("physical");
  const [typePeople, setTypePeople] = useState("physical");
  const [finalCostumer, setFinalCostumer] = useState("y");
  const [limitCredit, setLimitCredit] = useState("");
  // const [limitCreditPeriodicity, setLimitCreditPeriodicity] = useState<string>("");

  const [address, setAddress] = useState({
    addresses: [
      {
        default: true,
        zipcode: "",
        charge: false,
        delivery: false,
        withdrawal: false,
        address: "",
        number: "",
        district: "",
        city: "",
        state: "",
        country: "",
        complement: "",
        reference: "",
        typePeople: "physical",
        name: "",
        cpf: "",
        cnpj: "",
        phone: "",
        corporateName: "",
        stateRegistration: "",
        copyInformation: "",
      },
    ],
  });

  function addAddress() {
    const allAddress = address.addresses;

    allAddress.push({
      default: !allAddress.length ? true : false,
      zipcode: "",
      charge: false,
      delivery: false,
      withdrawal: false,
      address: "",
      number: "",
      district: "",
      city: "",
      state: "",
      country: "",
      complement: "",
      reference: "",
      typePeople: "physical",
      name: "",
      cpf: "",
      cnpj: "",
      phone: "",
      corporateName: "",
      stateRegistration: "",
      copyInformation: "",
    });

    setAddress({ addresses: allAddress });
  }

  const [contact, setContact] = useState({
    contacts: [
      {
        typeContact: "",
        relationship: "",
        isLegalResponsible: "n",
        name: "",
        birthDate: "",
        gender: "",
        cell: "",
        phone: "",
        extension: "",
        fax: "",
        email: "",
        website: "",
        note: "",
      },
    ],
  });

  function addContact() {
    var allContacts = contact.contacts;

    allContacts.push({
      typeContact: "",
      relationship: "",
      isLegalResponsible: "n",
      name: "",
      birthDate: "",
      gender: "",
      cell: "",
      phone: "",
      extension: "",
      fax: "",
      email: "",
      website: "",
      note: "",
    });

    setContact({ contacts: allContacts });
  }

  function copyInfoOfRegister(checkbox: any, index: number) {
    var addressChanged = address.addresses;

    if (checkbox) {
      addressChanged[index].typePeople = watch("typePeople");

      if (watch("typePeople") === "physical") {
        addressChanged[index].name = name;
        addressChanged[index].cpf = cpf;
      } else {
        addressChanged[index].corporateName = corporateName;
        addressChanged[index].cnpj = cnpj;
        addressChanged[index].stateRegistration = watch("stateRegistration");
      }
      addressChanged[index].phone = watch("tell");

      setAddress({ addresses: addressChanged });
    }
  }

  function changeTypeContact(i: number, value: string) {
    var allContacts = contact.contacts;

    allContacts[i].typeContact = value;

    setContact({ contacts: allContacts });
  }

  function changeDefaultAddress(index: number) {
    var allAddress = address.addresses;

    allAddress.map((value, i) => {
      allAddress[i].default = false;
    });

    allAddress[index].default = !allAddress[index].default;

    setAddress({ addresses: allAddress });
  }

  async function infoAddress(index: number) {
    const checkCep = async () => {
      var allAddress = address.addresses;

      if (!allAddress[index].zipcode) return;

      const cep = await addressSearch(allAddress[index].zipcode);

      allAddress[index].address = cep.logradouro;
      allAddress[index].district = cep.bairro;
      allAddress[index].city = cep.localidade;
      allAddress[index].state = cep.uf;
      if (cep.logradouro) {
        allAddress[index].country = "Brasil";
      }

      setAddress({ addresses: allAddress });
    };
    checkCep();
  }

  useEffect(() => {
    checkLimits();
  }, []);

  async function checkLimits() {
    const responseCustomer = await api.get(
      "companySubscriptionPlans/limits/byField?field=customers"
    );
    const limitCustomer = responseCustomer.data;
    if (
      limitCustomer.max !== -1 &&
      limitCustomer.current >= limitCustomer.max
    ) {
      setCanCreateCustomer(false);
      setShowModalLimit(true);
      setModalLimitMsg([
        "Seu plano atual não permite o cadastro de novos clientes/fornecedores",
      ]);
    }
  }

  const handleCloseModalLimit = useCallback(() => {
    if (!canCreateCustomer) {
      onCloseModalLimit();
    }
  }, [canCreateCustomer]);

  function removeAddress(index: number) {
    var addressChanged = address.addresses;

    addressChanged.splice(index, 1);

    setAddress({ addresses: addressChanged });
  }

  function removeContact(index: number) {
    var contactChanged = contact.contacts;

    contactChanged.splice(index, 1);

    setContact({ contacts: contactChanged });
  }

  async function CheckCnpj(value: string) {
    if (value) {
      const cnpjResponse = await cnpjSearch(value);

      if (cnpjResponse.status === "ERROR") {
        setMsgError(cnpjResponse.message);
        setShowModalExist(true);
        setCnpjInvalid(true);
        setCnpj("");
        return;
      }

      setCorporateName(cnpjResponse.nome);
      setFantasyName(cnpjResponse.fantasia);

      var allAddress = address.addresses;

      allAddress[0].zipcode = cnpjResponse.cep;
      allAddress[0].number = cnpjResponse.numero;
      setAddress({ addresses: allAddress });
      infoAddress(0);
    }
  }

  async function checkEmailCustomerExist() {
    return true;
  }

  async function checkCpfCustomerExist() {
    return true;
  }

  async function checkRgCustomerExist() {
    return true;
  }

  async function checkCnpjCustomerExist() {
    return true;
  }

  async function checkIMCustomerExist() {
    return true;
  }

  async function checkIECustomerExist() {
    return true;
  }

  async function checkCellCustomerExist() {
    return true;
  }

  async function checkEmailContactExist(index: number) {}

  function sanitizeInput(type: string, value: string): string {
    if (type === "cpf" || type === "cell" || type === "cnpj") {
      var str = value;
      str = str.replaceAll(/[^0-9]+/g, "");

      return str;
    } else if (type === "email") {
      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return regex.test(String(value).toLowerCase()).toString();
    }

    return "";
  }

  function inputsVerify(data: Inputs): boolean {
    setIssueDateInvalid(false);
    setNameInvalid(false);
    setCpfInvalid(false);
    setCorporateNameInvalid(false);
    setFantasyNameInvalid(false);
    setCnpjInvalid(false);
    setCellInvalid(false);
    setEmailInvalid(false);
    setLimitCreditInvalid(false);
    // setLimitCreditPeriodicityInvalid(false);

    if (typePeople === "physical") {
      if (!typeRegister) {
        setIsSubmit(false);
        setMsgError("Selecione o tipo de cadastro");
        setShowModalExist(true);

        return false;
      }

      if (!name) {
        setIsSubmit(false);
        setMsgError("Preencha o nome corretamente");
        setShowModalExist(true);
        setNameInvalid(true);

        return false;
      } else if (!cpf || sanitizeInput("cpf", cpf).length !== 11) {
        setActiveTab("details");
        setIsSubmit(false);
        setMsgError("Preencha o cpf corretamente");
        setShowModalExist(true);
        setCpfInvalid(true);

        return false;
      }
    } else if (typePeople === "legal") {
      if (!corporateName) {
        setIsSubmit(false);
        setMsgError("Preencha a razão social da empresa");
        setShowModalExist(true);
        setCorporateNameInvalid(true);

        return false;
      } else if (!fantasyName) {
        setIsSubmit(false);
        setMsgError("Preencha o nome fantasia da empresa");
        setShowModalExist(true);
        setFantasyNameInvalid(true);

        return false;
      } else if (!cnpj || sanitizeInput("cnpj", cnpj).length !== 14) {
        setActiveTab("details");
        setIsSubmit(false);
        setMsgError("Preencha o CNPJ corretamente");
        setShowModalExist(true);
        setCnpjInvalid(true);

        return false;
      }
    }

    if (!cell || sanitizeInput("cell", cell).length !== 11) {
      setActiveTab("details");
      setIsSubmit(false);
      setMsgError("Preencha o celular corretamente");
      setShowModalExist(true);
      setCellInvalid(true);

      return false;
    }

    if (!email || sanitizeInput("email", email) === "false") {
      setActiveTab("details");
      setIsSubmit(false);
      setMsgError("Preencha o email corretamente");
      setShowModalExist(true);
      setEmailInvalid(true);
      return false;
    }

    if (data.issueDate) {
      const dateIsValid = dateIsBetweenRange(
        data.issueDate,
        "",
        extractDateStringFromTimestamp()
      );
      if (!dateIsValid) {
        setActiveTab("additional-info");
        setIsSubmit(false);
        setMsgError("A data de emissão não pode ser maior que a data atual");
        setShowModalExist(true);
        setIssueDateInvalid(true);
        return false;
      }
    }

    return true;
  }

  function readPhoto(file: any) {
    var filePhoto = file.length ? file[0] : null;
    setImageFile(filePhoto);
  }

  async function uploadPhoto() {
    if (imageFile) {
      var formFile = new FormData();
      formFile.append("image", imageFile, imageFile.name);
      console.log(formFile);
      const header = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post("/upload-files", formFile, header).then((response) => {
        console.log(response);
      });
    }
  }

  async function onSubmit(data: Inputs) {
    setIsSubmit(true);

    if (!inputsVerify(data)) return;

    if (
      !(await checkEmailCustomerExist()) ||
      !(await checkCellCustomerExist()) ||
      !(await checkCpfCustomerExist()) ||
      !(await checkCnpjCustomerExist()) ||
      !(await checkRgCustomerExist()) ||
      !(await checkIECustomerExist()) ||
      !(await checkIMCustomerExist())
    ) {
      setIsSubmit(false);
      return;
    }

    try {
      let photoPath: string | boolean | null = "";
      let filesPath: any = [];
      let savedCustomer: Customer | null = null;

      if (fileSelected) {
        photoPath = await uploadImage(
          fileSelected,
          setShowModalExist,
          setMsgError,
          setIsSubmit
        );
        if (photoPath === false) {
          return;
        }
      }

      if (filesSelected) {
        filesPath = await uploadMultipleFiles(
          filesSelected,
          setShowModalExist,
          setMsgError,
          setIsSubmit
        );
        if (filesPath === false) {
          return;
        }
      }

      if (typePeople == "physical") {
        var customerRaw = JSON.stringify({
          name: !name ? null : name,
          cpf: !cpf ? null : cpf.replace(/[^0-9]/g, ""),
          cnpj: !cnpj ? null : cnpj.replace(/[^0-9]/g, ""),
          corporateName: !corporateName ? null : corporateName,
          fantasyName: !fantasyName ? null : fantasyName,
          cell: !cell ? null : cell.replace(/[^0-9]/g, ""),
          email: !email ? null : email,
          birthDate: !data.birthDate ? null : data.birthDate,
          gender: !data.gender ? null : data.gender,
          rg: !rg ? null : rg.replace(/[^0-9]/g, ""),
          issuingAgency: data.issuingOrgan,
          issueDate: data.issueDate,
          passport: data.passport,
          isForeign: foreign,
          ruralProducerRegistration: data.ruralProducer,
          isRuralProducer: isRuralProducer,
          profession: data.profession,
          employer: data.employer,
          isRetiree: isRetiree,
          maritalStatus: data.maritalStatus,
          startOfActivity: data.initialActivity,
          finalOfActivity: data.finalActivity,
          activityClosed: activityClosed,
          exempt: exempt,
          inscriptionInTheSuframa: data.inscriptionInTheSuframa,
          municipalRegistration: data.municipalRegistration,
          stateRegistration: data.stateRegistration,
          phone: !data.tell ? null : data.tell.replace(/[^0-9]/g, ""),
          extension: data.ramal,
          fax: data.fax,
          website: data.website,
          note: data.note,
          registrationStatus: "active",
          typePeople: data.typePeople,
          typeRegister: typeRegister,
          finalCostumer: finalCostumer,
          icmsTaxpayer: taxpayerIcms ?? "n",
          noteRemarks: data.noteRemarks,
          priceList: data.priceList,
          paymentTerm: data.paymentTerm,
          seller: data.nameSeller,
          deliveryMode: data.deliveryMode,
          carrying: data.carrying,
          limitCredit: limitCredit ? formatToFloat(limitCredit) : null,
          allowToExceedLimit: data.allowToExceedLimit,
          taxRegime: data.taxRegime || null,
          photo: photoPath,
          attachments: JSON.stringify(filesPath),
        });

        const objCustomer = await api.post("/customer", customerRaw);

        savedCustomer = objCustomer.data;

        address.addresses.map(async (value, index) => {
          var addressRaw = JSON.stringify({
            customerId: objCustomer.data.id,
            zipcode: value.zipcode,
            publicPlace: value.address,
            number: parseInt(value.number),
            district: value.district,
            city: value.city,
            state: value.state,
            country: value.country,
            defaultAddress: value.default ? "y" : "n",
            charge: value.charge ? "y" : "n",
            delivery: value.delivery ? "y" : "n",
            withdrawal: value.withdrawal ? "y" : "n",
            complement: value.complement,
            reference: value.reference,
            typePeople: value.typePeople,
            name: value.name,
            cpf: value.cpf.replace(/[^0-9]/g, ""),
            cnpj: value.cnpj.replace(/[^0-9]/g, ""),
            phone: value.phone.replace(/[^0-9]/g, ""),
            corporateName: value.corporateName,
            stateRegistration: value.stateRegistration,
          });

          await api.post("/address", addressRaw);
        });

        contact.contacts.map(async (value, index) => {
          var contactRaw = JSON.stringify({
            customerId: objCustomer.data.id,
            typeContact: value.typeContact || "family",
            relationship: value.relationship,
            isLegalResponsible: value.isLegalResponsible
              ? value.isLegalResponsible
              : "n",
            name: value.name,
            birthDate: !value.birthDate ? null : value.birthDate,
            gender: !value.gender ? null : value.gender,
            cell: value.cell.replace(/[^0-9]/g, ""),
            phone: value.phone.replace(/[^0-9]/g, ""),
            extension: value.extension,
            fax: value.fax,
            email: !value.email ? null : value.email,
            website: value.website,
            note: value.note,
          });

          await api.post("/contact", contactRaw);
        });
      } else {
        var customerRaw = JSON.stringify({
          name: !name ? null : name,
          cpf: !cpf ? null : cpf.replace(/[^0-9]/g, ""),
          cnpj: !cnpj ? null : cnpj.replace(/[^0-9]/g, ""),
          corporateName: !corporateName ? null : corporateName,
          fantasyName: !fantasyName ? null : fantasyName,
          cell: !cell ? null : cell.replace(/[^0-9]/g, ""),
          email: !email ? null : email,

          birthDate: !data.birthDate ? null : data.birthDate,
          gender: !data.gender ? null : data.gender,
          rg: !rg ? null : rg.replace(/[^0-9]/g, ""),
          issuingAgency: data.issuingOrgan,
          issueDate: data.issueDate,
          passport: data.passport,
          isForeign: foreign,
          ruralProducerRegistration: data.ruralProducer,
          isRuralProducer: isRuralProducer,
          profession: data.profession,
          employer: data.employer,
          isRetiree: isRetiree,
          maritalStatus: data.maritalStatus,
          startOfActivity: data.initialActivity,
          finalOfActivity: data.finalActivity,
          activityClosed: activityClosed,
          exempt: exempt,
          inscriptionInTheSuframa: data.inscriptionInTheSuframa,
          municipalRegistration: data.municipalRegistration,
          stateRegistration: data.stateRegistration,
          phone: !data.tell ? null : data.tell.replace(/[^0-9]/g, ""),
          extension: data.ramal,
          fax: data.fax,
          website: data.website,
          note: data.note,
          registrationStatus: "active",
          typePeople: data.typePeople,
          typeRegister: typeRegister,
          finalCostumer: finalCostumer,
          icmsTaxpayer: taxpayerIcms ?? "n",
          noteRemarks: data.noteRemarks,
          priceList: data.priceList,
          paymentTerm: data.paymentTerm,
          seller: data.nameSeller,
          deliveryMode: data.deliveryMode,
          carrying: data.carrying,
          limitCredit: limitCredit ? formatToFloat(limitCredit) : null,
          allowToExceedLimit: data.allowToExceedLimit,
          taxRegime: data.taxRegime || null,
          photo: photoPath,
          attachments: JSON.stringify(filesPath),
        });

        const objCustomer = await api.post<Customer>("/customer", customerRaw);

        savedCustomer = objCustomer.data;

        address.addresses.map(async (value, index) => {
          var addressRaw = JSON.stringify({
            customerId: objCustomer.data.id,
            zipcode: value.zipcode,
            publicPlace: value.address,
            number: parseInt(value.number),
            district: value.district,
            city: value.city,
            state: value.state,
            country: value.country,
            defaultAddress: value.default ? "y" : "n",
            charge: value.charge ? "y" : "n",
            delivery: value.delivery ? "y" : "n",
            withdrawal: value.withdrawal ? "y" : "n",
            complement: value.complement ? "y" : "n",
            reference: value.reference,
            typePeople: value.typePeople,
            name: value.name,
            cpf: value.cpf.replace(/[^0-9]/g, ""),
            cnpj: value.cnpj.replace(/[^0-9]/g, ""),
            phone: value.phone.replace(/[^0-9]/g, ""),
            corporateName: value.corporateName,
            stateRegistration: value.stateRegistration,
          });

          await api.post("/address", addressRaw);
        });

        contact.contacts.map(async (value, index) => {
          var contactRaw = JSON.stringify({
            customerId: objCustomer.data.id,
            typeContact: value.typeContact || "family",
            relationship: value.relationship,
            isLegalResponsible: value.isLegalResponsible
              ? value.isLegalResponsible
              : "n",
            name: value.name,
            birthDate: !value.birthDate ? null : value.birthDate,
            gender: !value.gender ? null : value.gender,
            cell: value.cell.replace(/[^0-9]/g, ""),
            phone: value.phone.replace(/[^0-9]/g, ""),
            extension: value.extension,
            fax: value.fax,
            email: !value.email ? null : value.email,
            website: value.website,
            note: value.note,
          });

          await api.post("/contact", contactRaw);
        });
      }

      savedCustomer = savedCustomer!;

      logService.logRegister({
        itemId: savedCustomer.id,
        module: "Clientes / Fornecedores",
        itemName:
          savedCustomer.typePeople === "physical"
            ? savedCustomer.name
            : savedCustomer.corporateName,
      });

      if (onCustomerCreated) {
        onCustomerCreated(savedCustomer);
      }

      setShowModalSuccess(true);
    } catch (error) {
      console.log("error", error);
      setMsgError("Ocorreu um erro ao processar a requisição");
      setShowModalExist(true);
    }

    setIsSubmit(false);
  }

  function handleCloseModalSuccess() {
    onCloseCustomerCreatedSuccessModal();
  }

  return (
    <div className="row card card-body p-4 newProductWrapper">
      <ModalLimit
        showModalLimit={showModalLimit}
        setShowModalLimit={setShowModalLimit}
        messages={modalLimitMsg}
        onHide={handleCloseModalLimit}
      />
      <ModalSuccess
        msgModal={"Dados criados com sucesso !"}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        onClose={handleCloseModalSuccess}
      />

      <Modal
        show={showModalExist}
        onHide={() => setShowModalExist(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalExist(!showModalExist)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={classes.container}>
        <div className="col-lg-12">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="h-100 d-flex justify-content-end align-items-end">
                <Button
                  type="button"
                  variant="primary"
                  disabled={isSubmit}
                  onClick={handleSubmit(onSubmit)}
                  className="mb-2"
                >
                  {isSubmit ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />

                      <span className="ml-2">Aguarde...</span>
                    </>
                  ) : (
                    <>
                      <span>Salvar</span>
                    </>
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="mb-3">
            <Grid item xs={3}>
              <TextField
                select
                size="small"
                label="Tipo de cadastro"
                className={classes.textField}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
                defaultValue="customer"
                value={typeRegister}
                onChange={(e) => setTypeRegister(e.target.value)}
              >
                <MenuItem
                  key="0"
                  value="customer"
                  disabled={allowedType === "supplier"}
                >
                  Cliente
                </MenuItem>

                <MenuItem
                  key="1"
                  value="supplier"
                  disabled={allowedType === "customer"}
                >
                  Fornecedor
                </MenuItem>

                <MenuItem key="2" value="both">
                  Ambos
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                label="Tipo de pessoa"
                className={classes.textField}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
                defaultValue={typePeople}
                {...register("typePeople")}
              >
                <MenuItem
                  key="0"
                  value="physical"
                  onClick={() => setTypePeople("physical")}
                >
                  Pessoa Física
                </MenuItem>

                <MenuItem
                  key="1"
                  value="legal"
                  onClick={() => setTypePeople("legal")}
                >
                  Pessoa Jurídica
                </MenuItem>
              </TextField>
            </Grid>

            {typePeople === "physical" ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Nome"
                    required
                    size="small"
                    className={classes.error}
                    margin="normal"
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameInvalid(e.target.value ? false : true);
                    }}
                    error={nameInvalid}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs>
                  <TextField
                    value={corporateName}
                    label="Razão social"
                    required
                    size="small"
                    className={classes.error}
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => {
                      setCorporateName(e.target.value);
                      setCorporateNameInvalid(e.target.value ? false : true);
                    }}
                    error={corporateNameInvalid}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    value={fantasyName}
                    label="Nome fantasia"
                    required
                    size="small"
                    className={classes.error}
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => {
                      setFantasyName(e.target.value);
                      setFantasyNameInvalid(e.target.value ? false : true);
                    }}
                    error={fantasyNameInvalid}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Tabs
            activeKey={activeTab}
            onSelect={(tab: string) => setActiveTab(tab)}
            id="newproduct-form-tabs"
            className="mb-3"
          >
            <Tab eventKey="details" title="Detalhes">
              {typePeople === "physical" ? (
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      type="date"
                      label="Data de nascimento"
                      size="small"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("birthDate")}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Gênero"
                      size="small"
                      className={classes.textField}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      variant="outlined"
                      defaultValue=""
                      {...register("gender")}
                    >
                      <MenuItem key="0" value="">
                        Selecione
                      </MenuItem>

                      <MenuItem key="0" value="male">
                        Masculino
                      </MenuItem>

                      <MenuItem key="0" value="female">
                        Feminino
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs>
                    <NumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      format="###.###.###-##"
                      label="CPF"
                      required
                      mask="_"
                      value={cpf}
                      onChange={(e) => {
                        setCpf(e.target.value);
                        setCpfInvalid(e.target.value ? false : true);
                      }}
                      error={cpfInvalid}
                      onBlur={() => checkCpfCustomerExist()}
                    />
                  </Grid>

                  <Grid item xs>
                    <NumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      format="(##) #####-####"
                      label="Celular"
                      required
                      mask="_"
                      value={cell}
                      onChange={(e) => {
                        setCell(e.target.value);
                        setCellInvalid(e.target.value ? false : true);
                      }}
                      error={cellInvalid}
                      onBlur={() => checkCellCustomerExist()}
                    />
                  </Grid>

                  <Grid item xs>
                    <NumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      format="(##) ####-####"
                      label="Telefone"
                      mask="_"
                      {...register("tell")}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={3} className="pl-6">
                    <NumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      label="CNPJ"
                      required
                      format="##.###.###/####-##"
                      mask="_"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={() => CheckCnpj(cnpj)}
                            >
                              <i className="flaticon-search"></i>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={cnpj}
                      onChange={(e) => {
                        setCnpj(e.target.value);
                        setCnpjInvalid(e.target.value ? false : true);
                      }}
                      error={cnpjInvalid}
                      onBlur={() => checkCnpjCustomerExist()}
                    />
                  </Grid>

                  <Grid item className="d-flex align-items-center">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e) =>
                            setExempt(e.target.checked ? "y" : "n")
                          }
                        />
                      }
                      label="Isento"
                      labelPlacement="end"
                      className="ml-1"
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      label="Inscrição municipal"
                      size="small"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      {...register("municipalRegistration")}
                      onBlur={() => checkIMCustomerExist()}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      label="Inscrição estadual"
                      size="small"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      {...register("stateRegistration")}
                      onBlur={() => checkIECustomerExist()}
                    />
                  </Grid>

                  <Grid item xs>
                    <NumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      format="(##) #####-####"
                      label="Celular"
                      required
                      mask="_"
                      value={cell}
                      onChange={(e) => {
                        setCell(e.target.value);
                        setCellInvalid(e.target.value ? false : true);
                      }}
                      error={cellInvalid}
                      onBlur={() => checkCellCustomerExist()}
                    />
                  </Grid>

                  <Grid item xs>
                    <NumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      format="(##) ####-####"
                      label="Telefone"
                      mask="_"
                      {...register("tell")}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={3}>
                <Grid item lg={2}>
                  <TextField
                    label="Ramal"
                    size="small"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    {...register("ramal")}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Fax"
                    size="small"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    {...register("fax")}
                  />
                </Grid>

                <Grid item lg={3}>
                  <TextField
                    label="E-mail"
                    required
                    size="small"
                    className={classes.error}
                    margin="normal"
                    variant="outlined"
                    // helperText="Este e-mail irá receber a nota fiscal. Para mais de um e-mail, separe por ponto e vírgula (;)"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailInvalid(e.target.value ? false : true);
                    }}
                    error={emailInvalid}
                    onBlur={() => checkEmailCustomerExist()}
                  />
                </Grid>

                <Grid item lg={3}>
                  <TextField
                    label="Site ou Perfil (URL)"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    {...register("website")}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} className="mt-2">
                <Grid item lg={6}>
                  <TextField
                    label="Observações"
                    multiline
                    rows="1"
                    size="small"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    {...register("note")}
                  />
                </Grid>

                <Grid item lg={4}>
                  <UploadFile
                    label="Foto do Cliente/Fornecedor"
                    fileSelected={fileSelected}
                    setFileSelected={setFileSelected}
                    accept="image/jpeg, image/png"
                  />
                  {/* <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label> */}
                </Grid>
              </Grid>

              <Grid container spacing={3} className="mt-2">
                <Grid item lg={4}>
                  <NumericFormat
                    label="Limite de Crédito"
                    startAdornment="R$"
                    value={limitCredit}
                    onChange={(e) => setLimitCredit(e.target.value)}
                    error={limitCreditInvalid}
                  />
                </Grid>
              </Grid>
            </Tab>

            <Tab eventKey="addresses" title="Endereços">
              <>
                {address.addresses.map((addressData, index) => {
                  return (
                    <Card key={index} className="mt-3 p-6">
                      <Card.Body className="row d-flex align-items-center p-0 pr-2 pl-2">
                        <Grid container spacing={3} className="mb-1">
                          <Grid item lg={12} className="p-0 text-right">
                            <button
                              type="button"
                              className="btn btn-link p-0"
                              onClick={() => removeAddress(index)}
                            >
                              <i className="flaticon2-delete"></i>
                            </button>
                          </Grid>

                          <Grid
                            item
                            lg={4}
                            className=" d-flex justify-content-between align-items-center"
                          >
                            <NumberFormat
                              margin="normal"
                              className="inputSmaller mr-3"
                              customInput={TextField}
                              variant="outlined"
                              format="#####-###"
                              label="CEP"
                              mask="_"
                              value={addressData.zipcode}
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].zipcode = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />

                            <Button
                              variant="primary"
                              className="btn-sm"
                              onClick={() => infoAddress(index)}
                            >
                              <i className="flaticon2-search-1 p-0"></i>
                            </Button>
                          </Grid>

                          <Grid
                            item
                            lg={3}
                            className="d-flex align-items-center"
                          >
                            <FormControlLabel
                              value="end"
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={addressData.default}
                                  onChange={() => {
                                    changeDefaultAddress(index);
                                    setTypePeopleAddress("physical");
                                  }}
                                />
                              }
                              label="Endereço padrão"
                              labelPlacement="end"
                              name="defaultAddress"
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].default = allAddress[index]
                                  .default
                                  ? false
                                  : true;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>

                          {addressData.default ? (
                            ""
                          ) : (
                            <Grid
                              item
                              lg={5}
                              className="d-flex flex-row align-items-center justify-content-between"
                            >
                              <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="Cobrança"
                                labelPlacement="end"
                                name="charge"
                                onChange={(e) => {
                                  var allAddress = address.addresses;
                                  allAddress[index].charge = allAddress[index]
                                    .charge
                                    ? false
                                    : true;
                                  setAddress({ addresses: allAddress });
                                }}
                              />

                              <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="Entrega"
                                labelPlacement="end"
                                name="delivery"
                                onChange={(e) => {
                                  var allAddress = address.addresses;
                                  allAddress[index].delivery = allAddress[index]
                                    .delivery
                                    ? false
                                    : true;
                                  setAddress({ addresses: allAddress });
                                }}
                              />

                              <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" />}
                                label="Retirada"
                                labelPlacement="end"
                                name="withdrawal"
                                onChange={(e) => {
                                  var allAddress = address.addresses;
                                  allAddress[index].withdrawal = allAddress[
                                    index
                                  ].withdrawal
                                    ? false
                                    : true;
                                  setAddress({ addresses: allAddress });
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>

                        <Grid container spacing={3} className="mb-1">
                          <Grid item lg={6}>
                            <TextField
                              label="Endereço"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="address"
                              value={addressData.address}
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].address = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>

                          <Grid item lg={2}>
                            <TextField
                              type="number"
                              label="Número"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="number"
                              value={addressData.number}
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].number = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>

                          <Grid item lg={4}>
                            <TextField
                              label="Bairro"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="bairro"
                              value={addressData.district}
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].district = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} className="mb-1">
                          <Grid item lg={4}>
                            <TextField
                              label="Cidade"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="city"
                              value={addressData.city}
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].city = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            lg={2}
                            className="d-flex align-items-center pr-3"
                          >
                            <Autocomplete
                              inputValue={addressData.state}
                              options={allStates.states}
                              getOptionLabel={({ sigla }) => sigla}
                              style={{ width: "100%", marginTop: "6px" }}
                              onInputChange={(event, newInputValue) => {
                                var allAddress = address.addresses;
                                allAddress[index].state = newInputValue;
                                setAddress({ addresses: allAddress });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label="Estado"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item lg={2}>
                            <TextField
                              label="País"
                              margin="normal"
                              size="small"
                              variant="outlined"
                              name="country"
                              value={addressData.country}
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].country = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>

                          <Grid item lg={4}>
                            <TextField
                              label="Complemento"
                              margin="normal"
                              size="small"
                              variant="outlined"
                              name="complement"
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].complement = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item lg={6}>
                            <TextField
                              label="Referência"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="reference"
                              onChange={(e) => {
                                var allAddress = address.addresses;
                                allAddress[index].reference = e.target.value;
                                setAddress({ addresses: allAddress });
                              }}
                            />
                          </Grid>

                          {addressData.default ? (
                            ""
                          ) : (
                            <>
                              <Grid item lg={2}>
                                <TextField
                                  select
                                  label="Tipo de pessoa"
                                  SelectProps={{
                                    MenuProps: {
                                      className: classes.menu,
                                    },
                                  }}
                                  size="small"
                                  margin="normal"
                                  variant="outlined"
                                  name="typePeopleAddress"
                                  defaultValue="physical"
                                  value={addressData.typePeople}
                                  onChange={(e) => {
                                    var allAddress = address.addresses;
                                    allAddress[index].typePeople =
                                      e.target.value;
                                    setAddress({ addresses: allAddress });
                                  }}
                                >
                                  <MenuItem
                                    key="0"
                                    value="physical"
                                    onClick={() =>
                                      setTypePeopleAddress("physical")
                                    }
                                  >
                                    Pessoa física
                                  </MenuItem>

                                  <MenuItem
                                    key="1"
                                    value="legal"
                                    onClick={() =>
                                      setTypePeopleAddress("legal")
                                    }
                                  >
                                    Pessoa Jurídica
                                  </MenuItem>
                                </TextField>
                              </Grid>

                              {addressData.typePeople == "physical" ? (
                                <>
                                  <Grid item lg={4}>
                                    <TextField
                                      id="outlined-error"
                                      label="Nome"
                                      size="small"
                                      margin="normal"
                                      variant="outlined"
                                      name="namePeopleAddress"
                                      value={addressData.name}
                                      onChange={(e) => {
                                        var allAddress = address.addresses;
                                        allAddress[index].name = e.target.value;
                                        setAddress({ addresses: allAddress });
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={3}
                                    style={{ marginTop: "-10px" }}
                                  >
                                    <NumberFormat
                                      margin="normal"
                                      className="inputSmaller"
                                      customInput={TextField}
                                      variant="outlined"
                                      format="###.###.###-##"
                                      label="CPF"
                                      mask="_"
                                      value={addressData.cpf}
                                      onChange={(e) => {
                                        var allAddress = address.addresses;
                                        allAddress[index].cpf = e.target.value;
                                        setAddress({ addresses: allAddress });
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs style={{ marginTop: "-10px" }}>
                                    <NumberFormat
                                      margin="normal"
                                      className="inputSmaller"
                                      customInput={TextField}
                                      variant="outlined"
                                      format="(##) ####-####"
                                      label="Telefone"
                                      value={addressData.phone}
                                      mask="_"
                                      onChange={(e) => {
                                        var allAddress = address.addresses;
                                        allAddress[index].phone =
                                          e.target.value;
                                        setAddress({ addresses: allAddress });
                                      }}
                                    />
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid item lg={4}>
                                    <TextField
                                      label="Razão Social"
                                      required
                                      size="small"
                                      margin="normal"
                                      variant="outlined"
                                      name="corporateNamePeopleAddress"
                                      value={addressData.corporateName}
                                      onChange={(e) => {
                                        var allAddress = address.addresses;
                                        allAddress[index].corporateName =
                                          e.target.value;
                                        setAddress({ addresses: allAddress });
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={3}
                                    style={{ marginTop: "-10px" }}
                                  >
                                    <NumberFormat
                                      margin="normal"
                                      className="inputSmaller"
                                      customInput={TextField}
                                      variant="outlined"
                                      label="CNPJ"
                                      format="##.###.###/####-##"
                                      mask="_"
                                      value={addressData.cnpj}
                                      onChange={(e) => {
                                        var allAddress = address.addresses;
                                        allAddress[index].cnpj = e.target.value;
                                        setAddress({ addresses: allAddress });
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={3}
                                    style={{ marginTop: "-10px" }}
                                  >
                                    <NumberFormat
                                      margin="normal"
                                      className="inputSmaller"
                                      customInput={TextField}
                                      variant="outlined"
                                      format="(##) ####-####"
                                      label="Telefone"
                                      value={addressData.phone}
                                      mask="_"
                                      onChange={(e) => {
                                        var allAddress = address.addresses;
                                        allAddress[index].phone =
                                          e.target.value;
                                        setAddress({ addresses: allAddress });
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={3}
                                    style={{ marginTop: "-10px" }}
                                  >
                                    <TextField
                                      label="Inscrição estadual"
                                      size="small"
                                      margin="normal"
                                      variant="outlined"
                                      value={addressData.stateRegistration}
                                      onChange={(e) => {
                                        var allAddress = address.addresses;
                                        allAddress[index].stateRegistration =
                                          e.target.value;
                                        setAddress({ addresses: allAddress });
                                      }}
                                    />
                                  </Grid>
                                </>
                              )}
                            </>
                          )}

                          {addressData.default ? (
                            ""
                          ) : (
                            <Grid
                              item
                              lg={3}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    color="primary"
                                    onChange={(e) =>
                                      copyInfoOfRegister(
                                        e.target.checked,
                                        index
                                      )
                                    }
                                  />
                                }
                                label="Copiar informações do cadastro"
                                labelPlacement="end"
                                name="copyInfoOfRegister"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Card.Body>
                    </Card>
                  );
                })}

                <Card className="mt-5">
                  <Card.Body className="row p-6">
                    <Button variant="secondary" onClick={addAddress}>
                      Adicionar endereço
                    </Button>
                  </Card.Body>
                </Card>
              </>
            </Tab>
            <Tab eventKey="additional-info" title="Mais informações">
              <Card.Body className="p-0">
                {typePeople == "physical" ? (
                  <>
                    <div className="row">
                      <div className="col-lg-4">
                        <TextField
                          label="RG"
                          size="small"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={rg}
                          onChange={(e) => setRg(e.target.value)}
                          onBlur={() => checkRgCustomerExist()}
                        />
                      </div>

                      <div className="col-lg-4">
                        <TextField
                          label="Órgão expedidor"
                          size="small"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          {...register("issuingOrgan")}
                        />
                      </div>

                      <div className="col-lg-4">
                        <TextField
                          type="date"
                          label="Data de emissão"
                          className={classes.textField}
                          size="small"
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={issueDateInvalid}
                          {...register("issueDate")}
                        />
                      </div>

                      <div className="col-lg-4">
                        <TextField
                          label="Passaporte"
                          size="small"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          {...register("passport")}
                        />
                      </div>

                      <div className="col-lg-2 d-flex align-items-center">
                        <FormControlLabel
                          value="end"
                          className={classes.textField}
                          control={
                            <Checkbox
                              color="primary"
                              onChange={(e) =>
                                setForeign(e.target.checked ? "y" : "n")
                              }
                            />
                          }
                          label="Estrangeiro"
                          labelPlacement="end"
                        />
                      </div>

                      <div className="col-lg-4">
                        <TextField
                          label="Inscrição produtor rural"
                          size="small"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          {...register("ruralProducer")}
                          disabled={isRuralProducer === "y" ? false : true}
                        />
                      </div>

                      <div className="col-lg-2 d-flex align-items-center">
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              color="primary"
                              onChange={(e) => {
                                setIsRuralProducer(
                                  e.target.checked ? "y" : "n"
                                );
                              }}
                            />
                          }
                          className={classes.textField}
                          label="Produtor rural"
                          labelPlacement="end"
                        />
                      </div>

                      <div className="col-lg-4">
                        <TextField
                          label="Profissão"
                          size="small"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          {...register("profession")}
                        />
                      </div>

                      <div className="col-lg-4">
                        <TextField
                          label="Empregador"
                          size="small"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          {...register("employer")}
                        />
                      </div>

                      <div className="col-lg-4 d-flex align-items-center">
                        <FormControlLabel
                          value="end"
                          className={classes.textField}
                          control={
                            <Checkbox
                              color="primary"
                              onChange={(e) =>
                                setIsRetiree(e.target.checked ? "y" : "n")
                              }
                            />
                          }
                          label="Aposentado"
                          labelPlacement="end"
                        />
                      </div>

                      <div className="col-lg-4">
                        <TextField
                          select
                          label="Estado Civíl"
                          size="small"
                          className={classes.textField}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          variant="outlined"
                          {...register("maritalStatus")}
                        >
                          <MenuItem key="0" value="">
                            Selecione
                          </MenuItem>

                          <MenuItem key="1" value="unmarried">
                            Solteiro
                          </MenuItem>

                          <MenuItem key="2" value="married">
                            Casado
                          </MenuItem>

                          <MenuItem key="3" value="widower">
                            Viúvo
                          </MenuItem>

                          <MenuItem key="4" value="separate">
                            Separado
                          </MenuItem>

                          <MenuItem key="5" value="divorced">
                            Divorciado
                          </MenuItem>

                          <MenuItem key="6" value="other">
                            Outro
                          </MenuItem>
                        </TextField>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-4">
                        <TextField
                          label="Inscrição na suframa"
                          size="small"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          helperText="Inscrição na zona franca de Manaus"
                          {...register("inscriptionInTheSuframa")}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-lg-4">
                    <TextField
                      type="date"
                      label="Início da atividade"
                      size="small"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { max: watch("finalActivity") },
                      }}
                      {...register("initialActivity")}
                    />
                  </div>

                  <div className="col-lg-4">
                    <TextField
                      type="date"
                      label="Final da atividade"
                      size="small"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { min: watch("initialActivity") },
                      }}
                      {...register("finalActivity")}
                    />
                  </div>

                  <div className="col-lg-4 d-flex align-items-center">
                    <FormControlLabel
                      value="end"
                      className={classes.textField}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e) =>
                            setActivityClosed(e.target.checked ? "y" : "n")
                          }
                        />
                      }
                      label="Atividade encerrada"
                      labelPlacement="end"
                    />
                  </div>
                </div>
              </Card.Body>
            </Tab>
            <Tab eventKey="contacts" title="Contatos Adicionais">
              <>
                {contact.contacts.map((contactData, i) => {
                  return (
                    <Card key={i} className="mb-3">
                      <Card.Body className="p-6">
                        <Grid container spacing={3}>
                          <Grid item lg={12} className="text-right">
                            <button
                              type="button"
                              className="btn btn-link p-0"
                              onClick={() => removeContact(i)}
                            >
                              <i className="flaticon2-delete"></i>
                            </button>
                          </Grid>

                          <Grid item lg={3}>
                            <TextField
                              select
                              label="Tipo de contato"
                              size="small"
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}
                              margin="normal"
                              variant="outlined"
                              name="typeContact"
                              onChange={(e) =>
                                changeTypeContact(i, e.target.value)
                              }
                            >
                              <MenuItem key="0" value="selecione">
                                Selecione
                              </MenuItem>

                              <MenuItem key="1" value="family">
                                Familiar
                              </MenuItem>

                              <MenuItem key="2" value="personal">
                                Pessoal
                              </MenuItem>

                              <MenuItem key="3" value="professional">
                                Profissional
                              </MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item lg={3}>
                            {contactData.typeContact === "family" ? (
                              <TextField
                                select
                                label="Relação"
                                size="small"
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                margin="normal"
                                variant="outlined"
                                name="relationship"
                                onChange={(e) => {
                                  var allContacts = contact.contacts;
                                  allContacts[i].relationship = e.target.value;
                                  setContact({ contacts: allContacts });
                                }}
                              >
                                <MenuItem key="0" value="selecione">
                                  Selecione
                                </MenuItem>
                                <MenuItem key="0" value="spouse">
                                  Cônjuge
                                </MenuItem>
                                <MenuItem key="1" value="father">
                                  Pai
                                </MenuItem>
                                <MenuItem key="2" value="mom">
                                  Mãe
                                </MenuItem>
                                <MenuItem key="3" value="son">
                                  Filho (a)
                                </MenuItem>
                                <MenuItem key="4" value="brother">
                                  irmão (a)
                                </MenuItem>
                                <MenuItem key="5" value="kinship">
                                  Parente
                                </MenuItem>
                              </TextField>
                            ) : (
                              ""
                            )}

                            {contactData.typeContact == "personal" ? (
                              <TextField
                                select
                                size="small"
                                label="Relação"
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                margin="normal"
                                variant="outlined"
                                name="relationship"
                                onChange={(e) => {
                                  var allContacts = contact.contacts;
                                  allContacts[i].relationship = e.target.value;
                                  setContact({ contacts: allContacts });
                                }}
                              >
                                <MenuItem key="0" value="selecione">
                                  Selecione
                                </MenuItem>
                                <MenuItem key="1" value="friend">
                                  Amigo
                                </MenuItem>
                                <MenuItem key="2" value="known">
                                  Conhecido
                                </MenuItem>
                                <MenuItem key="3" value="colleague">
                                  Colega
                                </MenuItem>
                                <MenuItem key="4" value="others">
                                  Outros
                                </MenuItem>
                              </TextField>
                            ) : (
                              ""
                            )}

                            {contactData.typeContact == "professional" ? (
                              <TextField
                                select
                                size="small"
                                label="Relação"
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                margin="normal"
                                variant="outlined"
                                name="relationship"
                                onChange={(e) => {
                                  var allContacts = contact.contacts;
                                  allContacts[i].relationship = e.target.value;
                                  setContact({ contacts: allContacts });
                                }}
                              >
                                <MenuItem key="0" value="selecione">
                                  Selecione
                                </MenuItem>
                                <MenuItem key="1" value="collaborator">
                                  Colaborador
                                </MenuItem>
                                <MenuItem key="2" value="principal">
                                  Diretor
                                </MenuItem>
                                <MenuItem key="3" value="commercial">
                                  Comercial
                                </MenuItem>
                                <MenuItem key="4" value="purchases">
                                  Compras
                                </MenuItem>
                                <MenuItem key="5" value="financial">
                                  Financeiro
                                </MenuItem>
                                <MenuItem key="6" value="factory">
                                  Fábrica
                                </MenuItem>
                                <MenuItem key="7" value="others">
                                  Outros
                                </MenuItem>
                              </TextField>
                            ) : (
                              ""
                            )}

                            {contactData.typeContact == "" ? (
                              <TextField
                                select
                                size="small"
                                label="Relação"
                                SelectProps={{
                                  MenuProps: {
                                    className: classes.menu,
                                  },
                                }}
                                margin="normal"
                                variant="outlined"
                                name="relationship"
                                disabled
                              >
                                <MenuItem key="0" value="selecione">
                                  Selecione
                                </MenuItem>
                              </TextField>
                            ) : (
                              ""
                            )}
                          </Grid>

                          <Grid
                            item
                            lg={3}
                            className="d-flex align-items-center"
                          >
                            <FormControlLabel
                              value="end"
                              control={<Checkbox color="primary" />}
                              label="Responsável legal"
                              labelPlacement="end"
                              name="legalResponsible"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].isLegalResponsible =
                                  allContacts[i].isLegalResponsible == "y"
                                    ? "n"
                                    : "y";
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item lg={6}>
                            <TextField
                              label="Nome do contato"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="nameContact"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].name = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>

                          <Grid item lg={3}>
                            <TextField
                              type="date"
                              label="Data de nascimento"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].birthDate = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>

                          <Grid item lg={3}>
                            <TextField
                              select
                              label="Gênero"
                              size="small"
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu,
                                },
                              }}
                              margin="normal"
                              variant="outlined"
                              name="gender"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].gender = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            >
                              <MenuItem key="0" value="">
                                Selecione
                              </MenuItem>

                              <MenuItem key="1" value="male">
                                Masculino
                              </MenuItem>

                              <MenuItem key="2" value="female">
                                Feminino
                              </MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item lg={3}>
                            <NumberFormat
                              margin="normal"
                              className="inputSmaller"
                              customInput={TextField}
                              variant="outlined"
                              format="(##) #####-####"
                              label="Celular"
                              mask="_"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].cell = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>

                          <Grid item lg={3}>
                            <NumberFormat
                              margin="normal"
                              className="inputSmaller"
                              customInput={TextField}
                              variant="outlined"
                              format="(##) ####-####"
                              label="Telefone"
                              mask="_"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].phone = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>

                          <Grid item lg={2}>
                            <TextField
                              label="Ramal"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="ramalContact"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].extension = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>

                          <Grid item lg={4}>
                            <TextField
                              label="Fax"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="faxContact"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].fax = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item lg={6}>
                            <TextField
                              label="E-mail"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="emailContact"
                              value={contactData.email}
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].email = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                              onBlur={() => checkEmailContactExist(i)}
                            />
                          </Grid>

                          <Grid item lg={6}>
                            <TextField
                              label="Site ou Perfil (URL)"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="urlContact"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].website = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item lg={6}>
                            <TextField
                              label="Observações"
                              multiline
                              rows="1"
                              size="small"
                              margin="normal"
                              variant="outlined"
                              name="noteContact"
                              onChange={(e) => {
                                var allContacts = contact.contacts;
                                allContacts[i].note = e.target.value;
                                setContact({ contacts: allContacts });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card.Body>
                    </Card>
                  );
                })}

                <Card className="mt-5">
                  <Card.Body className="row">
                    <Button
                      variant="secondary"
                      className="ml-6"
                      onClick={addContact}
                    >
                      Adicionar contato
                    </Button>
                  </Card.Body>
                </Card>
              </>
            </Tab>
            <Tab eventKey="tax" title="Fiscais e Tributárias">
              <Card.Body className="p-3">
                <Grid container spacing={3}>
                  <Grid item lg={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Consumidor final</FormLabel>
                      <RadioGroup
                        aria-label="finalCostumer"
                        onChange={(e) => setFinalCostumer(e.target.value)}
                        defaultValue={finalCostumer}
                        row
                      >
                        <FormControlLabel
                          value="y"
                          control={<Radio color="primary" />}
                          label="Sim"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="n"
                          control={<Radio color="primary" />}
                          label="Não"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item lg={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Contribuinte ICMS
                      </FormLabel>
                      <RadioGroup
                        aria-label="taxpayerIcms"
                        onChange={(e) => setTaxpayerIcms(e.target.value)}
                        defaultValue={taxpayerIcms}
                        row
                      >
                        <FormControlLabel
                          value="y"
                          control={<Radio color="primary" />}
                          label="Sim"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="n"
                          control={<Radio color="primary" />}
                          label="Não"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {taxpayerIcms === "y" ? (
                    <Grid item lg={2}>
                      <TextField
                        select
                        label="Regime tributário"
                        className="pl-0"
                        size="small"
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                        {...register("taxRegime")}
                      >
                        <MenuItem key="0" value="">
                          Selecione
                        </MenuItem>

                        <MenuItem key="1" value="simple national">
                          Simples Nacional
                        </MenuItem>

                        <MenuItem key="2" value="normal regime">
                          Regime Normal
                        </MenuItem>
                      </TextField>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid container spacing={3}>
                  <Grid item lg={6}>
                    <TextField
                      size="small"
                      label="Observações da nota"
                      multiline
                      rows="3"
                      className="ml-0 mr-0"
                      margin="normal"
                      helperText="Configure a mensagem do campo de observações da nota fiscal."
                      variant="outlined"
                      {...register("noteRemarks")}
                    />
                  </Grid>
                </Grid>
              </Card.Body>
            </Tab>
            <Tab eventKey="attachments" title="Anexos">
              <Card.Body className="col-6 p-3">
                <UploadFiles
                  label="Anexos"
                  filesSelected={filesSelected}
                  setFilesSelected={setFilesSelected}
                  filesNamesSaved={filesNamesSaved}
                  setFilesNamesSaved={setFilesNamesSaved}
                />
              </Card.Body>
            </Tab>
          </Tabs>

          <Accordion></Accordion>
        </div>
      </div>
    </div>
  );
}
