import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import {
  NfValidationErrors,
  ValidationMessageList,
} from "./ValidationMessageList";
import { useCompanyBranch } from "../hooks/companyBranch";
import NfValidationService from "../services/NfValidationService";

type ModalValidateNfProps = {
  nf?: any;
  nfType: "nfe" | "nfce" | "nfse";
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmIssue: () => Promise<void>;
  onCloseModal: () => void;
  url: string;
};

export function ModalValidateNf({
  nf,
  nfType,
  showModal,
  setShowModal,
  onConfirmIssue,
  onCloseModal,
  url,
}: ModalValidateNfProps) {
  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();
  const { selectedCompany } = useCompanyBranch({ withNfApiConfig: true });

  const [isLoading, setIsLoading] = useState(true);
  const [issuerValidationErrors, setIssuerValidationErrors] = useState<
    NfValidationErrors[]
  >([]);
  const [customerValidationErrors, setCustomerValidationErrors] = useState<
    NfValidationErrors[]
  >([]);
  const [productsValidationErrors, setProductsValidationErrors] = useState<
    NfValidationErrors[]
  >([]);
  const [transportValidationErrors, setTransportValidationErrors] = useState<
    NfValidationErrors[]
  >([]);
  const [detailsValidationErrors, setDetailsValidationErrors] = useState<
    NfValidationErrors[]
  >([]);

  const isNfValid =
    issuerValidationErrors.length === 0 &&
    customerValidationErrors.length === 0 &&
    productsValidationErrors.length === 0 &&
    transportValidationErrors.length === 0 &&
    detailsValidationErrors.length === 0;

  useEffect(() => {
    if (!nf || !isLoading || !selectedCompany) {
      return;
    }

    async function validateNf() {
      const validationService = new NfValidationService(selectedCompany);
      const validationErrors =
        nfType === "nfe"
          ? await validationService.getNfeValidationErrors(nf)
          : nfType === "nfce"
          ? await validationService.getNfceValidationErrors(nf)
          : await validationService.getNfseValidationErrors(nf);

      setIssuerValidationErrors(validationErrors.issuerErrors);
      setCustomerValidationErrors(
        validationErrors.customerErrors.filter(
          (error) => error.type === "error"
        )
      );
      setProductsValidationErrors(validationErrors.productsErrors);
      setTransportValidationErrors(validationErrors.transportErrors);
      setDetailsValidationErrors(validationErrors.detailsErrors);
      setIsLoading(false);
    }
    validateNf();
  }, [nf, selectedCompany]);

  function handleCloseModal() {
    setShowModal(false);
    setIsLoading(true);
    onCloseModal();
  }

  function handleClickEditNf() {
    // pushHistory(`{notas-fiscais}/${nf?.id}`);
    pushHistory(`${url}/${nf?.id}`);
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-warning"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          Emissão de Nota Fiscal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <Spinner
              as="span"
              variant="primary"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}

        {!isLoading && (
          <>
            <div className="row col-12">
              <h6>Dados do Emitente</h6>
            </div>
            <ValidationMessageList errors={issuerValidationErrors} />

            <div className="row col-12 mt-5">
              <h6>Dados do Cliente</h6>
            </div>
            <ValidationMessageList errors={customerValidationErrors} />

            {nfType !== "nfse" && (
              <>
                <div className="row col-12 mt-5">
                  <h6>Produtos</h6>
                </div>
                <ValidationMessageList errors={productsValidationErrors} />

                <div className="row col-12 mt-5">
                  <h6>Transporte</h6>
                </div>
                <ValidationMessageList errors={transportValidationErrors} />
              </>
            )}

            <div className="row col-12 mt-5">
              <h6>Informações</h6>
            </div>
            <ValidationMessageList errors={detailsValidationErrors} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isLoading && isNfValid && (
          <Button variant="success" onClick={onConfirmIssue}>
            Emitir
          </Button>
        )}

        {!isLoading && !isNfValid && (
          <Button variant="success" onClick={handleClickEditNf}>
            Alterar
          </Button>
        )}

        <Button variant="secondary" onClick={handleCloseModal}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
