import React, { useState, useEffect, useCallback } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { format } from "date-fns";
import ReactNumberFormat from "react-number-format";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { Product as SelectableProduct } from "../../services/ProductService";

import {
  Button,
  Accordion,
  Card,
  Spinner,
  Modal,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { formatToFloat, formatCurrency } from "../../utils/formatCurrency";
import api from "../../services/Api";
import { NumericFormat } from "../../components/NumericFormat";
import { paymentOptions } from "../../utils/paymentOptions";
import { Customer } from "../../types/Customer";
import LogService from "../../services/LogService";
import { useCompanyBranch } from "../../hooks/companyBranch";
import { Installment } from "../../types/Installment";
import CustomerSelect from "../../components/CustomerSelect";
import ProductSelect from "../../components/ProductSelect";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import SellerService from "../../services/SellerService";
import { PriceList } from "../../types/PriceList";
import PriceListService from "../../services/PriceListService";
import { Carrier } from "../../types/Carrier";
import CarrierService from "../../services/CarrierService";
import {
  dateIsBetweenRange,
  extractDateStringFromTimestamp,
} from "../../utils/dateTimeHelper";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { NewProductModal } from "../../components/Product/NewProductModal";
import useNewProductForm from "../../hooks/newProductForm";
import { Product } from "../../types/Product";
import useSellerUser from "../../hooks/sellerUser";

type urlParam = {
  typeOrder: string;
  orderId: string;
  idToClone: string;
};

export function NewNfe() {
  const [nfeConfig, setNfeConfig] = useState<any>();

  const [isSubmit, setIsSubmit] = useState(false);
  // HOOKS
  const history = useHistory();
  const { handleSubmit } = useForm();
  const { selectedCompany } = useCompanyBranch({ withNfApiConfig: true });
  const { userSellerInfo } = useSellerUser();

  // STATES
  const { typeOrder, orderId, idToClone } = useParams<urlParam>();

  const [activeTab, setActiveTab] = useState("details");

  const [customer, setCustomer] = useState<number>();
  const [seller, setSeller] = useState("");
  const [carrier, setCarrier] = useState("");
  const [priceList, setPriceList] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [outputDate, setOutputDate] = useState("");
  const [bcIcms, setBcIcms] = useState("0,00");
  const [valueIcms, setValueIcms] = useState("0,00");
  const [bcIcmsSt, setBcIcmsSt] = useState("0,00");
  const [valueIcmsSt, setValueIcmsSt] = useState("0,00");
  const [valueIpi, setValueIpi] = useState("0,00");
  const [valueProducts, setValueProducts] = useState("0,00");
  const [totalValue, setTotalValue] = useState("0,00");
  const [discountMoney, setDiscountMoney] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [valueFreight, setValueFreight] = useState("");
  const [valueExpenses, setValueExpenses] = useState("");
  const [valueSafe, setValueSafe] = useState("");
  const [valuePis, setValuePis] = useState("0,00");
  const [valueCofins, setValueCofins] = useState("0,00");
  const [valueIrrf, setValueIrrf] = useState("0,00");
  const [valueCsll, setValueCsll] = useState("0,00");
  const [valueSocial, setValueSocial] = useState("0,00");
  const [informDateEmission, setInformDateEmission] = useState("n");
  const [informIe, setInformIe] = useState("n");
  const [emissionDate, setEmissionDate] = useState("");
  const [taxSubstitute, setTaxSubstitute] = useState("");
  const [hours, setHours] = useState("");
  const [typeAttendance, setTypeAttendance] = useState(0);
  const [destinationOperation, setDestinationOperation] = useState(1);
  const [indicator, setIndicator] = useState(0);
  const [finality, setFinality] = useState(1);
  const [type, setType] = useState(1);
  const [intermediaryCnpj, setIntermediaryCnpj] = useState("");
  const [
    identifierRegisterIntermediary,
    setIdentifierRegisterIntermediary,
  ] = useState("");
  const [typeDocument, setTypeDocument] = useState("receipt");
  const [receiptElectronic, setReceiptElectronic] = useState("");
  const [modelDocument, setModelDocument] = useState("");
  const [sequentialEcf, setSequentialEcf] = useState("");
  const [counterOperation, setCounterOperation] = useState("");
  const [modelDocumentProducer, setModelDocumentProducer] = useState("");
  const [typePeopleProducerRural, setTypePeopleProducerRural] = useState("");
  const [cpfProducerRural, setCpfProducerRural] = useState("");
  const [cnpjProducerRural, setCnpjProducerRural] = useState("");
  const [ieIssuer, setIeIssuer] = useState("");
  const [exemptProducerRural, setExemptProducerRural] = useState("n");
  const [accessKeyCte, setAccessKeyCte] = useState("");
  const [yearEmission, setYearEmission] = useState("");
  const [monthEmission, setMonthEmission] = useState("");
  const [seriesProducerRural, setSeriesProducerRural] = useState("");
  const [numberProducerRural, setNumberProducerRural] = useState("");
  const [ufIssuer, setUfIssuer] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [liquidWeight, setLiquidWeight] = useState("");
  const [amountInstallments, setAmountInstallments] = useState("");
  const [modalityFreight, setModalityFreight] = useState(0);
  const [nature, setNature] = useState("");
  const [seriesNfe, setSeriesNfe] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [observation, setObservation] = useState("");
  const [internalObservation, setInternalObservation] = useState("");
  const [volumes, setVolumes] = useState("");
  const [species, setSpecies] = useState("");
  const [brand, setBrand] = useState("");
  const [numbering, setNumbering] = useState("");
  const [plateVehicle, setPlateVehicle] = useState("");
  const [rntc, setRntc] = useState("");
  const [ufExport, setUfExport] = useState("");
  const [locationExport, setLocationExport] = useState("");
  const [installments, setInstallments] = useState<{
    installment: Installment[];
  }>({
    installment: [],
  });
  const [products, setProducts] = useState({
    products: [
      {
        icms: "0",
        cfop: "0",
        ipi: "0",
        ncm: "0",
        total: 0,
        amount: 0,
        unitaryValue: 0,
        INSTANCE: {} as SelectableProduct,
      },
    ],
  });

  const [showModalAttention, setShowModalAttention] = useState(false);
  const [msgError, setMsgError] = useState("");

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomer(createdCustomer.id);
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  // Modal New Product
  const [productBeingCreated, setProductBeingCreated] = useState<{
    index: number;
    product: any;
  } | null>(null);
  const {
    showModalNewProduct,
    setShowModalNewProduct,
    newProductDefaultData,
    setNewProductDefaultData,
  } = useNewProductForm();

  const handleClickAddProduct = (
    typedText: string,
    index: number,
    product: Product
  ) => {
    setNewProductDefaultData({ nameProduct: typedText });
    setProductBeingCreated({ index, product });
    setShowModalNewProduct(true);
  };

  useEffect(() => {
    if (userSellerInfo) {
      setSeller(String(userSellerInfo.id));
    }
  }, [userSellerInfo]);

  useEffect(() => {
    const date = new Date();
    const formated = format(date, "yyyyMMddHHmmss");
    const outputDateFormated = format(date, "yyyy-MM-dd");

    setOutputDate(outputDateFormated);
    loadData();
    getNfeConfig();
  }, []);

  useEffect(() => {
    getDefaultInputs();
  }, [nfeConfig]);

  useEffect(() => {
    if (!selectedCompany?.id) return;

    if (selectedCompany.nfEnv === "production") {
      setSeriesNfe(selectedCompany.nfApiConfig.serie_nfe_producao ?? "1");
      setInvoiceNumber(
        selectedCompany.nfApiConfig.proximo_numero_nfe_producao ?? "1"
      );
    } else {
      setSeriesNfe(selectedCompany.nfApiConfig.serie_nfe_homologacao ?? "1");
      setInvoiceNumber(
        selectedCompany.nfApiConfig.proximo_numero_nfe_homologacao ?? "1"
      );
    }
  }, [selectedCompany]);

  const getCfop = useCallback(async () => {
    if (!customer || !selectedCompany || !nfeConfig) return undefined;

    const response = await api.get(`address/user/default/${customer}`);
    const customerAddress = response.data;
    if (!customerAddress) return undefined;

    return customerAddress.state === selectedCompany.state
      ? nfeConfig.cfopInUf
      : nfeConfig.cfopOutUf;

    // setProducts((prevState) => {
    //     console.log(prevState);
    //     return {
    //         products: prevState.products.map((product, index) => {
    //             const configCfop = customerAddress.state === selectedCompany.state ? nfeConfig.cfopInUf : nfeConfig.cfopOutUf;
    //             return {
    //                 ...product,
    //                 cfop: !product.cfop || product.cfop === '0' ? configCfop : product.cfop,
    //             };
    //         }),
    //     };
    // });
  }, [customer, selectedCompany, nfeConfig]);

  useEffect(() => {
    getCfop();
  }, [getCfop]);

  useEffect(() => {
    calcTotalInvoice();
    setInstallments({ installment: [] });
  }, [products]);

  useEffect(() => {
    calcTotalInvoice();
    setInstallments({ installment: [] });
  }, [
    valueProducts,
    discountMoney,
    discountPercentage,
    valueFreight,
    valueExpenses,
    valueSafe,
  ]);

  async function getOrder() {
    const nfeConfig = await api.get("nfeConfig");

    if (typeOrder == "pedido") {
      var order = await api.get(`/requests/${orderId}`);

      setCustomer(Number(order.data.customerId));
      setSeller(String(order.data.seller));
      setType(1);
      setOutputDate(order.data.requestDate);
      setProducts({ products: JSON.parse(order.data.products) });
      setValueFreight(order.data.freight);
      setDiscountMoney(order.data.discountMoney);
      setDiscountPercentage(order.data.discountPercentage);
      setAmountInstallments(JSON.parse(order.data.installments).length);
      setInstallments({ installment: JSON.parse(order.data.installments) });
      setCarrier(String(order.data.carrier));
      setNature(nfeConfig.data[0].nature);
    } else if (typeOrder == "ordem-de-compra") {
      var order = await api.get(`/purchase-order/${orderId}`);

      setCustomer(Number(order.data.supplierId));
      setSeller(String(order.data.seller));
      setType(0);
      setOutputDate(order.data.orderDate);
      setProducts({ products: JSON.parse(order.data.products) });
      setValueFreight(order.data.freightPrice);
      setDiscountMoney(order.data.discountMoney);
      setDiscountPercentage(order.data.discountPercentage);
      setAmountInstallments(JSON.parse(order.data.installments).length);
      setInstallments({ installment: JSON.parse(order.data.installments) });
      setCarrier(String(order.data.carrier));
      setNature(nfeConfig.data[0].nature);
    }
  }

  async function getNfeToCloneData() {
    const { data: nfeToClone } = await api.get(`/nfe/${idToClone}`);

    if (!nfeToClone) return;

    setCustomer(Number(nfeToClone.customer));
    setSeller(String(nfeToClone.seller));
    setTypeDocument(nfeToClone.typeDocument);
    setReceiptElectronic(nfeToClone.receiptElectronic);
    setModelDocument(nfeToClone.modelDocument);
    setModelDocumentProducer(nfeToClone.modelDocumentProducer);
    setSequentialEcf(nfeToClone.sequentialEcf);
    setCounterOperation(nfeToClone.counterOperation);
    setYearEmission(nfeToClone.yearEmission);
    setMonthEmission(nfeToClone.monthEmission);
    setSeriesProducerRural(nfeToClone.seriesProducerRural);
    setNumberProducerRural(nfeToClone.numberProducerRural);
    setUfIssuer(nfeToClone.ufIssuer);
    setTypePeopleProducerRural(nfeToClone.typePeopleProducerRural);
    setCpfProducerRural(nfeToClone.cpfProducerRural);
    setCnpjProducerRural(nfeToClone.cnpjProducerRural);
    setIeIssuer(nfeToClone.ieIssuer);
    setExemptProducerRural(nfeToClone.exemptProducerRural);
    setAccessKeyCte(nfeToClone.accessKeyCte);
    setInformDateEmission(nfeToClone.informDateEmission);
    setEmissionDate(nfeToClone.emissionDate);
    setInformIe(nfeToClone.informIe);
    setTaxSubstitute(nfeToClone.taxSubstitute);
    setNature(nfeToClone.nature);
    setFinality(nfeToClone.finality);
    setType(nfeToClone.type);
    setOutputDate(nfeToClone.outputDate);
    setHours(nfeToClone.hours);
    setPurchaseOrder(nfeToClone.purchaseOrder);
    setTypeAttendance(nfeToClone.typeAttendance);
    setDestinationOperation(nfeToClone.destinationOperation);
    setIndicator(nfeToClone.indicator);
    setIntermediaryCnpj(nfeToClone.intermediaryCnpj);
    setIdentifierRegisterIntermediary(
      nfeToClone.identifierRegisterIntermediary
    );
    setObservation(nfeToClone.observation);
    setInternalObservation(nfeToClone.internalObservation);
    setProducts({ products: JSON.parse(nfeToClone.products) });
    setPriceList(String(nfeToClone.priceList));
    setBcIcms(formatCurrency(nfeToClone.bcIcms));
    setValueIcms(formatCurrency(nfeToClone.valueIcms));
    setBcIcmsSt(formatCurrency(nfeToClone.bcIcmsSt));
    setValueIcmsSt(formatCurrency(nfeToClone.valueIcmsSt));
    setValueIpi(formatCurrency(nfeToClone.valueIpi));
    setValueProducts(formatCurrency(nfeToClone.valueProducts));
    setDiscountMoney(formatCurrency(nfeToClone.discountMoney));
    setDiscountPercentage(formatCurrency(nfeToClone.discountPercentage));
    setValueFreight(formatCurrency(nfeToClone.valueFreight));
    setValueExpenses(formatCurrency(nfeToClone.valueExpenses));
    setValueSafe(formatCurrency(nfeToClone.valueSafe));
    setTotalValue(formatCurrency(nfeToClone.totalValue));
    setValuePis(formatCurrency(nfeToClone.valuePis));
    setValueCofins(formatCurrency(nfeToClone.valueCofins));
    setValueIrrf(formatCurrency(nfeToClone.valueIrrf));
    setValueCsll(formatCurrency(nfeToClone.valueCsll));
    setValueSocial(formatCurrency(nfeToClone.valueSocial));
    setAmountInstallments(nfeToClone.amountInstallments);
    setInstallments({ installment: JSON.parse(nfeToClone.installments) });
    setGrossWeight(formatCurrency(nfeToClone.grossWeight));
    setLiquidWeight(formatCurrency(nfeToClone.liquidWeight));
    setVolumes(nfeToClone.volumes);
    setSpecies(nfeToClone.species);
    setBrand(nfeToClone.brand);
    setNumbering(nfeToClone.numbering);
    setModalityFreight(nfeToClone.modalityFreight);
    setCarrier(String(nfeToClone.carrier ?? ""));
    setPlateVehicle(nfeToClone.plateVehicle);
    setRntc(nfeToClone.rntc);
    setUfExport(nfeToClone.ufExport);
    setLocationExport(nfeToClone.locationExport);
  }

  async function getNfeConfig() {
    const response = await api.get("nfeConfig");
    const config = response.data[0];
    setNfeConfig(config);
  }

  async function loadData() {
    if (typeOrder && orderId) {
      getOrder();
    } else if (idToClone) {
      getNfeToCloneData();
    }
  }

  async function getDefaultInputs() {
    if (!nfeConfig) return;
    setTypeAttendance(nfeConfig.typeAttendance ?? 0);
  }

  function handleChangeFinality(e: any) {
    setReceiptElectronic("");
    setModelDocument("");
    setModelDocumentProducer("");
    setSequentialEcf("");
    setCounterOperation("");
    setYearEmission("");
    setMonthEmission("");
    setSeriesProducerRural("");
    setNumberProducerRural("");
    setUfIssuer("");
    setTypePeopleProducerRural("");
    setCpfProducerRural("");
    setCnpjProducerRural("");
    setIeIssuer("");
    setAccessKeyCte("");

    setFinality(Number(e.target.value));
  }

  function checkCpf(value: string) {
    var isSubmit = true;

    if (value) {
      var cpfFormated = value.replace(/[^0-9]/g, "");
      isSubmit = cpf.isValid(cpfFormated);
      if (!isSubmit) {
        setCpfProducerRural("");
        setMsgError(`Cpf do produtor rural inválido!`);
        setShowModalAttention(true);
      }
    }
    return isSubmit;
  }

  function checkCnpj(value: string, type: string) {
    var isSubmit = true;

    if (value) {
      var cnpjFormated = value.replace(/[^0-9]/g, "");
      isSubmit = cnpj.isValid(cnpjFormated);

      if (!isSubmit) {
        if (type == "producerRural") {
          setCnpjProducerRural("");
        } else {
          setActiveTab("details");
          setIntermediaryCnpj("");
        }

        setMsgError(
          `Cnpj do ${
            type == "producerRural"
              ? "produtor rural"
              : "intermediador da transação"
          } inválido!`
        );
        setShowModalAttention(true);
      }
    }
    return isSubmit;
  }

  function distributeValueInstallments(index: number) {
    var installmentsArray = installments.installment;

    var sumBeforeValue = 0;
    for (
      var indexInstallment = 0;
      indexInstallment < installmentsArray.length;
      indexInstallment++
    ) {
      if (indexInstallment <= index) {
        sumBeforeValue += installmentsArray[indexInstallment].value;
      }
    }

    if (sumBeforeValue > formatToFloat(totalValue)) {
      setMsgError(
        "A soma das parcelas ultrapassa o valor total da nota fiscal!"
      );
      setShowModalAttention(true);

      var valueDistributed =
        formatToFloat(totalValue) / (installmentsArray.length - (index - 1));
      for (
        var indexInstallment = 0;
        indexInstallment < installmentsArray.length;
        indexInstallment++
      ) {
        if (indexInstallment > 0) {
          installmentsArray[indexInstallment].value = valueDistributed;
        }
      }
    } else {
      var valueDistributed =
        (formatToFloat(totalValue) - sumBeforeValue) /
        (installmentsArray.length - (index + 1));
      for (
        var indexInstallment = 0;
        indexInstallment < installmentsArray.length;
        indexInstallment++
      ) {
        if (indexInstallment > index) {
          installmentsArray[indexInstallment].value = valueDistributed;
        }
      }
    }

    setInstallments({ installment: installmentsArray });
  }

  function calcTotalInvoice() {
    var totalProducts = 0;
    var totalInvoice = 0;
    var discountInMoney = formatToFloat(discountMoney) ?? 0;
    var discountInPercentage = formatToFloat(discountPercentage) ?? 0;
    var freight = formatToFloat(valueFreight) ?? 0;
    var expenses = formatToFloat(valueExpenses) ?? 0;
    var safe = formatToFloat(valueSafe) ?? 0;

    for (var product of products.products) {
      totalProducts += product.total;
    }

    totalInvoice = totalProducts + freight + expenses + safe; // produtos, frete, despesas e seguro
    totalInvoice = totalInvoice - discountInMoney; // desconto em dinheiro
    totalInvoice = totalInvoice - totalProducts * (discountInPercentage / 100); // desconto em porcentagem

    setValueProducts(formatCurrency(totalProducts));
    setTotalValue(formatCurrency(totalInvoice));
  }

  function getTotalInvoice() {
    var totalProducts = 0;
    var totalInvoice = 0;
    var discountInMoney = formatToFloat(discountMoney) ?? 0;
    var discountInPercentage = formatToFloat(discountPercentage) ?? 0;
    var freight = formatToFloat(valueFreight) ?? 0;
    var expenses = formatToFloat(valueExpenses) ?? 0;
    var safe = formatToFloat(valueSafe) ?? 0;

    for (var product of products.products) {
      totalProducts += product.total;
    }

    totalInvoice = totalProducts + freight + expenses + safe; // produtos, frete, despesas e seguro
    totalInvoice = totalInvoice - discountInMoney; // desconto em dinheiro
    totalInvoice = totalInvoice - totalProducts * (discountInPercentage / 100); // desconto em porcentagem

    return totalInvoice;
  }

  function addProducts() {
    var productsArray = products.products;

    productsArray.push({
      icms: "0",
      cfop: "0",
      ipi: "0",
      ncm: "0",
      total: 0,
      amount: 0,
      unitaryValue: 0,
      INSTANCE: {} as SelectableProduct,
    });

    setProducts({ products: productsArray });
  }

  function deleteProduct(index: number) {
    var productsArray = products.products;
    productsArray.splice(index, 1);
    setProducts({ products: productsArray });
  }

  const selectProduct = useCallback(
    async (value: any, index: number) => {
      var productArray = products.products;

      if (value) {
        const defaultCfop = (await getCfop()) ?? "0";

        var productsInKit = value.kit;
        var type = value.type;

        if (value.isKit == "y") {
          type = "kit";
        } else if (value.hasVariation == "y") {
          type = "variation";
        }

        productArray[index].amount = 1;
        productArray[index].total = value.saleValue;
        productArray[index].unitaryValue = value.saleValue;
        productArray[index].ipi = value.ipiRate ? value.ipiRate : "0";
        productArray[index].icms = value.icmsRate ? value.icmsRate : "0";
        productArray[index].cfop = defaultCfop;
        productArray[index].ncm = value.ncm ? value.ncm : "0";
        productArray[index].INSTANCE = {
          id: value.id,
          name: value.name,
          code: value.code,
          saleValue: value.saleValue,
          codeGtinEan: value.codeGtinEan,
          stockInitial: value.stockInitial,
          currentStock: formatToFloat(value.currentStock),
          hasVariation: value.hasVariation,
          isKit: value.isKit,
          kit: productsInKit,
          type: type,
        };
      } else {
        productArray[index].icms = "0";
        productArray[index].cfop = "0";
        productArray[index].ipi = "0";
        productArray[index].ncm = "0";
        productArray[index].amount = 0;
        productArray[index].total = 0;
        productArray[index].unitaryValue = 0;
        productArray[index].INSTANCE = {
          id: 0,
          name: "",
          code: "",
          saleValue: 0,
          codeGtinEan: "",
          stockInitial: "",
          currentStock: 0,
          hasVariation: "",
          isKit: "",
          kit: "",
          type: "product",
        };
      }

      setProducts({ products: productArray });
    },
    [getCfop]
  );

  const handleCreateProduct = useCallback(
    (createdProduct: Product) => {
      if (!productBeingCreated) return;

      const selectableProduct: SelectableProduct = {
        ...(createdProduct as any),
        saleValue: formatToFloat(createdProduct.saleValue),
      };

      selectProduct(selectableProduct, productBeingCreated.index);
      applyPriceList(priceList);
    },
    [productBeingCreated, priceList, selectProduct]
  );

  function changeAmount(value: any, index: number) {
    var productArray = products.products;
    productArray[index].amount = value;
    productArray[index].total =
      productArray[index].unitaryValue * formatToFloat(value);

    setProducts({ products: productArray });
  }

  function changeProductValue(value: any, index: number) {
    var productArray = products.products;
    productArray[index].unitaryValue = formatToFloat(value);
    productArray[index].total =
      productArray[index].unitaryValue * Number(productArray[index].amount);

    setProducts({ products: productArray });
  }

  function setIpi(value: any, index: number) {
    var productArray = products.products;
    productArray[index].ipi = value;
    setProducts({ products: productArray });
  }

  function setIcms(value: any, index: number) {
    var productArray = products.products;
    productArray[index].icms = value;
    setProducts({ products: productArray });
  }

  function setCfop(value: any, index: number) {
    var productArray = products.products;
    productArray[index].cfop = value;
    setProducts({ products: productArray });
  }

  function setNcm(value: any, index: number) {
    var productArray = products.products;
    productArray[index].ncm = value;
    setProducts({ products: productArray });
  }

  async function applyPriceList(idPriceList: any) {
    if (Number(idPriceList)) {
      var objPriceList = await api.get(`/priceList/${idPriceList}`);
      if (objPriceList.data.priceListType == "1") {
        setTotalValue(String(getTotalInvoice() + objPriceList.data.value));

        // var productsArray     = products.products;
        // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
        //     const {data} = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
        //     productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
        //     productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
        // }
        // setProducts({ products: productsArray });
      } else if (objPriceList.data.priceListType == "2") {
        setTotalValue(String(getTotalInvoice() - objPriceList.data.value));

        // var productsArray     = products.products;
        // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
        //     const {data} = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
        //     productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
        //     productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
        // }
        // setProducts({ products: productsArray });
      } else {
        var priceListProducts = JSON.parse(objPriceList.data.products);
        var productsArray = products.products;

        for (
          var indexProduct = 0;
          indexProduct < productsArray.length;
          indexProduct++
        ) {
          const { data } = await api.get(
            `/products/${productsArray[indexProduct].INSTANCE.id}`
          );
          var price = priceListProducts.find(
            (element: any) =>
              element.productId == productsArray[indexProduct].INSTANCE.id
          );

          if (data) {
            if (price) {
              if (price.type == "1") {
                productsArray[indexProduct].unitaryValue =
                  formatToFloat(data.saleValue) - formatToFloat(price.value);
                productsArray[indexProduct].total =
                  productsArray[indexProduct].amount *
                  productsArray[indexProduct].unitaryValue;
              } else {
                productsArray[indexProduct].unitaryValue =
                  formatToFloat(data.saleValue) + formatToFloat(price.value);
                productsArray[indexProduct].total =
                  productsArray[indexProduct].amount *
                  productsArray[indexProduct].unitaryValue;
              }
            } else {
              productsArray[indexProduct].unitaryValue = formatToFloat(
                data.saleValue
              );
              productsArray[indexProduct].total =
                productsArray[indexProduct].amount *
                formatToFloat(data.saleValue);
            }
          }
        }
        setProducts({ products: productsArray });
      }
    } else {
      // var productsArray     = products.products;
      // for (var indexProduct = 0; indexProduct < productsArray.length; indexProduct++) {
      //     const {data}      = await api.get(`/products/${productsArray[indexProduct].INSTANCE.id}`);
      //     if (data) {
      //         productsArray[indexProduct].unitaryValue  = formatToFloat(data.saleValue);
      //         productsArray[indexProduct].total         = productsArray[indexProduct].amount * formatToFloat(data.saleValue);
      //     }
      // }
      // setProducts({ products: productsArray });
    }
  }

  function setValuesInstallments(value: any, index: number, type: string) {
    var installmentArray = installments.installment;

    if (type == "date") {
      installmentArray[index].date = value;
    } else if (type == "value") {
      installmentArray[index].value = formatToFloat(value);
    } else if (type == "payment") {
      installmentArray[index].payment = value;
    } else {
      installmentArray[index].comments = value;
    }

    setInstallments({ installment: installmentArray });
  }

  function generateInstallments() {
    if (formatToFloat(totalValue)) {
      var installmentsArray = [];
      var today = new Date();
      var day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
      var month = String(today.getMonth() + 1);
      var year = today.getFullYear();

      var valueInstallment =
        formatToFloat(totalValue) / formatToFloat(amountInstallments);

      for (var index = 0; index < formatToFloat(amountInstallments); index++) {
        month =
          today.getMonth() < 10
            ? "0" + (today.getMonth() + (index + 1))
            : String(today.getMonth() + (index + 1));

        installmentsArray.push({
          date: year + "-" + month + "-" + day,
          value: valueInstallment,
          comments: "",
          payment: "Cartão de Crédito",
        });
      }
      setInstallments({ installment: installmentsArray });
    } else {
      setMsgError("Selecione pelo menos um produto para gerar as parcelas!");
      setShowModalAttention(true);
    }
  }

  function hasInstallments() {
    var isSubmit = true;

    // if (installments.installment.length == 0) {
    //     setMsgError("Informe as parcelas da nota fiscal!");
    //     setShowModalAttention(true);
    //     isSubmit = false;
    // }

    return isSubmit;
  }

  async function validateIssueData() {
    if (destinationOperation === 3) {
      const response = await api.get<Customer>(`customer/${customer}`);
      const selectedCustomer = response.data;
      if (selectedCustomer.isForeign === "n" || !selectedCustomer.passport) {
        setMsgError(
          "Para nota fiscal no exterior, é necessário que o cliente selecionado possua passaporte estrangeiro preenchido! (Na aba Cadastros -> Clientes/Fornecedores)"
        );
        setShowModalAttention(true);
        return false;
      }
    }

    return true;
  }

  function validateForm() {
    var isSubmit = true;

    if (!customer) {
      setMsgError("Selecione o cliente!");
      setShowModalAttention(true);
      isSubmit = false;
      return false;
    }

    if (!products.products[0].total) {
      setActiveTab("products");
      setMsgError("Selecione pelo menos um produto para nota fiscal!");
      setShowModalAttention(true);
      isSubmit = false;
      return false;
    }

    if (informDateEmission && emissionDate) {
      const dateIsValid = dateIsBetweenRange(
        emissionDate,
        "",
        extractDateStringFromTimestamp()
      );
      if (!dateIsValid) {
        setActiveTab("details");
        setMsgError("A data de emissão não pode ser maior que a data atual!");
        setShowModalAttention(true);
        isSubmit = false;
        return false;
      }
    }

    // Validando campo hora
    var auxHour = hours.split(":");
    if (Number(auxHour[0]) > 23) {
      setActiveTab("details");
      setMsgError("Horário informado inválido!");
      setShowModalAttention(true);
      setHours("");
      isSubmit = false;
      return false;
    } else if (Number(auxHour[1]) > 59) {
      setActiveTab("details");
      setMsgError("Horário informado inválido!");
      setShowModalAttention(true);
      setHours("");
      isSubmit = false;
      return false;
    }

    if (indicator === 1) {
      if (!intermediaryCnpj) {
        setActiveTab("details");
        setMsgError(
          "Para o intermediador selecionado é necessário informar o CNPJ do intermediador!"
        );
        setShowModalAttention(true);
        return false;
      }

      if (!identifierRegisterIntermediary) {
        setActiveTab("details");
        setMsgError(
          "Para o intermediador selecionado é necessário informar identificador do cadastro do intermediador!"
        );
        setShowModalAttention(true);
        return false;
      }
    }

    return isSubmit;
  }

  const onSubmit = async (data: any) => {
    if (
      validateForm() &&
      hasInstallments() &&
      checkCpf(cpfProducerRural) &&
      checkCnpj(intermediaryCnpj, "intermediary") &&
      checkCnpj(cnpjProducerRural, "producerRural") &&
      (await validateIssueData())
    ) {
      setIsSubmit(true);

      var installmentsArray = installments.installment;

      try {
        const raw = {
          ...data,
          customer: Number(customer),
          seller: Number(seller) ?? null,
          invoiceNumber: invoiceNumber,

          nature: nature,
          seriesNfe: seriesNfe,
          purchaseOrder: purchaseOrder,
          observation: observation,
          internalObservation: internalObservation,

          typeDocument: typeDocument,
          receiptElectronic: receiptElectronic,
          modelDocument: modelDocument,
          modelDocumentProducer: modelDocumentProducer,
          sequentialEcf: sequentialEcf,
          counterOperation: counterOperation,
          yearEmission: yearEmission,
          monthEmission: monthEmission,
          seriesProducerRural: seriesProducerRural,
          numberProducerRural: numberProducerRural,
          ufIssuer: ufIssuer,
          typePeopleProducerRural: typePeopleProducerRural,
          cpfProducerRural: cpfProducerRural,
          cnpjProducerRural: cnpjProducerRural,
          ieIssuer: ieIssuer,
          exemptProducerRural: exemptProducerRural,
          accessKeyCte: accessKeyCte,
          informDateEmission: informDateEmission,
          emissionDate: informDateEmission ? emissionDate : null,
          informIe: informIe,
          taxSubstitute: informIe ? taxSubstitute : null,
          finality: finality,
          type: type,
          outputDate: outputDate,
          hours: hours,
          typeAttendance: typeAttendance,
          destinationOperation: destinationOperation,
          indicator: indicator,
          intermediaryCnpj: intermediaryCnpj,
          identifierRegisterIntermediary: identifierRegisterIntermediary,
          products: JSON.stringify(products.products),
          priceList: Number(priceList) ?? null,

          bcIcms: formatToFloat(bcIcms),
          valueIcms: formatToFloat(valueIcms),
          bcIcmsSt: formatToFloat(bcIcmsSt),
          valueIcmsSt: formatToFloat(valueIcmsSt),
          valueIpi: formatToFloat(valueIpi),
          valueProducts: formatToFloat(valueProducts),
          discountMoney: formatToFloat(discountMoney),
          discountPercentage: formatToFloat(discountPercentage),
          valueFreight: formatToFloat(valueFreight),
          valueExpenses: formatToFloat(valueExpenses),
          valueSafe: formatToFloat(valueSafe),
          totalValue: formatToFloat(totalValue),
          valuePis: formatToFloat(valuePis),
          valueCofins: formatToFloat(valueCofins),
          valueIrrf: formatToFloat(valueIrrf),
          valueCsll: formatToFloat(valueCsll),
          valueSocial: formatToFloat(valueSocial),
          amountInstallments: formatToFloat(amountInstallments),
          installments: JSON.stringify(installmentsArray),
          grossWeight: formatToFloat(grossWeight),
          liquidWeight: formatToFloat(liquidWeight),
          modalityFreight: modalityFreight,
          carrier:
            carrier && ![4, 3, 9].includes(modalityFreight)
              ? Number(carrier)
              : null,
          volumes: volumes ? parseInt(volumes) : null,
          numbering: numbering || null,
          species: species || null,
          brand: brand || null,
          plateVehicle: plateVehicle || null,
          rntc: rntc || null,
          situation: "open",
          ufExport: type === 2 ? ufExport : null,
          locationExport: type === 2 ? locationExport : null,
        };

        const result = await api.post("/nfe", raw);

        try {
          const response = await api.put(`requests/${orderId}`, {
            invoiceId: result.data.id,
            invoiceType: "NFE",
          });
          console.log(response);
        } catch (error) {
          console.log(error);
        }

        LogService.logRegister({
          itemId: result.data.id,
          module: "Notas Fiscais",
          itemName: "NF-e",
        });

        history.push("/notas-fiscais");
      } catch (error) {
        console.log(error);
      }
      setIsSubmit(false);
    }
  };

  return (
    <div className="card card-custom gutter-b mt-2 newProductWrapper">
      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <NewProductModal
        showModal={showModalNewProduct}
        setShowModal={setShowModalNewProduct}
        onCreateProduct={handleCreateProduct}
        defaultData={newProductDefaultData}
        allowChangeType={false}
      />

      <Modal
        show={showModalAttention}
        onHide={() => setShowModalAttention(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalAttention(!showModalAttention)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-header row m-0 pt-2 pb-0">
          {/* INVOICES DETAIL*/}
          <div className="col-6 m-0 p-0">
            <h4 className="card-label m-0 mt-2">Detalhes da Nota Fiscal</h4>
          </div>
          <div className="col-lg-6 d-flex justify-content-end mb-3 pr-0">
            <Button type="submit" variant="primary" disabled={isSubmit}>
              {isSubmit ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />

                  <span className="ml-2">Aguarde...</span>
                </>
              ) : (
                <span>Salvar</span>
              )}
            </Button>

            {/* <Button
                            size="lg"
                            variant="secondary"
                        >
                            Salvar e Emitir
                        </Button> */}
          </div>
        </div>

        <div className="card-body mt-0 pt-0">
          <div className="row">
            <div className="col-lg-6">
              <CustomerSelect
                label="Cliente *"
                isEdit={false}
                customerId={customer}
                setCustomerId={setCustomer}
                onClickAddCustomer={handleClickAddCustomer}
              />
            </div>

            <div className="col-lg-4">
              <ApiResourceSelect
                label="Vendedor"
                getOptionLabel={(option: any) => option.name}
                value={seller}
                onSelect={(option) =>
                  setSeller(option ? String(option.id) : "")
                }
                apiSearchHandler={(typedText) =>
                  SellerService.getSellersFiltered({ name: typedText })
                }
                getSelectedOption={(loadedOptions) => {
                  if (!seller) return null;
                  return (
                    loadedOptions.find(
                      (option) => option.id === Number(seller)
                    ) ?? SellerService.getSellerById(seller)
                  );
                }}
              />
            </div>

            <div className="col-lg-2">
              <TextField
                size="small"
                disabled
                label="Nº NF-e"
                margin="normal"
                variant="outlined"
                value={invoiceNumber}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <TextField
                size="small"
                label="Natureza"
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                value={nature}
                onChange={(e) => setNature(e.target.value)}
              />
            </div>

            <div className="col-lg-3">
              <TextField
                select
                label="Finalidade"
                margin="normal"
                variant="outlined"
                size="small"
                value={finality}
                onChange={handleChangeFinality}
              >
                <MenuItem key="0" value="1">
                  NF-e normal
                </MenuItem>

                <MenuItem key="1" value="2">
                  NF-e complementar
                </MenuItem>

                <MenuItem key="2" value="3">
                  NF-e de ajuste
                </MenuItem>

                <MenuItem key="3" value="4">
                  Devolução/Retorno
                </MenuItem>
              </TextField>
            </div>

            <div className="col-lg-3">
              <TextField
                select
                label="Tipo"
                margin="normal"
                variant="outlined"
                size="small"
                value={type}
                onChange={(e) => setType(Number(e.target.value))}
              >
                <MenuItem key="0" value="0">
                  Entrada
                </MenuItem>

                <MenuItem key="1" value="1">
                  Saída
                </MenuItem>

                <MenuItem key="2" value="2">
                  Exportação
                </MenuItem>

                <MenuItem key="3" value="3">
                  Importação
                </MenuItem>
              </TextField>
            </div>

            <div className="col-lg-2">
              <TextField
                label="Série NF-e"
                margin="normal"
                variant="outlined"
                size="small"
                disabled
                inputProps={{ maxLength: 3 }}
                value={seriesNfe}
                onChange={(e) => setSeriesNfe(e.target.value)}
              ></TextField>
            </div>
          </div>

          {finality && finality !== 1 ? (
            <div className="row">
              <div className="col-lg-2">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tipo de Documento</FormLabel>
                  <RadioGroup
                    aria-label="Tipo de Documento"
                    value={typeDocument}
                    onChange={(e) => setTypeDocument(e.target.value)}
                  >
                    <FormControlLabel
                      value="receipt"
                      control={<Radio />}
                      label="Nota Fiscal"
                    />
                    <FormControlLabel
                      value="taxCoupon"
                      control={<Radio />}
                      label="Cupom Fiscal"
                    />
                    <FormControlLabel
                      value="ruralProducer"
                      control={
                        <Radio
                          onChange={() => {
                            setYearEmission(String(new Date().getFullYear()));
                            setMonthEmission(String(new Date().getMonth() + 1));
                            setTypePeopleProducerRural("physical");
                          }}
                        />
                      }
                      label="Produtor Rural"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {typeDocument == "receipt" ? (
                <div className="col-lg-10 d-flex align-items-center">
                  <ReactNumberFormat
                    margin="normal"
                    className="inputSmaller"
                    customInput={TextField}
                    variant="outlined"
                    label="Nota Fiscal Eletrônica Referenciada"
                    format="############################################"
                    mask="_"
                    value={receiptElectronic}
                    onChange={(e) => setReceiptElectronic(e.target.value)}
                  />
                </div>
              ) : (
                <></>
              )}

              {typeDocument == "taxCoupon" ? (
                <>
                  <div className="col d-flex align-items-center">
                    <TextField
                      select
                      label="Modelo do Documento"
                      margin="normal"
                      variant="outlined"
                      size="small"
                      value={modelDocument}
                      onChange={(e) => setModelDocument(e.target.value)}
                    >
                      <MenuItem key="0" value="">
                        Selecione
                      </MenuItem>

                      <MenuItem key="1" value="2B">
                        Cupom Fiscal emitido por máquina registradora (não ECF)
                      </MenuItem>

                      <MenuItem key="2" value="2C">
                        Cupom Fiscal PDV
                      </MenuItem>

                      <MenuItem key="3" value="2D">
                        Cupom Fiscal (emitido por ECF)
                      </MenuItem>
                    </TextField>
                  </div>

                  <div className="col d-flex align-items-center">
                    <ReactNumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      label="Ordem sequencial do ECF"
                      format="###"
                      mask="_"
                      value={sequentialEcf}
                      onChange={(e) => setSequentialEcf(e.target.value)}
                    />
                  </div>

                  <div className="col d-flex align-items-center">
                    <ReactNumberFormat
                      margin="normal"
                      className="inputSmaller"
                      customInput={TextField}
                      variant="outlined"
                      label="Contador de Ordem de Operação"
                      format="######"
                      mask="_"
                      value={counterOperation}
                      onChange={(e) => setCounterOperation(e.target.value)}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              {typeDocument == "ruralProducer" ? (
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col">
                      <TextField
                        select
                        label="Modelo do Documento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={modelDocumentProducer}
                        onChange={(e) =>
                          setModelDocumentProducer(e.target.value)
                        }
                      >
                        <MenuItem key="0" value="">
                          Selecione
                        </MenuItem>

                        <MenuItem key="1" value="01">
                          Notal Fiscal
                        </MenuItem>

                        <MenuItem key="2" value="04">
                          Nota Fiscal de Produtor
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col">
                      <TextField
                        size="small"
                        type="number"
                        label="Ano emissão"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={yearEmission}
                        onChange={(e) => setYearEmission}
                      />
                    </div>

                    <div className="col">
                      <TextField
                        select
                        label="Mês emissão"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={monthEmission}
                        onChange={(e) => setMonthEmission}
                      >
                        <MenuItem key="0" value="1">
                          01
                        </MenuItem>
                        <MenuItem key="1" value="2">
                          02
                        </MenuItem>
                        <MenuItem key="2" value="3">
                          03
                        </MenuItem>
                        <MenuItem key="3" value="4">
                          04
                        </MenuItem>
                        <MenuItem key="4" value="5">
                          05
                        </MenuItem>
                        <MenuItem key="5" value="6">
                          06
                        </MenuItem>
                        <MenuItem key="6" value="7">
                          07
                        </MenuItem>
                        <MenuItem key="7" value="8">
                          08
                        </MenuItem>
                        <MenuItem key="8" value="9">
                          09
                        </MenuItem>
                        <MenuItem key="9" value="10">
                          10
                        </MenuItem>
                        <MenuItem key="10" value="11">
                          11
                        </MenuItem>
                        <MenuItem key="11" value="12">
                          12
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col">
                      <ReactNumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="Série"
                        format="###"
                        mask=""
                        value={seriesProducerRural}
                        onChange={(e) => setSeriesProducerRural(e.target.value)}
                      />
                    </div>

                    <div className="col">
                      <ReactNumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="Número"
                        format="#########"
                        mask=""
                        value={numberProducerRural}
                        onChange={(e) => setNumberProducerRural(e.target.value)}
                      />
                    </div>

                    <div className="col">
                      <TextField
                        select
                        label="UF do emitente"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={ufIssuer}
                        onChange={(e) => setUfIssuer(e.target.value)}
                      >
                        <MenuItem key="0" value="RO">
                          RO
                        </MenuItem>
                        <MenuItem key="1" value="AC">
                          AC
                        </MenuItem>
                        <MenuItem key="2" value="AM">
                          AM
                        </MenuItem>
                        <MenuItem key="3" value="RR">
                          RR
                        </MenuItem>
                        <MenuItem key="4" value="PA">
                          PA
                        </MenuItem>
                        <MenuItem key="5" value="AP">
                          AP
                        </MenuItem>
                        <MenuItem key="6" value="TO">
                          TO
                        </MenuItem>
                        <MenuItem key="7" value="MA">
                          MA
                        </MenuItem>
                        <MenuItem key="8" value="PI">
                          PI
                        </MenuItem>
                        <MenuItem key="9" value="CE">
                          CE
                        </MenuItem>
                        <MenuItem key="10" value="RN">
                          RN
                        </MenuItem>
                        <MenuItem key="11" value="PB">
                          PB
                        </MenuItem>
                        <MenuItem key="12" value="PE">
                          PE
                        </MenuItem>
                        <MenuItem key="13" value="AL">
                          AL
                        </MenuItem>
                        <MenuItem key="14" value="SE">
                          SE
                        </MenuItem>
                        <MenuItem key="15" value="BA">
                          BA
                        </MenuItem>
                        <MenuItem key="16" value="MG">
                          MG
                        </MenuItem>
                        <MenuItem key="17" value="ES">
                          ES
                        </MenuItem>
                        <MenuItem key="18" value="RJ">
                          RJ
                        </MenuItem>
                        <MenuItem key="19" value="SP">
                          SP
                        </MenuItem>
                        <MenuItem key="20" value="PR">
                          PR
                        </MenuItem>
                        <MenuItem key="21" value="SC">
                          SC
                        </MenuItem>
                        <MenuItem key="22" value="RS">
                          RS
                        </MenuItem>
                        <MenuItem key="23" value="MS">
                          MS
                        </MenuItem>
                        <MenuItem key="24" value="MT">
                          MT
                        </MenuItem>
                        <MenuItem key="25" value="GO">
                          GO
                        </MenuItem>
                        <MenuItem key="26" value="DF">
                          DF
                        </MenuItem>
                      </TextField>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <TextField
                        select
                        label="Tipo de Pessoa"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={typePeopleProducerRural}
                        onChange={(e) =>
                          setTypePeopleProducerRural(e.target.value)
                        }
                      >
                        <MenuItem key="0" value="physical">
                          Pessoal Física
                        </MenuItem>
                        <MenuItem key="1" value="legal">
                          Pessoa Jurídica
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col">
                      {typePeopleProducerRural == "physical" ? (
                        <ReactNumberFormat
                          margin="normal"
                          className="inputSmaller"
                          customInput={TextField}
                          variant="outlined"
                          label="CPF"
                          format="###.###.###-##"
                          mask="_"
                          value={cpfProducerRural}
                          onChange={(e) => setCpfProducerRural(e.target.value)}
                          onBlur={(e) => checkCpf(e.target.value)}
                        />
                      ) : (
                        <ReactNumberFormat
                          margin="normal"
                          className="inputSmaller"
                          customInput={TextField}
                          variant="outlined"
                          label="CNPJ"
                          format="##.###.###/####-##"
                          mask="_"
                          value={cnpjProducerRural}
                          onChange={(e) => setCnpjProducerRural(e.target.value)}
                          onBlur={(e) =>
                            checkCnpj(e.target.value, "producerRural")
                          }
                        />
                      )}
                    </div>

                    <div className="col">
                      <ReactNumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="IE do emitente"
                        format="##############"
                        mask=""
                        value={ieIssuer}
                        onChange={(e) => setIeIssuer(e.target.value)}
                        disabled={exemptProducerRural == "y" ? true : false}
                      />
                    </div>

                    <div className="col-lg-2 d-flex align-items-center">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            color="primary"
                            checked={exemptProducerRural == "y" ? true : false}
                            onChange={() => {
                              setIeIssuer(exemptProducerRural ? "ISENTO" : "");
                              setExemptProducerRural(
                                exemptProducerRural == "y" ? "n" : "y"
                              );
                            }}
                          />
                        }
                        label="Isento"
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <ReactNumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="Chave de acesso do CT-e"
                        format="###########################################"
                        mask="_"
                        value={accessKeyCte}
                        onChange={(e) => setAccessKeyCte(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          <div className="row">
            <div className="col-lg-12">
              <Tabs
                activeKey={activeTab}
                onSelect={(tab: string) => setActiveTab(tab)}
                id="invoice-form-tabs"
              >
                <Tab eventKey="details" title="Detalhes">
                  <div className="row">
                    <div className="col-lg-6 pl-6">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            color="primary"
                            checked={informDateEmission == "y" ? true : false}
                            onChange={() =>
                              setInformDateEmission(
                                informDateEmission == "y" ? "n" : "y"
                              )
                            }
                          />
                        }
                        label="Informar data de emissão"
                        labelPlacement="end"
                        name="informDateEmission"
                      />

                      {informDateEmission == "y" ? (
                        <div className="row">
                          <div className="col-lg-6">
                            <TextField
                              size="small"
                              type="date"
                              label="Data de emissão"
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={emissionDate}
                              onChange={(e) => setEmissionDate(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            color="primary"
                            checked={informIe == "y" ? true : false}
                            onChange={() =>
                              setInformIe(informIe == "y" ? "n" : "y")
                            }
                          />
                        }
                        label="Informar IE do substituto tributário"
                        labelPlacement="end"
                        name="InformIe"
                      />

                      {informIe == "y" ? (
                        <div className="row">
                          <div className="col-lg-6">
                            <TextField
                              size="small"
                              label="IE do substituto tributário"
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{ maxLength: 14 }}
                              value={taxSubstitute}
                              onChange={(e) => setTaxSubstitute(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2 d-flex align-items-center">
                      <TextField
                        size="small"
                        type="date"
                        label="Data de saída"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={outputDate}
                        onChange={(e) => setOutputDate(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <ReactNumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="Hora"
                        format="##:##"
                        mask="_"
                        value={hours}
                        onChange={(e) => setHours(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        label="Ordem de compra"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 50 }}
                        value={purchaseOrder}
                        onChange={(e) => setPurchaseOrder(e.target.value)}
                      ></TextField>
                    </div>

                    <div className="col-lg-3">
                      <TextField
                        select
                        label="Tipo de atendimento"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={typeAttendance}
                        onChange={(e) => {
                          setTypeAttendance(Number(e.target.value));
                        }}
                      >
                        <MenuItem key="0" value="0">
                          Não se aplica
                        </MenuItem>

                        <MenuItem key="1" value="1">
                          Operação presencial
                        </MenuItem>

                        <MenuItem key="2" value="2">
                          Operação não presencial, pela internet
                        </MenuItem>

                        <MenuItem key="3" value="3">
                          Operação não presencial, Teleatendimento
                        </MenuItem>

                        <MenuItem key="4" value="4">
                          NFC-e em operação com entrega em domicílio
                        </MenuItem>

                        <MenuItem key="5" value="9">
                          Operação não presencial, Outros
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-3">
                      <TextField
                        select
                        label="Destino da operação"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={destinationOperation}
                        onChange={(e) =>
                          setDestinationOperation(Number(e.target.value))
                        }
                      >
                        <MenuItem key="1" value="1">
                          Operação Interna
                        </MenuItem>

                        <MenuItem key="2" value="2">
                          Operação Interestadual
                        </MenuItem>

                        <MenuItem key="3" value="3">
                          Operação com exterior
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-4">
                      <TextField
                        select
                        label="Indicador de intermediador/marketplace"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={indicator}
                        onChange={(e) => setIndicator(Number(e.target.value))}
                        disabled={typeAttendance === 0}
                      >
                        <MenuItem key="0" value="0">
                          Operação sem intermediador (em site ou plataforma
                          própria)
                        </MenuItem>

                        <MenuItem key="1" value="1">
                          Operação em site ou plataforma de terceiros
                          (intermediadores/marketplace)
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-4">
                      <ReactNumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="CNPJ do intermediador da transação"
                        format="##.###.###/####-##"
                        mask="_"
                        disabled={indicator === 0}
                        value={intermediaryCnpj}
                        onChange={(e) => setIntermediaryCnpj(e.target.value)}
                        onBlur={(e) =>
                          checkCnpj(e.target.value, "intermediary")
                        }
                      />
                    </div>

                    <div className="col-lg-4">
                      <TextField
                        label="Identificador cadastro intermediador"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 255 }}
                        disabled={indicator === 0}
                        value={identifierRegisterIntermediary}
                        onChange={(e) =>
                          setIdentifierRegisterIntermediary(e.target.value)
                        }
                      ></TextField>
                    </div>

                    <div className="col-lg-6">
                      <TextField
                        label="Observações"
                        multiline
                        rows="10"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 5000 }}
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-6">
                      <TextField
                        label="Observações Internas"
                        multiline
                        rows="10"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 5000 }}
                        value={internalObservation}
                        onChange={(e) => setInternalObservation(e.target.value)}
                      />
                    </div>

                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                      <Button variant="success" size="sm" block>
                        Mensagens padrões
                      </Button>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="products" title="Produtos">
                  <div className="row">
                    {products.products.map((product: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="col-lg-4 d-flex align-items-center p-1 ">
                            <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                              <label className="text-white m-0">
                                {index + 1}.
                              </label>

                              {product.INSTANCE.isKit == "y" ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      <b>Produtos do Kit</b>
                                      <br />
                                      {product.INSTANCE.kit}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    className="d-flex flex-row align-items-center justify-content-center ml-2 p-0"
                                    style={{
                                      padding: "5px",
                                      background: "transparent",
                                    }}
                                  >
                                    <i className="flaticon2-information m-0 p-0"></i>
                                  </Button>
                                </OverlayTrigger>
                              ) : (
                                <></>
                              )}
                            </div>

                            <ProductSelect
                              label="Produto"
                              selectedProduct={product}
                              onChangeProduct={(prod) => {
                                selectProduct(prod, index);
                                applyPriceList(priceList);
                              }}
                              type="product"
                              onClickAddProduct={(typedText) =>
                                handleClickAddProduct(typedText, index, product)
                              }
                            />
                          </div>

                          <div className="col p-1">
                            <TextField
                              type="number"
                              size="small"
                              label="Qtde."
                              margin="normal"
                              variant="outlined"
                              name="amount"
                              value={product.amount}
                              onChange={(evt) => {
                                changeAmount(evt.target.value, index);
                                applyPriceList(priceList);
                              }}
                            />
                          </div>

                          <div className="col p-1">
                            <NumericFormat
                              label="IPI %"
                              value={product.ipi}
                              onChange={(e: any) =>
                                setIpi(e.target.value, index)
                              }
                              onBlur={(e) => {
                                var converted = e.target.value
                                  ? formatToFloat(e.target.value)
                                  : 0;

                                if (converted > 100) {
                                  setIpi("100", index);
                                } else {
                                  setIpi(
                                    converted
                                      ? converted.toFixed(2).replace(".", ",")
                                      : "0",
                                    index
                                  );
                                }
                              }}
                            />
                          </div>

                          <div className="col p-1">
                            <NumericFormat
                              label="ICMS %"
                              value={product.icms}
                              onChange={(e: any) =>
                                setIcms(e.target.value, index)
                              }
                              onBlur={(e) => {
                                var converted = e.target.value
                                  ? formatToFloat(e.target.value)
                                  : 0;

                                if (converted > 100) {
                                  setIcms("100", index);
                                } else {
                                  setIcms(
                                    converted
                                      ? converted.toFixed(2).replace(".", ",")
                                      : "0",
                                    index
                                  );
                                }
                              }}
                            />
                          </div>

                          <div className="col-lg-2 p-1">
                            <NumericFormat
                              label="Valor Unit."
                              value={product.unitaryValue}
                              onChange={(evt) => {
                                changeProductValue(evt.target.value, index);
                                applyPriceList(priceList);
                              }}
                              startAdornment="R$"
                            />
                          </div>

                          <div className="col p-1">
                            <NumericFormat
                              label="CFOP"
                              format="####"
                              value={product.cfop}
                              onChange={(e: any) =>
                                setCfop(e.target.value, index)
                              }
                            />
                          </div>

                          <div className="col p-1">
                            <NumericFormat
                              label="NCM"
                              format="########"
                              value={product.ncm}
                              onChange={(e: any) =>
                                setNcm(e.target.value, index)
                              }
                            />
                          </div>

                          <div className="col-lg-2 p-1">
                            <NumericFormat
                              label="Valor Total"
                              disabled
                              value={product.total}
                              startAdornment="R$"
                            />
                          </div>

                          <>
                            {index > 0 && (
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => deleteProduct(index)}
                              >
                                <i className="flaticon-delete"></i>
                              </button>
                            )}
                          </>
                        </React.Fragment>
                      );
                    })}

                    <div className="row col-lg-12 mt-3 ml-2">
                      <button
                        type="button"
                        className="btn btn-link-secondary p-0"
                        onClick={() => addProducts()}
                      >
                        <ins>+ adicionar outro produto</ins>
                      </button>
                    </div>

                    <div className="col-lg-6">
                      <ApiResourceSelect
                        label="Lista de preços"
                        getOptionLabel={(option: PriceList) => option.name}
                        value={priceList}
                        onSelect={(option) => {
                          setPriceList(option ? String(option.id) : "");
                          applyPriceList(option ? String(option.id) : "");
                        }}
                        apiSearchHandler={(typedText) =>
                          PriceListService.getPriceListsFiltered({
                            name: typedText,
                          })
                        }
                        getSelectedOption={(loadedOptions) => {
                          if (!priceList) return null;
                          return (
                            loadedOptions.find(
                              (option) => option.id === Number(priceList)
                            ) ?? PriceListService.getPriceListById(priceList)
                          );
                        }}
                        disabled={formatToFloat(totalValue) === 0}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="total-invoice" title="Totais da Nota Fiscal">
                  <div className="row">
                    <div className="col-lg-2">
                      <NumericFormat
                        label="BC de ICMS"
                        startAdornment="R$"
                        value={bcIcms}
                        disabled
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Valor do ICMS"
                        startAdornment="R$"
                        value={valueIcms}
                        disabled
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="BC de ICMS-ST"
                        startAdornment="R$"
                        value={bcIcmsSt}
                        disabled
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Valor do ICMS-ST"
                        startAdornment="R$"
                        value={valueIcmsSt}
                        disabled
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Valor do IPI"
                        startAdornment="R$"
                        value={valueIpi}
                        disabled
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Valor dos produtos"
                        startAdornment="R$"
                        value={valueProducts}
                        disabled
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Desconto (R$)"
                        startAdornment="R$"
                        value={discountMoney}
                        onChange={(e) => setDiscountMoney(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Valor do Frete"
                        startAdornment="R$"
                        value={valueFreight}
                        onChange={(e) => setValueFreight(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Valor despesas"
                        startAdornment="R$"
                        value={valueExpenses}
                        onChange={(e) => setValueExpenses(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Valor do Seguro"
                        startAdornment="R$"
                        value={valueSafe}
                        onChange={(e) => setValueSafe(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Valor total da nota"
                        startAdornment="R$"
                        value={totalValue}
                        disabled
                      />
                    </div>

                    <div className="col-lg-12 mt-10">
                      <Accordion>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="h5 mb-3 pl-0 pt-0"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="/media/svg/icons/Navigation/Angle-double-right.svg"
                            className="mr-3"
                          />
                          Retenções
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                          <div className="row">
                            <div className="col-lg-2">
                              <NumericFormat
                                label="Valor do PIS retido"
                                startAdornment="R$"
                                value={valuePis}
                                onChange={(e) => setValuePis(e.target.value)}
                              />
                            </div>

                            <div className="col-lg-2">
                              <NumericFormat
                                label="Valor COFINS retido"
                                startAdornment="R$"
                                value={valueCofins}
                                onChange={(e) => setValueCofins(e.target.value)}
                              />
                            </div>

                            <div className="col-lg-2">
                              <NumericFormat
                                label="Valor IRRF retido"
                                startAdornment="R$"
                                value={valueIrrf}
                                onChange={(e) => setValueIrrf(e.target.value)}
                              />
                            </div>

                            <div className="col-lg-2">
                              <NumericFormat
                                label="Valor CSLL retido"
                                startAdornment="R$"
                                value={valueCsll}
                                onChange={(e) => setValueCsll(e.target.value)}
                              />
                            </div>

                            <div className="col-lg-2">
                              <NumericFormat
                                label="Prev. social retido"
                                startAdornment="R$"
                                value={valueSocial}
                                onChange={(e) => setValueSocial(e.target.value)}
                              />
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="payment" title="Pagamento">
                  <div className="row">
                    <div className="col-lg-4">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              amountInstallments == "0" &&
                              installments.installment.length === 0
                            }
                            onChange={() => {
                              if (
                                amountInstallments == "0" &&
                                installments.installment.length === 0
                              ) {
                                setAmountInstallments("1");
                                setInstallments({ installment: [] });
                              } else {
                                setAmountInstallments("0");
                                setInstallments({ installment: [] });
                              }
                            }}
                          />
                        }
                        label="Sem Pagamento"
                        labelPlacement="end"
                      />
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-lg-3 d-flex align-items-center">

                                            <TextField
                                                select
                                                label="Condição de Pagamento"
                                                margin="normal"
                                                variant="outlined"
                                                name="conditionPayment"
                                                size="small"
                                            >
                                                <MenuItem key="0" value="nf-e normal">
                                                    Selecione
                                                </MenuItem>

                                                <MenuItem key="1" value="30/60/90">
                                                    30/60/90
                                                </MenuItem>
                                            </TextField>

                                        </div>

                                        <div className="p-3 d-flex align-items-center justify-content-center">
                                            <label>ou</label>
                                        </div> */}

                    <div className="col-lg-4">
                      <TextField
                        type="number"
                        label="Quantidade de Parcelas"
                        margin="normal"
                        variant="outlined"
                        name="amountParcel"
                        size="small"
                        value={amountInstallments}
                        onChange={(e) => setAmountInstallments(e.target.value)}
                      ></TextField>
                    </div>

                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => generateInstallments()}
                        block
                      >
                        Gerar parcelas
                      </Button>
                    </div>

                    <div className="col-lg-12 mt-10">
                      {installments.installment.length > 0 &&
                        installments.installment.map((installment, index) => {
                          return (
                            <div className="row">
                              <div className="col-lg-3 d-flex align-items-center p-1">
                                <div className="d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2 mr-2">
                                  <label className="text-white m-0">
                                    {index + 1}.
                                  </label>
                                </div>

                                <TextField
                                  type="date"
                                  label="Data"
                                  margin="normal"
                                  variant="outlined"
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={installment.date}
                                  onChange={(e) =>
                                    setValuesInstallments(
                                      e.target.value,
                                      index,
                                      "date"
                                    )
                                  }
                                />
                              </div>

                              <div className="col p-1">
                                {index + 1 !==
                                installments.installment.length ? (
                                  <NumericFormat
                                    key={index}
                                    label="Valor"
                                    value={installment.value}
                                    startAdornment="R$"
                                    onBlur={(e) => {
                                      var installmentArray =
                                        installments.installment;
                                      installmentArray[
                                        index
                                      ].value = formatToFloat(e.target.value);
                                      setInstallments({
                                        installment: installmentArray,
                                      });
                                      distributeValueInstallments(index);
                                    }}
                                  />
                                ) : (
                                  <NumericFormat
                                    disabled
                                    key={index}
                                    label="Valor"
                                    value={installment.value}
                                    startAdornment="R$"
                                    onBlur={(e) => {
                                      setValuesInstallments(
                                        e.target.value,
                                        index,
                                        "value"
                                      );
                                      distributeValueInstallments(index);
                                    }}
                                  />
                                )}
                              </div>

                              <div className="col p-1">
                                <TextField
                                  select
                                  label="Forma de pgto"
                                  margin="normal"
                                  variant="outlined"
                                  value={installment.payment}
                                  size="small"
                                  onChange={(e) =>
                                    setValuesInstallments(
                                      e.target.value,
                                      index,
                                      "payment"
                                    )
                                  }
                                >
                                  <MenuItem key={-1} value="">
                                    Selecione
                                  </MenuItem>
                                  {paymentOptions.map((payment, i) => {
                                    return (
                                      <MenuItem key={i} value={payment.value}>
                                        {payment.value}
                                      </MenuItem>
                                    );
                                  })}
                                </TextField>
                              </div>

                              <div className="col p-1">
                                <TextField
                                  label="Observações"
                                  margin="normal"
                                  variant="outlined"
                                  value={installment.comments}
                                  size="small"
                                  onChange={(e) =>
                                    setValuesInstallments(
                                      e.target.value,
                                      index,
                                      "comments"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="transport" title="Transporte">
                  <div className="row">
                    <div className="col-lg-2">
                      <NumericFormat
                        label="Peso Bruto"
                        startAdornment="KG"
                        value={grossWeight}
                        onChange={(e) => setGrossWeight(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <NumericFormat
                        label="Peso Líquido"
                        startAdornment="KG"
                        value={liquidWeight}
                        onChange={(e) => setLiquidWeight(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <ReactNumberFormat
                        margin="normal"
                        className="inputSmaller"
                        customInput={TextField}
                        variant="outlined"
                        label="Volumes"
                        format="#####"
                        value={volumes}
                        onChange={(e) => setVolumes(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        label="Espécie"
                        margin="normal"
                        variant="outlined"
                        placeholder="EX: Caixa"
                        size="small"
                        value={species}
                        onChange={(e) => setSpecies(e.target.value)}
                      ></TextField>
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        label="Marca"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                      ></TextField>
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        label="Numeração"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={numbering}
                        onChange={(e) => setNumbering(e.target.value)}
                      ></TextField>
                    </div>

                    <div className="col-lg-4">
                      <TextField
                        select
                        label="Modalidade de Frete"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={modalityFreight}
                        onChange={(e) =>
                          setModalityFreight(Number(e.target.value))
                        }
                      >
                        <MenuItem key="0" value="0">
                          Emitente
                        </MenuItem>

                        <MenuItem key="1" value="1">
                          Destinatário
                        </MenuItem>

                        <MenuItem key="2" value="2">
                          Terceiro
                        </MenuItem>

                        <MenuItem key="3" value="3">
                          Próprio por conta do Remetente
                        </MenuItem>

                        <MenuItem key="4" value="4">
                          Próprio por conta Destinatário
                        </MenuItem>

                        <MenuItem key="5" value="9">
                          Sem Frete
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-4">
                      <ApiResourceSelect
                        label="Transportadora"
                        getOptionLabel={(option: Carrier) => option.name}
                        value={carrier}
                        onSelect={(option) =>
                          setCarrier(option ? String(option.id) : "")
                        }
                        apiSearchHandler={(typedText) =>
                          CarrierService.getCarriersFiltered({
                            name: typedText,
                          })
                        }
                        getSelectedOption={(loadedOptions) => {
                          if (!carrier) return null;
                          return (
                            loadedOptions.find(
                              (option) => option.id === Number(carrier)
                            ) ?? CarrierService.getCarrierById(carrier)
                          );
                        }}
                        disabled={[3, 4, 9].includes(modalityFreight)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        label="Placa"
                        margin="normal"
                        variant="outlined"
                        placeholder="Placa do veiculo"
                        size="small"
                        value={plateVehicle}
                        onChange={(e) => setPlateVehicle(e.target.value)}
                        disabled={modalityFreight === 9}
                      ></TextField>
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        label="RNTC (ANTT)"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={rntc}
                        onChange={(e) => setRntc(e.target.value)}
                        disabled={modalityFreight === 9}
                      ></TextField>
                    </div>
                  </div>
                </Tab>

                {type === 2 && (
                  <Tab eventKey="export" title="Exportação">
                    <div className="row">
                      <div className="col-lg-4">
                        <TextField
                          select
                          label="UF de Embarque"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          value={ufExport}
                          onChange={(e) => setUfExport(e.target.value)}
                        >
                          <MenuItem key="0" value="RO">
                            RO
                          </MenuItem>
                          <MenuItem key="1" value="AC">
                            AC
                          </MenuItem>
                          <MenuItem key="2" value="AM">
                            AM
                          </MenuItem>
                          <MenuItem key="3" value="RR">
                            RR
                          </MenuItem>
                          <MenuItem key="4" value="PA">
                            PA
                          </MenuItem>
                          <MenuItem key="5" value="AP">
                            AP
                          </MenuItem>
                          <MenuItem key="6" value="TO">
                            TO
                          </MenuItem>
                          <MenuItem key="7" value="MA">
                            MA
                          </MenuItem>
                          <MenuItem key="8" value="PI">
                            PI
                          </MenuItem>
                          <MenuItem key="9" value="CE">
                            CE
                          </MenuItem>
                          <MenuItem key="10" value="RN">
                            RN
                          </MenuItem>
                          <MenuItem key="11" value="PB">
                            PB
                          </MenuItem>
                          <MenuItem key="12" value="PE">
                            PE
                          </MenuItem>
                          <MenuItem key="13" value="AL">
                            AL
                          </MenuItem>
                          <MenuItem key="14" value="SE">
                            SE
                          </MenuItem>
                          <MenuItem key="15" value="BA">
                            BA
                          </MenuItem>
                          <MenuItem key="16" value="MG">
                            MG
                          </MenuItem>
                          <MenuItem key="17" value="ES">
                            ES
                          </MenuItem>
                          <MenuItem key="18" value="RJ">
                            RJ
                          </MenuItem>
                          <MenuItem key="19" value="SP">
                            SP
                          </MenuItem>
                          <MenuItem key="20" value="PR">
                            PR
                          </MenuItem>
                          <MenuItem key="21" value="SC">
                            SC
                          </MenuItem>
                          <MenuItem key="22" value="RS">
                            RS
                          </MenuItem>
                          <MenuItem key="23" value="MS">
                            MS
                          </MenuItem>
                          <MenuItem key="24" value="MT">
                            MT
                          </MenuItem>
                          <MenuItem key="25" value="GO">
                            GO
                          </MenuItem>
                          <MenuItem key="26" value="DF">
                            DF
                          </MenuItem>
                        </TextField>
                      </div>

                      <div className="col-lg-8">
                        <TextField
                          label="Local Embarque"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          value={locationExport}
                          onChange={(e) => setLocationExport(e.target.value)}
                        />
                      </div>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
