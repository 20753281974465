import React, { useEffect, useState } from "react";

import { Grid, TextField, FormControlLabel } from "@material-ui/core";
import { Button, Modal, Spinner, Pagination } from "react-bootstrap";

import "../../../style.css";
import ModalSuccess from "../../../components/ModalSuccess";
import { ProductPromotion } from "../../../types/ProductPromotion";
import { validatePromotionDate } from "../../../pages/Product/Promotions/validation";
import { makeScheduleMessage } from "../../../utils/watsappSchedule";
import useCompany from "../../../hooks/company";
import { useSelector } from "react-redux";
import apiBFF from "../../../services/ApiBFF";
import CustomSwitchComponent from "../../DS/Switch";
import CustomCheckboxComponent from "../../DS/Checkbox";
import CustomersList from "./Components/CustomerList";

type SendPromotionFormProps = {
  onClosePromotionSentSuccessModal: () => void;
  promotion?: ProductPromotion;
  customers: any[];
};

type UrlParam = {
  promotionId: string;
  id: string;
};

export function SendPromotionForm({
  onClosePromotionSentSuccessModal,
  promotion,
  customers,
}: SendPromotionFormProps) {
  const [message, setMessage] = useState("");
  const [sendAt, setSendAt] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalExist, setShowModalExist] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [nameFilter, setNameFilter] = useState("");

  const handleCustomerSelection = (customerId: string) => {
    setSelectedCustomers((prevSelected) => {
      const newSelected = prevSelected.includes(customerId)
        ? prevSelected.filter((id) => id !== customerId)
        : [...prevSelected, customerId];

      // updateBudget(newSelected);
      return newSelected;
    });
  };

  const { user } = useSelector((state: any) => state.auth);

  const { companies } = useCompany({
    only: user?.companyId,
    appendSubscriptionPlan: true,
  });

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCustomers([]);
    } else {
      const allCustomerIds = customers.map((customer) => customer.id);
      setSelectedCustomers(allCustomerIds);
    }
    setSelectAll(!selectAll);
  };

  async function inputsVerify(): Promise<boolean> {
    if (!message) {
      setIsSubmit(false);
      setMsgError("Escreva a mensagem a ser enviada para o cliente!");
      setShowModalExist(true);

      return false;
    }

    if (!sendAt) {
      setIsSubmit(false);
      setMsgError("Preencha a data de envio da promoção!");
      setShowModalExist(true);

      return false;
    }

    const sendAtValidation = validatePromotionDate(sendAt);
    if (!sendAtValidation.isValid) {
      setIsSubmit(false);
      setMsgError(sendAtValidation.message);
      setShowModalExist(true);

      return false;
    }

    return true;
  }
  const [customersFiltered, setCustomersFiltered] = useState(customers);
  useEffect(() => {
    setCustomersFiltered(customers);
  }, [customers]);

  const handleFilter = () => {
    if (nameFilter !== "") {
      setCustomersFiltered(
        customers.filter((item) => item.name.toUpperCase().includes(nameFilter))
      );
      return;
    }
    setCustomersFiltered(customers);
  };

  async function handleSubmit() {
    if (!companies[0]?.email) return;
    setIsSubmit(true);

    if (!(await inputsVerify())) return;

    const selectedCustomersData = customers.filter((customer) =>
      selectedCustomers.includes(customer.id)
    );

    try {
      const { data } = await apiBFF.get(
        `millionzap/user/hash/${companies[0].email}`
      );
      const { passwordHash } = data;

      const dataLoginBFF = await apiBFF.post("millionzap/login", {
        email: companies[0].email,
        passwordHash,
      });

      const { token, user: userMillionZap } = dataLoginBFF.data;

      for (const customer of selectedCustomersData) {
        await makeScheduleMessage({
          companyMail: companies[0].email,
          user: { token, id: userMillionZap.id },
          contact: {
            name: customer.name,
            number: customer.cell,
          },
          schedule: {
            body: message,
            sendAt: sendAt,
            userId: user.id,
          },
        });
      }

      setShowModalSuccess(true);
    } catch (error) {
      console.log("error", error);
      setMsgError("Ocorreu um erro ao processar a requisição");
      setShowModalExist(true);
    }

    setIsSubmit(false);
  }

  function handleCloseModalSuccess() {
    onClosePromotionSentSuccessModal();
  }

  return (
    <div className="row card card-body pt-2 newProductWrapper">
      <ModalSuccess
        msgModal={"Envio de promoção agendado com sucesso!"}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        onClose={handleCloseModalSuccess}
      />

      <Modal
        show={showModalExist}
        onHide={() => setShowModalExist(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalExist(!showModalExist)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="makeStyles-container-12">
        <Grid container spacing={3} className="mb-3">
          <Grid item md={6}>
            <TextField
              size="small"
              // className={classes.error}
              label="Mensagem"
              margin="normal"
              variant="outlined"
              value={message}
              InputLabelProps={{ shrink: true }}
              name="description"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-3">
          <Grid item md={4}>
            <TextField
              size="small"
              // className={classes.error}
              label="Data de envio"
              InputLabelProps={{ shrink: true }}
              type="date"
              margin="normal"
              variant="outlined"
              value={sendAt}
              name="sendAt"
              onChange={(e) => {
                setSendAt(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-3">
          <Grid item md={4}>
            <TextField
              size="small"
              // className={classes.error}
              label="Filtrar"
              margin="normal"
              variant="outlined"
              value={nameFilter}
              InputLabelProps={{ shrink: true }}
              name="nome"
              onChange={(e) => {
                setNameFilter(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className="mb-3">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <label style={{ fontSize: "1.25rem" }}>Clientes</label>
            </div>
            <div>
              <CustomSwitchComponent
                checked={selectAll}
                id={"selectAll"}
                onChange={handleSelectAll}
                name={"Selecionar todos"}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              paddingBottom: "8px",
            }}
          >
            <CustomersList
              handleCustomerSelection={handleCustomerSelection}
              customersFiltered={customers.filter((item) =>
                item?.name?.toUpperCase()?.includes(nameFilter?.toUpperCase())
              )}
              selectedCustomers={selectedCustomers}
            />
          </div>
        </Grid>

        <Grid
          style={{
            marginTop: "26px",
            justifyContent: "flex-end",
            width: "100%",
            display: "flex",
          }}
        >
          <Button
            type="button"
            className="mr-0 mt-0 mb-2"
            variant="primary"
            disabled={isSubmit}
            onClick={handleSubmit}
          >
            {isSubmit ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <span>Enviar</span>
            )}
          </Button>
        </Grid>
      </div>
    </div>
  );
}
