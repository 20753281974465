import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductPromotion } from "../../../types/ProductPromotion";
import api from "../../../services/Api";
import { SendPromotionForm } from "../../../components/Product/Promotion/SendPromotionForm";
import { Customer } from "../../../types/Customer";
import { Spinner } from "react-bootstrap";
interface promotionProps {
  promotionIdParam?: string;
}

export function SendPromotion({ promotionIdParam }: promotionProps) {
  const { id, promotionId } = useParams<{ id: string; promotionId: string }>();

  const [promotion, setPromotion] = useState<ProductPromotion | undefined>(
    undefined
  );
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (promotion) return;
    const fetchPromotion = async () => {
      setLoading(true);
      const { data } = await api.get<ProductPromotion>(
        `product/promotions/${promotionId || promotionIdParam}`
      );
      setPromotion(data);
      setLoading(false);
    };

    fetchPromotion();
  }, [promotionId]);

  useEffect(() => {
    if (customers?.length > 0) return;
    const fetchCustomers = async () => {
      setLoading(true);
      const { data } = await api.get<Customer[]>("customer", {
        params: { sortReference: "name", sortDirection: "asc" },
      });
      setLoading(false);
      setCustomers(data);
    };

    fetchCustomers();
  }, [promotion]);

  function handleClosePromotionSentSuccessModal() {
    if (id) window.location.href = `/produtos/${id}/promocoes`;
  }

  return loading ? (
    <div className="row card card-body pt-2 newProductWrapper">
      <div
        style={{
          margin: "24px",
          display: "flex",
          gap: "16px",
        }}
      >
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span>{" Carregando..."}</span>
      </div>
    </div>
  ) : (
    <SendPromotionForm
      promotion={promotion}
      customers={customers}
      onClosePromotionSentSuccessModal={handleClosePromotionSentSuccessModal}
    />
  );
}
