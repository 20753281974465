import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import api from "../../services/Api";
import {
  Situation,
  BodyDataBaseProps,
  HeadDataBaseProps,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import { formatCurrency } from "../../utils/formatCurrency";
import { formatToFloat } from "../../utils/formatCurrency";
import { extractDateStringFromTimestamp } from "../../utils/dateTimeHelper";
import "../../style.css";
import { Search } from "../../components/Search";
import {
  Collapse,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { NumericFormat } from "../../components/NumericFormat";
import { Autocomplete } from "@material-ui/lab";
import { paymentOptions } from "../../utils/paymentOptions";
import { getSituationText } from "../../utils/getSituationText";
import useBackendLoad from "../../hooks/backendReload";
import { BillsToPay } from "../../types/BillsToPay";
import BillToPayService from "../../services/BillToPayService";
import CustomerService from "../../services/CustomerService";
import SellerService from "../../services/SellerService";
import { useSelector } from "react-redux";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import { SubCategory } from "../../types/Dre";
import DreCategoryService from "../../services/DreCategoryService";
import { banks } from "../../utils/banks";
import CenterCostService from "../../services/CenterCostService";
import { CenterCost } from "../../types/CenterCost";
import ModalError from "../../components/ModalError";
import { useCompanyBranch } from "../../hooks/companyBranch";

type Filters = {
  searchQuery: string;
  situation: string;
  valueMin: number;
  valueMax: number;
  dreSubCategoryId: number;
  nameBank: string;
  centerCost: number;
  payment: string;
  issueDateMin: string;
  issueDateMax: string;
  dueDateMin: string;
  dueDateMax: string;
};

const headData: HeadDataBaseProps[] = [
  { reference: "id", value: "Id" },
  { reference: "doc", value: "Doc" },
  { reference: "name", value: "Beneficiário" },
  { reference: "date", value: "Data de vencimento" },
  { reference: "value", value: "Valor" },
  { reference: "payment", value: "Forma de pagamento", notSortable: true },
  {
    reference: "status",
    value: "Situação",
    situation: true,
    notSortable: true,
  },
];

export function ListBillsToPay() {
  const { user } = useSelector((state: any) => state.auth);
  const { selectedCompany } = useCompanyBranch();
  const [billsToPay, setBillsToPay] = useState<BillsToPay[]>([]);
  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
  const [situationData, setSituationData] = useState<Situation[]>([]);
  const [countTotalBillsToPay, setCountTotalBillsToPay] = useState(0);
  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [situation, setSituation] = useState("");
  const [valueMin, setValueMin] = useState(0);
  const [valueMax, setValueMax] = useState(0);
  const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
  const [nameBank, setNameBank] = useState("");
  const [centerCost, setCenterCost] = useState(0);
  const [payment, setPayment] = useState("");
  const [issueDateMin, setIssueDateMin] = useState("");
  const [issueDateMax, setIssueDateMax] = useState("");
  const [dueDateMin, setDueDateMin] = useState("");
  const [dueDateMax, setDueDateMax] = useState("");
  const filtersRef = useRef<Filters | null>(null);

  const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();

  const loadData = useCallback(
    async ({
      rowsPerPage,
      currentPage,
      sortDirection,
      sortReference,
    }: LoadDataParams) => {
      const { data } = await api.get<{ rows: BillsToPay[]; count: number }>(
        "billsToPay",
        {
          params: {
            skip: rowsPerPage * currentPage,
            take: rowsPerPage,
            filters: filtersRef.current
              ? JSON.stringify(filtersRef.current)
              : undefined,
            sortReference,
            sortDirection,
          },
        }
      );

      const { rows, count } = data;

      const foundBills = await BillToPayService.resolveBillsDueDates(rows);

      for (const bill of foundBills) {
        if (bill.supplier) {
          bill.supplierName = CustomerService.getCustomerName(
            bill.customerEntity
          );
        }
        if (bill.seller) {
          bill.sellerName = SellerService.getName(bill.sellerEntity);
        }
      }

      setCountTotalBillsToPay(count);
      setBillsToPay(foundBills);

      setBillsToPay(foundBills);
    },
    []
  );

  useEffect(() => {
    if (billsToPay.length) {
      const situation: Situation[] = billsToPay.map((bill) => ({
        ...bill,
        customer: bill.supplier,
        beneficiaryName:
          bill.beneficiaryType === "supplier"
            ? CustomerService.getCustomerName(bill.customerEntity)
            : SellerService.getName(bill.sellerEntity),
      }));
      setSituationData(situation);
    }
  }, [billsToPay]);

  useEffect(() => {
    const list: BodyDataBaseProps[][] = [];
    const aux = billsToPay;

    aux.forEach((bill) => {
      const amount = bill.totalPaid
        ? formatCurrency(bill.totalPaid)
        : formatCurrency(bill.remaining);
      let status = getSituationText(bill.status);
      const today = extractDateStringFromTimestamp();
      if (bill.status === "pending") {
        if (bill.dueDate === today) {
          status = "Vencendo";
        } else {
          const dueDateObj = new Date(bill.dueDate);
          if (dueDateObj.getTime() <= new Date().getTime()) {
            status = "Vencido";
          }
        }
      }

      // Formatando data
      let date;
      if (bill.dueDate) {
        const [year, month, day] = bill.dueDate.split("-");
        date = `${day}/${month}/${year}`;
      }

      const data: BodyDataBaseProps[] = [
        { for: "id", value: String(bill.id), id: true },
        { for: "doc", value: bill.name },
        { for: "name", value: bill.sellerName || bill.supplierName || "-" },
        { for: "date", value: date ?? "-" },
        {
          for: "value",
          value:
            status == "paid" && bill.partialPayments
              ? formatCurrency(bill.amount)
              : amount,
        },
        { for: "payment", value: bill.payment ?? "-" },
        { for: "status", value: status },
        { for: "type", value: bill.recordType },
      ];

      list.push(data);
    });

    setBodyData(list);
  }, [billsToPay]);

  const handleClickEdit = (id: string) => {
    pushHistory(`${pathname}/${id}`);
  };

  const handleClickClone = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/duplicar/${id}`);
    },
    [pathname]
  );

  const handleClickDelete = useCallback(
    async (id: string, reason: string) => {
      try {
        var bill = await api.get(`billsToPay/${id}`);

        await api.put(`billsToPay/${id}`, {
          status: "canceled",
          reasonCanceled: reason,
        });
        await api.put(`billsToReceive/${bill.data.idBillTransfer}`, {
          status: "canceled",
          reasonCanceled: reason,
        });

        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    [billsToPay]
  );

  const handleClickReversal = useCallback(
    async (id: string) => {
      try {
        const aux = billsToPay;
        const billIndex = aux.findIndex((bill) => bill.id === Number(id));

        if (billIndex < 0) {
          throw new Error();
        }

        const hasPermission = await BillToPayService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(
          aux[billIndex].payedDate || aux[billIndex].dueDate,
          selectedCompany
        );
        if (!hasPermission) {
          setMsgError(
            "O mês fiscal anterior foi encerrado e o usuário não tem permissão para administrar fechamento de mês! Em caso de dúvidas contate o administrador do sistema."
          );
          setShowModalError(true);
          return;
        }

        aux[billIndex] = {
          ...aux[billIndex],
          status: "pending",
          remaining: aux[billIndex].amount,
          accValue: 0,
          taxValue: 0,
          discountValue: 0,
          feeValue: 0,
          totalPaid: 0,
          payedDate: "",
          partialPayments: null,
        };

        await api.put(`billsToPay/${id}`, aux[billIndex]);

        setBillsToPay([...aux]);
      } catch (error) {
        console.log(error);
      }
    },
    [billsToPay]
  );

  const handleChangeSituation = useCallback(
    async ({
      id,
      status,
      dueDate,
      payment,
      customer,
      remaining,
      bankAccount,
      accValue,
      taxValue,
      discountValue,
      feeValue,
      totalPaid,
      payedDate,
      partialPayments,
      amount,
      writeOffValue,
    }: Situation) => {
      try {
        const aux = billsToPay;
        const billIndex = aux.findIndex((bill) => bill.id === id);

        if (billIndex < 0) {
          throw new Error();
        }

        const partialsJson = partialPayments
          ? JSON.parse(partialPayments)
          : null;

        if (remaining == 0 && partialsJson == null) {
          aux[billIndex] = {
            ...aux[billIndex],
            status,
            dueDate,
            payment,
            remaining,
            bankAccount,
            accValue: Number(accValue),
            taxValue: Number(taxValue),
            discountValue: Number(discountValue),
            feeValue: Number(feeValue),
            totalPaid: Number(totalPaid),
            payedDate: payedDate,
          };
        } else if (remaining == 0 && partialsJson) {
          var auxPartials = [
            ...partialsJson,
            {
              payedDate,
              writeOffValue: Number(writeOffValue),
              feeValue: Number(feeValue),
              discountValue: Number(discountValue),
              taxValue: Number(taxValue),
              accValue: Number(accValue),
              totalPaid: Number(totalPaid),
              payment,
              bankAccount,
            },
          ];

          aux[billIndex] = {
            ...aux[billIndex],
            status: "paid",
            partialPayments: JSON.stringify(auxPartials),
            remaining,
          };
        } else {
          var auxPartials = [];

          if (partialsJson) {
            auxPartials = [
              ...partialsJson,
              {
                payedDate,
                writeOffValue,
                feeValue: Number(feeValue),
                discountValue: Number(discountValue),
                taxValue: Number(taxValue),
                accValue: Number(accValue),
                totalPaid: Number(totalPaid),
                payment,
                bankAccount,
              },
            ];
          } else {
            auxPartials = [
              {
                payedDate,
                writeOffValue,
                feeValue: Number(feeValue),
                discountValue: Number(discountValue),
                taxValue: Number(taxValue),
                accValue: Number(accValue),
                totalPaid: Number(totalPaid),
                payment,
                bankAccount,
              },
            ];
          }

          aux[billIndex] = {
            ...aux[billIndex],
            partialPayments: JSON.stringify(auxPartials),
            remaining,
          };
        }

        await api.put(`billsToPay/${id}`, aux[billIndex]);

        setBillsToPay([...aux]);
      } catch (error) {
        console.log(error);
      }
    },
    [billsToPay]
  );

  const handleClickAdd = useCallback(() => {
    pushHistory(`${pathname}/adicionar`);
  }, [pathname]);

  const handleClickRecurringExpenses = useCallback(() => {
    pushHistory(`despesas-recorrentes`);
  }, []);

  const clearSearch = () => {
    setSearchQuery("");
    setSituation("");
    setDreSubCategoryId(0);
    setNameBank("");
    setPayment("");
    setCenterCost(0);
    setValueMin(0);
    setValueMax(0);
    setIssueDateMin("");
    setIssueDateMax("");
    setDueDateMin("");
    setDueDateMax("");
  };

  const handleClickSearch = useCallback(async () => {
    filtersRef.current = {
      searchQuery,
      situation,
      valueMin,
      valueMax,
      issueDateMin,
      issueDateMax,
      dueDateMin,
      dueDateMax,
      dreSubCategoryId,
      nameBank,
      payment,
      centerCost,
    };

    reloadData();
  }, [
    billsToPay,
    searchQuery,
    situation,
    valueMin,
    valueMax,
    issueDateMin,
    issueDateMax,
    dueDateMin,
    dueDateMax,
    dreSubCategoryId,
    nameBank,
    payment,
    centerCost,
  ]);

  return (
    <div className="card card-body pt-4 newProductWrapper">
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />
      <div className="row d-flex align-items-center">
        <div className="col-lg-9 mt-3">
          {user.isAccountant == "n" ? (
            <>
              <Button
                type="button"
                variant="success"
                className="mr-2 mb-2"
                onClick={() => handleClickAdd()}
              >
                Adicionar
              </Button>
              <Button
                type="button"
                variant="info"
                className="mr-2 mb-2"
                onClick={() => handleClickRecurringExpenses()}
              >
                Despesas Recorrentes
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="col-lg-3 mt-3">
          <Search
            query={searchQuery}
            setQuery={setSearchQuery}
            setCollapseAdvancedSearch={setAdvancedSearch}
            onClickSearch={handleClickSearch}
          />
        </div>
      </div>
      <Collapse in={advancedSearch}>
        <div className="row d-flex align-items-center">
          <div className="col-lg-2">
            <TextField
              select
              size="small"
              label="Situação"
              margin="normal"
              variant="outlined"
              value={situation}
            >
              <MenuItem key="0" value="" onClick={() => setSituation("")}>
                Nenhum
              </MenuItem>
              <MenuItem
                key="1"
                value="pending"
                onClick={() => setSituation("pending")}
              >
                Pendente
              </MenuItem>
              <MenuItem
                key="2"
                value="late"
                onClick={() => setSituation("late")}
              >
                Vencido
              </MenuItem>
              <MenuItem
                key="3"
                value="paid"
                onClick={() => setSituation("paid")}
              >
                Pago
              </MenuItem>
              <MenuItem
                key="4"
                value="canceled"
                onClick={() => setSituation("canceled")}
              >
                Cancelado
              </MenuItem>
            </TextField>
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor"
              startAdornment="DE"
              value={valueMin}
              onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor"
              startAdornment="ATÉ"
              value={valueMax}
              onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-3">
            <ApiResourceSelect
              label="Plano de Contas"
              getOptionLabel={(option: SubCategory) => option.name}
              value={dreSubCategoryId}
              onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
              apiSearchHandler={(typedText) =>
                DreCategoryService.getDreSubCategoriesFiltered(
                  { name: typedText },
                  "expense"
                )
              }
              getSelectedOption={(loadedOptions) => {
                if (!dreSubCategoryId) return null;
                return (
                  loadedOptions.find(
                    (option) => option.id === Number(dreSubCategoryId)
                  ) ??
                  DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                );
              }}
            />
          </div>
          <div className="col-lg-3">
            <Autocomplete
              size="small"
              noOptionsText="Sem opções"
              options={banks}
              value={banks.find((bank) => bank.name === nameBank) ?? null}
              getOptionLabel={({ name }) => name}
              onChange={(event, newInputValue) =>
                setNameBank(newInputValue?.name ?? "")
              }
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Banco"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className="col-lg-3">
            <ApiResourceSelect
              label="Centro de Custos"
              getOptionLabel={(option: CenterCost) => option.name}
              value={centerCost}
              onSelect={(option) => setCenterCost(option?.id ?? 0)}
              apiSearchHandler={(typedText) =>
                CenterCostService.getCenterCostsFiltered({ name: typedText })
              }
              getSelectedOption={(loadedOptions) => {
                if (!centerCost) return null;
                return (
                  loadedOptions.find(
                    (option) => option.id === Number(centerCost)
                  ) ?? CenterCostService.getCenterCostById(centerCost)
                );
              }}
            />
          </div>
          <div className="col-lg-3">
            <Autocomplete
              size="small"
              noOptionsText="Sem opções"
              options={paymentOptions}
              value={
                paymentOptions.find(
                  (payOption) => payOption.value === payment
                ) ?? null
              }
              getOptionLabel={({ value }) => value}
              onChange={(event, newInputValue) =>
                setPayment(newInputValue?.value ?? "")
              }
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Forma de Pagamento"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data de Emissão"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">DE</InputAdornment>
                ),
              }}
              value={issueDateMin}
              onChange={(e) => setIssueDateMin(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data de Emissão"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">ATÉ</InputAdornment>
                ),
              }}
              value={issueDateMax}
              onChange={(e) => setIssueDateMax(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data de Vencimento"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">DE</InputAdornment>
                ),
              }}
              value={dueDateMin}
              onChange={(e) => setDueDateMin(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data de Vencimento"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">ATÉ</InputAdornment>
                ),
              }}
              value={dueDateMax}
              onChange={(e) => setDueDateMax(e.target.value)}
            />
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button onClick={handleClickSearch} className="mr-3">
              Pesquisar
            </Button>

            <Button onClick={clearSearch}>Limpar</Button>
          </div>
        </div>
      </Collapse>

      <div className="mt-3">
        <ListWithModalChangeSituation
          billsToPay
          situation
          sortable={true}
          headData={headData}
          bodyData={bodyData}
          onEdit={handleClickEdit}
          onClone={handleClickClone}
          onDelete={handleClickDelete}
          onReversal={handleClickReversal}
          objectSituation={situationData}
          onChangeSituation={handleChangeSituation}
          situationModalTitle="Liquidar conta a pagar"
          loadData={loadData}
          totalCount={countTotalBillsToPay}
          triggerLoad={triggerLoad}
          setTriggerLoad={setTriggerLoad}
        />
      </div>
    </div>
  );
}
