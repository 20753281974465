import { MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles } from "../../hooks/styles";
import CompanyService from "../../services/CompanyService";

interface IProps {
  selectedCompany: any;
  setSelectedCompany: any;
}

export default function CompanySelect({
  selectedCompany,
  setSelectedCompany,
}: IProps) {
  const classes = useStyles();

  const [allCompanies, setAllCompanies] = useState<any[]>([]);

  const loadCompanies = async () => {
    const allCompaniesData = await CompanyService.getMainCompanyAndCompanyBranches();

    setAllCompanies(allCompaniesData);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  return (
    <TextField
      value={selectedCompany}
      select
      label="Empresa"
      size="small"
      className="ml-0"
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      margin="normal"
      variant="outlined"
      onChange={(e) => {
        setSelectedCompany(e.target.value);
      }}
    >
      {allCompanies?.length > 0 &&
        allCompanies?.map((company: any, idx: number) => (
          <MenuItem
            key={idx}
            value={company?.id}
            selected={company?.id === selectedCompany}
          >
            {`${company?.name} - ${company?.cnpj}`}
          </MenuItem>
        ))}
    </TextField>
  );
}
