import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import useCompany from "../hooks/company";
import CompanyService from "../services/CompanyService";
import { formatCpfCnpjDynamic } from "../utils/formatCpfCnpj";
import api from "../services/Api";

export type CompanyBranchSelectorProps = {
  user: any;
  mainCompany: any;
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCompany: any;
  onClose: (selectedCompany: any) => void;
};

export function CompanyBranchSelector({
  user,
  mainCompany,
  showDialog,
  setShowDialog,
  selectedCompany,
  onClose,
}: CompanyBranchSelectorProps) {
  const { companies } = useCompany({ mainCompanyId: mainCompany?.id });
  const [availableCompanies, setAvailableCompanies] = useState<any[]>([]);
  //
  useEffect(() => {
    async function getSelectableCompanies() {
      if (!mainCompany || !selectedCompany || !user) return;

      const userBranchesIds = user.branchesIds
        ? JSON.parse(user.branchesIds)
        : [];

      let companiesArr: any = [];
      if (
        user.isAccountant == "y" ||
        user.isSuper == "y" ||
        user.isAdmin == "y"
      ) {
        const accountantCompanies = await CompanyService.getCompaniesByIdsArray(
          userBranchesIds
        );
        accountantCompanies.forEach((company) => {
          if (
            company.id !== selectedCompany.id &&
            userBranchesIds.includes(company.id) &&
            !!CompanyService.getStatusValue(company)
          ) {
            companiesArr.push(company);
          }
        });
      } else {
        companiesArr =
          mainCompany.id === selectedCompany.id ||
          user.canAccessMainCompany === "n"
            ? []
            : [mainCompany];
        companies.forEach((company) => {
          if (
            company.id !== selectedCompany.id &&
            userBranchesIds.includes(company.id) &&
            !!CompanyService.getStatusValue(company)
          ) {
            companiesArr.push(company);
          }
        });
      }

      // setAvailableCompanies(companiesArr);
      setAvailableCompanies(
        companiesArr.sort((a: any, b: any) => {
          const nameA = a?.corporateName?.toUpperCase();
          const nameB = b?.corporateName?.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          const nameA2 = a?.name?.toUpperCase();
          const nameB2 = b?.name?.toUpperCase();

          if (nameA2 < nameB2) {
            return -1;
          }
          if (nameA2 > nameB2) {
            return 1;
          }
          return 0;
        })
      );
    }

    getSelectableCompanies();
  }, [mainCompany, selectedCompany, companies, user]);

  function handleCloseDialog() {
    onClose(selectedCompany);
  }

  async function handleClickCompany(company: any) {
    if (user?.isSuper === "y" || user?.isAdmin === "y")
      await api.put(`users/${user.id}`, { companyId: company.id });
    onClose(company);
  }

  if (!mainCompany) return <></>;

  return (
    <Dialog open={showDialog} onClose={handleCloseDialog}>
      <DialogTitle>Trocar de empresa</DialogTitle>
      {availableCompanies.length > 0 ? (
        <List>
          {availableCompanies.map((company) => (
            <ListItem
              key={company.id}
              button
              onClick={() => handleClickCompany(company)}
            >
              <ListItemText
                primary={`${CompanyService.getCorporateName(
                  company
                )} - ${formatCpfCnpjDynamic(
                  CompanyService.getCpfCnpj(company)
                )}`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <DialogContentText className="mx-2">
          Nenhuma empresa filial disponível
        </DialogContentText>
      )}
    </Dialog>
  );
}
