import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { format } from "date-fns";
import { Button, Modal, Spinner, Tabs, Tab } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { useSubheader } from "../../../_metronic/layout";
import api from "../../services/Api";
import { Installment } from "./Installment";
import { ListProducts } from "./ListProducts";
import {
  InstallmentDetails,
  Product,
  useProductsInstallments,
} from "./context";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import { NumericFormat } from "../../components/NumericFormat";
import { UnderlineLink } from "../../components/UnderlineLink";
import { OrdersSituation } from "../../components/ListWithModalChangeSituation";
import "../../style.css";
import LogService from "../../services/LogService";
import { useLinkedFieldsError } from "../../hooks/linkedFieldsError";
import ModalLinkedFieldsError from "../../components/ModalLinkedFieldsError";
import CustomerSelect from "../../components/CustomerSelect";
import { ServiceOrderData } from "../../types/ServiceOrderData";
import { yesOrNo } from "../../types/yesOrNo";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import SellerService from "../../services/SellerService";
import {
  dateIsBetweenRange,
  extractDateStringFromTimestamp,
} from "../../utils/dateTimeHelper";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { Customer } from "../../types/Customer";
import { NewProductModal } from "../../components/Product/NewProductModal";
import useNewProductForm from "../../hooks/newProductForm";
import { Product as GeneralProduct } from "../../types/Product";
import { Product as SelectableProduct } from "../../services/ProductService";
import { useStyles } from "../../hooks/styles";
import { CustomerLimitCreditInformation } from "../../types/CustomerLimitCreditInformation";
import CustomerService from "../../services/CustomerService";
import { CustomerLimitCreditAlert } from "../../components/CustomerLimitCreditAlert";
import useSellerUser from "../../hooks/sellerUser";
import { uploadMultipleFiles } from "../../utils/FilesHelper";
import UploadFiles from "../../components/UploadFiles";

export function NewServiceOrder() {
  const [activeTab, setActiveTab] = useState("service");

  const [type, setType] = useState("");
  const [warranty, setWarranty] = useState("");
  const [startDate, setStartDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [comments, setComments] = useState("");
  const [referencies, setReferencies] = useState("");
  const [internalComments, setInternalComments] = useState("");
  const [accomplishmentDate, setAccomplishmentDate] = useState("");
  const [accomplishmentTime, setAccomplishmentTime] = useState("");
  const [issues, setIssues] = useState("");
  const [report, setReport] = useState("");
  const [equipment, setEquipment] = useState("");
  const [receivement, setReceivement] = useState("");
  const [date, setDate] = useState("");
  const [numberSO, setNumberSO] = useState(0);
  const [customerId, setCustomerId] = useState<number>();
  const [customerName, setCustomerName] = useState("");
  const [seller, setSeller] = useState("");
  const [sellerCommissioning, setSellerCommissioning] = useState(false);
  const [installmentAmount, setInstallmentAmount] = useState(0);

  const [discount, setDiscount] = useState("R$0,00");
  const [totalValue, setTotalValue] = useState("R$0,00");
  const [expenditure, setExpenditure] = useState("R$0,00");
  const [totalValueServices, setTotalValueServices] = useState("R$0,00");
  const [totalValueProducts, setTotalValueProducts] = useState("R$0,00");

  // Input Verify
  const [customerError, setCustomerError] = useState(false);
  const [sellerError, setSellerError] = useState(false);
  const [installmentsError, setInstallmentsError] = useState(false);
  const [productsError, setProductsError] = useState(false);
  const [servicesError, setServicesError] = useState<any>();
  const [accomplishmentDateError, setAccomplishmentDateError] = useState(false);

  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);

  const {
    showModalLinkedFieldsError,
    setShowModalLinkedFieldsError,
    linkedFieldsErrors,
    setLinkedFieldsErrors,
  } = useLinkedFieldsError();

  const [isSubmit, setIsSubmit] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [showModalExist, setShowModalExist] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [
    customerLimitCreditInformation,
    setCustomerLimitCreditInformation,
  ] = useState<CustomerLimitCreditInformation>();

  const { idToClone } = useParams<{ idToClone: string }>();
  const classes = useStyles();
  const history = useHistory();
  const { userSellerInfo } = useSellerUser();
  const { register, handleSubmit } = useForm();
  const {
    products,
    services,
    installments,
    dispatchProducts,
    dispatchServices,
    dispatchInstallments,
  } = useProductsInstallments();

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomerId(createdCustomer.id);
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  // Modal New Product
  const [productBeingCreated, setProductBeingCreated] = useState<{
    index: number;
    product: Product;
    isService?: boolean;
  } | null>(null);
  const {
    showModalNewProduct,
    setShowModalNewProduct,
    newProductDefaultData,
    setNewProductDefaultData,
  } = useNewProductForm();

  const handleCustomerChanged = useCallback(
    async (customerId: number | undefined, customerName: string) => {
      if (customerId) {
        const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(
          customerId
        );
        setCustomerLimitCreditInformation(limitCreditInfo);
      } else {
        setCustomerLimitCreditInformation(undefined);
      }
    },
    []
  );

  const handleCreateProduct = useCallback(
    (createdProduct: GeneralProduct) => {
      if (!productBeingCreated) return;

      const selectableProduct: SelectableProduct = {
        ...(createdProduct as any),
        saleValue: formatToFloat(createdProduct.saleValue),
      };

      const amount = productBeingCreated.product.amount || 1;

      if (productBeingCreated.isService) {
        dispatchServices({
          type: "CHANGE",
          payload: {
            index: productBeingCreated.index,
            product: {
              ...productBeingCreated.product,
              isLinked: true,
              name: createdProduct.name,
              amount,
              unitaryValue:
                productBeingCreated.product.unitaryValue ||
                selectableProduct.saleValue,
              totalValue:
                productBeingCreated.product.totalValue ||
                selectableProduct.saleValue * amount,
              INSTANCE: selectableProduct,
            },
          },
        });
      } else {
        dispatchProducts({
          type: "CHANGE",
          payload: {
            index: productBeingCreated.index,
            product: {
              ...productBeingCreated.product,
              isLinked: true,
              name: createdProduct.name,
              amount,
              unitaryValue:
                productBeingCreated.product.unitaryValue ||
                selectableProduct.saleValue,
              totalValue:
                productBeingCreated.product.totalValue ||
                selectableProduct.saleValue * amount,
              INSTANCE: selectableProduct,
            },
          },
        });
      }
    },
    [productBeingCreated]
  );

  const handleClickAddProduct = (
    typedText: string,
    index: number,
    product: Product,
    isService?: boolean
  ) => {
    setNewProductDefaultData({
      nameProduct: typedText,
      selectedType: isService ? "service" : "product",
    });
    setProductBeingCreated({ index, product, isService });
    setShowModalNewProduct(true);
  };

  useEffect(() => {
    if (userSellerInfo) {
      setSeller(String(userSellerInfo.id));
    }
  }, [userSellerInfo]);

  useLayoutEffect(() => {
    setDate(format(new Date(), "yyyy-MM-dd"));
  }, []);

  useEffect(() => {
    async function getNewNumberSO() {
      const lastNumber = await api.get(`service-order/lastNumberSO`);
      setNumberSO(Number(lastNumber.data.lastNumber) + 1);
    }

    getNewNumberSO();
  }, []);

  useEffect(() => {
    const totalService = services.reduce(
      (acc, { totalValue }) => acc + totalValue,
      0
    );
    const totalProduct = products.reduce(
      (acc, { totalValue }) => acc + totalValue,
      0
    );
    const discountNumber = formatToFloat(discount);
    const expenditureNumber = formatToFloat(expenditure);

    const partial = totalService + totalProduct + expenditureNumber;

    if (discountNumber > partial) {
      alert("Desconto não pode ser maior que o valor total");

      setTotalValueServices(formatCurrency(totalService));
      setTotalValueProducts(formatCurrency(totalProduct));

      setDiscount("R$0,00");
      setTotalValue("R$0,00");

      return;
    }

    const total = partial - discountNumber;

    setTotalValueServices(formatCurrency(totalService));
    setTotalValueProducts(formatCurrency(totalProduct));
    setTotalValue(formatCurrency(total));
  }, [services, products, expenditure, discount]);

  useEffect(() => {
    if (!idToClone) return;

    async function getOrderToCloneData() {
      const { data: orderToClone } = await api.get<ServiceOrderData>(
        `service-order/${idToClone}`
      );

      if (!orderToClone) return;

      const services: Product[] = JSON.parse(orderToClone.services);
      const products: Product[] = JSON.parse(orderToClone.products);
      const installments: InstallmentDetails[] = JSON.parse(
        orderToClone.installments
      );

      dispatchServices({
        type: "INITIAL",
        payload: {
          index: 0,
          products: services,
        },
      });

      dispatchProducts({
        type: "INITIAL",
        payload: {
          index: 0,
          products,
        },
      });

      // dispatchInstallments({
      //     type: "INITIAL",
      //     payload: {
      //         installments,
      //     }
      // });

      const discountData = formatCurrency(orderToClone.discount);
      const totalValueData = formatCurrency(orderToClone.totalValue);
      const expenditureData = formatCurrency(orderToClone.expenditure);

      setType(orderToClone.type ?? "");
      setIssues(orderToClone.issues ?? "");
      setReport(orderToClone.report ?? "");
      setSeller(String(orderToClone.seller));
      setDiscount(discountData ?? "");
      setNumberSO(orderToClone.numberSO + 1);
      setCustomerId(orderToClone.customerId ?? undefined);
      setCustomerName(orderToClone.customerName ?? "");
      setComments(orderToClone.comments ?? "");
      setWarranty(orderToClone.warranty ?? "");
      setEquipment(orderToClone.equipment ?? "");
      setTotalValue(totalValueData ?? "");
      setExpenditure(expenditureData ?? "");
      setReceivement(orderToClone.receivement ?? "");
      setReferencies(orderToClone.referencies ?? "");
      // setStartDate(orderToClone.startDate ?? '');
      setDeliveryDate(orderToClone.deliveryDate ?? "");
      setInternalComments(orderToClone.internalComments ?? "");
      setInstallmentAmount(orderToClone.installmentAmount ?? "");
      setAccomplishmentDate(orderToClone.accomplishmentDate ?? "");
      setAccomplishmentTime(orderToClone.accomplishmentTime ?? "");
      setSellerCommissioning(orderToClone.sellerCommissioning === "y");

      handleCustomerChanged(
        orderToClone.customerId ?? undefined,
        orderToClone.customerName
      );
    }

    getOrderToCloneData();
  }, [idToClone]);

  async function onSubmit(data: any) {
    // Situação que aparece na listagem
    const situation: OrdersSituation[] = [];

    if (!inputsError()) return;

    let filesPath: any = [];
    if (filesSelected) {
      filesPath = await uploadMultipleFiles(
        filesSelected,
        setShowModalExist,
        setMsgError,
        setIsSubmit
      );
      if (filesPath === false) {
        return;
      }
    }

    situation.push({
      dateSituation: format(Date.now(), "yyyy-MM-dd"),
      commentsSituation: "",
      statusSituation: "open",
    });

    try {
      const raw = {
        date,
        type: data.type ? data.type : "serviceOrder",
        deliveryDate,
        warranty,
        accomplishmentDate,
        accomplishmentTime,
        referencies,
        comments,
        internalComments,
        equipment,
        issues,
        receivement,
        report,
        numberSO,
        customerId: customerId,
        customerName: !customerId ? customerName : null,
        seller: seller ? Number(seller) : null,
        sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,
        status: "open",
        installmentAmount,
        discount: formatToFloat(discount),
        totalValue: formatToFloat(totalValue),
        expenditure: formatToFloat(expenditure),

        products: JSON.stringify(products),
        services: JSON.stringify(services),
        situation: JSON.stringify(situation),
        installments: JSON.stringify(installments),
        attachments: JSON.stringify(filesPath),
      };

      const result = await api.post("service-order", raw);

      history.push("/ordem-de-servico");

      LogService.logRegister({
        itemId: result.data.id,
        module: "Ordem de Serviço",
        itemName: "Serviço",
      });
    } catch (error) {
      console.log(error);
      setMsgError("Ocorreu um erro ao processar a requisição");
      setShowModalExist(true);
    }
  }

  useEffect(() => {
    dispatchProducts({ type: "INITIAL", payload: { index: 0, products: [] } });
    dispatchProducts({ type: "ADD" });
    dispatchServices({ type: "INITIAL", payload: { index: 0, products: [] } });
    dispatchServices({ type: "ADD" });
    dispatchInstallments({
      type: "GENERATE",
      payload: {
        amount: 0,
        value: 0,
      },
    });
  }, []);

  function sanitizeInput(type: string, value: string): string {
    if (type === "cpf" || type === "cell" || type === "cnpj") {
      var str = value;
      str = str.replaceAll(/[^0-9]+/g, "");

      return str;
    } else if (type === "email") {
      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return regex.test(String(value).toLowerCase()).toString();
    }

    return "";
  }

  function inputsError() {
    setSellerError(false);
    setProductsError(false);
    setAccomplishmentDateError(false);
    setCustomerError(false);
    setInstallmentsError(false);
    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      setServiceError(i, service, false);
    }
    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      setProductError(i, product, false);
    }

    if (!customerId && !customerName) {
      setIsSubmit(false);
      setMsgError("Campo Cliente é obrigatório!");
      setShowModalExist(true);
      setCustomerError(true);
      return false;
    }

    if (customerId && customerLimitCreditInformation) {
      const totalOrder = formatToFloat(totalValue ?? 0);

      if (
        totalOrder + customerLimitCreditInformation.currentCreditThisPeriod >
        customerLimitCreditInformation.limit
      ) {
        // Se salvar a OS ultrapassar o limite de crédito do cliente
        setMsgError(
          "Não é possível salvar a ordem de serviço pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema."
        );
        setShowModalExist(true);
        setCustomerError(true);
        return false;
      }
    }

    // if(!seller){
    //     setIsSubmit(false)
    //     setMsgError("Campo Vendedor é obrigatório!")
    //     setShowModalExist(true);
    //     setSellerError(true);
    //     return false
    // }

    if (accomplishmentDate) {
      const dateIsValid = dateIsBetweenRange(
        accomplishmentDate,
        "",
        extractDateStringFromTimestamp()
      );
      if (!dateIsValid) {
        setMsgError(
          "A data de emissão não pode ser maior que o horário atual!"
        );
        setAccomplishmentDateError(true);
        setActiveTab("detailsServiceOrder");
        setShowModalExist(true);
        setIsSubmit(false);
        return false;
      }
    }

    const hasServices = services.find(
      (service) => service.name || service.INSTANCE.id
    );

    if (!hasServices) {
      setActiveTab("service");
      setMsgError("Selecione pelo menos 1(um) Serviço!");
      setShowModalExist(true);
      return false;
    }

    for (let i = 0; i < services.length; i++) {
      const service = services[i];

      if (service.totalValue && !service.INSTANCE?.id && !service.name) {
        setServiceError(i, service, true);
        setMsgError("Informe um serviço!");
        setActiveTab("service");
        setShowModalExist(true);
        return false;
      }
    }

    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      if (product.totalValue && !product.INSTANCE?.id && !product.name) {
        setProductError(i, product, true);
        setMsgError("Informe um produto!");
        setActiveTab("products");
        setShowModalExist(true);
        return false;
      }
    }

    if (!installments.length) {
      setActiveTab("payment");
      setMsgError("Informe as parcelas do pedido!");
      setShowModalExist(true);
      setInstallmentsError(true);
      return false;
    }

    const sumInstallments = installments.reduce(
      (sum, installment) => sum + installment.value,
      0
    );

    if (sumInstallments !== formatToFloat(totalValue)) {
      setActiveTab("payment");
      setMsgError(
        "A soma das parcelas deve concidir com o valor total da ordem de serviço!"
      );
      setShowModalExist(true);
      setInstallmentsError(true);
      return false;
    }

    return true;
  }

  function setServiceError(index: number, service: Product, error?: boolean) {
    dispatchServices({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...service,
          hasError: error,
        },
      },
    });
  }

  function setProductError(index: number, product: Product, error?: boolean) {
    dispatchProducts({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...product,
          hasError: error,
        },
      },
    });
  }

  const isValid = (data: any) => {
    setProductsError(data);
    console.log(productsError);
  };

  const isValid2 = (data: any) => {
    setServicesError(data);
  };

  const subHeader = useSubheader();
  subHeader.setTitle("Adicionar um Cliente / Fornecedor");

  return (
    <div className="card card-custom gutter-b mt-4 p-5 newProductWrapper">
      <ModalLinkedFieldsError
        message="Para lançar em contas é necessário completar o cadastro do Pedido!"
        errors={linkedFieldsErrors}
        showModal={showModalLinkedFieldsError}
        setShowModal={setShowModalLinkedFieldsError}
      />

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <NewProductModal
        showModal={showModalNewProduct}
        setShowModal={setShowModalNewProduct}
        onCreateProduct={handleCreateProduct}
        defaultData={newProductDefaultData}
        allowChangeType={false}
      />

      <Modal
        show={showModalExist}
        onHide={() => setShowModalExist(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalExist(!showModalExist)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body p-0 mb-6">
          <CustomerLimitCreditAlert
            customerLimitCreditInformation={customerLimitCreditInformation}
          />

          <Grid container spacing={3}>
            <Grid item lg={3}>
              <TextField
                size="small"
                select
                label="Tipo da Os"
                margin="normal"
                variant="outlined"
                className={classes.textField}
                defaultValue="serviceOrder"
                value={type}
                onChange={(event) => setType(event.target.value)}
              >
                <MenuItem value="serviceOrder">Ordem de serviço</MenuItem>

                <MenuItem value="budget">Orçamento</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={3} lg={3}>
              <CustomerSelect
                label="Cliente"
                hasError={customerError}
                isEdit={false}
                customerId={customerId}
                setCustomerId={setCustomerId}
                customerName={customerName}
                setCustomerName={setCustomerName}
                onCustomerChanged={handleCustomerChanged}
                allowIncomplete
                onClickAddCustomer={handleClickAddCustomer}
              />
            </Grid>

            <Grid item lg={3}>
              <ApiResourceSelect
                style={{ width: "100%" }}
                label="Vendedor"
                getOptionLabel={(option: any) => option.name}
                value={seller}
                hasError={sellerError}
                textFieldClass={classes.error}
                onChangeTextField={(e) =>
                  setSellerError(e.target.value ? false : true)
                }
                onSelect={(option) =>
                  setSeller(option ? String(option.id) : "")
                }
                apiSearchHandler={(typedText) =>
                  SellerService.getSellersFiltered({ name: typedText })
                }
                getSelectedOption={(loadedOptions) => {
                  if (!seller) return null;
                  return (
                    loadedOptions.find(
                      (option) => option.id === Number(seller)
                    ) ?? SellerService.getSellerById(seller)
                  );
                }}
              />
            </Grid>

            <Grid item lg={2}>
              <TextField
                disabled
                type="text"
                size="small"
                margin="normal"
                variant="outlined"
                label="N° da OS"
                className={classes.textField}
                value={numberSO}
              />
            </Grid>

            <Grid
              item
              lg={1}
              className="d-flex align-items-end justify-content-end"
            >
              <Button
                type="submit"
                className="mb-2"
                variant="primary"
                disabled={isSubmit}
              >
                {isSubmit ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />

                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <span>Salvar</span>
                )}
              </Button>
            </Grid>

            <Modal
              show={showModalSuccess}
              onHide={() => {
                setShowModalSuccess(false);
              }}
              aria-labelledby="contained-modal-warning"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                  <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
                  Sucesso
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <b>Dados criados com sucesso !</b>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalSuccess(!showModalSuccess);
                    window.location.href = "/ordem-de-servico/adicionar";
                  }}
                >
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </Grid>
        </div>

        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="form-tabs"
          className="mb-5"
        >
          <Tab eventKey="service" title="Serviços">
            <div className="card-body p-4">
              {services.map((service, index) => (
                <ListProducts
                  key={index}
                  index={index}
                  product={service}
                  service
                  isContent={(contentExists: boolean) =>
                    isValid2(contentExists)
                  }
                  onClickAddProduct={handleClickAddProduct}
                />
              ))}

              <UnderlineLink
                text="adicionar outro serviço"
                onClick={() => dispatchServices({ type: "ADD" })}
              />
            </div>
          </Tab>

          <Tab eventKey="products" title="Produtos">
            <div className="card-body p-4">
              {products.map((product, index) => (
                <ListProducts
                  key={index}
                  index={index}
                  product={product}
                  isContent={(contentExists: boolean) => isValid(contentExists)}
                  onClickAddProduct={handleClickAddProduct}
                />
              ))}

              <UnderlineLink
                text="adicionar outro produto"
                onClick={() => dispatchProducts({ type: "ADD" })}
              />
            </div>
          </Tab>

          <Tab eventKey="detailsServiceOrder" title="Detalhes da OS">
            <div className="card-body pl-6 pr-12">
              <Grid container spacing={6}>
                <Grid item>
                  <TextField
                    disabled
                    size="small"
                    type="date"
                    margin="normal"
                    label="Data da OS"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={date}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="datetime-local"
                    margin="normal"
                    label="Previsão data de início"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value)}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="datetime-local"
                    margin="normal"
                    label="Data da entrega"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={deliveryDate}
                    onChange={(event) => setDeliveryDate(event.target.value)}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="text"
                    margin="normal"
                    label="Garantia"
                    variant="outlined"
                    className={classes.textField}
                    value={warranty}
                    onChange={(event) => setWarranty(event.target.value)}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="date"
                    margin="normal"
                    variant="outlined"
                    label="Data da realização"
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    value={accomplishmentDate}
                    onChange={(event) =>
                      setAccomplishmentDate(event.target.value)
                    }
                    error={accomplishmentDateError}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    size="small"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="Hora da realização"
                    className={classes.textField}
                    value={accomplishmentTime}
                    onChange={(event) =>
                      setAccomplishmentTime(event.target.value)
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    size="small"
                    type="text"
                    margin="normal"
                    variant="outlined"
                    label="Referência"
                    className={classes.textField}
                    value={referencies}
                    onChange={(event) => setReferencies(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Observações"
                    className={classes.textField}
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                  />
                </Grid>

                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Observações internas"
                    className={classes.textField}
                    value={internalComments}
                    onChange={(event) =>
                      setInternalComments(event.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Equipamento"
                    className={classes.textField}
                    value={equipment}
                    onChange={(event) => setEquipment(event.target.value)}
                  />
                </Grid>

                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    label="Problemas"
                    className={classes.textField}
                    value={issues}
                    onChange={(event) => setIssues(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    label="Observações do recebimento"
                    value={receivement}
                    onChange={(event) => setReceivement(event.target.value)}
                  />
                </Grid>

                <Grid item lg>
                  <TextField
                    size="small"
                    rows={6}
                    multiline
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    label="Laudo técnico / Diagnóstico"
                    value={report}
                    onChange={(event) => setReport(event.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
          </Tab>

          <Tab eventKey="totalServiceOrder" title="Totais da Ordem de Serviço">
            <div className="card-body">
              <Grid container spacing={5}>
                <div className="col p-1">
                  <NumericFormat
                    className="inputSmaller"
                    disabled
                    label="Valor dos serviços"
                    value={totalValueServices}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    className="inputSmaller"
                    disabled
                    label="Valor dos produtos"
                    value={totalValueProducts}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    className="inputSmaller"
                    label="Valor das despesas"
                    value={expenditure}
                    onChange={(evt) => setExpenditure(evt.target.value)}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    className="inputSmaller"
                    label="Valor do desconto"
                    value={discount}
                    onChange={(evt) => setDiscount(evt.target.value)}
                  />
                </div>

                <div className="col p-1">
                  <NumericFormat
                    className="inputSmaller"
                    disabled
                    label="Total"
                    value={totalValue}
                  />
                </div>
              </Grid>
            </div>
          </Tab>

          <Tab eventKey="payment" title="Pagamento">
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-6">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        color="primary"
                        checked={sellerCommissioning}
                        onChange={(e, checked) =>
                          setSellerCommissioning(checked)
                        }
                        className={classes.textField}
                      />
                    }
                    label="Emitir comissionamento (vendedor)"
                    labelPlacement="end"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <TextField
                    type="number"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    label="Quantidade de Parcelas"
                    error={installmentsError}
                    className={classes.error}
                    value={installmentAmount}
                    onChange={(evt) => {
                      setInstallmentAmount(Number(evt.target.value));
                      setInstallmentsError(evt.target.value ? false : true);
                    }}
                  />
                </div>

                <div className="col-md-3 d-flex align-items-center justify-content-center">
                  <Button
                    variant="primary"
                    className="btn-sm"
                    block
                    onClick={() =>
                      dispatchInstallments({
                        type: "GENERATE",
                        payload: {
                          amount: installmentAmount,
                          value: formatToFloat(totalValue),
                        },
                      })
                    }
                  >
                    Gerar parcelas
                  </Button>
                </div>

                <div className="row col-lg-12 mt-10">
                  {installments.length > 0 &&
                    installments.map((installment, index) => (
                      <Installment
                        key={index}
                        index={index}
                        installment={installment}
                        installmentAmount={installmentAmount}
                      />
                    ))}
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="attachment" title="Anexo">
            <Grid container spacing={3} className="p-4">
              <Grid item lg={6}>
                <UploadFiles
                  label="Proposta/Anexos"
                  filesSelected={filesSelected}
                  setFilesSelected={setFilesSelected}
                  filesNamesSaved={filesNamesSaved}
                  setFilesNamesSaved={setFilesNamesSaved}
                />
              </Grid>
            </Grid>
          </Tab>
        </Tabs>
      </form>
    </div>
  );
}
