import React, { useState, useEffect } from "react";
import api from "../services/Api";

type changeValueSwitch = {
  value: boolean;
  url?: string;
};

const ChangeValueSwitch: React.FC<changeValueSwitch> = ({
  value,
  url = null,
}) => {
  const [switchValue, setSwitchValue] = useState(value);
  const [containerStyle, setContainerStyle] = useState<any>({});

  useEffect(() => {
    const fetch = async () => {
      let style = switchValue
        ? { ...styles.container, ...styles.active }
        : { ...styles.container, ...styles.notActive };
      setContainerStyle(style);
    };
    fetch().then();
  }, [switchValue]);

  const changeValue = async (url: any = null) => {
    try {
      if (url) {
        await api.post(url, { active: !switchValue });
      }
      setSwitchValue(!switchValue);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={containerStyle} onClick={() => changeValue(url)}>
      <div style={styles.switch}></div>
    </div>
  );
};

const styles = {
  container: {
    width: 40,
    height: 20,
    borderColor: "#000",
    borderRadius: 13,
    cursor: "pointer",
  },
  switch: {
    height: 20,
    width: 20,
    borderRadius: 50,
    backgroundColor: "#DCDCDC",
    vorderColor: "#777",
  },
  active: {
    paddingLeft: 19,
    backgroundColor: "green",
  },
  notActive: {
    backgroundColor: "red",
  },
};

export default ChangeValueSwitch;
