import React, { useState, useEffect, useCallback } from "react";
import { Button, Spinner, Tabs, Tab, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { format, isBefore } from "date-fns";

import {
  MenuItem,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@material-ui/core";

import api from "../../services/Api";
import { Installment } from "./Installment";
import { Request } from "../../types/Request";
import { ListProducts } from "./ListProducts";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import { NumericFormat } from "../../components/NumericFormat";
import { useRequest, Product, InstallmentDetails } from "./context";
import { OrdersSituation } from "../../components/ListWithModalChangeSituation";
import "../../style.css";
import LogService from "../../services/LogService";
import CustomerService from "../../services/CustomerService";
import { getSituationText } from "../../utils/getSituationText";
import { freightModality } from "../../utils/freightModality";
import CustomerSelect from "../../components/CustomerSelect";
import { useLinkedFieldsError } from "../../hooks/linkedFieldsError";
import RequestService from "../../services/RequestService";
import ModalLinkedFieldsError from "../../components/ModalLinkedFieldsError";
import { yesOrNo } from "../../types/yesOrNo";
import { ModalThrowToBills } from "../../components/ModalThrowToBills";
import useThrowToBills from "../../hooks/throwToBills";
import SellerService from "../../services/SellerService";
import { useSelector } from "react-redux";
import PriceListService from "../../services/PriceListService";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import CarrierService from "../../services/CarrierService";
import { Carrier } from "../../types/Carrier";
import { PriceList } from "../../types/PriceList";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { Customer } from "../../types/Customer";
import { NewProductModal } from "../../components/Product/NewProductModal";
import useNewProductForm from "../../hooks/newProductForm";
import { Product as GeneralProduct } from "../../types/Product";
import { Product as SelectableProduct } from "../../services/ProductService";
import { useStyles } from "../../hooks/styles";
import { CustomerLimitCreditInformation } from "../../types/CustomerLimitCreditInformation";
import { CustomerLimitCreditAlert } from "../../components/CustomerLimitCreditAlert";
import { GenericObject } from "../../types/GenericObject";
import useSellerUser from "../../hooks/sellerUser";
import SellerConsignmentService from "../../services/SellerConsignmentService";
import { SellerConsignmentStockLocationSelector } from "../../components/SellerConsignmentStockLocationSelector";
import {
  deleteMultipleFiles,
  uploadMultipleFiles,
} from "../../utils/FilesHelper";
import UploadFiles from "../../components/UploadFiles";

export function EditRequests() {
  const { user } = useSelector((state: any) => state.auth);

  const [currentTab, setCurrentTab] = useState("details");

  const [isSubmit, setIsSubmit] = useState(false);
  const [beforeSubmitData, setBeforeSubmitData] = useState<GenericObject>({});
  const [freigth, setFreigth] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [requestNumber, setRequestNumber] = useState("");
  const [discountMoney, setDiscountMoney] = useState("");
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [installmentError, setInstallmentError] = useState(false);
  const [modalityFreight, setModalityFreight] = useState("recipient");
  const [totalValueProducts, setTotalValueProducts] = useState("0");
  const [discountPercentage, setDiscountPercentage] = useState("0");
  const [grossWeight, setGrossWeight] = useState("0");
  const [liquidWeight, setLiquidWeight] = useState("0");
  const [sellerCommissioning, setSellerCommissioning] = useState(false);
  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);
  const [note, setNote] = useState("");
  const [seller, setSeller] = useState("");
  const [carrier, setCarrier] = useState("");
  const [customerId, setCustomerId] = useState<number>();
  const [customerName, setCustomerName] = useState("");
  const [deadLine, setDeadLine] = useState("");
  const [reference, setReference] = useState("");
  const [insideNote, setInsideNote] = useState("");
  const [priceList, setPriceList] = useState<PriceList>({} as PriceList);
  const [status, setStatus] = useState("");
  const [showModalAttention, setShowModalAttention] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [
    customerLimitCreditInformation,
    setCustomerLimitCreditInformation,
  ] = useState<CustomerLimitCreditInformation>();
  const [
    sellerConsignmentStockLocationId,
    setSellerConsignmentStockLocationId,
  ] = useState(0);
  const [requestDataLoaded, setRequestDataLoaded] = useState(false);

  //Lançar em contas
  const {
    isThrowToBillsModalOpen,
    setIsThrowToBillsModalOpen,
    dreSubCategoryId,
    setDreSubCategoryId,
    centerCost,
    setCenterCost,
    installments: billInstallments,
    setInstallments: setBillInstalmments,
    dreSubCategoryIdCommission,
    setDreSubCategoryIdCommission,
    centerCostCommission,
    setCenterCostCommission,
    installmentsCommission,
    setInstallmentsCommission,
    revenue,
    setRevenue,
    handleSetIsPaid,
    handleSetInstallmentBankAccount,
    handleSetInstallmentPaymentDate,
    handleSetIsPaidCommission,
    handleSetInstallmentBankAccountCommission,
    handleSetInstallmentPaymentMethodCommission,
    handleSetInstallmentPaymentDateCommission,
    handleSetBillInstallments,
  } = useThrowToBills();

  // Input Verify
  const [customerError, setCustomerError] = useState(false);
  const [productError, setProductError] = useState(false);

  const {
    showModalLinkedFieldsError,
    setShowModalLinkedFieldsError,
    linkedFieldsErrors,
    setLinkedFieldsErrors,
  } = useLinkedFieldsError();

  const history = useHistory();
  const {
    userSellerInfo,
    productsAvailableForConsignment,
    filterProductsByStockLocationId,
  } = useSellerUser();
  const selectedSellerIsAuthConsignmentSeller =
    !!userSellerInfo &&
    userSellerInfo.isConsignment === yesOrNo.YES &&
    userSellerInfo.id === Number(seller);
  const { id } = useParams<{ id: string }>();

  const {
    classes,
    products,
    installments,
    dispatchProducts,
    dispatchInstallments,
  } = useRequest();

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCustomerChanged = useCallback(
    async (customerId: number | undefined, customerName: string) => {
      if (customerId) {
        const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(
          customerId
        );
        setCustomerLimitCreditInformation(limitCreditInfo);
      } else {
        setCustomerLimitCreditInformation(undefined);
      }
    },
    []
  );

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setCustomerId(createdCustomer.id);
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText });
    setShowModalNewCustomer(true);
  };

  // Modal New Product
  const [productBeingCreated, setProductBeingCreated] = useState<{
    index: number;
    product: Product;
  } | null>(null);
  const {
    showModalNewProduct,
    setShowModalNewProduct,
    newProductDefaultData,
    setNewProductDefaultData,
  } = useNewProductForm();

  const handleCreateProduct = useCallback(
    (createdProduct: GeneralProduct) => {
      if (!productBeingCreated) return;

      const selectableProduct: SelectableProduct = {
        ...(createdProduct as any),
        saleValue: formatToFloat(createdProduct.saleValue),
      };

      const amount = productBeingCreated.product.amount || 1;

      dispatchProducts({
        type: "CHANGE",
        payload: {
          index: productBeingCreated.index,
          product: {
            ...productBeingCreated.product,
            isLinked: true,
            name: createdProduct.name,
            amount,
            unitaryValue:
              productBeingCreated.product.unitaryValue ||
              selectableProduct.saleValue,
            total:
              productBeingCreated.product.total ||
              selectableProduct.saleValue * amount,
            INSTANCE: selectableProduct,
          },
        },
      });
    },
    [productBeingCreated]
  );

  const handleClickAddProduct = (
    typedText: string,
    index: number,
    product: Product
  ) => {
    setNewProductDefaultData({ nameProduct: typedText });
    setProductBeingCreated({ index, product });
    setShowModalNewProduct(true);
  };

  useEffect(() => {
    function handleConsignmentSeller() {
      if (
        !userSellerInfo ||
        userSellerInfo.isConsignment === yesOrNo.NO ||
        status === "attended"
      ) {
        return;
      }

      filterProductsByStockLocationId(sellerConsignmentStockLocationId);

      if (!requestDataLoaded) {
        return;
      }

      dispatchProducts({
        type: "INITIAL",
        payload: { index: 0, products: [] },
      });
      dispatchProducts({ type: "ADD" });
      dispatchInstallments({
        type: "GENERATE",
        payload: {
          amount: 0,
          value: 0,
        },
      });

      filterProductsByStockLocationId(sellerConsignmentStockLocationId);
    }

    handleConsignmentSeller();
  }, [
    userSellerInfo,
    seller,
    sellerConsignmentStockLocationId,
    filterProductsByStockLocationId,
  ]);

  useEffect(() => {
    async function loadData() {
      const { data } = await api.get<Request>(`requests/${id}`);
      const dataProducts: Product[] = JSON.parse(data.products);
      const dataInstallments: InstallmentDetails[] = JSON.parse(
        data.installments
      );

      const dataAmountInstallments = dataInstallments.length;

      dispatchProducts({
        type: "INITIAL",
        payload: {
          index: 0,
          products: dataProducts,
        },
      });

      dispatchInstallments({
        type: "INITIAL",
        payload: {
          installments: dataInstallments,
        },
      });

      const dataPriceList = await PriceListService.getPriceListById(
        data.priceList
      );

      if (dataPriceList) {
        setPriceList(dataPriceList);
      }

      setNote(data.note);
      setSeller(String(data.seller ?? ""));
      setCarrier(data.carrier);
      setCustomerId(data.customerId ?? undefined);
      setCustomerName(data.customerName ?? "");
      setDeadLine(data.deadLine);
      setReference(data.reference);
      setInsideNote(data.insideNote);
      setRequestDate(data.requestDate);
      setRequestNumber(data.requestNumber);
      setInstallmentAmount(dataAmountInstallments);
      setModalityFreight(data.modalityFreight);
      setSellerCommissioning(data.sellerCommissioning === "y");
      setSellerConsignmentStockLocationId(
        data.sellerConsignmentStockLocationId || 0
      );
      setStatus(data.status);

      setFreigth(formatCurrency(data.freigth));
      setTotalValue(formatCurrency(data.totalValue));
      setDiscountMoney(formatCurrency(data.discountMoney));
      setDiscountPercentage(data.discountPercentage);
      setGrossWeight(data.grossWeight);
      setLiquidWeight(data.liquidWeight);

      setBeforeSubmitData(data);
      handleCustomerChanged(data.customerId ?? undefined, data.customerName);
      setFilesNamesSaved(data.attachments ? JSON.parse(data.attachments) : []);
      setRequestDataLoaded(true);
    }

    loadData();
  }, []);

  useEffect(() => {
    let totalProduct = products.reduce((acc, { total }) => acc + total, 0);

    if (priceList.priceListType === "1") {
      totalProduct = Number(totalProduct) + Number(priceList.value);
    }

    if (priceList.priceListType === "2") {
      totalProduct = Number(totalProduct) - Number(priceList.value);
    }

    const discountNumber = discountMoney ? formatToFloat(discountMoney) : 0;
    const discountPercentageFloat = formatToFloat(discountPercentage);
    const freigthNumber = freigth ? formatToFloat(freigth) : 0;

    const partial = Number(totalProduct) + Number(freigthNumber);

    if (discountNumber > partial) {
      alert("Desconto não pode ser maior que o valor total");

      setTotalValueProducts(formatCurrency(totalProduct));

      setFreigth("0");
      setTotalValue("0");
      setDiscountMoney("0");

      return;
    }

    let total = partial - discountNumber;

    if (discountPercentageFloat > 0 && discountPercentageFloat <= 100) {
      total = total - total * (discountPercentageFloat / 100);
    }

    setTotalValueProducts(formatCurrency(totalProduct));
    setTotalValue(formatCurrency(total));
  }, [products, discountMoney, discountPercentage, freigth, priceList]);

  useEffect(() => {
    let converted = formatToFloat(discountPercentage);
    if (converted > 100) {
      setDiscountPercentage("100,00");
    } else {
      setDiscountPercentage(converted ? formatCurrency(converted) : "0,00");
    }
  }, [discountPercentage]);

  async function throwToBills() {
    setInstallmentError(false);

    const request = {
      customerId,
      customerName,
      products: JSON.stringify(products),
    };

    const linkErrors = RequestService.verifyLinkedFields(request);

    if (linkErrors.length > 0) {
      setLinkedFieldsErrors(linkErrors);
      setShowModalLinkedFieldsError(true);
      return;
    }

    handleSetBillInstallments({
      order: true,
      typeOrder: "request",
      orderObject: {
        installments: JSON.stringify(installments),
        sellerHasCommission: sellerCommissioning,
        sellerId: Number(seller),
        sellerCommissionPercentage: await SellerService.getCommissionPercentage(
          seller ?? ""
        ),
      },
    });

    setIsThrowToBillsModalOpen(true);
  }

  function validateInputs() {
    setCustomerError(false);
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      dispatchProductError(i, product, false);
    }

    if (!customerId && !customerName) {
      setIsThrowToBillsModalOpen(false);
      setMsgError("Selecione o cliente!");
      setShowModalAttention(true);
      setCustomerError(true);
      return false;
    }

    if (
      customerId &&
      customerLimitCreditInformation &&
      beforeSubmitData.customerId !== customerId
    ) {
      const totalRequest = formatToFloat(totalValue ?? 0);

      if (
        totalRequest + customerLimitCreditInformation.currentCreditThisPeriod >
        customerLimitCreditInformation.limit
      ) {
        // Se salvar o pedido ultrapassar o limite de crédito do cliente
        setIsThrowToBillsModalOpen(false);
        setMsgError(
          "Não é possível salvar o pedido de venda pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema."
        );
        setShowModalAttention(true);
        setCustomerError(true);
        return false;
      }
    }

    const hasProducts = products.find(
      (product) => product.name || product.INSTANCE.id
    );

    if (!hasProducts) {
      setIsThrowToBillsModalOpen(false);
      setMsgError("Selecione um Produto!");
      setShowModalAttention(true);
      setProductError(true);
      return false;
    }

    for (let i = 0; i < products.length; i++) {
      const product = products[i];

      if (product.total && !product.INSTANCE?.id && !product.name) {
        dispatchProductError(i, product, true);
        setMsgError("Informe um produto!");
        setCurrentTab("products");
        setShowModalAttention(true);
        return false;
      }
    }

    if (
      selectedSellerIsAuthConsignmentSeller &&
      productsAvailableForConsignment
    ) {
      for (const product of products) {
        const valid = SellerConsignmentService.validateConsignmentProductQuantity(
          product.INSTANCE,
          product.amount,
          sellerConsignmentStockLocationId,
          productsAvailableForConsignment
        );
        if (!valid) {
          setIsThrowToBillsModalOpen(false);
          setCurrentTab("products");
          setMsgError(
            `O produto ${product.name} não tem estoque em consignação o suficiente para realizar a venda!`
          );
          setShowModalAttention(true);
          return false;
        }
      }
    }

    return true;
  }

  function dispatchProductError(
    index: number,
    product: Product,
    error?: boolean
  ) {
    dispatchProducts({
      type: "CHANGE",
      payload: {
        index: index,
        product: {
          ...product,
          hasError: error,
        },
      },
    });
  }

  const handleSetCarrier = useCallback((value: string | null) => {
    if (value) {
      return setCarrier(value);
    }

    return setCarrier("");
  }, []);

  const handleSetDeadLine = useCallback(() => {
    const [year, month, day] = deadLine.split("-");

    const date = new Date(Number(year), Number(month) - 1, Number(day));

    const dateNow = new Date();

    if (isBefore(date, dateNow)) {
      alert("Não é possível adicionar uma data que já passou!");
      return setDeadLine("");
    }
  }, [deadLine]);

  const handleSetPriceList = useCallback((value: PriceList | null) => {
    if (value) {
      return setPriceList(value);
    }

    return setPriceList({} as PriceList);
  }, []);

  function hasInstallments() {
    var isSubmit = true;

    if (!installments.length) {
      setMsgError("Informe as parcelas do pedido!");
      setShowModalAttention(true);
      setInstallmentError(true);
      isSubmit = false;
      setCurrentTab("payment");
    }

    const sumInstallments = installments.reduce(
      (sum, installment) => sum + installment.value,
      0
    );

    if (sumInstallments !== formatToFloat(totalValue)) {
      setMsgError(
        "A soma das parcelas deve concidir com o valor total do pedido!"
      );
      setShowModalAttention(true);
      setInstallmentError(true);
      isSubmit = false;
      setCurrentTab("payment");
    }

    return isSubmit;
  }

  async function onSubmit(isEmited?: boolean) {
    setInstallmentError(false);

    if (!validateInputs()) {
      setIsSubmit(false);
      return;
    }

    if (hasInstallments()) {
      const { data } = await api.get<Request>(`requests/${id}`);
      const filesRemained = await deleteMultipleFiles(
        data.attachments ? JSON.parse(data.attachments) : [],
        filesNamesSaved,
        setShowModalAttention,
        setMsgError,
        setIsSubmit
      );
      let filesPath: any = [];
      if (filesSelected) {
        filesPath = await uploadMultipleFiles(
          filesSelected,
          setShowModalAttention,
          setMsgError,
          setIsSubmit
        );
        if (filesPath === false) {
          return;
        }
      } else {
        filesPath = [];
      }

      // Situação que aparece na listagem
      const situation: OrdersSituation[] = [];

      situation.push({
        dateSituation: format(Date.now(), "yyyy-MM-dd"),
        commentsSituation: "",
        statusSituation: "open",
      });

      try {
        const raw = {
          carrier,
          reference,
          note,
          insideNote,
          requestDate,
          deadLine,
          requestNumber,
          modalityFreight,
          customerId: customerId ?? null,
          customerName: !customerId ? customerName : null,
          seller: Number(seller) ?? null,
          freigth: formatToFloat(freigth),
          totalValue: formatToFloat(totalValue),
          discountMoney: formatToFloat(discountMoney),
          discountPercentage: formatToFloat(discountPercentage),
          grossWeight: formatToFloat(grossWeight),
          liquidWeight: formatToFloat(liquidWeight),
          sellerCommissioning: sellerCommissioning ? yesOrNo.YES : yesOrNo.NO,
          priceList: priceList?.id || null,

          status: isEmited ? "attended" : "open",
          sellerConsignmentStockLocationId:
            sellerConsignmentStockLocationId || null,
          products: JSON.stringify(products),
          situation: JSON.stringify(situation),
          installments: JSON.stringify(installments),
          movedToStock: "n",
          attachments: JSON.stringify([...filesRemained, ...filesPath]),
        };

        if (isEmited) {
          const linkErrors = RequestService.verifyLinkedFields(raw);

          if (linkErrors.length > 0) {
            setLinkedFieldsErrors(linkErrors);
            setShowModalLinkedFieldsError(true);
            setIsSubmit(false);
            return;
          }
        }

        const response = await api.put(`requests/${id}`, raw);

        if (isEmited) {
          await RequestService.throwToBills({
            requestId: Number(id),
            installments: billInstallments,
            subCategoryId: dreSubCategoryId,
            centerCost,
            installmentsCommission,
            centerCostCommission,
            subCategoryIdCommission: dreSubCategoryIdCommission,
            requestObj: response.data,
          });
        }

        const previousCustomerEntity = await CustomerService.getCustomerById(
          beforeSubmitData.customerId
        );
        const newCustomerEntity = await CustomerService.getCustomerById(
          response.data.customerId
        );

        LogService.logEdit({
          itemId: response.data.id,
          itemName: response.data.reference || "Pedido",
          module: "Pedidos",
          oldData: {
            ...beforeSubmitData,
            customer: CustomerService.getCustomerName(
              previousCustomerEntity,
              beforeSubmitData.customerName
            ),
          },
          newData: {
            ...response.data,
            customer: CustomerService.getCustomerName(
              newCustomerEntity,
              response.data.customerName
            ),
          },
          formattedFields: {
            deadLine: (value) =>
              value ? new Date(value).toLocaleDateString() : "",
            requestDate: (value) =>
              value ? new Date(value).toLocaleDateString() : "",
            movedToStock: (value) => getSituationText(value),
            discountMoney: (value) => formatCurrency(value),
            grossWeight: (value) => formatCurrency(value),
            liquidWeight: (value) => formatCurrency(value),
            modalityFreight: (value) => freightModality(value),
          },
          fieldsMap: {
            reference: "Referência",
            carrier: "Transportadora",
            customer: "Cliente",
            deadLine: "Prazo de entrega",
            discountMoney: "Desconto em dinheiro",
            discountPercentage: "Desconto por porcentagem",
            grossWeight: "Peso bruto",
            insideNote: "Observações internas",
            liquidWeight: "Peso líquido",
            modalityFreight: "Modalidade de frete",
            movedToStock: "Movido para Estoque",
            note: "Observações",
            priceList: "Lista de preço",
            requestDate: "Data do pedido",
            requestNumber: "Nº do pedido",
            seller: "Vendedor",
            freight: "Frete",
          },
        });

        history.push("/pedidos");
      } catch (error) {
        console.log(error);
        setMsgError("Ocorreu um erro ao processar a requisição");
        setShowModalAttention(true);
      }
    }
    setIsSubmit(false);
  }

  return (
    <div className="card card-custom gutter-b mt-2 newProductWrapper">
      <ModalLinkedFieldsError
        message="Para lançar em contas é necessário completar o cadastro do Pedido!"
        errors={linkedFieldsErrors}
        showModal={showModalLinkedFieldsError}
        setShowModal={setShowModalLinkedFieldsError}
      />

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <NewProductModal
        showModal={showModalNewProduct}
        setShowModal={setShowModalNewProduct}
        onCreateProduct={handleCreateProduct}
        defaultData={newProductDefaultData}
        allowChangeType={false}
      />

      <Modal
        show={showModalAttention}
        onHide={() => setShowModalAttention(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalAttention(!showModalAttention)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de lançar em contas */}
      <ModalThrowToBills
        isThrowToBillsModalOpen={isThrowToBillsModalOpen}
        setIsThrowToBillsModalOpen={setIsThrowToBillsModalOpen}
        dreCategoryType="revenue"
        dreSubCategoryId={dreSubCategoryId}
        setDreSubCategoryId={setDreSubCategoryId}
        dreSubCategoryIdCommission={dreSubCategoryIdCommission}
        setDreSubCategoryIdCommission={setDreSubCategoryIdCommission}
        setCenterCost={setCenterCost}
        setCenterCostCommission={setCenterCostCommission}
        installments={billInstallments}
        installmentsCommission={installmentsCommission}
        onThrowToBills={() => onSubmit(true)}
        onSetInstallmentBankAccount={handleSetInstallmentBankAccount}
        onSetInstallmentPaymentDate={handleSetInstallmentPaymentDate}
        onSetIsPaid={handleSetIsPaid}
        onSetInstallmentBankAccountCommission={
          handleSetInstallmentBankAccountCommission
        }
        onSetInstallmentPaymentMethodCommission={
          handleSetInstallmentPaymentMethodCommission
        }
        onSetInstallmentPaymentDateCommission={
          handleSetInstallmentPaymentDateCommission
        }
        onSetIsPaidCommission={handleSetIsPaidCommission}
      />

      <div>
        <div className="card-header row m-0 pt-2 pb-0">
          <div className="col-6 m-0 p-0">
            <h4 className="card-label m-0 mt-2">Detalhes do Pedido</h4>
          </div>
          {status === "attended" || user.isAccountant == "y" ? (
            <></>
          ) : (
            <div className="col-lg-6 d-flex justify-content-end mb-3">
              <Button
                className="mr-3"
                variant="primary"
                onClick={() => {
                  setIsSubmit(true);
                  onSubmit();
                }}
                disabled={isSubmit}
              >
                {isSubmit ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />

                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <span>Salvar</span>
                )}
              </Button>

              {status == "open" ? (
                <Button
                  size="lg"
                  variant="secondary"
                  onClick={() => throwToBills()}
                >
                  <span>Lançar em contas</span>
                </Button>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

        <div className="card-body mt-0 pt-0">
          <CustomerLimitCreditAlert
            customerLimitCreditInformation={customerLimitCreditInformation}
          />

          {selectedSellerIsAuthConsignmentSeller && status !== "attended" && (
            <SellerConsignmentStockLocationSelector
              stockLocationId={sellerConsignmentStockLocationId}
              setStockLocationId={setSellerConsignmentStockLocationId}
            />
          )}

          <div className="row">
            <div className="col-lg-6">
              <CustomerSelect
                label="Cliente"
                hasError={customerError}
                isEdit
                customerId={customerId}
                setCustomerId={setCustomerId}
                customerName={customerName}
                setCustomerName={setCustomerName}
                onCustomerChanged={handleCustomerChanged}
                entityId={id}
                entityType="request"
                allowIncomplete={true}
                disabled={
                  status === "attended" || user.isAccountant == "y"
                    ? true
                    : false
                }
                onClickAddCustomer={handleClickAddCustomer}
              />
            </div>

            <div className="col-lg-4">
              <ApiResourceSelect
                label="Vendedor"
                disabled={
                  status === "attended" || user.isAccountant == "y"
                    ? true
                    : false
                }
                getOptionLabel={(option: any) => option.name}
                value={seller}
                onSelect={(option) =>
                  setSeller(option ? String(option.id) : "")
                }
                apiSearchHandler={(typedText) =>
                  SellerService.getSellersFiltered({ name: typedText })
                }
                getSelectedOption={(loadedOptions) => {
                  if (!seller) return null;
                  return (
                    loadedOptions.find(
                      (option) => option.id === Number(seller)
                    ) ?? SellerService.getSellerById(seller)
                  );
                }}
              />
            </div>

            <div className="col-lg-2">
              <TextField
                size="small"
                disabled
                label="Nº pedido"
                margin="normal"
                variant="outlined"
                value={requestNumber}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Tabs
                activeKey={currentTab}
                onSelect={(key: string) => setCurrentTab(key)}
                id="newproduct-form-tabs"
              >
                <Tab eventKey="details" title="Detalhes">
                  <div className="row">
                    <div className="col-lg-2 d-flex align-items-center">
                      <TextField
                        size="small"
                        type="date"
                        label="Data do pedido"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={requestDate}
                        disabled
                      />
                    </div>

                    <div className="col-lg-2">
                      <TextField
                        size="small"
                        type="date"
                        margin="normal"
                        variant="outlined"
                        label="Prazo de Entrega"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={deadLine}
                        onBlur={() => handleSetDeadLine()}
                        onChange={(evt) => setDeadLine(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-8">
                      <TextField
                        size="small"
                        label="Referência"
                        margin="normal"
                        variant="outlined"
                        value={reference}
                        onChange={(evt) => setReference(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-6">
                      <TextField
                        size="small"
                        label="Observações"
                        multiline
                        rows="2"
                        placeholder="Esta informação será impressa nas observações da nota."
                        margin="normal"
                        variant="outlined"
                        value={note}
                        onChange={(evt) => setNote(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-6">
                      <TextField
                        size="small"
                        multiline
                        rows="2"
                        margin="normal"
                        variant="outlined"
                        label="Observações Internas"
                        placeholder="Esta informação é de uso interno, portanto não será impressa na nota."
                        value={insideNote}
                        onChange={(evt) => setInsideNote(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-6 ml-2">
                      <UploadFiles
                        label="Contrato/Anexos"
                        filesSelected={filesSelected}
                        setFilesSelected={setFilesSelected}
                        filesNamesSaved={filesNamesSaved}
                        setFilesNamesSaved={setFilesNamesSaved}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="products" title="Produtos">
                  <div className="row">
                    {products.map((prod, index) => (
                      <ListProducts
                        key={index}
                        product={prod}
                        index={index}
                        isEdit
                        entityId={id}
                        onClickAddProduct={handleClickAddProduct}
                        sellerConsignmentProducts={
                          selectedSellerIsAuthConsignmentSeller &&
                          status !== "attended"
                            ? productsAvailableForConsignment
                            : undefined
                        }
                      />
                    ))}

                    {user.isAccountant == "n" ? (
                      <div className="row col-lg-12 mt-3 ml-2">
                        <button
                          type="button"
                          className="btn btn-link-secondary p-0"
                          onClick={() => dispatchProducts({ type: "ADD" })}
                          disabled={
                            status === "attended" || user.isAccountant == "y"
                              ? true
                              : false
                          }
                        >
                          <ins>+ adicionar outro produto</ins>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="col-lg-6">
                      <ApiResourceSelect
                        label="Lista de preços"
                        getOptionLabel={(option: PriceList) => option.name}
                        value={priceList}
                        onSelect={(option) => handleSetPriceList(option)}
                        apiSearchHandler={(typedText) =>
                          PriceListService.getPriceListsFiltered({
                            name: typedText,
                          })
                        }
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="total-request" title="Totais do Pedido">
                  <div className="row">
                    <div className="col-lg-3">
                      <NumericFormat
                        disabled
                        label="Valor Total dos produtos"
                        value={totalValueProducts}
                        startAdornment="R$"
                      />
                    </div>

                    <div className="col-lg-3">
                      <TextField
                        size="small"
                        label="Valor do IPI"
                        margin="normal"
                        variant="outlined"
                        disabled
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Valor Total do frete"
                        startAdornment="R$"
                        value={freigth}
                        onChange={(evt) => setFreigth(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Desconto"
                        startAdornment="R$"
                        value={discountMoney}
                        onChange={(evt) => setDiscountMoney(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Desconto"
                        startAdornment="%"
                        value={discountPercentage}
                        onChange={(evt) =>
                          setDiscountPercentage(evt.target.value)
                        }
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Peso Bruto"
                        value={grossWeight}
                        onChange={(evt) => setGrossWeight(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-3">
                      <NumericFormat
                        label="Peso Líquido"
                        value={liquidWeight}
                        onChange={(evt) => setLiquidWeight(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-lg-3 ">
                      <NumericFormat
                        disabled
                        label="Valor total"
                        value={totalValue}
                        startAdornment="R$"
                      />
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="payment" title="Pagamento">
                  <div className="row">
                    <div className="col-lg-6">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            disabled={user.isAccountant == "y" ? true : false}
                            color="primary"
                            checked={sellerCommissioning}
                            onChange={(e, checked) =>
                              setSellerCommissioning(checked)
                            }
                            className={classes.textField}
                          />
                        }
                        label="Emitir comissionamento (vendedor)"
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        size="small"
                        required
                        type="number"
                        margin="normal"
                        variant="outlined"
                        label="Quantidade de Parcelas*"
                        value={installmentAmount}
                        onChange={(evt) =>
                          setInstallmentAmount(Number(evt.target.value))
                        }
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                        error={installmentError}
                      />
                    </div>

                    {user.isAccountant == "n" ? (
                      <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <Button
                          variant="primary"
                          size="sm"
                          block
                          onClick={() =>
                            dispatchInstallments({
                              type: "GENERATE",
                              payload: {
                                amount: installmentAmount,
                                value: formatToFloat(totalValue),
                              },
                            })
                          }
                          disabled={status === "attended" ? true : false}
                        >
                          Gerar parcelas
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="row col-lg-12 mt-10">
                      {installments.length > 0 &&
                        installments.map((installment, index) => (
                          <Installment
                            key={index}
                            index={index}
                            installment={installment}
                            installmentAmount={installmentAmount}
                            status={status}
                          />
                        ))}
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="transport" title="Transporte">
                  <div className="row">
                    <div className="col-lg-4">
                      <TextField
                        size="small"
                        select
                        label="Modalidade de Frete"
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                        value={modalityFreight}
                        onChange={(evt) => setModalityFreight(evt.target.value)}
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                      >
                        <MenuItem key="0" value="sender">
                          Remetente
                        </MenuItem>

                        <MenuItem key="1" value="recipient">
                          Destinatário
                        </MenuItem>

                        <MenuItem key="5" value="freeShipping">
                          Sem Frete
                        </MenuItem>
                      </TextField>
                    </div>

                    <div className="col-lg-8">
                      <ApiResourceSelect
                        label="Transportadora"
                        freeSolo
                        disabled={
                          status === "attended" || user.isAccountant == "y"
                            ? true
                            : false
                        }
                        getOptionLabel={(option: Carrier) => option.name}
                        value={carrier}
                        onSelect={(option) =>
                          handleSetCarrier(option ? String(option.name) : "")
                        }
                        onInputChange={(typedText) =>
                          handleSetCarrier(typedText)
                        }
                        apiSearchHandler={(typedText) =>
                          CarrierService.getCarriersFiltered({
                            name: typedText,
                          })
                        }
                        // getSelectedOption={async (loadedOptions) => {
                        //     if(!carrier) return null;

                        //     const loadedCarrier = loadedOptions.find((option) => option.id === Number(carrier));
                        //     if(loadedCarrier) return loadedCarrier;

                        //     const foundCarriers = (await CarrierService.getCarriersFiltered({ name: carrier }));
                        //     return foundCarriers.length > 0 ? foundCarriers[0] : null;
                        // }}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
