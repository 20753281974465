import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SubscriptionPlan } from "../../../types/SubscriptionPlan";
import CompanySubscriptionPlanService from "../../../services/CompanySubscriptionPlanService";
import { CompanySubscriptionPlan } from "../../../types/CompanySubscriptionPlan";
import { CompanySubscriptionPlanPaymentIsRequiredReason } from "../../../types/CompanySubscriptionPlanPaymentIsRequiredReason";
import {
  ExecuteCreateCompanyPlanFunction,
  Step,
  SubscriptionPlanCheckout,
} from "../../../components/SubscriptionPlanCheckout";
import ModalSuccess from "../../../components/ModalSuccess";
import { yesOrNo } from "../../../types/yesOrNo";
import ModalConfirm from "../../../components/ModalConfirm";
import ModalError from "../../../components/ModalError";
import { formatDate } from "../../../utils/dateFormat";

export function PromptPaymentSubscriptionPlan() {
  const { user } = useSelector((state: any) => state.auth);

  const [companyPlan, setCompanyPlan] = useState<CompanySubscriptionPlan>();
  const [reason, setReason] = useState<
    CompanySubscriptionPlanPaymentIsRequiredReason
  >("");

  const [dataLoaded, setDataLoaded] = useState(false);
  const [additionalFilters, setAdditionalFilters] = useState<any>();

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showModalConfirmPlanChange, setShowModalConfirmPlanChange] = useState(
    false
  );
  const [confirmPlanChangeCallback, setConfirmPlanChangeCallback] = useState<
    () => void
  >();
  const [confirmPlanChangeText, setConfirmPlanChangeText] = useState("");

  const isUpgradingPlan = !reason && companyPlan;

  useEffect(() => {
    async function loadData() {
      const companyPaymentRequired = await CompanySubscriptionPlanService.planPaymentIsRequired(
        user.companyId
      );

      const plan = companyPaymentRequired.data;
      //   console.log("plan @@##", plan);

      if (plan && plan.requireToSubscribe === yesOrNo.YES) {
        setAdditionalFilters({ id: plan.planId });
      }

      setCompanyPlan(plan);
      setReason(companyPaymentRequired.reason);
      setDataLoaded(true);
    }

    loadData();
  }, [user]);

  async function handleSubmit(
    executeCreateCompanyPlan: ExecuteCreateCompanyPlanFunction,
    subscriptionPlan: SubscriptionPlan,
    expirationDate: string,
    permissions: number[]
  ) {
    const companySubscriptionPlan = await executeCreateCompanyPlan();
    setShowModalSuccess(true);
    if (
      companySubscriptionPlan.planIdToDowngradeTo &&
      companySubscriptionPlan.downgradeDate
    ) {
      setSuccessMsg(
        `Inscrição realizada com sucesso, os benefícios do novo plano serão aplicados em ${formatDate(
          companySubscriptionPlan.downgradeDate
        )}, ao término do período do seu plano atual!`
      );
    } else if (
      companySubscriptionPlan.planIdToUpgradeTo &&
      companySubscriptionPlan.upgradeDate
    ) {
      setSuccessMsg(
        `Inscrição realizada com sucesso, os benefícios do novo plano serão aplicados em ${formatDate(
          companySubscriptionPlan.upgradeDate
        )}, ao término do período do seu plano atual!`
      );
    } else if (subscriptionPlan.value > 0) {
      setSuccessMsg(
        "Inscrição realizada com sucesso, verifique suas faturas clicando no seu nome no menu superior e então em Faturas"
      );
    } else {
      setSuccessMsg("Inscrição realizada com sucesso!");
    }
  }

  /**
   * Tratamento para quando estiver fazendo upgrade/downgrade no plano da empresa
   */
  async function handleClickNextStep(
    currentStep: Step,
    selectedSubscriptionPlan: SubscriptionPlan | undefined,
    toNextStep: () => void
  ) {
    if (
      !isUpgradingPlan ||
      currentStep !== "selectPlan" ||
      !selectedSubscriptionPlan
    ) {
      toNextStep();
      return;
    }

    // Caso o plano selecionado seja igual ao plano atual
    if (selectedSubscriptionPlan!.id === companyPlan!.planId) {
      setShowModalError(true);
      setErrorMsg("O plano selecionado é igual ao seu plano atual!");
      return;
    }

    if (selectedSubscriptionPlan!.value < companyPlan!.value) {
      // Caso seja um downgrade de plano
      setConfirmPlanChangeText(`
                O plano selecionado (${
                  selectedSubscriptionPlan.name
                }) possui o valor menor que o seu plano atual (${
        companyPlan!.subscriptionPlan.name
      }).
                Ao realizar a troca de plano, você continuará utilizando o seu plano atual até o fim deste período e partir daí você passará a utilizar os benefícios do plano de valor mais baixo.
                Tem certeza que deseja prosseguir?
            `);
    } else {
      // Caso seja um downgrade de plano
      setConfirmPlanChangeText(`
                O plano selecionado (${
                  selectedSubscriptionPlan.name
                }) possui o valor maior que o seu plano atual (${
        companyPlan!.subscriptionPlan.name
      }).
                Ao realizar a troca de plano, você continuará utilizando o seu plano atual até o fim deste período e partir daí você passará a utilizar os benefícios do plano de valor mais alto.
                Tem certeza que deseja prosseguir?
            `);

      // TODO: lidar com upgrade de plano
      // setShowModalError(true);
      // setErrorMsg('Upgrade no plano será implementado em breve...');
      // return;
    }

    setShowModalConfirmPlanChange(true);
    setConfirmPlanChangeCallback(() => () => {
      toNextStep();
      setShowModalConfirmPlanChange(false);
      setConfirmPlanChangeText("");
    });
  }

  return (
    <>
      <ModalError
        msgError={errorMsg}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />
      <ModalSuccess
        msgModal={successMsg}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        redirect="/"
      />
      <ModalConfirm
        showModal={showModalConfirmPlanChange}
        setShowModal={setShowModalConfirmPlanChange}
        confirmText={confirmPlanChangeText}
        onConfirm={confirmPlanChangeCallback ?? (() => {})}
      />
      <div className="card card-body pt-4">
        {reason === "subscriptionPaymentRequired" && (
          <>
            <div className="col-lg-12 mt-3">
              <div className="alert alert-danger">
                Você tem uma fatura com o pagamento atrasado, acesso ao sistema
                ficará bloqueado até que o pagamento seja confirmado.
              </div>
            </div>

            <div className="col-12">
              <Link to="/faturas" className="btn btn-primary">
                Minhas Faturas
              </Link>
            </div>
          </>
        )}

        {dataLoaded && reason !== "subscriptionPaymentRequired" && (
          <>
            <div className="row">
              <div className="col bg-warning-o-30 p-5 mb-3">
                {!!reason && reason !== "planExpired" ? (
                  <h4>
                    Bem-vindo à Milliontech! Para utilizar o sistema, será
                    necessário realizar a sua inscrição em um dos nossos planos.
                  </h4>
                ) : (
                  <>
                    {!!reason ? (
                      <h4>Seu plano expirou. Realize a renovação agora!</h4>
                    ) : (
                      <h4>
                        Você já tem um plano vinculado, mas é possível fazer um
                        upgrade agora mesmo para aproveitar mais benefícios!
                      </h4>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SubscriptionPlanCheckout
                  onSubmit={handleSubmit}
                  onClickNextStep={handleClickNextStep}
                  companyId={user.companyId ?? 0}
                  companyPlan={companyPlan}
                  additionalFilters={additionalFilters}
                  title={
                    additionalFilters?.id
                      ? "O seu cadastro está vinculado com o plano abaixo"
                      : "Selecione uma das opções de plano abaixo"
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
