import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import BankAccountService from "../services/BankAccountService";
import CenterCostService from "../services/CenterCostService";
import DreCategoryService from "../services/DreCategoryService";
import { BankAccount } from "../types/BankAccount";
import { CenterCost } from "../types/CenterCost";
import { SubCategory } from "../types/Dre";
import { ThrowBillInstallment } from "../types/ThrowBillInstallment";
import { formatCurrency } from "../utils/formatCurrency";
import { paymentOptions } from "../utils/paymentOptions";
import ApiResourceSelect from "./ApiResourceSelect";

type ModalThrowToBillsProps = {
  isThrowToBillsModalOpen: boolean;
  setIsThrowToBillsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dreCategoryType: "expense" | "revenue";
  dreSubCategoryId: number;
  setDreSubCategoryId: React.Dispatch<React.SetStateAction<number>>;
  dreSubCategoryIdCommission: number;
  setDreSubCategoryIdCommission: React.Dispatch<React.SetStateAction<number>>;
  setCenterCost: React.Dispatch<React.SetStateAction<string>>;
  setCenterCostCommission: React.Dispatch<React.SetStateAction<string>>;
  installments: ThrowBillInstallment[];
  installmentsCommission: ThrowBillInstallment[];
  onThrowToBills: () => Promise<void>;
  onSetInstallmentBankAccount: (value: any, index: number) => void;
  onSetInstallmentPaymentDate: (value: string, index: number) => void;
  onSetIsPaid: (index: number) => void;
  onSetInstallmentBankAccountCommission: (value: any, index: number) => void;
  onSetInstallmentPaymentMethodCommission: (
    value: string,
    index: number
  ) => void;
  onSetInstallmentPaymentDateCommission: (value: string, index: number) => void;
  onSetIsPaidCommission: (index: number) => void;
};

export function ModalThrowToBills({
  isThrowToBillsModalOpen,
  setIsThrowToBillsModalOpen,
  dreCategoryType,
  dreSubCategoryId,
  setDreSubCategoryId,
  dreSubCategoryIdCommission,
  setDreSubCategoryIdCommission,
  setCenterCost,
  setCenterCostCommission,
  installments,
  installmentsCommission,
  onThrowToBills,
  onSetInstallmentBankAccount,
  onSetInstallmentPaymentDate,
  onSetIsPaid,
  onSetInstallmentBankAccountCommission,
  onSetInstallmentPaymentMethodCommission,
  onSetInstallmentPaymentDateCommission,
  onSetIsPaidCommission,
}: ModalThrowToBillsProps) {
  function validateInputs() {
    let isValid = true;
    let errorMessage = "";

    if (!dreSubCategoryId) {
      alert("Selecione um plano de contas para o lançamento!");
      return false;
    }

    installments.map((value: any, index: number) => {
      if (!value.bankAccount && isValid) {
        errorMessage =
          "Por favor selecione a conta bancária para lançar em contas!";
        isValid = false;
      }
    });

    if (!isValid) {
      alert(errorMessage);
      return false;
    }

    if (installmentsCommission.length > 0) {
      if (!dreSubCategoryIdCommission) {
        alert("Selecione um plano de contas para a comissão!");
        return false;
      }

      installments.map((value, index: number) => {
        if (!value.bankAccount && isValid) {
          errorMessage =
            "Por favor selecione a conta bancária para a comissão!";
          isValid = false;
        }
        if (!value.payment && isValid) {
          errorMessage =
            "Por favor selecione a forma de pagamento para a comissão!";
          isValid = false;
        }
      });

      if (!isValid) {
        alert(errorMessage);
        return false;
      }
    }

    if (isValid) {
      onThrowToBills();
    }
  }

  return (
    <Modal
      centered
      aria-labelledby="contained-modal-warning"
      size="xl"
      show={isThrowToBillsModalOpen}
      onHide={() => setIsThrowToBillsModalOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          Lançar em contas
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="newProductWrapper pt-1">
        <div className="row">
          <div className="col-lg mt-1 d-flex align-items-center justify-content-end">
            <Button
              variant="primary"
              className="mr-2"
              onClick={() => validateInputs()}
            >
              Lançar
            </Button>
            <Button
              variant="secondary"
              onClick={() => setIsThrowToBillsModalOpen(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <h3>Lançamento do registro</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <ApiResourceSelect
              label="Plano de Contas"
              getOptionLabel={(option: SubCategory) => option.name}
              onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
              apiSearchHandler={(typedText) =>
                DreCategoryService.getDreSubCategoriesFiltered(
                  { name: typedText },
                  dreCategoryType
                )
              }
              required
            />
          </div>

          <div className="col-lg-4">
            <ApiResourceSelect
              label="Centro de custos"
              getOptionLabel={(option: CenterCost) => option.name}
              onSelect={(option) => setCenterCost(String(option?.id ?? ""))}
              apiSearchHandler={(typedText) =>
                CenterCostService.getCenterCostsFiltered({ name: typedText })
              }
            />
          </div>
        </div>

        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Forma de pag.</TableCell>
                <TableCell>Conta bancária</TableCell>
                <TableCell>Pago</TableCell>
                <TableCell>Data pag.</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {installments.map((installment, index) => (
                <TableRow key={index}>
                  <TableCell>{installment.date}</TableCell>

                  <TableCell>{formatCurrency(installment.value)}</TableCell>

                  <TableCell>{installment.payment}</TableCell>

                  <TableCell>
                    <ApiResourceSelect
                      label="Conta bancária"
                      required
                      getOptionLabel={(option: BankAccount) =>
                        `${option.nameBank} - ${option.name}`
                      }
                      value={installment.bankAccount}
                      onSelect={(option) =>
                        onSetInstallmentBankAccount(
                          String(option?.id ?? ""),
                          index
                        )
                      }
                      apiSearchHandler={(typedText) =>
                        BankAccountService.getBankAccountsFiltered({
                          name: typedText,
                          situation: "y",
                        })
                      }
                      getSelectedOption={(loadedOptions) => {
                        if (!installment.bankAccount) return null;
                        return (
                          loadedOptions.find(
                            (option) =>
                              option.id === Number(installment.bankAccount)
                          ) ??
                          BankAccountService.getBankAccountById(
                            installment.bankAccount
                          )
                        );
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <FormControlLabel
                      label="Pago"
                      className="ml-1 mt-1"
                      checked={installment.isPaid}
                      onChange={() => onSetIsPaid(index)}
                      control={<Checkbox color="primary" />}
                    />
                  </TableCell>

                  <TableCell>
                    {installment.isPaid ? (
                      <TextField
                        type="date"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={installment.paymentDate}
                        onChange={(evt) =>
                          onSetInstallmentPaymentDate(evt.target.value, index)
                        }
                      />
                    ) : (
                      <TextField
                        disabled
                        type="date"
                        size="small"
                        margin="normal"
                        variant="outlined"
                        value=""
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {installmentsCommission.length > 0 && (
          <>
            <div className="row mt-3">
              <div className="col">
                <h3>Lançamento da Comissão do Vendedor</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <ApiResourceSelect
                  label="Plano de Contas"
                  getOptionLabel={(option: SubCategory) => option.name}
                  value={dreSubCategoryIdCommission}
                  onSelect={(option) =>
                    setDreSubCategoryIdCommission(option?.id ?? 0)
                  }
                  apiSearchHandler={(typedText) =>
                    DreCategoryService.getDreSubCategoriesFiltered(
                      { name: typedText },
                      "expense"
                    )
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!dreSubCategoryIdCommission) return null;
                    return (
                      loadedOptions.find(
                        (option) =>
                          option.id === Number(dreSubCategoryIdCommission)
                      ) ??
                      DreCategoryService.getDreSubCategoryById(
                        dreSubCategoryIdCommission
                      )
                    );
                  }}
                />
              </div>

              <div className="col-lg-4">
                <ApiResourceSelect
                  label="Centro de custos"
                  getOptionLabel={(option: CenterCost) => option.name}
                  onSelect={(option) =>
                    setCenterCostCommission(String(option?.id ?? ""))
                  }
                  apiSearchHandler={(typedText) =>
                    CenterCostService.getCenterCostsFiltered({
                      name: typedText,
                    })
                  }
                />
              </div>
            </div>

            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Forma de pag.</TableCell>
                    <TableCell>Conta bancária</TableCell>
                    <TableCell>Pago</TableCell>
                    <TableCell>Data pag.</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {installmentsCommission.map((installment, index) => (
                    <TableRow key={index}>
                      <TableCell>{installment.date}</TableCell>

                      <TableCell>{formatCurrency(installment.value)}</TableCell>

                      <TableCell>
                        <TextField
                          select
                          label="Forma de Pagamento *"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          value={installment.payment}
                          onChange={(evt) =>
                            onSetInstallmentPaymentMethodCommission(
                              String(evt.target.value ?? ""),
                              index
                            )
                          }
                        >
                          <MenuItem key="0" value="">
                            Selecione
                          </MenuItem>

                          {paymentOptions.map((payment, index) => (
                            <MenuItem key={index + 1} value={payment.value}>
                              {payment.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>

                      <TableCell>
                        <ApiResourceSelect
                          label="Conta bancária"
                          required
                          getOptionLabel={(option: BankAccount) =>
                            `${option.nameBank} - ${option.name}`
                          }
                          value={installment.bankAccount}
                          onSelect={(option) =>
                            onSetInstallmentBankAccountCommission(
                              String(option?.id ?? ""),
                              index
                            )
                          }
                          apiSearchHandler={(typedText) =>
                            BankAccountService.getBankAccountsFiltered({
                              name: typedText,
                              situation: "y",
                            })
                          }
                          getSelectedOption={(loadedOptions) => {
                            if (!installment.bankAccount) return null;
                            return (
                              loadedOptions.find(
                                (option) =>
                                  option.id === Number(installment.bankAccount)
                              ) ??
                              BankAccountService.getBankAccountById(
                                installment.bankAccount
                              )
                            );
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <FormControlLabel
                          label="Pago"
                          className="ml-1 mt-1"
                          checked={installment.isPaid}
                          onChange={() => onSetIsPaidCommission(index)}
                          control={<Checkbox color="primary" />}
                        />
                      </TableCell>

                      <TableCell>
                        {installment.isPaid ? (
                          <TextField
                            type="date"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            value={installment.paymentDate}
                            onChange={(evt) =>
                              onSetInstallmentPaymentDateCommission(
                                evt.target.value,
                                index
                              )
                            }
                          />
                        ) : (
                          <TextField
                            disabled
                            type="date"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            value=""
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
