export function convertProductUnitToEnum(originalUnit: string) {
    if (['PÇ', 'PC'].includes(originalUnit.toUpperCase())) {
        return 'Pç';
    }

    if (['UN', 'PCT', 'CX'].includes(originalUnit.toUpperCase())) {
        return 'Un';
    }

    if (['KG'].includes(originalUnit.toUpperCase())) {
        return 'Kg';
    }

    return 'Un';
}