import React, { forwardRef, useCallback } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import ReactNumberFormat, {
  NumberFormatProps as ReactNumberFormatProps,
} from "react-number-format";
import { formatToFloat } from "../utils/formatCurrency";

interface NumericFormatProps extends ReactNumberFormatProps {
  label: string;
  startAdornment?: string | JSX.Element;
  endAdornment?: string | JSX.Element;
}

export const NumericFormat: React.FC<NumericFormatProps> = forwardRef(
  ({ label, startAdornment, endAdornment, ...props }, ref) => {
    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (target.value === null || target.value === undefined) {
          return;
        }

        if (formatToFloat(target.value) === 0) {
          target.select();
        }
      },
      [props.value]
    );

    return (
      <ReactNumberFormat
        getInputRef={ref}
        className={`${props.className ?? ""} inputSmaller`}
        customInput={TextField}
        variant="outlined"
        margin="normal"
        label={label}
        thousandSeparator={props.thousandSeparator ?? "."}
        decimalSeparator={","}
        allowNegative={props.allowNegative ?? false}
        decimalScale={props.decimalScale ?? 2}
        fixedDecimalScale={true}
        onFocus={handleFocus}
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : (
            undefined
          ),
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : (
            undefined
          ),
        }}
        {...props}
      />
    );
  }
);
