import React from "react";
import ImageUploader from "./ImageUploader";

interface EcommerceProps {
  data: any;
  images: any;
  setImages: any;
}

const Ecommerce: React.FC<EcommerceProps> = (props) => {
  return (
    <div className="col-lg-12">
      <h5>Configurações de Ecommerce</h5>
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <ImageUploader {...props} />
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
