import { CompanySubscriptionPlan } from "../types/CompanySubscriptionPlan";
import { CompanySubscriptionPlanPaymentIsRequiredReason } from "../types/CompanySubscriptionPlanPaymentIsRequiredReason";
import { durationType, SubscriptionPlan } from "../types/SubscriptionPlan";
import { bypassAuthHeader } from "../utils/bypassAuthHeader";
import api from "./Api";

type Params = {
  bypassAuth?: boolean;
};

type PlanPaymentIsRequiredResponse = {
  isRequired: boolean;
  reason: CompanySubscriptionPlanPaymentIsRequiredReason;
  data: CompanySubscriptionPlan;
};

class CompanySubscriptionPlanService {
  async getCurrentActiveCompanySubscriptionPlanByCompanyId(
    companyId: number,
    { bypassAuth }: Params = {}
  ) {
    const response = await api.get<CompanySubscriptionPlan>(
      `/companies/${companyId}/subscriptionPlan/active`,
      {
        headers: bypassAuth ? bypassAuthHeader : undefined,
      }
    );

    return response.data;
  }
  async getCurrentCompanySubscriptionPlanByCompanyId(
    companyId: number,
    { bypassAuth }: Params = {}
  ) {
    const response = await api.get<CompanySubscriptionPlan>(
      `/companies/${companyId}/subscriptionPlan/current`,
      {
        headers: bypassAuth ? bypassAuthHeader : undefined,
      }
    );

    return response.data;
  }

  async planPaymentIsRequired(companyId: number | string) {
    const response = await api.get<PlanPaymentIsRequiredResponse>(
      `/companySubscriptionPlans/paymentRequired/${companyId}`
    );

    return response.data;
  }
}

export default new CompanySubscriptionPlanService();
