import React from "react";
import { Pagination } from "react-bootstrap";
import { useState } from "react";
import CustomSwitchComponent from "../../../DS/Switch";

interface Customer {
  id: string;
  name: string;
}

interface CustomersListProps {
  customersFiltered: Customer[];
  selectedCustomers: string[];
  handleCustomerSelection: (id: string) => void;
}

const CustomersList: React.FC<CustomersListProps> = ({
  customersFiltered,
  selectedCustomers,
  handleCustomerSelection,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 10;
  const visiblePagesLimit: number = 5;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const startIndex: number = (currentPage - 1) * itemsPerPage;
  const selectedCustomersOnPage = customersFiltered.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalPages: number = Math.ceil(customersFiltered.length / itemsPerPage);

  const items: (string | number)[] = [];
  const getPaginationItems = () => {
    const half = Math.floor(visiblePagesLimit / 2);

    if (totalPages <= visiblePagesLimit) {
      for (let page = 1; page <= totalPages; page++) {
        items.push(page);
      }
    } else {
      if (currentPage <= half + 1) {
        for (let page = 1; page <= visiblePagesLimit; page++) {
          items.push(page);
        }
        items.push("...");

        items.push(totalPages);
      } else if (currentPage >= totalPages - half) {
        items.push(1);
        items.push("...");
        for (
          let page = totalPages - visiblePagesLimit + 1;
          page <= totalPages;
          page++
        ) {
          items.push(page);
        }
      } else {
        items.push(1);
        items.push("...");
        for (
          let page = currentPage - half;
          page <= currentPage + half;
          page++
        ) {
          if (!items.includes(page)) {
            items.push(page);
          }
        }
        items.push("...");
        items.push(totalPages);
      }
    }

    return items;
  };

  return (
    <div>
      {selectedCustomersOnPage.map((customer) => (
        <div
          key={customer.id}
          style={{
            maxHeight: "28px",
            margin: "4px",
            marginLeft: "16px",
          }}
        >
          <CustomSwitchComponent
            checked={!!selectedCustomers.find((item) => item === customer.id)}
            id={customer.id}
            onChange={() => handleCustomerSelection(customer.id)}
            name={customer.name}
          />
        </div>
      ))}

      <div style={{ width: "350px", overflow: "hidden" }}>
        <Pagination>
          {getPaginationItems().map((item, index) =>
            item === "..." ? (
              <Pagination.Ellipsis key={`ellipses-${index}`} />
            ) : (
              <Pagination.Item
                key={index}
                active={item === currentPage}
                onClick={() => handlePageChange(Number(item))}
              >
                {item}
              </Pagination.Item>
            )
          )}
        </Pagination>
      </div>
    </div>
  );
};

export default CustomersList;
