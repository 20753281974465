import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Paper, Typography, Modal } from "@material-ui/core";

export function Home() {
  const useStyles = makeStyles((theme) => ({
    mainPaperContainer: {
      overflowY: "auto",
      maxHeight: "calc(100vh - 200px)",
    },
    mainPaper: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    helpPaper: {
      position: "relative",
      width: "100%",
      minHeight: "740px",
      padding: theme.spacing(2),
      boxShadow: theme.shadows[3],
      borderRadius: theme.spacing(1),
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
    },
    paperHover: {
      transition: "transform 0.3s, box-shadow 0.3s",
      "&:hover": {
        transform: "scale(1.03)",
        boxShadow: `0 0 8px`,
        color: theme.palette.primary.main,
      },
    },
    videoThumbnail: {
      width: "100%",
      height: "calc(100% - 56px)",
      objectFit: "cover",
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    },
    videoTitle: {
      marginTop: theme.spacing(1),
      flex: 1,
    },
    videoDescription: {
      maxHeight: "100px",
      overflow: "hidden",
    },
    videoModal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    videoModalContent: {
      outline: "none",
      width: "90%",
      maxWidth: 1024,
      aspectRatio: "16/9",
      position: "relative",
      backgroundColor: "white",
      borderRadius: theme.spacing(1),
      overflow: "hidden",
    },
  }));
  const classes = useStyles();
  const [selectedVideo, setSelectedVideo] = useState(null);

  const openVideoModal = (video: any) => {
    setSelectedVideo(video);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  const handleModalClose = useCallback((event) => {
    if (event.key === "Escape") {
      closeVideoModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleModalClose);
    return () => {
      document.removeEventListener("keydown", handleModalClose);
    };
  }, [handleModalClose]);

  const renderVideoModal = () => {
    return (
      <Modal
        open={Boolean(selectedVideo)}
        onClose={closeVideoModal}
        className={classes.videoModal}
      >
        <div className={classes.videoModalContent}>
          {selectedVideo && (
            <iframe
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              src={`https://www.youtube.com/embed/${selectedVideo}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Paper
        className={`${classes.helpPaper} ${classes.paperHover}`}
        onClick={() => openVideoModal("u_EFjjv5aDQ")}
      >
        <img
          src={`/media/primeirospassos.png`}
          alt="Thumbnail"
          className={classes.videoThumbnail}
        />
        <Typography variant="button" className={classes.videoTitle}>
          {"Comece por aqui"}
        </Typography>
        <Typography variant="caption" className={classes.videoDescription}>
          {"Primeiros passos"}
        </Typography>
      </Paper>
      {renderVideoModal()}
    </>
  );
}
