import React from "react";
import { Route, Switch } from "react-router-dom";
import { Home as HomePage } from "../pages/Home";
import { Help } from "./Help";
import { ListHelp } from "../pages/Help/ListHelp";

export function Home() {
  return (
    <Switch>
      <Route path="/Home" component={HomePage} />
    </Switch>
  );
}
