import React from "react";
import { Form } from "react-bootstrap";

interface CustomSwitchProps
  extends React.ComponentPropsWithoutRef<typeof Form.Check> {}

const CustomSwitchComponent = ({ ...props }: CustomSwitchProps) => {
  return <Form.Check type="switch" {...props} label={props.name} custom />;
};

export default CustomSwitchComponent;
